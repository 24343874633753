import { createStyles } from '@mantine/core';

export const useStyles = createStyles(
  (
    theme,
    {
      floating,
      focus,
      disabled,
      size,
      error,
      top,
      fontSize,
    }: {
      floating: boolean;
      focus: boolean;
      disabled: boolean;
      size: number;
      fontSize: number;
      error?: string;
      top: number;
    }
  ) => ({
    root: {
      position: 'relative',
      flex: 1,
      backgroundColor: 'transparent',
    },
    input: {
      height: `${size}px`,
      minHeight: `${size}px`,
      padding: `${size / 4}px 16px 0px 0px`,
      fontSize: `${fontSize}px`,
      '&::placeholder': {
        transition: 'color 150ms ease',
        color: !floating ? 'transparent' : undefined,
      },
      backgroundColor: 'transparent',
    },
    label: {
      position: 'absolute',
      zIndex: 2,
      top: top,
      left: 0,
      pointerEvents: 'none',
      transition: 'transform 150ms ease, color 150ms ease',
      transformOrigin: 'top left',
      transform: floating ? `translateY(-130%) scale(0.75)` : 'translateY(-50%)',
      fontSize: '14px',
      fontWeight: floating ? 500 : 400,
      color: floating ? 'inherit' : theme.colors.gray[5],
    },
    textarea: {
      width: '100%',
      height: '100%',
      paddingBottom: 16,
      paddingTop: 8,
      scrollPaddingBottom: 16,
      border: 'none',
      borderRadius: theme.radius.md,
      lineHeight: 1.2,
      gridArea: '1 / 1 / 2 / 3',
      ':focus': {
        border: 'none',
      },
    },
    textareaLabel: {
      paddingBottom: '8px',
      pointerEvents: 'none',
      fontSize: '14px',
      fontWeight: floating ? 500 : 400,
    },
    textareaWrapper: {
      resize: 'none',
      position: 'relative',
      paddingTop: 24,
      border: error
        ? `1px solid ${theme.colors.red[5]}`
        : focus
        ? `1px solid ${theme.colors.blue[6]}`
        : `1px solid ${theme.colors.gray[4]}`,
      transition: 'border-color 0.2s',
      borderRadius: theme.radius.sm,
      overflow: 'hidden',
      backgroundColor: disabled ? theme.colors.gray[1] : 'transparent',
    },
    textareaInputWrapper: {
      display: 'grid',
      gridTemplate: '1fr / 1fr',
    },
    icon: {
      fill: '#000'
    }
  })
);
