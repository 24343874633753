import { MaybePromise } from '@/types/common/common';

import { AppDispatch } from '@/store';
import { suppliesApi } from '@/store/slices/supplies/slice';

export interface MenuItem {
  /**
   * Optional identifier that used with preNavigate
   */
  id?: string;

  /**
   * Menu title that will be rendered on page
   */
  name: string;

  /**
   * URL to navigate to
   */
  href: string;

  /**
   * Sync or async function that may return boolean value to indicate
   * whether to navigate to `href` or not
   * @returns {boolean}
   */
  preNavigate?: (dispatch: AppDispatch) => MaybePromise<boolean | void>;

  /**
   * Nested menu items
   */
  children?: MenuItem[];
}

export const MAIN_MENU: MenuItem[] = [
  {
    name: 'Аналитика',
    href: '/main',
  },
  {
    name: 'Товары и цены',
    href: '#',
    children: [
      {
        name: 'Товары и цены',
        href: '/products',
      },
      {
        name: 'Документы на товар',
        href: '/docs',
      },
    ],
  },
  {
    id: 'supplies',
    name: 'Поставки',
    href: '/supplies/list',
    preNavigate: async (dispatch) => {
      const { error } = await dispatch(
        suppliesApi.endpoints.getTabs.initiate(undefined, { forceRefetch: true })
      );
      return !error;
    },
  },
  {
    name: 'Отчеты и документы',
    href: '/documents',
  },
];
