import { keyframes, MantineTheme, NotificationStylesParams, Styles } from '@mantine/core';
import { NotificationProps, showNotification } from '@mantine/notifications';
import { Check, CircleX } from 'tabler-icons-react';

type NotifyType = {
  message: string;
  type?: 'info' | 'success' | 'warning' | 'error' | 'default' | 'dark';
  params?: Partial<NotificationProps>;
};

type NotificationStyles = Styles<
  'closeButton' | 'icon' | 'root' | 'body' | 'loader' | 'title' | 'description',
  NotificationStylesParams
>;

const getNotificationStyles =
  (variant: NotifyType['type']): NotificationStyles =>
  (theme: MantineTheme) => {
    const timerAnimation = keyframes({
      from: {
        backgroundPosition: '100%',
      },
      to: {
        backgroundPosition: '0%',
      },
    });

    let mainColor: string;
    let secColor: string;

    switch (variant) {
      case 'success':
        mainColor = theme.colors.brandGreen[4];
        secColor = theme.colors.brandGreen[0];
        break;
      case 'error':
        mainColor = theme.colors.brandOrange[5];
        secColor = theme.colors.brandOrange[0];
        break;
      default:
        mainColor = 'white';
        secColor = theme.primaryColor[6];
    }

    return {
      root: {
        position: 'relative',
        backgroundColor: mainColor,
        color: 'white',
        borderRadius: 0,
        ':after': {
          position: 'absolute',
          bottom: 0,
          left: 0,
          content: '""',
          width: '100%',
          height: 4,
          backgroundSize: '200%',
          backgroundImage: `linear-gradient(90deg, ${secColor} 50%, ${mainColor} 50%)`,
          animation: `${timerAnimation} 4s ease-in-out`,
        },
      },
      icon: {
        alignSelf: 'flex-start',
        backgroundColor: 'transparent',
      },
      title: {
        color: 'white',
        fontWeight: 700,
        fontSize: '1rem',
        marginBottom: 4,
      },
      description: {
        color: 'white',
      },
      closeButton: {
        color: 'white',
      },
    };
  };

const getNotificationIcon = (type: NotifyType['type']) => {
  switch (type) {
    case 'success':
      return <Check />;
    case 'error':
      return <CircleX strokeWidth={2} />;
    default:
      return null;
  }
};

export default function notify({ message, type = 'default', params }: NotifyType) {
  const config: NotificationProps = {
    autoClose: 4000,
    message,
    styles: getNotificationStyles(type),
    icon: getNotificationIcon(type),
    withCloseButton: false,
    ...params,
  };

  switch (type) {
    case 'info':
      return showNotification({ ...config, color: 'blue' });
    case 'success':
      return showNotification({
        ...config,
      });
    case 'warning':
      return showNotification({ ...config, color: 'blue' });
    case 'error':
      return showNotification({ ...config, color: 'red' });
    case 'dark':
      return showNotification({ ...config, color: 'black' });
    default:
      return showNotification({ ...config });
  }
}
