import { IProductTab, IProudctField } from '@/types/entites/product';

import { Box, Flex, Group, Text, Title } from '@mantine/core';
import { Image } from '@mantine/core';

import { colors } from '@/utils/theme';

import Head from '@/ui/pages/ProfilePage/components/Head/Head';

import { TabsCodeType } from '../../types';
import { useProductContext } from '../../utils/ProductContext';

import { useStyles } from './styles';

import previewImageStub from '@/assets/images/previewImage.jpg';

const skipFields = ['supplierProductId', 'name', 'price', 'discount_price'];

const ProductPreview = () => {
  const { data, fieldValues } = useProductContext();
  const { classes } = useStyles();

  const renderField = (fieldCode: string, fieldName: string) => {
    const fieldValue = fieldValues[fieldCode];

    return (
      <>
        {fieldValue && (
          <Box key={fieldCode} mb={16}>
            <Text className={classes.fieldTitle}>{fieldName}</Text>
            <Text className={fieldCode === 'name' ? classes.fieldNameValue : classes.fieldValue}>
              {fieldValue === 'true' ? 'Да' : fieldValue}
            </Text>
          </Box>
        )}
      </>
    );
  };

  const renderFields = (fields: IProudctField[], missFields: string[] = []) => {
    return fields?.map((field) => {
      if (missFields.includes(field.code)) return null;
      return renderField(field.code, field.title);
    });
  };

  const renderTabs = () => {
    return data?.tabs.map((tab: IProductTab) => {
      if (tab.code === TabsCodeType.IMAGES) return;
      return (
        <Box key={tab.code} mb={32}>
          <Title order={4} mb={32}>
            {tab.title}
          </Title>
          {renderFields(tab.fields, skipFields)}
        </Box>
      );
    });
  };

  const findObjectByFieldCode = (jsonString: any) => {
    try {
      if (!jsonString) {
        return null;
      }
      const JSONdata = JSON.parse(jsonString);
      const foundItem = JSONdata.find((item: any) => item.fieldCode === 'products_images_1');
      return foundItem ? foundItem.imageUrl : null;
    } catch (error) {
      console.error('Ошибка при разборе JSON:', error);
      return null;
    }
  };

  return (
    <>
      <Head title="Предварительный просмотр" />
      <Group spacing={40} className={classes.previewWrapper}>
        {findObjectByFieldCode(localStorage.getItem('uploadedImages')) ? (
          <Flex className={classes.previewImageContainer}>
            <Image
              width={220}
              height={220}
              src={findObjectByFieldCode(localStorage.getItem('uploadedImages'))}
            />
          </Flex>
        ) : (
          <Flex
            className={classes.previewImageContainer}
            style={{ border: `1px solid ${colors.brandGrey[4]}` }}
          >
            <Image width={30} height={30} src={previewImageStub} />
          </Flex>
        )}

        <Box p={4}>
          {renderField('supplierProductId', '')}
          {renderField('name', '')}
          {renderField('price', 'Цена, ₽')}
          {renderField('discount_price', 'Цена со скидкой, ₽')}
          {renderTabs()}
        </Box>
      </Group>
    </>
  );
};

export default ProductPreview;
