import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme, { nameEllipsis, border }: { nameEllipsis: boolean, border: boolean }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px 0px',
    ...(border && { borderBottom: `1px solid ${theme.colors.brandGrey[3]}` }),
    '@media (max-width: 1000px)': {
      flexWrap: 'wrap'
    },
  },
  name: {
    maxWidth: nameEllipsis ? '470px' : 'auto',
    whiteSpace: nameEllipsis ? 'nowrap' : 'pre-wrap',
    overflow: nameEllipsis ? 'hidden' : 'visible',
    textOverflow: nameEllipsis ? 'ellipsis' : 'initial',
    fontSize: '16px',
    color: theme.colors.brandDark[9],
    lineHeight: 1.2
  },
  uploadProgress: {
    fontSize: '16px',
    maxWidth: '300px',
    margin: '0px 0px 8px',
  },
  info: {
    marginLeft: '22px',
  },
  inner: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    fontSize: '14px',
    fontWeight: 700,
    letterSpacing: '-0.01em',
    color: theme.colors.mainColor[6],
    display: 'flex',
    alignItems: 'center',
    lineHeight: 1,
  },
  buttonRemove: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.brandGrey[5],
  },
  buttonFileName: {
    marginTop: '-3px'
  },
  iconWrapper: {
    position: 'relative',
  },
  icon: {
    marginLeft: '8px',
  },
  iconRepeat: {
    marginLeft: '6px',
  },
  iconEdit: {
    position: 'relative',
    marginLeft: '8px',
    bottom: '2px',
  },
  IconCross: {
    width: 'auto',
    height: '20px',
    position: 'relative',
    marginLeft: '6px',
    bottom: '2px',
    left: '2px',
  },
  iconClose: {
    fill: theme.colors.mainColor[6],
  },
  required: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    flexShrink: 1,
    marginLeft: '5px',
    background: `${theme.colors.red[8]}`
  },
  buttons: {
    display: 'flex',
    alignItems: 'flex-end',
    flex: '1 0 auto'
  },
  buttonDelete: {
    color: theme.colors.brandGrey[5],
    margin: '10px 0px 0px',
  },
  buttonChange: {
    color: theme.colors.mainColor[6],
    margin: '0px 0px 10px',
  },
  buttonCanceled: {
    color: theme.colors.brandGrey[5],
  },
  errorCirlce: {
    position: 'absolute',
    right: '-10px',
    top: '-3px',
  },
  description: {
    whiteSpace: 'pre-line',
  },
}));
