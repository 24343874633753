import { createStyles, getStylesRef } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  tabsList: {
    borderBottomWidth: 1,
    paddingLeft: 8,
  },
  tooltip: {},
  staticTooltip: {
    marginLeft: 16,
    borderRadius: 8,
    border: `1px solid ${theme.colors.brandGrey[3]}`,
    lineHeight: 1.4,
    maxWidth: 490,
    padding: 16,
  },
  icon: {
    color: theme.colors.gray[5],
  },
  text: {
    lineHeight: 1.4,
  },
  tab: {
    [`&[data-active] .${getStylesRef('supplies__count')}`]: {
      backgroundColor: 'white',
      color: theme.colors[theme.primaryColor][6],
      fontWeight: 600,
    },
  },
  count: {
    fontSize: 14,
    fontFamily: 'monospace',
    ref: getStylesRef('supplies__count'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 22,
    height: 22,
    borderRadius: '50%',
    backgroundColor: 'rgba(0,0,0, 0.2)',
    color: 'white',
  },
}));
