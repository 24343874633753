

import { IDocumentGenerateBody, IDocumentGenerateParams, IDocumentGenerateRes, IDocumentItemsListParams, IDocumentItemsListRes, IDocumentStatusParams, IDocumentStatusRes, IDocumentTypesRes } from "@/types/api/documents";

import api from "../instance";
import { tryAction } from "../tryAction";
import { documentsUrls } from "../urls/documentsUrls";

export const tryGetDocumentTypes = () =>
    tryAction(
        api.get<IDocumentTypesRes>(documentsUrls.getDocumentTypes)
    );

export const tryGetDocumentItemsList = (
    params: IDocumentItemsListParams,
) =>
    tryAction(
        api.get<IDocumentItemsListRes>(documentsUrls.getDocumentsList, { params })
    );

export const tryGetDocumentGenerate = (
    params: IDocumentGenerateParams,
    body: IDocumentGenerateBody,
) =>
    tryAction(
        api.post<IDocumentGenerateRes>(documentsUrls.postDocumentGenerate, body, { params })
    )

export const tryGetDocumentStatus = (
    params: IDocumentStatusParams,
) =>{
  return  tryAction(
        api.get<IDocumentStatusRes>(documentsUrls.getDocumentStatus, { params })
    )
}
