import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Button, Flex, Text } from '@mantine/core';
import { modals } from '@mantine/modals';

import { openChooseCategory, openChooseFilter, openLoadingError } from '../Modals/modals';

import { useStyles } from './styles';

import { ReactComponent as CLoseIcon } from '@/assets/icons/close.svg';
import { AppDispatch } from '@/store';
import { categoriesApi } from '@/store/slices/category/api';
import { selectCurrentCategory } from '@/store/slices/category/selectors';
import {
  setActiveCategories,
  setCategoriesCurrentTree,
  setCurrentCategory,
  setSelectedCategory,
} from '@/store/slices/category/slice';
import { selectProductsLimit } from '@/store/slices/products/selectors';
import { deleteFilterProduct } from '@/store/slices/search/slice';

const SelectCategory: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const currentCategory = useSelector(selectCurrentCategory);
  const [getCategoriesTree, { data: tree, isError: error }] =
    categoriesApi.useLazyGetCategoriesTreeQuery();
  const productsLimit = useSelector(selectProductsLimit);
  const [searchParams, setSearchParams] = useSearchParams();
  const { classes } = useStyles();
  const getParam = (paramName: string) =>
    searchParams.get(paramName) ? String(searchParams.get(paramName)) : null;
  const statusNameCategories = getParam('nameCategories');
  const hasEmptyAttributes = getParam('has_empty_attributes');
  const urlParams = [hasEmptyAttributes];
  const searchParamsCount = urlParams.filter((i) => i !== null).length;
  const status = getParam('status')

  const params = useMemo(
    () => ({
      page: '1',
      limit: productsLimit.toString(),
      offset: '0',
      ...(status && { status: status }),
    }),
    [searchParams]
  );

  const hanlderRemove = (e: Event) => {
    e.stopPropagation();
    dispatch(setCategoriesCurrentTree(tree ? [{ tree }] : []));
    dispatch(setCurrentCategory(null));
    dispatch(setSelectedCategory(null));
    dispatch(setActiveCategories([]));
    dispatch(deleteFilterProduct('categories'));
    dispatch(deleteFilterProduct('nameCategories'))
    setSearchParams(params);
  };

  useEffect(() => {
    if (error) {
      modals.closeAll();
      openLoadingError({ id: 'loadingError' });
    }
  }, [error]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentCategory(null));
      dispatch(setSelectedCategory(null));
      dispatch(setActiveCategories([]));
      dispatch(deleteFilterProduct('categories'));
    };
  }, []);

  return (
    <>
      <Button
        variant="outline"
        classNames={{ root: classes.button }}
        onClick={() => {
          getCategoriesTree();
          openChooseCategory({ id: 'chooseCategory' });
        }}
      >
        {statusNameCategories || currentCategory?.code ? (
          <Flex align="center">
            <Text className={classes.categoryLabel}>
              {statusNameCategories ?? currentCategory?.label}
            </Text>
            <CLoseIcon onClick={(e: Event) => hanlderRemove(e)} className={classes.icon} />
          </Flex>
        ) : (
          'Категория'
        )}
      </Button>
      <Button
        variant="outline"
        classNames={{ root: classes.button }}
        onClick={() => {
          openChooseFilter({ id: 'chooseFilter' });
        }}
      >
        Фильтры
        {searchParamsCount !== 0 && (
          <Flex align="center" justify="center" className={classes.filter}>
            {searchParamsCount}
          </Flex>
        )}
      </Button>
    </>
  );
};

export default SelectCategory;
