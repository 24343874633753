import { ISummaryOrdersStatsItem } from "@/types/api/summaryOrders";

import { IChartDataItem } from "../SummaryOrdersPage";

import { PERIOD_DATE } from "./constants";

export const buildDataForChart = (
    items: ISummaryOrdersStatsItem[],
    currentData: string
): IChartDataItem[] => {
    const lastDays: string[] = [];
    const startDate = new Date(currentData);
    startDate.setDate(startDate.getDate() - (PERIOD_DATE - 1));

    for (let i = 0; i < PERIOD_DATE; i++) {
        const date = new Date(startDate);
        date.setDate(date.getDate() + i);
        const formattedDate = date.toISOString().split('T')[0];
        lastDays.push(formattedDate);
    }
    const sortedItems = [...items];

    sortedItems.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA.getTime() - dateB.getTime();
    });

    const data: IChartDataItem[] = lastDays.map(dateString => {
        const item = sortedItems.find(item1 => item1.date === dateString);
        const soldSum = item ? item.info.sold.sum : 0;
        const purchasedSum = item ? item.info.purchased.sum : 0;
        const soldCount = item ? item.info.sold.count : 0;
        const purchasedCount = item ? item.info.purchased.count : 0;

        return {
            date: dateString,
            soldSum,
            purchasedSum,
            soldCount,
            purchasedCount
        };
    });

    return data;
};