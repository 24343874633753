import { IInfoItem, IInfoRes } from '@/types/api/instructions';

import { useEffect, useMemo, useState } from 'react';
import { Box, Grid, LoadingOverlay, Stack } from '@mantine/core';
import filterXSS from 'xss';

import { MenuItem } from './components/MenuItem/MenuItem';
import { isFlatItem } from './utils/helpers';
import { MenuContext } from './utils/MenuContext';
import { useStyles } from './styles';

import { instructionsApi } from '@/store/slices/instructions/api';

export const InfoPage = () => {
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [activeInfo, setActiveInfo] = useState<IInfoRes>();
  const { classes } = useStyles();
  const {
    data: info,
    isFetching
  } = instructionsApi.useGetInstuctionsInfoQuery();

  const getChildren = useMemo(() => (items: IInfoItem[]): [string, string][] =>
    items.map((el) => [el.title, el.id]), []);

  const menu = useMemo(() => {
    return info?.length && info.reduce((acc: any, el: IInfoRes) => {
      if (el.items) {
        acc.push([el.title, getChildren(el.items)]);
      } else {
        acc.push([el.title, el.id]);
      }
      return acc;
    }, [] as [string, string | [string, string[]]][]);
  }, [info])

  const handleSelect = (tabCode: string) => {
    if (!info?.length) return;
    let foundItem: IInfoItem | undefined;

    if (typeof window !== 'undefined') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    const findById = (items: IInfoItem[], tabId: string): IInfoItem | undefined => {
      return items.find((elItem) => elItem.id === tabId) || undefined;
    };

    for (let element of info) {
      if (element.items) {
        const foundChild = findById(element.items, tabCode);
        if (foundChild) foundItem = foundChild;
      } else if (element.id === tabCode) foundItem = element;
    }

    if (foundItem) setActiveInfo(foundItem);
    setActiveTab(tabCode);
  };

  useEffect(() => {
    if (info && info?.length > 0) {
      setActiveInfo(info[0]);
      setActiveTab(info[0].id);
    }
  }, [info])

  return (
    <>
      <LoadingOverlay visible={isFetching} zIndex={100} />
      <Grid columns={15} gutter={0}>
        <Grid.Col span={3} className={classes.aside}>
          <MenuContext.Provider value={{ active: activeTab || '' }}>
            <Stack role="tablist" spacing={0} className={classes.menu}>
              {menu?.map((item: any) =>
                isFlatItem(item) ? (
                  <MenuItem title={item[0]} id={item[1]} key={item[1]} onSelect={handleSelect} />
                ) : (
                  <MenuItem title={item[0]} key={item[0]} inner={item[1]} onSelect={handleSelect} />
                )
              )}
            </Stack>
          </MenuContext.Provider>
        </Grid.Col>
        <Grid.Col span={12}>
          <Box className={classes.panel}
            dangerouslySetInnerHTML={{
              __html: filterXSS(activeInfo?.content),
            }}
          />
        </Grid.Col>
      </Grid >
    </>
  );
};
