import { ChangeEventHandler, ComponentProps, FC } from 'react';

import { onPastePhoneNormalizer } from '@/utils/normalizePhoneNumber';

import { EnhancedInput } from '@/ui/atoms/EnhancedInput/EnhancedInput';

import { MaskedInput } from '../MaskedInput/MaskedInput';

interface PhoneInputProps extends Omit<ComponentProps<typeof EnhancedInput>, 'onChange'> {
  unmask?: boolean;
  onChange?: (value: string) => void;
}

const unmaskPhoneString = (phoneString: string) => phoneString.replace(/[^0-9]/g, '');

export const PhoneInput: FC<PhoneInputProps> = ({ onChange, unmask, ...props }) => {
  const changeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = unmask ? unmaskPhoneString(e.currentTarget.value) : e.currentTarget.value;
    onChange?.(value);
  };
  const { tooltipText, clearable, ...maskedInputProps } = props;

  return (
    <MaskedInput
      inputMode={'tel'}
      mask={'+7 (999) 999-99-99'}
      autoComplete="off"
      maskChar=""
      data-tooltip-text={tooltipText}
      clearable={clearable?.toString()}
      {...maskedInputProps}
      onChange={changeHandler}
      beforeMaskedValueChange={onPastePhoneNormalizer}
    />
  );
};
