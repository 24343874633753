import { createStyles } from '@mantine/core';
import { ReactComponent as ArrowBlack } from '@/assets/icons/chevron_bottom.svg';

export const useStyles = createStyles((theme) => ({
  item: {
    position: 'relative',
    paddingRight: '20px',
    '&[data-selected]': {
      '&': {
        backgroundColor: 'transparent',
        color: 'black',
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        color: 'black',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        right: '8px',
        width: '8px',
        height: '12px',
        border: 'solid black',
        borderWidth: '0 2px 2px 0',
        transform: 'translateY(-50%) rotate(45deg)',
        backgroundImage: `url(${ArrowBlack})`,
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
      color: 'black',
    },
    '&:nth-child(2n)': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  dropdown: {
    backgroundColor: theme.colors.brandGrey[9],
  },
  label: {
    '.mantine-Select-label': {
      fontSize: '16px',
      marginBottom: '8px',
      fontWeight: 600,
      lineHeight: '19px',
    },
  },
}));
