import { ID } from '@/types/common/common';
import { IOperations, TOperationsCodes } from '@/types/entites/template';

import { useEffect } from 'react';

import useWebSocketWithLocalStorage from '@/hooks/useWebSocketWithLocalStorage';

import { isClient, isOperationMessage } from './common';

const LOCALSTORAGE_KEY = 'operations';

const createOperationMessage = (payload: IOperations) =>
  ({
    type: 'operations',
    payload,
  } as const);

const dispatchOperation = (op: IOperations) => {
  if (!isClient) return;
  window.postMessage(createOperationMessage(op));
};

const saveOperaton = (op: IOperations) => {
  const currentOperations = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY) || '[]');
  localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify([...currentOperations, op]));
};

export const useOperations = () => {
  useWebSocketWithLocalStorage<IOperations>(
    process.env.WEB_SOCKET_URL || '',
    LOCALSTORAGE_KEY,
    (op) => {
      saveOperaton(op);
      dispatchOperation(op);
    }
  );
};

interface UseOperationsArgs {
  id?: ID;
  code?: TOperationsCodes;
  skip?: boolean;
  onOperationChange?: (op: IOperations) => void;
}

export const useOperation = ({ id, onOperationChange, code, skip }: UseOperationsArgs) => {
  const updateOperations = (opId: ID) => {
    const currentOperations: IOperations[] = JSON.parse(
      localStorage.getItem(LOCALSTORAGE_KEY) || '[]'
    );
    const updatedOperations = currentOperations.filter((op) => op.id !== opId);

    setTimeout(() => localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(updatedOperations)), 10);
  };

  const processOperation = (operation: IOperations) => {
    if (skip) return;
    if (code && operation.code !== code) return;
    if (id && operation.id !== id) return;

    updateOperations(operation.id);
    onOperationChange?.(operation);
  };

  const handlePostMessage = (message: any) => {
    if (!isOperationMessage(message)) return;
    processOperation(message.data.payload);
  };

  useEffect(() => {
    const unhandledOperations: IOperations[] = JSON.parse(
      localStorage.getItem(LOCALSTORAGE_KEY) || '[]'
    );
    if (unhandledOperations.length) unhandledOperations.forEach((op) => processOperation(op));
    window.addEventListener('message', handlePostMessage);
    return () => window.removeEventListener('message', handlePostMessage);
  }, [id, onOperationChange, code]);
};
