import {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Stack, Text, UnstyledButton } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import debounce from 'debounce';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';

import { profileBuilderConfig } from '../../utils/formBuilderHelpers';

import { useStyles } from './styles';

import { profileApi } from '@/store/slices/profile/slice';

export type SuggestionsProps = {
  code: string;
  value: unknown;
  touched: boolean;
  onSubmit?: (res: Record<string, string>) => void;
};

type Suggestion = {
  mainLabel: string;
  additional?: {
    label: string;
    value: string;
  }[];
};

type SuggestionItemProps = {
  substring: string;
  item: Suggestion;
  onSubmit: () => void;
};

const getRegex = (substr: string) =>
  new RegExp(substr.trim().replace(/([^a-zа-яё0-9])/g, '\\$1'), 'gi');
const SuggestionItem = ({ item, substring, onSubmit }: SuggestionItemProps) => {
  const { classes } = useStyles();
  const regex = useMemo(() => getRegex(substring), [substring]);
  const highlight = useCallback(
    (str: string) => {
      if (!str) return str;

      const matches = [...str.matchAll(regex)];
      const res: ReactNode[] = [];

      matches.reduce((lastPos, match, matchIndex, arr) => {
        const substr = match[0];
        const matchStart = match.index || 0;
        const matchEnd = matchStart + substr.length;
        const isLast = matchIndex === arr.length - 1;
        res.push(str.slice(lastPos, matchStart));
        res.push(<span className={classes.highlight}>{substr}</span>);
        if (isLast) res.push(str.slice(matchEnd));
        return matchStart + matchEnd;
      }, 0);

      return res.length ? res : str;
    },
    [regex, substring]
  );

  return (
    <UnstyledButton className={classes.item} onClick={onSubmit}>
      <Text className={classes.label} truncate>
        {highlight(item.mainLabel)}
      </Text>
      <Text className={classes.additinalLabel} truncate>
        {item.additional?.map(({ label, value: info }, i, arr) => (
          <>
            {label}: {highlight(info)}
            {i === arr.length - 1 ? '' : ', '}
          </>
        ))}{' '}
      </Text>
    </UnstyledButton>
  );
};

export const Suggestions: FC<PropsWithChildren<SuggestionsProps>> = ({
  code,
  value,
  touched,
  children,
  onSubmit,
}) => {
  const [trigger, { data, isFetching, isSuccess }] = profileApi.useLazyGetSuggestionsQuery();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const config = useMemo(() => profileBuilderConfig.suggestionsConfig[code], [code]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { classes } = useStyles();
  const ref = useClickOutside(() => setShowSuggestions(false));

  const getSuggestions = useCallback(
    debounce((searchValue: string) => {
      if (!isString(value)) return;
      const params = {
        endpoint: config.endpoint,
        params: config.transformArgs(searchValue.trim()),
      };
      trigger(params);
    }, 1000),
    [config]
  );

  const dropdownItems = useMemo(() => {
    return data ? data.slice(0, 6) : [];
  }, [data, config]);

  const handleSubmit = (item: Record<string, string>) => {
    setShowSuggestions(false);
    if (!onSubmit) return;
    onSubmit(config.transformResult(item));
  };

  useEffect(() => {
    setShowSuggestions(false);
    if (!isString(value) && !isNumber(value)) return;
    if (!value.toString().trim() || !profileBuilderConfig.suggestionsConfig[code] || !touched)
      return;
    getSuggestions(value.toString());
  }, [value]);

  useEffect(() => {
    if (!isString(value) && !isNumber(value)) return;
    if (!value.toString().trim() || isFetching || !isSuccess) return;
    if (data && data.length) setShowSuggestions(true);
  }, [isFetching]);

  return (
    <Box ref={wrapperRef} className={classes.wrapper}>
      {children}
      {showSuggestions && isString(value) && (
        <Stack spacing={8} className={classes.dropdown} ref={ref}>
          {dropdownItems.map((item) => (
            <SuggestionItem
              item={config.transformLabel(item)}
              substring={value}
              onSubmit={() => {
                handleSubmit(item);
              }}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
};
