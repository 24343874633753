import { Tabs } from '@mantine/core';

import { AgreementList } from '../AgreementList/AgreementList';
import { CreatedList } from '../CreatedList/CreatedList';
import { FinishedList } from '../FinishedList/FinishedList';

export const SuppliesPanels = () => (
  <>
    <Tabs.Panel value="created">
      <CreatedList />
    </Tabs.Panel>
    <Tabs.Panel value="agreement">
      <AgreementList />
    </Tabs.Panel>
    <Tabs.Panel value="finished">
      <FinishedList />
    </Tabs.Panel>
  </>
);
