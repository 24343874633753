import { createStyles, getStylesRef } from '@mantine/core';

interface SelectStyleProps {
  size: number;
}

export const useStyles = createStyles((theme, { size }: SelectStyleProps) => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
    maxWidth: '300px',
    fontSize: size,
    borderBottom: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: 0,
    padding: 0,
    height: '3rem',
    ':focus-within': {
      borderColor: theme.colors[theme.primaryColor][6],
    },
    [`&[data-value]:not([data-value=""]) .${getStylesRef('select__label')}`]: {
      top: '0.2em',
      transform: 'translateY(0) scale(0.8)',
      color: theme.colors.gray[6],
    },
    zIndex: 100,
  },
  valueDisplay: {
    content: '""',
    flex: '1 0 0',
    position: 'relative',
    pointerEvents: 'none',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingTop: '0.6rem',
    whiteSpace: 'nowrap',
  },
  overlay: {
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    padding: '0.5em',
    paddingLeft: 0,
    height: '3em',
    transition: '0.2s',
    pointerEvents: 'none',
    width: '100%',
  },
  label: {
    ref: getStylesRef('select__label'),
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    paddingLeft: 'inherit',
    color: theme.colors.gray[5],
    pointerEvents: 'none',
    transformOrigin: 'top left',
  },
  input: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    ref: getStylesRef('select__input'),
    outline: 'none',
    border: 'none',
    height: '100%',
    paddingTop: '0.6rem',
    '&:not(:disabled)': {
      cursor: 'pointer',
    },
    opacity: 0,
  },
  rightSection: {
    display: 'flex',
    alignItems: 'center',
    padding: 'inherit',
    flexWrap: 'nowrap',
  },
  clear: {
    pointerEvents: 'auto',
  },
  check: {
    ref: getStylesRef('select__check'),
    color: 'black',
    pointerEvents: 'none',
    height: '1em',
  },
  dropdown: {
    position: 'absolute',
    bottom: '-0.25rem',
    width: '100%',
    transform: 'translateY(100%)',
    background: 'white',
    // boxShadow: theme.shadows.md,
    backgroundColor: theme.colors.brandGrey[9]
  },
  dropdownList: {
    padding: '0 8px',
  },
  dropdownItem: {
    padding: '0.6em',
    borderRadius: theme.radius.sm,
    color: theme.colors.brandDark[9],
    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}));
