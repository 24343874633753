import { FC, useMemo, useRef, useState } from 'react';
import {
  Box,
  BoxProps,
  FileButton,
  Group,
  Stack,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { Text } from '@mantine/core';

import { fileFilterBuilder, FileRejectionType, getStringFromFileSize } from '@/utils/file';

import { useStyles } from './styles';

import { ReactComponent as FileIcon } from '@/assets/icons/fileIcon.svg';
import { ReactComponent as IconCross } from '@/assets/icons/redesign/iconCross.svg';
import { ReactComponent as IconEdit } from '@/assets/icons/redesign/iconEdit.svg';
import { ReactComponent as IconUpload } from '@/assets/icons/redesign/iconUpload.svg';


interface FileInputProps extends BoxProps {
  ext?: string | string[];
  type?: string | string[];
  size?: number;
  file?: File;
  onChange?: (payload: File | null) => void;
  required?: boolean;
  title?: string;
  fixedTitle?: boolean;
  description?: string;
  customErrors?: Partial<Record<FileRejectionType, string>>;
  uploadProgress?: number | null;
  error?: string | null;
  isUploading?: boolean;
  disabled?: boolean;
  direction?: 'row' | 'column';
  onAbort?: () => void;
}

const DEFAULT_ERROR_TEXTS: Record<FileRejectionType, string> = {
  'file-too-large': 'Превышен максимальный размер файла',
  'invalid-extension': 'Некорретный тип файла',
  'size-limit-exceeded': 'Превышен общий максимальный размер файлов',
  'too-many-files': 'Слишком много файлов',
};

export const FileInput: FC<FileInputProps> = ({
  ext,
  type,
  size,
  file,
  fixedTitle,
  title,
  description,
  customErrors,
  required,
  onChange,
  uploadProgress,
  error,
  disabled,
  isUploading,
  onAbort,
  direction = 'column',
  ...boxProps
}) => {
  const [innerFile, setInnerFile] = useState<File | null>(file || null);
  const [err, setErr] = useState<string | null>(null);
  const resetRef = useRef<() => void>(null);
  const { colors } = useMantineTheme();
  const { classes } = useStyles();

  const errorTexts = useMemo(() => ({ ...DEFAULT_ERROR_TEXTS, ...customErrors }), [customErrors]);

  const displayError = useMemo(() => err || error, [err, error]);

  const filter = useMemo(() => {
    const builder = fileFilterBuilder();

    if (ext) builder.byExt(ext);
    if (type) builder.byType(type);
    if (size) builder.bySize(size);

    return builder.getFilter();
  }, [ext, type, size]);

  const [currentName, currentExt] = useMemo(() => {
    if (!innerFile) return ['', ''];
    const splittedName = innerFile?.name.split('.');
    const extenstion = splittedName.pop();
    const name = splittedName.join('.');

    return [name, extenstion];
  }, [innerFile]);

  const handleFileSelect = (newFile: File | null) => {
    if (!newFile) return;
    const [accepted, errors] = filter([newFile]);
    if (errors.length) setErr(errorTexts[errors[0].error]);
    else {
      setErr(null);
      setInnerFile(accepted[0]);
      onChange?.(accepted[0]);
    }
  };

  const handleReset = () => {
    resetRef.current?.();
    setErr(null);
    setInnerFile(null);
    onChange?.(null);
  };

  const showFileInfo = useMemo(() => innerFile && !uploadProgress, [innerFile, uploadProgress]);
  const showDescription = useMemo(() => !innerFile && !uploadProgress, [innerFile, uploadProgress]);
  const displayProgress = useMemo(
    () => isUploading && Number.isFinite(uploadProgress),
    [uploadProgress]
  );

  return (
    <Box {...boxProps}>
      <Group position="left" spacing={12} noWrap>
        <Box sx={{ flex: '0 0 60px' }}>
          <FileIcon fill={colors.brandDark[9]} />
        </Box>

        <Stack py={6} miw={0} spacing={8} sx={{ flex: '1 0 0' }} w={0}>
          <Box
            className={classes.title}
            data-required={required && !innerFile}
            w="fit-content"
            maw="100%"
          >
            <Text truncate miw={0}>
              {innerFile && !fixedTitle ? currentName : title}
            </Text>
          </Box>
          {!displayError && (
            <Text size={12} color={colors.text[5]}>
              {showFileInfo && (
                <>
                  {currentExt?.toUpperCase()} &middot; {getStringFromFileSize(innerFile?.size || 0)}
                </>
              )}
              {showDescription && description}
              {displayProgress && `Загрузка ${uploadProgress}%`}
            </Text>
          )}
          {displayError && (
            <Text size={12} color={colors.red[5]}>
              {displayError}
            </Text>
          )}
        </Stack>
        {!disabled && (
          <Stack
            spacing={12}
            align={direction === 'column' ? 'flex-end' : 'center'}
            style={{ flexDirection: direction }}
          >
            {!isUploading && (
              <FileButton
                onChange={handleFileSelect}
                resetRef={resetRef}
                accept={Array.isArray(type) ? type.join(',') : type}
              >
                {(props) => (
                  <UnstyledButton {...props} c={colors.mainColor[6]} style={{ flex: '1 0 auto' }}>
                    <Group spacing={4} align="center" noWrap>
                      <Text size={14} weight={700}>
                        {innerFile ? 'Изменить' : 'Загрузить файл'}
                      </Text>
                      {innerFile ? (
                        <IconEdit size={24} fill={colors.mainColor[6]} />
                      ) : (
                        <IconUpload fill={colors.mainColor[6]} />
                      )}
                    </Group>
                  </UnstyledButton>
                )}
              </FileButton>
            )}
            {innerFile && !isUploading && (
              <UnstyledButton onClick={handleReset}>
                <Group spacing={4} c={colors.gray[6]} align='center' noWrap>
                  <Text size={14} weight={700}>
                    Удалить
                  </Text>
                  <IconCross style={{ width: '20px', height: '20px' }} />
                </Group>
              </UnstyledButton>
            )}
            {isUploading && (
              <UnstyledButton onClick={onAbort}>
                <Group spacing={4} noWrap>
                  <Text color={colors.brandGrey[5]} size={14} weight={700}>
                    Отменить загрузку
                  </Text>
                  <IconCross style={{ width: '20px', height: '20px' }} />
                </Group>
              </UnstyledButton>
            )}
          </Stack>
        )}
      </Group>
    </Box>
  );
};
