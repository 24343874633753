import PageContent from '@/ui/organisms/Page/components/PageContent/PageContent';
import PageHeader from '@/ui/organisms/Page/components/PageHeader/PageHeader';
import Page from '@/ui/organisms/Page/Page';
import { SuppliesPage } from '@/ui/pages/SuppliesPage/SuppliesPage';

export const Supplies = () => (
  <Page>
    <PageHeader title="Заявки на поставку" />
    <PageContent>
      <SuppliesPage />
    </PageContent>
  </Page>
);
