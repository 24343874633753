import { IFilterProduct, ISearchProduct } from '@/types/api/product';
import { TSearchQuery } from '@/types/entites/search';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

interface ISearchState {
  status: {
    isSuggestsFetching: boolean;
  };
  search: {
    search: ISearchProduct | null;
    filter: Record<string, IFilterProduct>;
  };
  query: TSearchQuery;
  sort: string;
  order: string;
}

const initialState: ISearchState = {
  status: {
    isSuggestsFetching: false,
  },
  search: {
    search: null,
    filter: {},
  },
  query: '',
  sort: '',
  order: '',
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchQuery: (state, action: PayloadAction<TSearchQuery>) => {
      state.query = action.payload;
    },
    setSearchProducts: (state, action: PayloadAction<ISearchProduct | null>) => {
      state.search.search = action.payload;
    },
    setFilterProducts: (state, action: PayloadAction<IFilterProduct>) => {
      const { fieldName, fieldValue } = action.payload;
      if (!fieldName) return;

      if (isNull(fieldValue) || isUndefined(fieldValue)) delete state.search.filter[fieldName];
      else state.search.filter[fieldName] = action.payload;
    },
    deleteFilterProduct: (state, action: PayloadAction<string>) => {
      delete state.search.filter[action.payload];
    },
  },
});

export const { setSearchQuery, setSearchProducts, setFilterProducts, deleteFilterProduct } =
  searchSlice.actions;

export default searchSlice.reducer;
