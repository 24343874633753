import { FC, useState } from 'react';
import { ActionIcon, clsx, Loader, Popover, Stack, UnstyledButton } from '@mantine/core';
import { DotsVertical } from 'tabler-icons-react';

import { downloadBlob } from '@/utils/downloadBlob';
import notify from '@/utils/notify';

import { useStyles } from './styles';

import { suppliesApi } from '@/store/slices/supplies/slice';

interface SupplyActionButtonProps {
  id: string;
  onRefetch?: () => void;
  cancellable?: boolean;
}

export const SupplyActionButton: FC<SupplyActionButtonProps> = ({ id, onRefetch, cancellable }) => {
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles();
  const [getProductsList, { isFetching: isDownloading }] = suppliesApi.useLazyGetProductsQuery();
  const [cancelSupply, { isLoading: isCancelling }] = suppliesApi.useCancelSupplyMutation();

  const handleGetProducts = async () => {
    setOpened(false);
    try {
      const res = await getProductsList(id).unwrap();
      downloadBlob(res.file, res.name);
    } catch (e) {}
  };

  const handleCancel = async () => {
    setOpened(false);
    try {
      const { message } = await cancelSupply(id).unwrap();
      notify({ message, type: 'success' });
      onRefetch?.();
    } catch (e) {}
  };

  return (
    <Popover opened={opened} onChange={setOpened} withinPortal position="left-start">
      <Popover.Target>
        <ActionIcon onClick={() => setOpened(!opened)}>
          {isDownloading || isCancelling ? <Loader /> : <DotsVertical />}
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown className={classes.wrapper}>
        <Stack spacing={4}>
          <UnstyledButton
            variant="subtle"
            onClick={handleGetProducts}
            className={clsx(classes.button, classes.downloadButton)}
          >
            Скачать товары
          </UnstyledButton>
          {cancellable && (
            <UnstyledButton
              variant="subtle"
              onClick={handleCancel}
              className={clsx(classes.button)}
            >
              Отменить
            </UnstyledButton>
          )}
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};
