import { useEffect } from 'react';

interface UseExternalScriptOptions {
  src: string;
  strategy?: 'sync' | 'async' | 'defer';
}

interface UseInlineScriptOptions {
  script: string;
}

function isExternalScript(
  opts: UseExternalScriptOptions | UseInlineScriptOptions
): opts is UseExternalScriptOptions {
  const unknownOpts = opts as any;
  return Boolean(unknownOpts.src) || Boolean(!unknownOpts.script);
}

export function useScript(options: UseExternalScriptOptions, dev?: boolean): void;
export function useScript(options: UseInlineScriptOptions, dev?: boolean): void;
export function useScript(options: UseExternalScriptOptions | UseInlineScriptOptions, dev = true) {
  useEffect(() => {
    if (!dev && process.env.NODE_ENV === 'development') return;

    const script = document.createElement('script');

    if (isExternalScript(options)) {
      const { src, strategy } = options;
      script.src = src;
      if (strategy === 'async') script.async = true;
      if (strategy === 'defer') script.defer = true;
      document.head.append(script);
    } else {
      const { script: inlineScript } = options;
      script.innerText = inlineScript;
      document.body.append(script);
    }

    return () => {
      script.remove();
    };
  }, []);
}
