


import { IChangePasswordByEmailConfirm, IChangePasswordByEmailRequest, IChangePasswordConfirmRequest, IChangePasswordProfileRequest, IChangePasswordRequest } from '@/types/api/auth';
import { IToken } from '@/types/common/common';

import { axiosErrorHandler } from '@/utils/axiosErrorHandler';

import api from '../instance';
import { tryAction } from '../tryAction';
import { usersUrls } from '../urls/usersUrls';

export const tryChangePassword = (data: IChangePasswordRequest) => {
  const { recaptchaToken, phone } = data;
  return tryAction(
    api.post(
      usersUrls.changePassword.changePassword,
      { phone },
      { headers: { recaptchaToken, isSellerProfile: true } },
    )
  );
};

export const tryChangePasswordConfirm = (data: IChangePasswordConfirmRequest) => {
  const { recaptchaToken, ...send } = data;
  return tryAction(
    api.patch<{ token: IToken }>(usersUrls.changePassword.changePasswordConfirm, send, {
      headers: { recaptchaToken, isSellerProfile: true },
    })
  );
};

export const tryChangePasswordByEmail = (data: IChangePasswordByEmailRequest) => {
  const { recaptchaToken, emailSellerLogin } = data;
  return tryAction(
    api.post(
      usersUrls.changePassword.changePasswordByEmail,
      { emailSellerLogin },
      { headers: { recaptchaToken, isSellerProfile: true } },
    )
  );
};

export const tryChangePasswordByEmailConfirm = (data: IChangePasswordByEmailConfirm) => {
  const { recaptchaToken, ...send } = data;
  return tryAction(
    api.patch<{ token: IToken }>(
      usersUrls.changePassword.changePasswordByEmailConfirm,
      send,
      { headers: { recaptchaToken, isSellerProfile: true } },
    )
  );
};

export const tryChangePasswordProfile = (data: IChangePasswordProfileRequest) =>
  api.patch<{ token: IToken }>(usersUrls.changePassword.changePasswordProfile, data)
    .then((res) => ({ data: res.data, status: res.status }))
    .catch(axiosErrorHandler);
