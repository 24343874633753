import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  aside: {
    position: 'relative',
    paddingTop: 20,
    borderRight: `1px solid ${theme.colors.gray[3]}`,
    '@media (max-width:1250px)': {
      flexDirection: 'column',
      width: '100%',
      maxWidth: '100%'
    },
  },
  content: {
    '@media (max-width:1250px)': {
      flexDirection: 'column',
      width: '100%',
      maxWidth: '100%'
    },
  },
  panel: {
    lineHeight: '24px',
    padding: '24px 48px',
    '& li + li': { marginTop: 8 },
    'li li': { marginTop: 0 },
    pre: {
      backgroundColor: theme.colors.gray[3],
      maxWidth: '100%',
      overflowX: 'scroll',
      padding: 16,
      margin: '16px 0',
    },
  },
  menu: {
    position: 'sticky',
    top: 20,
  },
}));
