import { TFile } from '@/types/api/profile';

import { FC, useMemo, useState } from 'react';
import { Box, Flex, Group, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { Dropzone, FileWithPath, PDF_MIME_TYPE } from '@mantine/dropzone';
import { AxiosProgressEvent } from 'axios';
import noop from 'lodash/noop';
import uniqueId from 'lodash/uniqueId';

import { NOOP } from '@/constants/common';

import DocumentInput from '@/ui/atoms/DocumentInput/DocumentInput';

import { IFileInputProps, TInputState } from '../../types';
import {
  ERR_PREFIX_SOME,
  ERROR_MSG,
  ERROR_SIZE_EXCEEDED,
  ERROR_SIZE_EXCEEDED_ALL,
  errorTextsByCode,
  FileRejection,
  mapMultipleValuesToFilesMap,
  mapValuesToInputStates,
  MAX_ADDITIONAL_FILES_SIZE,
  removeFile,
  sizeUnits,
  uploadFile,
} from '../../utils/files';

import { ReactComponent as IconAttach } from '@/assets/icons/redesign/iconUpload.svg';
import { useAppDispatch } from '@/store';
import { setModalSameFilesNames } from '@/store/slices/modal/slice';

type TUploadFileProps = IFileInputProps & { value: TFile[]; onChange: (v: TFile[]) => void };

const UploadFiles: FC<TUploadFileProps> = ({
  value,
  onChange = NOOP,
  description,
  label,
  type,
  confirmed,
}) => {
  const theme = useMantineTheme();
  const dispatch = useAppDispatch();
  const [inputStates, setInputStates] = useState<Record<string, TInputState>>(
    mapValuesToInputStates(value)
  );
  const [controllers, setControllers] = useState<Record<string, AbortController>>({});
  const [error, setError] = useState<string | null>(null);
  const filesMap = useMemo(() => mapMultipleValuesToFilesMap(value), [value]);
  const [loadingFilesSizes, setLoadingFilesSize] = useState(0);
  const placeholders = useMemo(() => {
    const fileIds = value.map((file) => file.id);
    return Object.values(inputStates).filter((state) => !fileIds.includes(state.id));
  }, [inputStates]);
  const totalSize = useMemo(() => {
    return (
      loadingFilesSizes +
      value.reduce((acc, { size }) => {
        const [quantity, units] = size.split(' ');
        const sizeInBytes = Number(quantity) * sizeUnits[units];
        return acc + sizeInBytes;
      }, 0)
    );
  }, [loadingFilesSizes, value]);

  const changeInputState = (id: string, state: Partial<TInputState>) => {
    setInputStates((states) => ({ ...states, [id]: { ...states[id], ...state, id } }));
  };

  const removeInputState = (id: string) => {
    setInputStates((states) => {
      delete states[id];
      return { ...states };
    });
  };

  const handleProgress = (e: AxiosProgressEvent, id: string) => {
    if (!e.total) return;
    const newProgress = Math.round((e.loaded / e.total) * 100);
    changeInputState(id, { uploadProgress: newProgress });
  };

  const handleCancel = (id: string) => {
    if (controllers[id]) controllers[id].abort();
  };

  const handleRemove = async (id: string) => {
    changeInputState(id, { removing: true });
    await removeFile(id);
    removeInputState(id);
    onChange(value.filter((file) => file.id !== id));
  };

  const loadFile = async (file: FileWithPath, fileId?: string) => {
    const id = fileId || `placeholder-${uniqueId('placeholder')}`;

    changeInputState(id, { id, uploadFailed: false, uploading: true, label: file.name });

    try {
      const { request, controller } = uploadFile(
        type || '',
        file,
        (e) => handleProgress(e, id),
        fileId
      );
      setControllers((ctrls) => ({ ...ctrls, [id]: controller }));
      const {
        data: { values },
      } = await request;
      removeInputState(id);
      onChange(values);
    } catch (catchError: any) {
      const errorMessage = 'Вы пытаетесь загрузить документы с одинаковыми названиями.';
      if (catchError.response && catchError.response.data.message.includes(errorMessage)) {
        dispatch(setModalSameFilesNames({ id: 'true', fileName: file.name }));
        setError(
          'Документ с таким именем уже существует. Пожалуйста, измените название и попробуйте снова.'
        );
      } else {
        setError(ERROR_MSG);
      }
      changeInputState(id, {
        uploadFailed: true,
        uploading: false,
        error: ERROR_MSG,
        disabled: true,
      });
    }

    setLoadingFilesSize((size) => Math.max(0, size - file.size));
  };

  const getLoadableFiles = (files: FileWithPath[]) => {
    let size = totalSize;
    let sizeExceeded = false;
    let payloadSize = 0;

    const filesToLoad: FileWithPath[] = [];

    files.forEach((file) => {
      if (file.size + size < MAX_ADDITIONAL_FILES_SIZE) {
        filesToLoad.push(file);
        size += file.size;
        payloadSize += file.size;
      } else sizeExceeded = true;
    });

    if (sizeExceeded) setError(filesToLoad.length ? ERROR_SIZE_EXCEEDED : ERROR_SIZE_EXCEEDED_ALL);
    setLoadingFilesSize(payloadSize);

    return filesToLoad;
  };

  const processFiles = (files: FileWithPath[]) => {
    const filesToLoad = getLoadableFiles(files);

    filesToLoad.forEach((file) => {
      loadFile(file);
    });
  };

  const handleFileChange = (file: FileWithPath, id: string) => {
    const [processedFile] = getLoadableFiles([file]);
    if (!processedFile) return;

    loadFile(processedFile, id);
  };

  const handleDropAny = (files: FileWithPath[], fileRejections: any) => {
    // FileRejections isn't exported from @mantine/dropzone, typing similar type leads to TS errors
    setError(null);

    if (fileRejections.length) {
      let errorString = '';

      fileRejections
        .reverse()
        .forEach(
          (rejection: FileRejection) => (errorString = errorTextsByCode[rejection.errors[0].code])
        );

      const prefix = files.length ? ERR_PREFIX_SOME : '';

      setError(prefix + errorString);
    }

    if (!files.length) return;

    processFiles(files);
  };

  const showDropZone = useMemo(() => value.length < 10, [value]);

  const getState = (id: string): TInputState => {
    const state = inputStates[id];
    const file = filesMap[id];

    return {
      id: file?.id || state?.id,
      label: state?.label || file?.originalName,
      value: filesMap[id],
      uploading: state?.uploading,
      removing: state?.removing,
      error: state?.error,
      changeable: Boolean(file),
      removeable: true,
      uploadProgress: state?.uploadProgress,
      onUploadCancel: () => {
        handleCancel(id);
      },
      onRemove: () => {
        handleRemove(id);
      },
      onUpload: file ? handleFileChange : noop,
    };
  };

  return (
    <Box pt={48}>
      <Text weight={700} mb={16}>
        {label}
      </Text>
      {!confirmed && (
        <Text fz={14} lh={'20px'} color={theme.colors.text[5]} weight={400} mb={32}>
          {description}
        </Text>
      )}

      {value.map((file) => (
        <DocumentInput disabled={confirmed} extraFile {...getState(file.id)} key={file.id} />
      ))}
      {placeholders.map((state) => (
        <DocumentInput
          disabled={confirmed}
          extraFile
          {...state}
          {...getState(state.id)}
          key={state.id}
        />
      ))}
      {!confirmed && showDropZone && (
        <Dropzone
          accept={PDF_MIME_TYPE}
          maxSize={10000000}
          onDrop={NOOP}
          onDropAny={handleDropAny}
          multiple
        >
          <Flex
            direction="column"
            justify="center"
            align="center"
            mih={164}
            sx={{ pointerEvents: 'none' }}
          >
            <Text weight={700} size={14}>
              Перетащите файлы в эту область или выберите на компьютере
            </Text>
            <Text color={theme.colors.text[5]} size={14} mt={8}>
              Суммарный размер файлов не должен превышать 10 МБ
            </Text>
            <UnstyledButton mt={24} c={theme.colors[theme.primaryColor][7]}>
              <Group spacing={8}>
                <Text color={theme.colors.mainColor[6]}>Загрузить файл</Text>
                <IconAttach width={18} />
              </Group>
            </UnstyledButton>
            {error && (
              <Text mt={32} color={theme.colors.red[5]} size={14}>
                {error}
              </Text>
            )}
          </Flex>
        </Dropzone>
      )}
    </Box>
  );
};

export default UploadFiles;
