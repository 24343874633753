import { FC } from "react"
import { useMantineTheme } from "@mantine/core"
import { Text } from "@mantine/core"

import Cell from "../Cell/Cell"
import Row from "../Row/Row"

interface IRowEmpty {
    text: string
}

const RowEmpty: FC<IRowEmpty> = ({ text }) => {
    const { colors } = useMantineTheme();
    return (
        <Row>
            <Cell colspan={100}>
                <Text sx={{ display: 'flex', justifyContent: 'center' }} color={colors.brandGrey[8]}>
                    {text}
                </Text>
            </Cell>
        </Row>
    )
}

export default RowEmpty