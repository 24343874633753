import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Tabs } from '@mantine/core';

import { STATUSES_WITH_TOPBAR } from '@/constants/documents';

import DocumentsHead from '../DocumentsHead/DocumentsHead';
import DocumentsList from '../DocumentsList/DocumentsList';

import { documentsApi } from '@/store/slices/documents/api';
import { selectDocumentsLimit } from '@/store/slices/documents/selectors';

interface IDocumentsPanelsProps {
  currentTab: string;
}

const DocumentsPanels: FC<IDocumentsPanelsProps> = ({ currentTab }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [concat, setConcat] = useState(false);
  const documentsLimit = useSelector(selectDocumentsLimit);
  const { data: types } = documentsApi.useGetDocumentTypesQuery();
  const { tab } = useParams();
  const {
    data: documents,
    isSuccess,
    isFetching: isDocumentsFetching,
    refetch: refetchDocuments,
  } = documentsApi.useGetDocumentsItemsQuery({
    documentType: String(tab),
    limit: documentsLimit,
    offset: (currentPage - 1) * documentsLimit,
    concat,
  });

  return (
    <Box>
      {STATUSES_WITH_TOPBAR.includes(String(tab)) && (
        <DocumentsHead refetchDocuments={refetchDocuments} />
      )}
      <Box>
        {types?.items?.map((type) => (
          <Tabs.Panel value={type.code} key={type.code}>
            {type.code === currentTab && (
              <DocumentsList
                documents={documents}
                setCurrentPage={setCurrentPage}
                setConcat={setConcat}
                currentPage={currentPage}
                isSuccess={isSuccess}
                isDocumentsFetching={isDocumentsFetching}
              />
            )}
          </Tabs.Panel>
        ))}
      </Box>
    </Box>
  );
};

export default DocumentsPanels;
