import {
  IAuthByEmailRequest,
  IAuthByPhoneRequest,
  ICheckEmailRequest,
  ICheckEmailResponse,
  ICheckPhoneRequest,
  ICheckPhoneResponse,
} from '@/types/api/auth';
import { IToken } from '@/types/common/common';

import api from '../instance';
import { tryAction } from '../tryAction';
import { usersUrls } from '../urls/usersUrls';

export const apiAuthByRefresh = (refreshToken: string) =>
  tryAction(
    api.post<{ token: IToken }>(
      usersUrls.signIn.authByRefresh,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }
    )
  );

export const tryCheckPhone = (data: ICheckPhoneRequest) => {
  const { recaptchaToken, ...send } = data;
  return tryAction(
    api.post<ICheckPhoneResponse>(usersUrls.signIn.checkPhone, send, {
      headers: {
        recaptchaToken,
      },
    })
  );
};

export const tryCheckEmail = (data: ICheckEmailRequest) => {
  const { recaptchaToken, ...send } = data;
  return tryAction(
    api.post<ICheckEmailResponse>(usersUrls.signIn.checkEmail, send, {
      headers: {
        recaptchaToken,
      },
    })
  );
};

export const tryCheckSellerEmailLogin = (data: Pick<ICheckEmailRequest, 'email'>) => {
  return tryAction(api.post<ICheckEmailResponse>(usersUrls.signIn.checkSellerEmailLogin, data));
};

export const tryAuthByPhone = (data: IAuthByPhoneRequest) => {
  const { recaptchaToken, ...send } = data;
  return tryAction(
    api.post<{ token: IToken }>(usersUrls.signIn.authByPhone, send, {
      headers: {
        recaptchaToken,
      },
    })
  );
};

export const tryAuthByEmail = (data: IAuthByEmailRequest) => {
  const { recaptchaToken, ...send } = data;
  return tryAction(
    api.post<{ token: IToken }>(usersUrls.signIn.authByEmail, send, {
      headers: {
        recaptchaToken,
      },
    })
  );
};
