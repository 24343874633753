import { FC, useMemo } from 'react';
import { Loader, Stack, UnstyledButton, useMantineTheme } from '@mantine/core';
import { Text } from '@mantine/core';

import { downloadBlob } from '@/utils/downloadBlob';
import { pluralize } from '@/utils/pluralize';

import { openSupplyPackages } from '@/ui/organisms/Modals/modals';
import Cell from '@/ui/organisms/TableCustom/components/Cell/Cell';

import { suppliesApi } from '@/store/slices/supplies/slice';

interface SupplyPackagesCellProps {
  canSetPackages?: boolean;
  id: string;
  packages: number;
  onRefetch?: () => void;
  boxesFolderId?: string;
}

export const SupplyPackagesCell: FC<SupplyPackagesCellProps> = ({
  canSetPackages,
  id,
  packages,
  onRefetch,
  boxesFolderId,
}) => {
  const { colors } = useMantineTheme();
  const [getDocs, { isFetching }] = suppliesApi.useLazyGetDocumentsQuery();
  const isBoxFolder = useMemo(() => boxesFolderId && Boolean(packages), [boxesFolderId, packages]);

  const handleDownloadDoc = async () => {
    if (!boxesFolderId) return;
    try {
      const { file, name } = await getDocs({ id, folderId: boxesFolderId }).unwrap();
      downloadBlob(file, name);
    } catch (e) { }
  };


  return (
    <Cell>
      <Stack spacing={8}>
        {isBoxFolder ?
          <UnstyledButton
            onClick={() => openSupplyPackages({ id: id, packages, onSubmit: onRefetch })}
          >
            <Text size={14}>
              Изменить
            </Text>
          </UnstyledButton>
          :
          <UnstyledButton
            disabled={!canSetPackages}
            sx={{ color: !canSetPackages ? `${colors.brandGrey[6]}` : '' }}
            onClick={() => openSupplyPackages({ id: id, packages, onSubmit: onRefetch })}
          >
            {!Boolean(packages) &&
              <Text size={14}>
                Указать
              </Text>
            }
          </UnstyledButton>
        }
        {Boolean(packages) && (
          <Text size={12} color={colors.gray[7]}>
            {packages} {pluralize(packages, ['грузовое место', 'грузовых места', 'грузовых мест'])}
          </Text>
        )}
        {boxesFolderId && (
          <UnstyledButton onClick={handleDownloadDoc}>
            <Text size={14} sx={{ whiteSpace: 'nowrap' }}>
              {isFetching ? <Loader size={12} /> : 'Скачать ярлыки'}
            </Text>
          </UnstyledButton>
        )}
      </Stack>
    </Cell>
  );
};
