import dayjs from "dayjs";

import { FORMAT_DATE } from "./constants";

export const getformattedDate = (
    dateValue: [Date | null, Date | null] | Date | null
): { startDate: string; endDate: string } => {
    let startDate: string = '';
    let endDate: string = '';

    if (Array.isArray(dateValue) && dateValue.length > 0) {
        [startDate, endDate] = dateValue.map((date) =>
            date ? dayjs(date).format(FORMAT_DATE) : ''
        );
    } else if (dateValue instanceof Date) {
        const formattedDate = dayjs(dateValue).format(FORMAT_DATE);
        startDate = formattedDate;
        endDate = formattedDate;
    }

    return { startDate, endDate };
};