import { FC, KeyboardEvent, ReactNode, useMemo, useRef, useState } from 'react';
import { ActionIcon, Box, Group } from '@mantine/core';
import {
  DateInput,
  DateInputProps,
  DatePickerInput,
  DatePickerInputProps,
  DatePickerType,
  DatePickerValue,
  DatesProvider,
  DateValue,
} from '@mantine/dates';
import isArray from 'lodash/isArray';
import omit from 'lodash/omit';
import { X } from 'tabler-icons-react';

import { dateParser } from './utils/dateParser';
import { useStyles } from './styles';

import { ReactComponent as IconCalendar } from '@/assets/icons/redesign/iconCalendar.svg';

type PolymorphicDateValue = Date | Date[] | [Date | null, Date | null] | null;

interface RightSectionProps {
  showClearButton: boolean;
  onClear?: () => void;
}

const RightSection: FC<RightSectionProps> = ({ showClearButton, onClear }) => {
  return (
    <Group>
      {showClearButton && (
        <ActionIcon sx={{ pointerEvents: 'all', color: 'inherit' }} onClick={onClear}>
          <X size={22} />
        </ActionIcon>
      )}
      {/* <Calendar size={24} color={colors.gray[6]} strokeWidth={1} /> */}
      <IconCalendar />
    </Group>
  );
};

const getInputContainer: (
  containerClass: string,
  placeholderClass: string,
  placeholder: string
) => (children: ReactNode) => ReactNode =
  (containerClass, placeholderClass, placeholder) => (children) =>
    (
      <Box className={containerClass}>
        {children}
        <Box className={placeholderClass}>{placeholder}</Box>
      </Box>
    );

interface CommonCalendarInputProps {
  h?: string | number;
  disableBackwardsRange?: boolean;
}

type CalendarInputProps<T extends DatePickerType = 'default'> = CommonCalendarInputProps &
  (
    | ({
        type?: T;
        inline?: false;
      } & Omit<DatePickerInputProps<T>, 'h'>)
    | ({
        type?: 'default';
        value: DateValue;
        inline: true;
      } & Omit<DateInputProps, 'h'>)
  );

export function CalendarInput<T extends DatePickerType>({
  disableBackwardsRange,
  ...props
}: CalendarInputProps<T>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [currentValue, setCurrentValue] = useState<PolymorphicDateValue | undefined>(props.value);
  const changeHandler = (newValue: PolymorphicDateValue | undefined) => {
    setCurrentValue(newValue);
    props.onChange?.(newValue as any);
  };

  const displayValue = useMemo(
    () => (typeof props.value === 'undefined' ? currentValue : props.value),
    [props.value, currentValue]
  );

  const hasValue = useMemo(() => {
    if (!displayValue) return false;
    return isArray(displayValue)
      ? !displayValue.every((date) => date === null)
      : Boolean(props.value);
  }, [displayValue]);

  const showClearButton = useMemo(() => {
    if (!props.clearable) return false;
    return hasValue;
  }, [props.clearable, hasValue]);

  const { classes } = useStyles({ showClearButton, h: props.h });

  const clearHandler = () => {
    const newValue = (props.type === 'range' ? [null, null] : null) as DatePickerValue<T>;
    changeHandler(newValue);
  };

  const minDate = useMemo(() => {
    if (props.type !== 'range' || !disableBackwardsRange || !isArray(props.value))
      return props.minDate;
    return props.value?.[0] || props.minDate;
  }, [disableBackwardsRange, props.value, props.minDate]);

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (inputRef.current && e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      inputRef.current.blur();
    }
  };

  return (
    <Box
      data-required={props.required}
      data-has-value={hasValue}
      data-single={!props.type || props.type === 'default'}
      className={classes.calendarWrapper}
    >
      <DatesProvider
        settings={{
          locale: 'ru',
          firstDayOfWeek: 1,
          weekendDays: [0, 6],
        }}
      >
        {props.inline && (
          <DateInput
            onKeyDown={handleKeyDown}
            {...omit(props, 'inline', 'placeholder')}
            valueFormat="DD.MM.YYYY"
            rightSection={<RightSection showClearButton={showClearButton} onClear={clearHandler} />}
            classNames={classes}
            onChange={changeHandler}
            minDate={minDate}
            ref={inputRef}
            dateParser={dateParser}
            locale="ru"
            clearable={false}
            value={displayValue as DateValue}
            withCellSpacing={false}
            popoverProps={{ radius: 'lg' }}
            inputContainer={getInputContainer(
              classes.placeholderWrapper,
              classes.placeholder,
              props.placeholder || ''
            )}
          />
        )}
        {!props.inline && (
          <DatePickerInput
            {...omit(props, 'inline', 'placeholder')}
            locale="ru"
            valueFormat="DD.MM.YYYY"
            rightSection={<RightSection showClearButton={showClearButton} onClear={clearHandler} />}
            classNames={classes}
            onChange={changeHandler}
            minDate={minDate}
            value={displayValue as DatePickerValue<T>}
            popoverProps={{ radius: 'lg' }}
            inputContainer={getInputContainer(
              classes.placeholderWrapper,
              classes.placeholder,
              props.placeholder || ''
            )}
          />
        )}
      </DatesProvider>
    </Box>
  );
}
