import { FC } from "react";
import { useDispatch } from "react-redux";
import { Text, UnstyledButton } from "@mantine/core";

import { useStyles } from "./styles";

import { AppDispatch } from "@/store";
import { setCurrentDadata } from "@/store/slices/dadata/slice";

interface IInputDropItem {
  item?: {
    inn: string,
    registrationForm: string,
    legalName: string,
    managementName: string,
    legalAddress: string,
    kpp: string,
    ogrn: string
  },
  setFocus: (value: boolean) => void,
  value?: string | ReadonlyArray<string> | number | undefined
}

const InputDropItem: FC<IInputDropItem> = ({ item, setFocus, value }) => {
  const dispatch: AppDispatch = useDispatch();
  const { classes } = useStyles();

  const highlightWords = (string: string | ReadonlyArray<string> | number | undefined, matched: string | ReadonlyArray<string> | number | undefined) => {
    if (typeof string == 'string' && typeof matched == 'string') {
      const matchedStartPosition = string.toLowerCase().indexOf(matched.toLowerCase())
      const firstUnmatched = string.substring(0, matchedStartPosition)
      const secondUnmatched = string.substring(matchedStartPosition + matched.length, string.length)
      const matchedString = string.substring(matchedStartPosition, matchedStartPosition + matched.length)

      if (string.toLowerCase().includes(matched.toLowerCase())) {
        return (
          <>
            {firstUnmatched}
            <Text className={classes.textColor}>
              {matchedString}
            </Text>
            {secondUnmatched}
          </>
        )
      } else {
        return <> {string} </>
      }
    } else {
      return <> {string} </>
    }
  }

  return (
    <UnstyledButton
      className={classes.item}
      onClick={() => {
        dispatch(setCurrentDadata(item))
        setFocus(false)
      }}>
      <Text className={classes.textTop}>
        {/* {`${item?.registrationForm ? item?.registrationForm : ''}`} */}
        {highlightWords(item?.legalName ? item?.legalName : '', value)}
      </Text>
      <Text className={classes.textBottom}>
        {highlightWords(item?.inn ? item?.inn : '', value)}
        {highlightWords(item?.legalAddress ? item?.legalAddress : '', value)}
        {/* {`${highlightWords(item?.inn ? item?.inn : '', value)} ${highlightWords(item?.legalAddress ? item?.legalAddress : '', value)}`} */}
      </Text>
    </UnstyledButton >
  )
}

export default InputDropItem
