import { IOperations, TOperationsCodes, TOperationsStatuses } from '@/types/entites/template';

import { useOperation } from '@/utils/wsOperations';

import { openErrorModal, openSuccessModal } from '@/ui/organisms/Modals/modals';
import { getModalSuccessText } from '@/ui/pages/ProductsPage/components/ProductsOperationsController/utils/getModalSuccessText';
import { getModalTitle } from '@/ui/pages/ProductsPage/components/ProductsOperationsController/utils/getModalTitle';

interface ProductDocsOperationsControllerProps {
  onRefetch?: () => void;
}

export const ProductDocsOperationsController = ({
  onRefetch,
}: ProductDocsOperationsControllerProps) => {
  const handleOperationChange = (operation: IOperations) => {
    if (
      operation.status === TOperationsStatuses.FINISHED ||
      operation.status === TOperationsStatuses.ERROR
    ) {
      if (!operation.errorCount && !operation.errorMessage)
        openSuccessModal({
          title: getModalTitle(operation, 'success'),
          body: getModalSuccessText(operation),
          onClose: onRefetch,
        });
      else
        openErrorModal(
          operation.id.toString(),
          operation.successCount,
          operation.toProcessCount,
          operation.path,
          operation.errorMessage
        );
    }
  };

  useOperation({
    code: TOperationsCodes.ATTACH_PRODUCTS,
    onOperationChange: handleOperationChange,
  });

  return null;
};
