import { IFamily } from '@/types/common/common';
import { ICategory, ICategoryTree, TActiveCategories } from '@/types/entites/category';

import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Box, Button, Text } from '@mantine/core';
import cn from 'classnames';

import getStringWithSeparator from '@/utils/getStringWithSeparator';

import LoaderDots from '@/ui/atoms/LoaderDots/LoaderDots';
import SelectCustom from '@/ui/atoms/SelectCustom/SelectCustom';
import { rebuildDataForSelect } from '@/ui/pages/ProductsPage/utils/rebuildDataForSelect';

import PageLoader from '../Page/components/PageLoader/PageLoader';
import CategorySelection from '../СategorySelection/CategorySelection';

import { useStyles } from './styles';

import { AppDispatch } from '@/store';
import { categoriesApi } from '@/store/slices/category/api';
import { getProductsTemplate } from '@/store/slices/products/asyncActions';
import { selectFetchingProductsTemplate } from '@/store/slices/products/selectors';
import {
  selectActiveCategoriesTemplate,
  selectCurrentCategoryTemplate,
  selectCurrentTreeTemplate,
  selectDownloadTemplate,
} from '@/store/slices/template/selectors';
import {
  setCurrentCategoryTemplate,
  setDownloadTemplate,
  setTemplateTree,
} from '@/store/slices/template/slice';

const DownloadTemplate = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const fetchingTemplate = useSelector(selectFetchingProductsTemplate);
  const linkTemplate = useSelector(selectDownloadTemplate);
  const currentTree = useSelector(selectCurrentTreeTemplate);
  const activeCategories = useSelector(selectActiveCategoriesTemplate);
  const currentCategory = useSelector(selectCurrentCategoryTemplate);
  const [selectedFamily, setSelectedFamily] = useState<IFamily | null>(null);
  const [currentFamily, setCurrentFamily] = useState<IFamily | null>();
  const buttonRef = useRef<HTMLAnchorElement>(null);
  const [getCategoriesTree, { data: tree, isFetching: fetchingTree }] =
    categoriesApi.useLazyGetCategoriesTreeQuery();
  const [getCategoriesFamily, { data: categoryFamily, isFetching: fetchingFamily }] =
    categoriesApi.useLazyGetCategoriesFamilyQuery();

  const [currentTreeState, setCurrentTreeState] = useState<ICategoryTree[]>(currentTree);
  const [activeCategoriesState, setActiveCategoriesState] = useState<
    TActiveCategories[] | ICategory[]
  >(activeCategories);

  const categories = useMemo(() => {
    if (Object.values(activeCategoriesState).length > 0) {
      const list = Object.values(activeCategoriesState).map((el) => el?.label);
      return getStringWithSeparator(list, ', ');
    }
    return '';
  }, [activeCategoriesState]);

  const families = useMemo(() => {
    if (categoryFamily && categoryFamily.length > 0) {
      return rebuildDataForSelect(categoryFamily);
    }
    return [];
  }, [categoryFamily]);

  const isLastFamilyInThree = useMemo(() => !!currentCategory?.childrens, [currentCategory]);

  const handlerOnChange = (value: string | null) => {
    const current = families?.find((el) => el.value === value);

    if (current) {
      setSelectedFamily({
        code: String(current?.value),
        label: String(current?.label),
      });
    }
  };

  const handlerDownload = () => {
    dispatch(
      getProductsTemplate({
        category: {
          code: Number(currentCategory?.code),
          label: String(currentCategory?.label),
        },
        family: {
          code: String(selectedFamily?.code || currentFamily?.code),
          label: String(selectedFamily?.label || currentFamily?.label),
        },
      })
    );
  };

  const startDownload = () => {
    buttonRef.current?.click();
    setTimeout(() => {
      dispatch(setDownloadTemplate(null));
    }, 0);
    return false;
  };

  const handleChange = (element: ICategory) => {
    dispatch(setCurrentCategoryTemplate(element));
    setActiveCategoriesState((prev) => {
      const updated = prev.slice(0, element.depthLevel);
      updated[element.depthLevel] = element;
      return updated;
    });
  };

  const handleSetCurrentTree = (list: ICategoryTree[]) => {
    setCurrentTreeState(list);
  };

  useEffect(() => {
    setSelectedFamily(null);
    if (families.length > 0) {
      const initialFamily = families[0];
      setCurrentFamily({
        code: initialFamily.value,
        label: initialFamily.label,
      });
    }
    if (currentCategory?.code) {
      getCategoriesFamily(currentCategory?.code);
    }
  }, [families, currentCategory]);

  useEffect(() => {
    if (linkTemplate) startDownload();
  }, [linkTemplate]);

  useEffect(() => {
    if (currentTreeState.length <= 0) {
      getCategoriesTree();
      if (tree && tree.length > 0) {
        dispatch(setTemplateTree([{ tree }]));
      }
    }
  }, [tree]);

  useEffect(() => {
    setCurrentTreeState(currentTree);
    setActiveCategoriesState(activeCategories);
  }, [currentTree, activeCategories]);

  return (
    <Box>
      <CategorySelection
        activeCategories={activeCategoriesState}
        currentTree={currentTreeState}
        onChangeElement={handleChange}
        onCatergoriestTree={handleSetCurrentTree}
        isLoading={fetchingTree}
        fetchFamily
      />
      <Box className={cn(classes.inner, { [classes.innerDirection]: families.length > 1 })}>
        <PageLoader loading={fetchingFamily} />

        <a style={{ display: 'none' }} href={linkTemplate || ''} ref={buttonRef} download></a>
        {categories.length > 1 &&
          (families.length > 1 && !isLastFamilyInThree ? (
            <>
              <Button
                size="sm"
                variant="filled"
                pos={'relative'}
                className={classes.button}
                onClick={handlerDownload}
                disabled={!selectedFamily?.code}
              >
                <LoaderDots loading={fetchingTemplate} bgr="blue" />
                Скачать шаблон
              </Button>
              <SelectCustom
                size="sm"
                onChange={handlerOnChange}
                className={classes.select}
                placeholder="Пожалуйста уточните"
                options={families}
                optionValue={selectedFamily?.label}
                withinPortal={false}
              />
            </>
          ) : (
            <>
              {categories.length > 0 && (
                <Box className={classes.rightColomn}>
                  <Text className={classes.title}>Шаблон для категорий:</Text>
                  {categories}
                </Box>
              )}
              {currentCategory?.code && !isLastFamilyInThree && (
                <>
                  <Button
                    pos={'relative'}
                    size="sm"
                    variant="filled"
                    className={classes.button}
                    onClick={handlerDownload}
                    disabled={isLastFamilyInThree}
                  >
                    <LoaderDots loading={fetchingTemplate} bgr="blue" />
                    Скачать шаблон
                  </Button>
                </>
              )}
            </>
          ))}
      </Box>
    </Box>
  );
};

export default DownloadTemplate;
