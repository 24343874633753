export const supplyUrls = {
    getTabs: '/seller/supply/tabs',
    getWarehouses: '/seller/supply/warehouses/list',
    getSupplies: '/seller/supply/list',
    getTemplate: '/seller/supply/products/template',
    validate: '/seller/supply/products/validate',
    create: '/seller/supply/create',
    getSlots: (id: string) => `/seller/supply/${id}/slots`,
    setSlot: (id: string) => `/seller/supply/${id}/slot`,
    setPackages: (id: string) => `/seller/supply/${id}/packages`,
    setCars: (id: string) => `/seller/supply/${id}/cars`,
    downloadProducts: (id: string) => `/seller/supply/${id}/products/download`,
    cancelSupply: (id: string) => `/seller/supply/${id}/cancel`,
    getDocs: (id: string) => `/seller/supply/${id}/files/download`,
    getAcceptance: (id: string) => `/seller/supply/${id}/acceptance`,
    getAcceptDoc: (id: string) => `/seller/supply/${id}/acceptance/files/download`,
    getAcceptProducts: (id: string) => `/seller/supply/${id}/acceptance/products`,
    confirm: (id: string) => `/seller/supply/${id}/acceptance/confirm`,
}