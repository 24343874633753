export const RESEND_CODE_TIME = 120; // seconds
export const REFRESH_CHECK_TIMEOUT = 180000;

export const DEFAULT_PRODUCT_OFFSET: number = 0;
export const DEFAULT_PRODUCT_LIMIT: number = 50;

export const DEFAULT_PRODUCT_SORT = 'date_create';
export const DEFAULT_PRODUCT_ORDER = 'desc';

export const SORT_DATE = 'date_create';
export const SORT_NAME = 'name';
export const SORT_ARTICLE = 'supplierProductId';
export const SORT_HAS_EMPTY_ATTRIBUTES = 'has_empty_attributes';

export const PAGE = 1;

export const cookiesNames = {
  LOCATION: 'location',
  LOCATION_COORDS: 'locationCoords',
  NEXT_USER_TOKEN: 'NEXT_USER_TOKEN',
  NEXT_CUSTOMER_TOKEN: 'NEXT_CUSTOMER_TOKEN',
  ADDRESSES_TO_REMOVE: 'ADDRESSES_TO_REMOVE',
  CART_ITEMS_TO_REMOVE: 'CART_ITEMS_TO_REMOVE',
  BALANCE: 'balance',
  CART_NO_CACHE: 'CART_NO_CACHE',
  // MINDBOX_DEVICE_UUID: 'mindboxDeviceUUID',
};

export const DEFAULT_REQUEST_ERROR = 'Ошибка запроса. Попробуйте позже';

export const MULTIPLE_PRODUCT_TITLES = ['товар', 'товара', 'товаров'];
export const MULTIPLE_PRODUCT_TITLES_REMOVE = ['cнят', 'сняты', 'снято'];
export const MULTIPLE_PRODUCT_TITLES_RETURN = ['переведен', 'переведены', 'переведено'];
export const MULTIPLE_PRODUCT_TITLES_RESTORE = ['восстановлен', 'восстановлены', 'восстановлено'];
export const MULTIPLE_PRODUCT_TITLES_ADDED = ['Добавлен', 'Добавлено', 'Добавлено'];

export const MULTIPLE_EANS_TITLES = ['штрихкод', 'штрихкода', 'штрихкодов'];

export const SELLER_STATUS_ACTIVE = 'active';
export const SELLER_STATUS_INACTIVE = 'inactive';
export const SELLER_STATUS_MODERATION = 'moderation';
export const SELLER_STATUS_REJECTED = 'rejected';

export const AVAILABLE_REG_FORMS = ['ИП', 'ООО', 'АО', 'ПАО', 'НАО', 'Самозанятый'];
export const PRODUCT_OPERATIONS_KEY = 'product_operations';

export const NOOP = () => null;
