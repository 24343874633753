import { IOptions } from '@/types/common/common';

import { ChangeEventHandler, FC, forwardRef, useMemo, useRef, useState } from 'react';
import {
  ActionIcon,
  Box,
  Group,
  Input,
  InputProps,
  Select,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { Check, ChevronDown, X } from 'tabler-icons-react';

import { useInputStyles, useSelectStyles } from './styles';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  selected?: boolean;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ label, selected, ...rest }, ref) => {
  const theme = useMantineTheme();
  return (
    <Group ref={ref} {...rest} position="apart" spacing={16} noWrap bg={'transparent'}>
      <Text truncate>{label}</Text>
      {selected && <Check stroke={theme?.colors?.brandGreen[0]} size={16} />}
    </Group>
  );
});

interface IInputRightSectionProps {
  clearable: boolean;
  onClear?: () => void;
}

const InputRightSection: FC<IInputRightSectionProps> = ({ clearable, onClear }) => {
  return (
    <Group sx={{ pointerEvents: 'none' }}>
      {clearable && (
        <ActionIcon sx={{ pointerEvents: 'all', color: 'inherit' }} onClick={onClear}>
          <X size={20} />
        </ActionIcon>
      )}
    </Group>
  );
};

export interface TypedInputChangeEvent {
  type: string;
  value: string;
}
interface TypedInputProps extends InputProps {
  types: IOptions[];
  type?: IOptions['value'];
  defaultType?: IOptions['value'];
  value?: string;
  onChange?: (e: TypedInputChangeEvent) => void;
  selectWidth?: number;
  inputWidth?: number;
  clearable?: boolean;
}

export const TypedInput: FC<TypedInputProps> = ({
  types,
  type,
  defaultType = types[0].value,
  value,
  onChange,
  selectWidth,
  inputWidth,
  clearable,
  ...props
}) => {
  const [textValue, setText] = useState(value || '');
  const [typeValue, setType] = useState(type || defaultType);

  const selectRef = useRef<HTMLSelectElement>(null);

  const { classes: selectClasses } = useSelectStyles();
  const { classes: inputClasses } = useInputStyles({ clearable: !!clearable });

  const valueChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newTextValue = e.currentTarget.value;
    setText(newTextValue);
    onChange?.({ type: typeValue, value: newTextValue });
  };

  const typeChangeHandler = (newType: string) => {
    onChange?.({ type: newType, value: '' });
    setType(newType);
    setText('');
  };

  const handleClear = () => {
    onChange?.({ type: typeValue, value: '' });
    setText('');
  };

  const currentItem = useMemo(
    () => types.find((item) => item.value === typeValue) || { value: '', label: '' },
    [typeValue]
  );

  const IsShowRemoveButton = useMemo(() => !!(clearable && textValue), [clearable, textValue]);

  return (
    <Input.Wrapper className={inputClasses.wrapper}>
      <Group align="center" className={selectClasses.content}>
        <Box
          className={selectClasses.selectWrapper}
          onClick={() => {
            selectRef.current?.click();
          }}
        >
          <Group spacing={8} >
            <Text>{currentItem.label}</Text>
            <ChevronDown stroke="white" size={20} />
          </Group>
          <Select
            itemComponent={SelectItem}
            data={types}
            defaultValue={defaultType}
            onChange={typeChangeHandler}
            variant="unstyled"
            classNames={selectClasses}
            w={selectWidth}
            withinPortal
          />
        </Box>
        <Input
          {...props}
          value={textValue}
          onChange={valueChangeHandler}
          classNames={{ input: inputClasses.input }}
          rightSection={<InputRightSection clearable={IsShowRemoveButton} onClear={handleClear} />}
          variant="unstyled"
          w={inputWidth}
          placeholder={currentItem.placeholder}
          inputMode={currentItem.inputMode}
        />
      </Group>
    </Input.Wrapper>
  );
};
