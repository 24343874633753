import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  button: {
    width: 36,
    height: 36,
    position: 'relative',
    borderRadius: '50%',
    padding: 8,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    color: 'white',
    backgroundColor: theme.colors.mainColor[6],
    ':hover': {
      backgroundColor: theme.colors.mainColor[7],
      color: 'white',
    },
  },
  outlined: {
    backgroundColor: 'white',
    color: 'black',
    ':hover': {
      backgroundColor: 'white',
      color: 'black',
    },
  },
}));
