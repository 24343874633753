import { createStyles } from "@mantine/core";

export const useStyles = createStyles(() => ({
    wrapper:{
        // padding: '20px 32px 34px',
        fontSize: '16px',
        lineHeight: '24px',
        textAlign: 'center'
    },
    icon:{
        maxWidth: '100%'
    },
    button:{
        padding: '13px 24px',
        minWidth: '280px',
        fontSize: '14px'
    }
}));