import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  inner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '18px 0px 0px',
    position: 'relative',
    width: '100%',
    height: '36px',
    fontSize: '14px',
    color: theme.colors.brandDark[9],
  },
  innerDirection: {
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },
  rightColomn: {
    display: 'flex',
    alignItems: ' center',
    marginRight: '20px',
    fontSize: '14px',
  },
  select: {
    marginRight: '20px',
    flex: 1,
  },
  title: {
    color: theme.colors.text[5],
    marginRight: '4px',
    flexShrink: 0,
  },
  button: {
    position: 'relative',
    padding: '8px 16px',
    fontSize: '14px',
  },
}));
