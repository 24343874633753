import { IChangePasswordConfirmRequest } from '@/types/api/auth';

import { FC, useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Button, Input } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { formatedPhone } from '@/utils/formatedPhone';
import reCaptchaVerify from '@/utils/reCaptchaVerify';

import { RESEND_CODE_TIME } from '@/constants/common';
import { routNames } from '@/constants/routNames';
import { SIGNIN_WITH_PHONE_CODE } from '@/constants/validationSchemas';

import InputWithLabel from '@/ui/atoms/InputWithLabel/InputWithLabel';
import { PhoneInput } from '@/ui/organisms/PhoneInput/PhoneInput';
import ResendCode from '@/ui/organisms/ResendCode/ResendCode';

import AuthFooter from '../AuthFooter/AuthFooter';

import { AppDispatch } from '@/store';
import { changePasswordAction, changePasswordConfirmAction } from '@/store/slices/auth/asyncAction';
import { selectFetchingChangePasswordConfirm, selectUserPhone } from '@/store/slices/auth/auth';

const RestorePasswordPhoneCode: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const fetching = useSelector(selectFetchingChangePasswordConfirm);
  const userPhone = useSelector(selectUserPhone);
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    reCaptchaVerify(executeRecaptcha, 'changePasswordAction').then((recaptchaToken) => {
      if (recaptchaToken) {
        const sendData = {
          phone: formatedPhone(userPhone),
          recaptchaToken,
        };
        dispatch(changePasswordAction(sendData));
      }
    });
  }, [userPhone]);

  const getNewCode = useCallback(() => {
    reCaptchaVerify(executeRecaptcha, 'changePasswordAction').then((recaptchaToken) => {
      if (recaptchaToken) {
        const sendData = {
          phone: formatedPhone(userPhone),
          recaptchaToken,
        };
        dispatch(changePasswordAction(sendData));
      }
    });
  }, [executeRecaptcha, userPhone]);

  const handleSubmit = (values: Record<string, string>) => {
    reCaptchaVerify(executeRecaptcha, 'changePasswordConfirmAction').then((recaptchaToken) => {
      if (recaptchaToken) {
        const sendData: IChangePasswordConfirmRequest = {
          phone: formatedPhone(values.phone),
          password: values.password,
          recaptchaToken,
          actionSource: 'web',
        };
        dispatch(changePasswordConfirmAction(sendData));
      }
    });
  };

  const form = useForm({
    initialValues: {
      phone: userPhone,
      password: '',
    },
    validateInputOnChange: true,
    validate: yupResolver(SIGNIN_WITH_PHONE_CODE),
  });

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} noValidate={true}>
      <Box>
        <Input.Wrapper required mx="auto">
          <PhoneInput
            name="phone"
            label="Телефон"
            labelType="floating"
            autoComplete="off"
            size="md"
            disabled={!!userPhone}
            {...form.getInputProps('phone')}
            mb={16}
            unmask
          />
        </Input.Wrapper>
        <InputWithLabel
          // type="password"
          inputtype="password"
          label="Пароль"
          size="md"
          autoComplete="off"
          {...form.getInputProps('password')}
          value={form.getInputProps('password').value}
        />
        <ResendCode
          resendHandler={getNewCode}
          timer={RESEND_CODE_TIME}
          timerText="Отправить пароль повторно можно через"
          buttonText="Отправить пароль повторно"
        />
      </Box>
      <AuthFooter>
        <Box>
          <Button
            loading={fetching}
            disabled={!form.isDirty}
            type="submit"
            size="md"
            variant="filled"
            fullWidth
            fz={14}
            h={48}
          >
            Подтвердить
          </Button>
        </Box>
        <Box mt={16} fz={14}>
          <Link target="_blank" to={routNames.SERVICES.FAQ}>
            Не приходит пароль
          </Link>
        </Box>
      </AuthFooter>
    </form >
  );
};

export default RestorePasswordPhoneCode;
