import { FC } from "react";
import { useDocumentTitle } from "@mantine/hooks";

import SummaryOrdersPage from "@/ui/pages/SummaryOrdersPage/SummaryOrdersPage";


const SummaryOrders: FC = () => {
    useDocumentTitle('Сводная информация по заказам');

    return (
        <SummaryOrdersPage title={'Сводная информация по заказам'} />
    )
}

export default SummaryOrders;