import { useScript } from '@/hooks/useScript';

const script = `
  window['l'+'iv'+'eT'+'ex'] = true,
  window['liv'+'eT'+'exID'] = 178523,
  window['liveTe'+'x'+'_ob'+'ject'] = true;
  (function() {
  var t = document['create'+'El'+'em'+'ent']('script');
  t.type ='text/javascript';
  t.async = true;
  t.src = '//cs15.'+'livet'+'e'+'x'+'.ru/js/cl'+'ient.js';
  var c = document['getElem'+'ent'+'sByTagNa'+'m'+'e']('script')[0];
  if ( c ) c['par'+'e'+'nt'+'No'+'d'+'e']['in'+'sertBe'+'for'+'e'](t, c);
  else document['documen'+'t'+'Element']['f'+'irs'+'tChi'+'ld']['a'+'p'+'pend'+'Chi'+'ld'](t);
  })();
`;

function LiveTexScript() {
  useScript({ script }, false);
  return null;
}

export default LiveTexScript;
