import { FC, ReactNode } from 'react';
import { Box, Modal } from '@mantine/core';
import cn from 'classnames';

import ModalCLose from '@/ui/organisms/Modals/ModalClose/ModalClose';
import ModalContent from '@/ui/organisms/Modals/ModalContent/ModalContent';

import { useStyles } from './styles';

interface IModalCustomProps {
  children: ReactNode;
  opened: boolean;
  size?: 'xs' | 'md' | 'lg' | 'xl';
  title?: string;
  withoutPadding?: boolean;
  withoutFooter?: boolean;
  fullSize?: boolean;
  onClose: () => void;
}

const ModalCustom: FC<IModalCustomProps> = ({
  children,
  size,
  opened,
  title,
  withoutPadding,
  fullSize = false,
  withoutFooter,
  onClose,
}) => {
  const { classes } = useStyles({ fullSize: !!fullSize });

  const getModalSize = (width: string) => {
    if (width === 'xs') {
      return '435px';
    }
    return '800px';
  };

  return (
    <Modal
      fullScreen={fullSize}
      withinPortal
      lockScroll
      // scrollAreaComponent={ScrollArea}
      withCloseButton={true}
      title={title || ''}
      classNames={{
        root: classes.root,
        body: classes.body,
        content: classes.content,
        close: classes.close,
        title: classes.title,
        header: classes.header,
        overlay: classes.overlay,
        inner: classes.inner,
      }}
      size={getModalSize(size || 'xs')}
      opened={opened}
      onClose={onClose}
    >
      <ModalCLose onClose={onClose} />
      <ModalContent
        boxSize={480}
        fullScreen={fullSize}
        withoutPadding={!!withoutPadding} 
        withoutFooter={withoutFooter}
      >
        <Box className={cn({ [classes.content]: !withoutPadding })}>{children}</Box>
      </ModalContent>
    </Modal>
  );
};

export default ModalCustom;
