import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    icon: {
        display: 'flex',
        transform: 'rotate(0deg)',
        fill: theme.colors.brandGrey[5]
    },
    icon_active: {
        transform: 'rotate(180deg)',
        fill: theme.colors.mainColor[6]
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        color: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit'
    }
}));