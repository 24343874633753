import { FC } from 'react';
import { UnstyledButton, useMantineTheme } from '@mantine/core';
import { Text } from '@mantine/core';

import Cell from '@/ui/organisms/TableCustom/components/Cell/Cell';

interface SupplyDateCellProps {
  date?: string;
  onOpenSlots?: () => void;
}

export const SupplyDateCell: FC<SupplyDateCellProps> = ({ date, onOpenSlots }) => {
  const { colors } = useMantineTheme();
  return (
    <Cell>
      {!onOpenSlots || date ? (
        <Text sx={{ whiteSpace: 'nowrap' }}>{date}</Text>
      ) : (
        <UnstyledButton onClick={onOpenSlots}>
          <Text color={colors.mainColor[6]} size={14}>
            Записаться
          </Text>
        </UnstyledButton>
      )}
    </Cell>
  );
};
