import {
  createContext,
  FC,
  forwardRef,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Loader, Select, Text, useMantineTheme } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import isUndefined from 'lodash/isUndefined';

import { getRegex } from '@/utils/common';

import { useOutsideClick } from '@/hooks/useOutsideClick';

import { useStyles } from './styles';

import { suppliesApi } from '@/store/slices/supplies/slice';

interface ItemProps {
  label: string;
  value: string;
}

interface WarehouseSelectProps {
  onSelect?: (v: string | undefined) => void;
}

const suggestionsContext = createContext({ search: '' });

const SuggestionItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, ...props }: ItemProps, ref) => {
    const { search } = useContext(suggestionsContext);
    const regex = useMemo(() => getRegex(search), [search]);
    const theme = useMantineTheme();

    const highlightedText = useMemo(() => {
      const matches = label.matchAll(regex);
      const arrayString: ReactNode[] = Array.from(label);

      for (const match of matches) {
        if (isUndefined(match.index)) continue;
        arrayString.splice(
          match.index,
          match[0].length,
          <span style={{ color: theme.colors[theme.primaryColor][6] }}>{match[0]}</span>
        );
      }

      return arrayString;
    }, [regex, search]);

    return (
      <Box ref={ref} {...props}>
        <Text truncate>{highlightedText}</Text>
      </Box>
    );
  }
);

const SEARCH_REGEX = /^[а-яёй\.]*$/i;

export const WarehouseSelect: FC<WarehouseSelectProps> = ({ onSelect }) => {
  const {
    classes: { wrapper, ...classes },
  } = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string | undefined>();
  const [search, setSearch] = useState('');
  const [focusInput, setFocusInput] = useState<boolean>(false);
  let timeoutId: NodeJS.Timeout | null = null;

  const [debouncedSearch] = useDebouncedValue(search, 1000);
  const { data, isFetching } = suppliesApi.useGetWarehousesSuggestionsQuery(
    { region: debouncedSearch },
    { skip: !debouncedSearch }
  );

  const suggestions = useMemo(() => data?.regions ?? [], [data, isFetching]);

  useOutsideClick(inputRef, () => {
    if (!search && inputRef.current) {
      timeoutId = setTimeout(() => {
        setFocusInput((prev) => !prev);
      }, 100);
    }
  });

  const updateSearch = (v: string) => {
    if (v === search || v === value || !SEARCH_REGEX.test(v)) return;
    setSearch(v ?? value);
  };

  const changeHandler = (v: string) => {
    setValue(v);
    setSearch(v);
    onSelect?.(v);
  };

  useEffect(() => {
    if (!search && inputRef.current) {
      inputRef.current.focus();
      setFocusInput(false);
    }
  }, [focusInput]);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  return (
    <Box pos="relative" data-has-value={!!search} className={wrapper}>
      <suggestionsContext.Provider value={{ search }}>
        <Select
          classNames={classes}
          ref={inputRef}
          label="Регион"
          placeholder="Начните вводить название"
          searchable
          searchValue={search}
          onSearchChange={updateSearch}
          data={suggestions}
          itemComponent={SuggestionItem}
          dropdownPosition="bottom"
          value={value}
          onChange={changeHandler}
          withinPortal
          initiallyOpened
          zIndex={1000}
          maxDropdownHeight={160}
          rightSection={isFetching ? <Loader size={14} /> : <></>}
          data-autofocus
        />
      </suggestionsContext.Provider>
    </Box>
  );
};
