import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ActionIcon, Menu, Stack, useMantineTheme } from '@mantine/core';
import { Text, Tooltip } from '@mantine/core';

import { useToken } from '@/utils/token';

import { HeaderProfileTooltip } from '../HeaderTooltips';
import { useStyles } from '../styles';

import { ReactComponent as IconProfile } from '@/assets/icons/profile.svg';
import { useAppDispatch } from '@/store';
import { logoutAction } from '@/store/slices/auth/asyncAction';
import { userLogout } from '@/store/slices/auth/auth';
import { selectUser } from '@/store/slices/user/slice';

const HeaderProfile = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(selectUser);
  const { token } = useToken();
  const { colors, shadows } = useMantineTheme();
  const navigate = useNavigate();
  const { classes } = useStyles();

  const handleLogout = async () => {
    if (!token) return;
    await dispatch(logoutAction(token.refreshToken));
    dispatch(userLogout());
    navigate('/auth');
  };

  const tooltipContent = useMemo(() => <HeaderProfileTooltip user={user} />, [user]);

  return user ? (
    <Menu position="bottom-end" shadow={shadows.sm} withinPortal={true}>
      <Tooltip
        label={tooltipContent}
        width={'auto'}
        color={colors.mainColor[6]}
        offset={10}
        position="bottom-end"
      >
        <Menu.Target>
          <ActionIcon className={classes.button}>
            <IconProfile width="18px" height="18px" />
          </ActionIcon>
        </Menu.Target>
      </Tooltip>

      <Menu.Dropdown px={0} miw={250}>
        <Stack spacing={8} pt={12}>
          <Stack spacing={8} px={12}>
            <Text weight={700} truncate>{`${user.lastName} ${user.name}`}</Text>
            <Text size={12} color={colors.brandGrey[5]} truncate>
              {user?.emailSellerLogin}
            </Text>
          </Stack>
          <Menu.Divider />
          <Menu.Item onClick={() => navigate('/profile/account')}>
            <Text size={12}>Профиль</Text>
          </Menu.Item>
          <Menu.Item onClick={handleLogout} closeMenuOnClick={false}>
            <Text size={12}>Выйти</Text>
          </Menu.Item>
        </Stack>
      </Menu.Dropdown>
    </Menu>
  ) : null;
};

export default HeaderProfile;
