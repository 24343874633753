import { ProfileTabCode, TFile, TProfileField } from '@/types/api/profile';

import { useCallback, useMemo, useState } from 'react';
import { Box, Flex, Text, useMantineTheme } from '@mantine/core';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { InfoCircle } from 'tabler-icons-react';

import { generateForm, parseFormErrors, useSubmitMethods } from '../../utils/formBuilderHelpers';

import { FormBuilderInput } from './components/FormBuilderInput';
import { OfferCheckbox } from './components/OfferCheckbox';
import { SubmitButton } from './components/SubmitButton';
import { FormBuilderprovider, useFormBuilderForm } from './utils/FormContext';
import { LinkedFieldsContext } from './utils/LinkedFieldsContext';
import { PHONE_TOOLTIP_TEXT } from './utils/texts';

import { useAppDispatch } from '@/store';
import { profileApi } from '@/store/slices/profile/slice';
import { confirmEmailAction, fetchUserAction } from '@/store/slices/user/slice';

type TProfileFormBuilderProps = {
  fields: TProfileField[];
  code: ProfileTabCode;
  confirmed: boolean;
  offerLink?: string;
};

export const ProfileFormBuilder = ({
  fields,
  code,
  confirmed,
  offerLink,
}: TProfileFormBuilderProps) => {
  const dispatch = useAppDispatch();

  const theme = useMantineTheme();
  const formOptions = useMemo(() => generateForm(fields), [fields]);
  const form = useFormBuilderForm(formOptions);
  const [linkedFields, setLinkedFields] = useState<[string, string][]>([]);
  const [offerAccepted, setOfferAccepted] = useState(false);

  const [fetchTabs] = profileApi.useLazyGetTabsQuery();

  const [trigger, { status, error }] = useSubmitMethods(code);
  const saving = useMemo(() => status === QueryStatus.pending, [status]);
  const rejected = useMemo(() => status === QueryStatus.rejected, [status]);

  const resetField = (fieldCode: string) => {
    form.setFieldValue(fieldCode, formOptions.initialValues[fieldCode]);
  };

  const submitHandler = async (values: Record<string, string | TFile | string[] | TFile[]>) => {
    form.validate();
    if (Object.keys(form.errors).length) return;
    await trigger(values);
    fetchTabs();
    dispatch(fetchUserAction({ type: null }));
  };

  const getIsFieldDisplayed = (field: TProfileField) => {
    if (field.code === 'additional' && !field.values.length && confirmed) return false;
    return true;
  };

  const handleCheckEmail = useCallback(async (email: any): Promise<boolean> => {
    try {
      const sendConfirmEmail = { companyEmail: email };
      const resultConfirm = (await dispatch(confirmEmailAction(sendConfirmEmail))) as any;

      if (resultConfirm) {
        if (resultConfirm.error && resultConfirm.error.message) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } catch (er) {
      return false;
    }
  }, []);

  return (
    <LinkedFieldsContext.Provider
      value={{
        linked: linkedFields,
        setLinked: setLinkedFields,
        fields: fields,
        onReset: resetField,
        onCheckClick: handleCheckEmail,
        fieldConfirmed: confirmed,
        tooltipText: PHONE_TOOLTIP_TEXT,
      }}
    >
      <FormBuilderprovider form={form}>
        <Box>
          {form.values.company_bik === '' && ProfileTabCode.REQUISITES ? (
            <Flex gap={8} align={'center'} mb={40}>
              <InfoCircle width={16.5} height={16.5} />
              <Text size={14}>Введите БИК, и мы автоматически заполним некоторые поля</Text>
            </Flex>
          ) : null}
          <form onSubmit={form.onSubmit(submitHandler)}>
            {fields.map((field) =>
              getIsFieldDisplayed(field) ? (
                <FormBuilderInput fieldType={field.type} fieldOptions={field} key={field.code} />
              ) : null
            )}
            {!confirmed && (
              <>
                {code === ProfileTabCode.DOCUMENTS && (
                  <OfferCheckbox onChange={setOfferAccepted} offerLink={offerLink} />
                )}
                <SubmitButton
                  isFormValid={form.isValid()}
                  isOfferAccepted={offerAccepted}
                  isSaving={saving}
                  tabCode={code}
                />
                <Box mih={42} mt={16}>
                  {rejected && (
                    <Text color={theme.colors.red[5]} size={14} sx={{ whiteSpace: 'pre' }}>
                      Данные не сохранены
                      <br />
                      <br />
                      {parseFormErrors(error)}
                    </Text>
                  )}
                </Box>
              </>
            )}
          </form>
        </Box>
      </FormBuilderprovider>
    </LinkedFieldsContext.Provider>
  );
};
