import {
  IPRofilePhoneField,
  IProfileSelectField,
  IProfileStringField,
  ProfileFieldType,
  ProfileFieldValue,
  ProfileTabCode,
  SuggestionsEndpoint,
  TFile,
  TProfileField,
} from '@/types/api/profile';

import { ComponentProps, FC } from 'react';
import { yupResolver } from '@mantine/form';
import isString from 'lodash/isString';
import { object, Schema } from 'yup';

import { isNumberLike } from '@/utils/isNumberLike';

import {
  getDefaultValidator,
  VALIDATION_BANK,
  VALIDATION_BANK_ADDRESS,
  VALIDATION_BIK,
  VALIDATION_COMPANY_MANAGEMENT,
  VALIDATION_EMAIL,
  VALIDATION_KPP,
  VALIDATION_KS,
  VALIDATION_OGRN,
  VALIDATION_PHONE,
  VALIDATION_RS,
} from '@/constants/validationSchemas';
import { validationTexts } from '@/constants/validationTexts';

import InputWithLabel from '@/ui/atoms/InputWithLabel/InputWithLabel';
import SelectCustom from '@/ui/atoms/SelectCustom/SelectCustom';
import { PhoneInput } from '@/ui/organisms/PhoneInput/PhoneInput';

import { FileInput } from '../components/FileInput/FileInput';
import { IFileInputProps } from '../types';

import { profileApi } from '@/store/slices/profile/slice';

interface AdditionalAttributes {
  maxLength?: number;
  tooltipText?: string;
}

type TInputProps = ComponentProps<typeof InputWithLabel>;
type TTextareaProps = ComponentProps<typeof InputWithLabel<'textarea'>>;
type TPhoneInputProps = ComponentProps<typeof InputWithLabel<'input'>>;
type TSelectProps = ComponentProps<typeof SelectCustom>;

export type TInputPropsMap = {
  string: TInputProps;
  file: IFileInputProps;
  select: Omit<TSelectProps, 'options'>;
  textarea: TTextareaProps;
  phone: Omit<TPhoneInputProps, 'onChange'> & { onChange?: (value: string) => void };
};

type InputsMap = {
  [type in ProfileFieldType]: (
    field: TProfileField
  ) => FC<TInputPropsMap[type] & AdditionalAttributes>;
};

type InputFilterssConfig = {
  [fieldCode: string]: {
    filter: (value: ProfileFieldValue) => boolean;
    error?: string;
  };
};

type SugestionsConfig = {
  [index: string]: {
    endpoint: SuggestionsEndpoint;
    transformArgs: (value: string) => Record<string, string>;
    transformLabel: (value: Record<string, string>) => {
      mainLabel: string;
      additional?: {
        label: string;
        value: string;
      }[];
    };
    transformResult: (value: Record<string, any>) => Record<string, any>;
  };
};

const additionalAttributesMap: Record<string, AdditionalAttributes> = {
  company_kpp: {
    maxLength: 9,
  },
  company_ogrn: {
    maxLength: 13,
  },
  company_bik: {
    maxLength: 9,
  },
  company_rs: {
    maxLength: 20,
  },
  company_ks: {
    maxLength: 20,
  },
  company_bank: {
    maxLength: 300,
  },
  company_bank_address: {
    maxLength: 300,
  },
  management_position: {
    maxLength: 200,
  },
};

const customValidators: Record<string, Schema> = {
  company_kpp: VALIDATION_KPP,
  company_ogrn: VALIDATION_OGRN,
  company_bik: VALIDATION_BIK,
  company_rs: VALIDATION_RS,
  company_ks: VALIDATION_KS,
  company_bank: VALIDATION_BANK,
  company_bank_address: VALIDATION_BANK_ADDRESS,
  management_position: VALIDATION_COMPANY_MANAGEMENT,
  companyEmail: VALIDATION_EMAIL,
  companyPhone: VALIDATION_PHONE,
};

const inputFiltersConfig: InputFilterssConfig = {
  company_bik: {
    filter: isNumberLike,
    error: validationTexts.NUMBER,
  },
  company_ks: {
    filter: isNumberLike,
    error: validationTexts.NUMBER,
  },
  company_rs: {
    filter: isNumberLike,
    error: validationTexts.NUMBER,
  },
};

const suggestionsConfig: SugestionsConfig = {
  company_bank: {
    endpoint: 'bank',
    transformArgs: (queryString) => ({ queryString }),
    transformLabel: (item) => ({
      mainLabel: item.name,
      additional: [
        {
          label: 'БИК',
          value: item.bik,
        },
        {
          label: 'К. счет',
          value: item.ks,
        },
        {
          label: 'Адрес',
          value: item.address,
        },
      ],
    }),
    transformResult: (item) => ({
      company_bank: item.name,
      company_bik: item.bik,
      company_ks: item.ks,
      company_bank_address: item.address,
    }),
  },
  company_bik: {
    endpoint: 'bank',
    transformArgs: (queryString) => ({ queryString }),
    transformLabel: (item) => ({
      mainLabel: item.name,
      additional: [
        {
          label: 'БИК',
          value: item.bik,
        },
        {
          label: 'К. счет',
          value: item.ks,
        },
        {
          label: 'Адрес',
          value: item.address,
        },
      ],
    }),
    transformResult: (item) => ({
      company_bank: item.name,
      company_bik: item.bik,
      company_ks: item.ks,
      company_bank_address: item.address,
    }),
  },
};

export const profileBuilderConfig = {
  additionalAttributes: additionalAttributesMap,
  validators: customValidators,
  inputFilters: inputFiltersConfig,
  suggestionsConfig,
};

export const getAdditionalAttributes = (code: string) =>
  profileBuilderConfig.additionalAttributes[code] || {};

export const inputsMap: InputsMap = {
  string:
    ({ name, disabled, code }) =>
    (props) => {
      return (
        <InputWithLabel
          size="lg"
          radius={8}
          {...props}
          label={name}
          disabled={disabled}
          clearable
          inputMode={props.inputMode}
          {...getAdditionalAttributes(code)}
        />
      );
    },
  file:
    ({ name, code }) =>
    (props) =>
      (
        <FileInput
          {...props}
          label={name}
          type={code}
          changeable
          {...getAdditionalAttributes(code)}
        />
      ),
  select:
    ({ name, options = [], disabled, code }) =>
    (props) =>
      (
        <SelectCustom
          {...props}
          size="lg"
          label={name}
          options={options?.map(({ value }) => ({ label: value, value }))}
          optionValue={props.value}
          // placeholder="Выберите вариант"
          disabled={disabled}
          {...getAdditionalAttributes(code)}
        />
      ),
  textarea:
    ({ name, disabled, code }) =>
    (props) =>
      (
        <InputWithLabel
          {...props}
          size="lg"
          label={name}
          type="textarea"
          disabled={disabled}
          clearable
          {...getAdditionalAttributes(code)}
        />
      ),
  phone:
    ({ name, disabled, code }) =>
    ({ ...props }) =>
      (
        <PhoneInput
          size="lg"
          type="input"
          autoComplete="off"
          unmask
          radius={8}
          {...props}
          labelType={name ? 'floating' : 'normal'}
          label={name}
          disabled={disabled}
          clearable
          inputMode="tel"
          {...getAdditionalAttributes(code)}
        />
      ),
};

export const changeableInputCodes = ['companyEmail'];

function isStringField(
  field: TProfileField
): field is IProfileStringField | IProfileSelectField | IPRofilePhoneField {
  return field.type !== ProfileFieldType.FILE;
}

function getFieldValue(field: TProfileField) {
  if (isStringField(field)) {
    return field.multiple ? field.values.map((value) => value || '') : field.values[0] || '';
  } else return field.multiple ? field.values : field.values[0];
}

const getFormValidator = (fields: TProfileField[]) => {
  const shape = fields.reduce<Record<string, Schema>>((res, field) => {
    let validator = getDefaultValidator(field.required, isStringField(field));
    const validators = profileBuilderConfig.validators;

    if (validators[field.code]) {
      validator = field.required
        ? validators[field.code].required(validationTexts.REQUIRED)
        : validators[field.code];
    }

    return {
      ...res,
      [field.code]: validator,
    };
  }, {});
  return yupResolver(object().shape(shape));
};

export const generateForm = (fields: TProfileField[]) => ({
  initialValues: fields.reduce<Record<string, string | string[] | TFile | TFile[]>>(
    (acc, field) => ({
      ...acc,
      [field.code]: getFieldValue(field),
    }),
    {}
  ),
  validate: getFormValidator(fields),
  validateInputOnChange: true,
  validateInputOnBlur: true,
});

export const useSubmitMethods = (code: ProfileTabCode) => {
  const aboutHook = () => profileApi.useSubmitAboutMutation();
  const docsHook = () => profileApi.useSubmitDocsMutation();
  const requisitesHook = () => profileApi.useSubmitRequisitesMutation();

  const hooksMap = {
    [ProfileTabCode.ABOUT]: aboutHook,
    [ProfileTabCode.DOCUMENTS]: docsHook,
    [ProfileTabCode.REQUISITES]: requisitesHook,
  };

  return hooksMap[code]();
};

type ParsedErrors = Record<string, string>;

export const parseFormErrors = (err: unknown) => {
  if (!isString(err)) return null;
  try {
    const parsedErrors: ParsedErrors = JSON.parse(err);
    return Object.values(parsedErrors).join('\r\n');
  } catch {
    return err;
  }
};
