import { FC } from 'react';
import { Group, Switch, SwitchProps, useMantineTheme } from '@mantine/core';
import { Text } from '@mantine/core';
import { Box } from '@mantine/core';

import { useStyles } from './styles';

interface ICustomSwitchProps extends Omit<SwitchProps, 'onChange'> {
  label: string;
  checked: boolean;
  error?: string;
  onChange?: (checked: boolean) => void;
}

const CustomSwitch: FC<ICustomSwitchProps> = ({ label, checked, onChange, disabled, error }) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.checked);
  };

  return (
    <Box>
      <Group align="center" position="apart">
        <Text fz={14} color={theme.colors.brandGrey[8]}>
          {label}
        </Text>
        <Switch
          classNames={{ ...classes }}
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
        />
      </Group>
      {error && (
        <Text size={12} color={theme.colors.red[5]}>
          {error}
        </Text>
      )}
    </Box>
  );
};

export default CustomSwitch;
