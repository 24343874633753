import { FC } from 'react';
import { useDocumentTitle } from '@mantine/hooks';

import AuthPage from '@/ui/pages/AuthPage/AuthPage';

const Auth: FC = () => {
    useDocumentTitle('Авторизация');

    return (
        <AuthPage title={'Авторизация'} />
    )
}

export default Auth;