import { FC, HTMLAttributes } from 'react';
import { Button, ButtonProps } from '@mantine/core';

import { useButtonStyles } from './styles';

type OldButonProps = Omit<ButtonProps, 'variant'> & HTMLAttributes<HTMLButtonElement>;

interface SubtleButtonProps extends OldButonProps {}

export const SubtleButton: FC<SubtleButtonProps> = (props) => {
  const { classes: buttonClasses } = useButtonStyles();

  return <Button variant="subtle" classNames={buttonClasses} {...props} />;
};
