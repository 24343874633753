import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Text } from '@mantine/core';

import ModalFooter from '@/ui/organisms/Modals/ModalFooter/ModalFooter';

import { useStyles } from './styles';

import { ReactComponent as IconSameFilesNames } from '@/assets/icons/redesign/iconSameFilesNames.svg';
import { AppDispatch } from '@/store';
import { setModalSameFilesNames } from '@/store/slices/modal/slice';

interface ISameFilesNames {
  fileName: string | null | undefined;
}

export const SameFilesNames: FC<ISameFilesNames> = ({ fileName }) => {
  const dispatch: AppDispatch = useDispatch();
  const { classes } = useStyles();

  const handleClose = (): void => {
    dispatch(setModalSameFilesNames(null));
  };

  return (
    <>
      <Box className={classes.wrapper}>
        <IconSameFilesNames className={classes.icon} />
        <Box mb={32}>
          <Text className={classes.text}>
            Вы пытаетесь загрузить документы с одинаковыми названиями. Документы «{fileName}» и «
            {fileName}» не были загружены. Пожалуйста, измените названия документов и попробуйте
            снова.
          </Text>
        </Box>
      </Box>
      <ModalFooter align="center" variant="xs">
        <Button
          className={classes.button}
          type="button"
          size="lg"
          variant="filled"
          onClick={handleClose}
        >
          Хорошо
        </Button>
      </ModalFooter>
    </>
  );
};
