import { IDocumentProductsSearchRequest } from '@/types/api/productDocuments';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Group,
  Image,
  LoadingOverlay,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import debounce from 'debounce';

import { getDocumentMock } from '@/utils/mocks';
import { pluralize } from '@/utils/pluralize';

import PageContent from '@/ui/organisms/Page/components/PageContent/PageContent';
import PageHeader from '@/ui/organisms/Page/components/PageHeader/PageHeader';
import PaginationCustom from '@/ui/organisms/PaginationCustom/PaginationCustom';
import { ProductDocsOperationsController } from '@/ui/organisms/ProductDocsOperationsController/ProductDocsOperationsController';
import { TableCustom } from '@/ui/organisms/TableCustom';
import Cell from '@/ui/organisms/TableCustom/components/Cell/Cell';
import Row from '@/ui/organisms/TableCustom/components/Row/Row';
import TBody from '@/ui/organisms/TableCustom/components/TBody/TBody';
import THead from '@/ui/organisms/TableCustom/components/THead/THead';
import { TypedInput } from '@/ui/organisms/TypedInput/TypedInput';

import { openAddProductModal, openLoadingError } from '../../organisms/Modals/modals';

import { Card } from './components/Card/Card';
import { DocInfo } from './components/DocInfo/DocInfo';
import { FileList } from './components/FileList/FileList';

import { productDocsApi } from '@/store/slices/productDocs/api';
import { selectDocProducts } from '@/store/slices/productDocs/slice';
import { selectUser } from '@/store/slices/user/slice';

interface ProductDocDetailsPageProps {
  doc?: ReturnType<typeof getDocumentMock>;
}

export const ProductDocDetailsPage: FC<ProductDocDetailsPageProps> = () => {
  const user = useSelector(selectUser);
  const isActiveSeller = useMemo(() => user?.sellerStatus === 'active', [user]);
  const { colors } = useMantineTheme();
  const { id } = useParams<{ id: string }>();
  const [getDocDetails, { data: doc, isLoading, isError }] = productDocsApi.useGetDocumentDetailsMutation();
  const [getDocProducts, { isLoading: isProductsLoading }] =
    productDocsApi.useGetProductsMutation();
  const products = useSelector(selectDocProducts);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<IDocumentProductsSearchRequest | undefined>();
  const [concat, setConcat] = useState(false);

  const handlePageChange = (page: number, isMore: boolean) => {
    setConcat(isMore);
    setCurrentPage(page);
  };

  const handleSearchChange = useCallback(
    debounce((e: IDocumentProductsSearchRequest) => {
      if (e.value.length > 2) setSearch(e);
      else if (search?.value && !e.value) setSearch(e);
    }, 500),
    [search]
  );

  const fetchProducts = () => {
    if (id) {
      getDocProducts({
        id,
        limit: 50,
        offset: (currentPage - 1) * 50,
        search,
        concat: concat,
      });
    }
    setConcat(false);
  };

  const fetchDoc = () => {
    if (id) {
      getDocDetails(id);
      fetchProducts();
    }
  };

  const handlerOpenAddModal = (docId: number) => {
    return (!isError)
      ? openAddProductModal(docId)
      : openLoadingError({ id: 'loadingError' });
  }

  const showFilter = useMemo(() => {
    const NO_PRODUCTS = products.meta.count === 0;
    const DEFAULT_SEARCH = !search || (search.type === 'name' && search.value === '');

    if (NO_PRODUCTS && DEFAULT_SEARCH) return false;
    return true;
  }, [search, products.meta.count]);

  useEffect(fetchDoc, []);
  useEffect(fetchProducts, [search, currentPage]);

  return (
    <Box pos="relative">
      <ProductDocsOperationsController onRefetch={fetchDoc} />
      <LoadingOverlay visible={isLoading} zIndex={100} />
      <PageHeader title={doc?.number ? `Документ ${doc?.number}` : ''} />

      <Stack spacing={16}>
        <Group spacing={16}>
          <Card bg="white" sx={{ flex: '526px 0 0' }} h={236}>
            {doc && <DocInfo doc={doc} onRefetch={fetchDoc} />}
          </Card>
          <Card sx={{ flex: '1 0 0 ' }} h={236}>
            {doc && doc.files && <FileList files={doc.files} id={doc.id} />}
          </Card>
        </Group>
        <PageContent>
          <Group p={24} position="apart">
            <Group spacing={24}>
              {showFilter && (
                <TypedInput
                  types={[
                    {
                      value: 'supplierProductId',
                      label: 'По артикулу',
                      placeholder: 'Введите артикул товара',
                      inputMode: 'numeric',
                    },
                    {
                      value: 'name',
                      label: 'По наименованию',
                      placeholder: 'Введите название товара',
                      inputMode: 'text',
                    },
                  ]}
                  onChange={handleSearchChange}
                  inputWidth={280}
                  clearable
                />
              )}
              <Text size={14} color={colors.gray[6]}>
                {Boolean(products?.meta.count) && (
                  <>
                    {pluralize(products?.meta.totalCount || 0, ['Найден', 'Найдено', 'Найдено'])}{' '}
                    {products?.meta.totalCount}{' '}
                    {pluralize(products?.meta.totalCount || 0, ['товар', 'товара', 'товаров'])}
                  </>
                )}
              </Text>
            </Group>
            <Button
              size="md"
              fz={14}
              w={172}
              h={48}
              onClick={() => {
                if (doc) handlerOpenAddModal(doc?.id)
              }}
              disabled={!isActiveSeller}
            >
              Загрузить товары
            </Button>
          </Group>
          <Box pos="relative">
            <LoadingOverlay visible={!isLoading && isProductsLoading} zIndex={100} />
            <TableCustom>
              {() => (
                <>
                  <THead>
                    <Row>
                      <Cell th sticky minWidth={300}>
                        Артикул
                      </Cell>
                      <Cell th sticky minWidth={300}>
                        Фото
                      </Cell>
                      <Cell th sticky grow>
                        Наименование товара
                      </Cell>
                    </Row>
                  </THead>
                  <TBody>
                    {products &&
                      products.items.map((product) => (
                        <Row key={product.supplierProductId}>
                          <Cell>{product.supplierProductId}</Cell>
                          <Cell>
                            <Image src={product.image} width={48} height={48} />
                          </Cell>
                          <Cell>{product.name}</Cell>
                        </Row>
                      ))}
                    {!products?.meta.count &&
                      (isProductsLoading ? (
                        <Row>
                          <Cell colspan={3}>
                            <Text align="center">Загрузка...</Text>
                          </Cell>
                        </Row>
                      ) : (
                        <Row>
                          <Cell colspan={3}>
                            <Text align="center" color={colors.brandGrey[8]}>
                              Нет записей
                            </Text>
                          </Cell>
                        </Row>
                      ))}
                  </TBody>
                </>
              )}
            </TableCustom>
          </Box>
          <PaginationCustom
            totalItems={products?.meta.totalCount || 0}
            limitItems={50}
            currentPage={currentPage}
            handler={handlePageChange}
          />
        </PageContent>
      </Stack>
    </Box>
  );
};
