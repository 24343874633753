import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
    table: {
        fontSize: '14px'
    },
    cell: {
        height: '44px'
    },
    cellTh:{
        fontWeight: 700
    },
    wrapper: {
        border: `1px solid ${theme.colors.brandGrey[7]}`,
        borderRadius: '12px',
        padding: '20px',
        height: '400px'
    }
}));