import { createStyles, getStylesRef } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  tab: {
    [`&[data-active] .${getStylesRef('supplies__count')}`]: {
      backgroundColor: 'white',
      color: theme.colors[theme.primaryColor][6],
      fontWeight: 600,
    },
  },
  count: {
    ref: getStylesRef('supplies__count'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 22,
    height: 22,
    borderRadius: '50%',
    backgroundColor: 'rgba(0,0,0, 0.2)',
    color: 'white',
    lineHeight: 1,
  },
}));
