import { IGetFormProductRes, IPostFormProductRes } from '@/types/api/product';
import { IProduct, IProductTab } from '@/types/entites/product';

import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { productUrls } from '@/api/urls/productUrls';

import useWebSocketWithLocalStorage from '@/hooks/useWebSocketWithLocalStorage';

import { openStatusNotification } from '@/store/slices/notification/slice';
import { productsApi } from '@/store/slices/products/api';
import {
  selectFormProductData,
  selectProductsCurrentStatus,
  selectSupplierProductId,
} from '@/store/slices/products/selectors';

export interface IFieldError extends Pick<IPostFormProductRes, 'errors'> {}

interface IProductContextType {
  data: IGetFormProductRes | undefined;
  editingProductData: IGetFormProductRes | undefined;
  currentTab: string | undefined;
  fieldValues: { [key: string]: any };
  changedFields: { [key: string]: any };
  errors: { [key: string]: string };
  isFetchingGetFormOfProduct: boolean;
  isFetchingGetFormOfEditingProduct: boolean;
  isFieldsFilledAllTab: boolean;
  isLoadingSave: boolean;
  setErrors: (errors: { [key: string]: string }) => void;
  setFieldValues: (values: { [key: string]: any }) => void;
  setChangedFields: (fields: { [key: string]: any }) => void;
  setIsLoadingSave: (isLoading: boolean) => void;
}

const ProductContext = createContext<IProductContextType | undefined>(undefined);

interface ProductProviderProps {
  children: ReactNode;
}

export const ProductProvider: React.FC<ProductProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const { tab } = useParams<{ tab: string }>();
  const dispatch = useDispatch();

  const formData = useSelector(selectFormProductData);
  const supplierProductId = useSelector(selectSupplierProductId);
  const currentStatus = useSelector(selectProductsCurrentStatus);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [currentTab, setCurrentTab] = useState<string | undefined>(tab);
  const [fieldValues, setFieldValues] = useState<{ [key: string]: string }>({});
  const [changedFields, setChangedFields] = useState<{ [key: string]: string }>({});
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
  const [hasNotification, setHasNotification] = useState(false);
  const newStatusNotificationText =
    'Изменился статус товара. Обновите страницу для дальнейшей работы с товаром';

  const changeFavicon = (iconURL: string) => {
    const link =
      (document.querySelector("link[rel*='icon']") as HTMLLinkElement) ||
      (document.createElement('link') as HTMLLinkElement);
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = `${iconURL}?v=${new Date().getTime()}`;
    document.getElementsByTagName('head')[0].appendChild(link);
  };

  const [getFormOfNewProduct, { data, isFetching: isFetchingGetFormOfProduct }] =
    productsApi.useLazyGetFormProductQuery();
  const [
    getFormProductById,
    { data: editingProductData, isFetching: isFetchingGetFormOfEditingProduct },
  ] = productsApi.useLazyGetFormProductByIdQuery();

  const isFieldsFilledAllTab = useMemo(() => {
    if (!data) return false;
    return data.tabs.reduce((result: boolean, productTab: IProductTab): boolean => {
      if (!productTab.fields || productTab.fields.length === 0) return result;
      const tabFields = productTab.fields.filter((field) => field.required);
      if (tabFields.length === 0) return result;
      const isTabFilled = tabFields.every((field) => fieldValues[field.code]);
      return result && isTabFilled;
    }, true);
  }, [data, fieldValues]);

  useEffect(() => {
    if (editingProductData) {
      const newFieldValues: { [key: string]: any } = {};
      editingProductData.tabs.forEach((t) => {
        t.fields?.forEach((field) => {
          if (field.required) {
            newFieldValues[field.code] = field.value;
          }
        });
      });
      setFieldValues(newFieldValues);
    }
  }, [editingProductData]);

  useWebSocketWithLocalStorage<IProduct>(
    productUrls.getProductStatusWebSoket,
    'productStatusUpdates',
    (productUpdate) => {
      if (
        productUpdate.supplierProductId === supplierProductId &&
        currentStatus?.code !== productUpdate.status.code
      ) {
        dispatch(openStatusNotification(newStatusNotificationText));
        setHasNotification(true);
        if (document.visibilityState === 'hidden') {
          changeFavicon('notificationsFavicon.ico');
        }
      }
    }
  );

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (hasNotification) {
          changeFavicon('favicon.ico');
          setHasNotification(false);
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [hasNotification]);

  useEffect(() => {
    if (formData) {
      getFormOfNewProduct({
        familyCode: formData?.familyCode,
        categoryCode: formData?.categoryCode,
      });
    }
  }, [getFormOfNewProduct]);

  useEffect(() => {
    setCurrentTab(tab);
  }, [tab]);

  useEffect(() => {
    let familyCode = formData?.familyCode;
    let categoryCode = formData?.categoryCode;
    let supplierId = supplierProductId;

    if (!familyCode || !categoryCode) {
      familyCode = localStorage.getItem('familyCode');
      categoryCode = localStorage.getItem('categoryCode');
    } else {
      localStorage.setItem('familyCode', familyCode);
      localStorage.setItem('categoryCode', categoryCode);
    }

    if (!supplierId) {
      supplierId = localStorage.getItem('supplierProductId');
    } else {
      localStorage.setItem('supplierProductId', supplierId);
    }

    if (familyCode && categoryCode) {
      getFormOfNewProduct({ familyCode, categoryCode });
    }

    if (supplierId) {
      getFormProductById({ supplierProductId: supplierId });
    }
  }, [getFormOfNewProduct, getFormProductById, formData, supplierProductId]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const message = 'Изменения могут не сохранится.';
      event.returnValue = message;
      sessionStorage.setItem('navigateToProducts', 'true');
      return message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      localStorage.removeItem('supplierProductId');
    };
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('navigateToProducts') === 'true') {
      sessionStorage.removeItem('navigateToProducts');
      navigate('/products');
    }
  }, [navigate]);

  const contextValue = useMemo(
    () => ({
      data,
      editingProductData,
      currentTab,
      isFetchingGetFormOfProduct,
      isFetchingGetFormOfEditingProduct,
      isFieldsFilledAllTab,
      isLoadingSave,
      fieldValues,
      changedFields,
      errors,
      setErrors,
      setFieldValues,
      setChangedFields,
      setIsLoadingSave,
    }),
    [data, currentTab, fieldValues, changedFields, errors]
  );

  return <ProductContext.Provider value={contextValue}>{children}</ProductContext.Provider>;
};

export const useProductContext = (): IProductContextType => {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error('useProductContext error');
  }
  return context;
};
