import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  dropdown: {
    width: '100%',
    position: 'absolute',
    top: '100%',
    left: 0,
    transform: 'translateY(12px)',
    zIndex: 10,
    borderRadius: theme.radius.md,
    padding: 14,
    background: 'white',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.12)',
  },
  item: {
    padding: 4,
    fontSize: 14,
    borderRadius: theme.radius.xs,
    cursor: 'pointer',
    [':hover']: {
      background: theme.colors.brandGrey[3],
    },
  },
  label: {
    marginBottom: 8,
  },
  additinalLabel: {
    fontSize: 12,
  },
  highlight: {
    color: theme.colors.mainColor[8],
  },
}));
