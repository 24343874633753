import { InputModeType } from '@/types/common/common';

export enum ProfileTabCode {
  ABOUT = 'about',
  REQUISITES = 'requisites',
  DOCUMENTS = 'documents',
}

export enum ProfileFieldType {
  FILE = 'file',
  SELECT = 'select',
  STRING = 'string',
  TEXTAREA = 'textarea',
  PHONE = 'phone',
}

export type TProfileFieldOption = { value: string };

export type TFile = {
  id: string;
  originalName: string;
  extension: string;
  size: string;
  date: string;
  imageUrl?: string;
};

export interface IProfileBaseField {
  code: string;
  name: string;
  description?: string;
  type: ProfileFieldType;
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  clearable?: boolean;
  options?: TProfileFieldOption[];
  linkedWith?: { code: string; text: string };
  inputMode?: InputModeType;
}

export interface IProfileStringField extends IProfileBaseField {
  type: ProfileFieldType.STRING;
  values: string[];
}

export interface IPRofilePhoneField extends IProfileBaseField {
  type: ProfileFieldType.PHONE;
  values: string[];
}

export interface IProfileSelectField extends IProfileBaseField {
  type: ProfileFieldType.SELECT;
  options: TProfileFieldOption[];
  values: string[];
}

export interface IProfileFileField extends IProfileBaseField {
  type: ProfileFieldType.FILE;
  values: TFile[];
}

export type TProfileField =
  | IProfileStringField
  | IProfileSelectField
  | IProfileFileField
  | IPRofilePhoneField;

export type ProfileFieldValue = TProfileField['values'] extends Array<infer T> ? T | T[] : never;

export interface IProfile {
  docs: IProfileDocs;
  tabs: IPRofileTab[];
}

export interface IProfileDocs {
  offerDate: string | null;
  offerLink: string | null;
  offerName: string | null;
}

export interface IPRofileTab {
  code: ProfileTabCode;
  title: string;
  description?: string;
  confirmed: boolean;
  fields: TProfileField[];
}

export type TProfileTabsResponse = {
  offerDate: string | null;
  offerLink: string | null;
  offerName: string | null;
  tabs: IPRofileTab[];
};

export type SuggestionsEndpoint = 'bank'; // | 'company' | 'product' | ...etc;
export type SuggestionProps = {
  endpoint: SuggestionsEndpoint;
  params: Record<string, string | number>;
};
