import { IGetCategoryFamilyRes, TGetCategoriesTreeRes } from '@/types/api/category';

import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQuery } from '@/api/instance';
import { categoryUrls } from '@/api/urls/categoryUrls';

export const categoriesApi = createApi({
  reducerPath: 'categoriesApi',
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getCategoriesTree: builder.query<TGetCategoriesTreeRes, void>({
      query: () => categoryUrls.getCategoriesTree,
    }),
    getCategoriesFamily: builder.query<IGetCategoryFamilyRes[], string>({
      query: (category) => ({
        url: categoryUrls.getCategoryFamily,
        params: { category },
      }),
    }),
  }),
});
