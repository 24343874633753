import { TFilterProduct } from '@/types/api/product';

import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Button } from '@mantine/core';

import LoaderDots from '@/ui/atoms/LoaderDots/LoaderDots';

import { useStyles } from './styles';

import { AppDispatch } from '@/store';
import { getProductsDownload } from '@/store/slices/products/asyncActions';
import {
  selectIsExportActive,
  selectProductsLimit,
  selectProductsMeta,
  selectProductsOffset,
  selectProductsSelected,
} from '@/store/slices/products/selectors';
import { setExportState } from '@/store/slices/products/slice';
import { selectFilterProducts, selectSearchProducts } from '@/store/slices/search/selectors';

const DownloadProducts: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { classes } = useStyles();
  const [searchParams] = useSearchParams();
  const productsMeta = useSelector(selectProductsMeta);
  const searchData = useSelector(selectSearchProducts);
  const filterData = useSelector(selectFilterProducts);
  const selected = useSelector(selectProductsSelected);
  const productsLimit = useSelector(selectProductsLimit);
  const productsOffset = useSelector(selectProductsOffset);
  const isExportActive = useSelector(selectIsExportActive);

  const filters = useMemo(() => Object.values(filterData), [filterData]);

  let requestBody: TFilterProduct = {};

  const params = {
    limit: searchParams.get('limit') || productsLimit,
    offset: searchParams.get('offset') || productsOffset,
  };

  if (searchData?.type || searchData?.value) requestBody.search = searchData;
  if (filters.length > 0) requestBody.filter = [...filters];
  if (selected.length > 0)
    requestBody.filter = [
      ...filters,
      {
        fieldName: 'supplierProductId',
        fieldValue: selected.join(','),
      },
    ];

  const reqBody = Object.keys(requestBody).length ? requestBody : null;

  const handleDownloadProducts = () => {
    dispatch(getProductsDownload([params, reqBody]));
    dispatch(setExportState(true));
  };

  return (
    <Button
      variant="outline"
      classNames={{ root: classes.button }}
      onClick={handleDownloadProducts}
      disabled={isExportActive || productsMeta.count <= 0}
    >
      <LoaderDots loading={isExportActive} />
      Скачать
    </Button>
  );
};

export default DownloadProducts;
