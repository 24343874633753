import { FC } from "react";
import { useDocumentTitle } from "@mantine/hooks";

import DocumentsPage from "@/ui/pages/DocumentsPage/DocumentsPage";


const Documents: FC = () => {
    useDocumentTitle('Финансовые документы');

    return (
        <DocumentsPage title={'Финансовые документы'} />
    )
}

export default Documents;