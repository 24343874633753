import { InputMaskState } from 'react-input-mask';

export function onPastePhoneNormalizer(
  newState: InputMaskState,
  oldState: InputMaskState,
  userInput: string | null
): InputMaskState {
  if (!userInput) return newState;
  const maxlen = 10;
  const unmaskedOldValue = oldState.value.replace(/[^0-9]/g, '');
  const value = userInput.trim().replace(/[^0-9]/g, '');
  const isSelectedFromStart = !oldState.selection || oldState.selection.start <= 4;
  const selectionLength = oldState.selection
    ? oldState.value.slice(oldState.selection.start, oldState.selection.end).replace(/[^0-9]/g, '')
        .length
    : 0;
  const shouldTrimPrefix = value.length > maxlen;
  const isCurrentValueEmpty = oldState.value === '+7 (' || oldState.value === '';
  const prefixesToTerminate = ['8', '7', '+7'];

  function getModifiedState(state: Partial<InputMaskState>, selection = oldState.selection): InputMaskState {
    return { ...newState, ...state, selection };
  }

  // function getModifiedState(state: Partial<InputMaskState>): InputMaskState {
  //   return { ...newState, ...state };
  // }

  function constructPhone(str: string) {
    return `+7 (${str.slice(0, 3)}) ${str.slice(3, 6)}-${str.slice(6, 8)}-${str.slice(8, 11)}`;
  }

  if (!isCurrentValueEmpty && !isSelectedFromStart) return newState;

  if (shouldTrimPrefix) {
    for (let i in prefixesToTerminate) {
      const prefix = prefixesToTerminate[i];
      if (value.startsWith(prefix)) {
        let slicedValue = value.slice(prefix.length);
        let suffix = '';

        if (isSelectedFromStart && oldState.selection?.end !== oldState.selection?.start) {
          const pastedValueMaxLen = selectionLength + maxlen - unmaskedOldValue.length;
          slicedValue = slicedValue.slice(0, pastedValueMaxLen);
          suffix = unmaskedOldValue.slice(slicedValue.length);
        }

        return getModifiedState({
          value: constructPhone(slicedValue + suffix),
        });
      }
    }
  }

  return newState;
}
