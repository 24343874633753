import { FC, ReactNode } from 'react';
import { Box, Flex, Text, UnstyledButton } from '@mantine/core';

import { useStyles } from './styles';
interface IModalStepProps {
  title?: string;
  titleSub?: string;
  size?: 'md' | 'lg';
  description?: string;
  buttonText?: string;
  children?: ReactNode;
  borderBottom?: boolean;
  onClick?: () => void;
}

const ModalStep: FC<IModalStepProps> = ({
  title,
  titleSub,
  description,
  children,
  buttonText,
  size,
  borderBottom,
  onClick,
}) => {
  const { classes } = useStyles({ size: size || '', borderBottom: !!borderBottom });

  return (
    <Box className={classes.root}>
      <Flex direction='column' align='flex-start' className={classes.header}>
        {title && <Text className={classes.title}>{title}</Text>}
        {titleSub && <Text className={classes.subTitle}>{titleSub}</Text>}
      </Flex>
      {buttonText && (
        <UnstyledButton className={classes.link} onClick={onClick}>
          {buttonText}
        </UnstyledButton>
      )}
      {description && <Text className={classes.description}>{description}</Text>}
      <Box>{children}</Box>
    </Box>
  );
};

export default ModalStep;
