import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { AppShell, LoadingOverlay } from '@mantine/core';
import { modals } from '@mantine/modals';

import { useAuth } from '@/hooks/useAuth';
import useEmailConsent from '@/hooks/useEmailConsent';
import useHistoryListener from '@/hooks/useHistoryListener';

import LayoutHeader from './components/LayoutHeader/LayoutHeader';

import LiveTexScript from '@/scripts/LiveTexScript';

const Layout: FC = () => {
  useEmailConsent();
  useHistoryListener({ onCloseCallback: () => modals.closeAll() });
  const isAuth = useAuth();

  return isAuth ? (
    <AppShell fixed={false} header={<LayoutHeader />}>
      <LiveTexScript />
      <Outlet />
    </AppShell>
  ) : (
    <LoadingOverlay visible />
  );
};

export default Layout;
