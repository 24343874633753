import PageContent from '@/ui/organisms/Page/components/PageContent/PageContent';
import PageHeader from '@/ui/organisms/Page/components/PageHeader/PageHeader';
import Page from '@/ui/organisms/Page/Page';
import { InfoPage } from '@/ui/pages/InfoPage/InfoPage';

export const Info = () => (
  <Page>
    <PageHeader title="Инструкция для продавца" />
    <PageContent>
      <InfoPage />
    </PageContent>
  </Page>
);
