import { IUser } from '@/types/api/user';

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { SELLER_STATUS_INACTIVE } from '@/constants/common';


type INavigateToProfile = () => void;

const useNavigateToProfile = (user: IUser | null): INavigateToProfile => {
    const navigate = useNavigate();

    const navigateToProfile = useCallback(() => {
        if (user?.sellerStatus === SELLER_STATUS_INACTIVE) {
            navigate('/profile/about', { replace: false });
        }
        else navigate('/profile', { replace: false });
    }, [user, navigate]);

    return navigateToProfile;
};

export default useNavigateToProfile;
