import {
  createStyles,
  CSSObject,
  getStylesRef,
  MantineNumberSize,
  MantineSize,
} from '@mantine/core';

interface EnhancedInputStyleProps {
  floatingLabel?: boolean;
  size?: MantineSize;
  h?: MantineNumberSize;
  tooltipLabel?: boolean;
}

export const useStyles = createStyles<
  'label' | 'wrapper' | 'input' | 'rightSection' | 'error' | 'tooltip' | 'buttonTooltip' | 'labelText',
  EnhancedInputStyleProps
>((theme, { floatingLabel, size, h, tooltipLabel }) => {
  const getLabelFontSize = () => {
    if (size === 'xs') return '0.75rem';
    if (size === 'sm') return '0.875rem';
    if (size === 'md') return '0.875rem';
    if (size === 'lg') return '1rem';
    if (size === 'xl') return '1.25rem';
    return '1rem';
  };
  const labelProps: CSSObject = floatingLabel
    ? {
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      transition: '0.2s',
      pointerEvents: tooltipLabel ? 'all' : 'none',
      transformOrigin: 'top left',
      fontSize: getLabelFontSize(),
    }
    : {};

  const wrapperProps: CSSObject = floatingLabel
    ? {
      position: 'relative',
      [`&:focus-within .${getStylesRef('label')},
          &[data-value="true"] .${getStylesRef('label')}`]: {
        transform: 'translateY(0)  scale(0.7)',
        top: 8,
        color: theme.colors.gray[8],
      },
      [`&[data-value="true"] .${getStylesRef('label')}:after`]: {
        opacity: 0,
      },
      [`.${getStylesRef('input')}::placeholder`]: {
        opacity: 0,
        transition: '0.1s',
      },
      [`&:focus-within .${getStylesRef('input')}::placeholder`]: {
        opacity: 1,
      },
    }
    : {};

  return {
    input: {
      paddingTop: '0.8em',
      height: h || '3em',
      fontSize: '16px',
      ref: getStylesRef('input'),
    },
    label: {
      ref: getStylesRef('label'),
      color: theme.colors.gray[5],
      position: 'relative',
      ...labelProps,
    },
    labelText: {
      ref: getStylesRef('labelText'),
    },
    wrapper: {
      position: 'relative',
      ref: getStylesRef('wrapper'),
      ...wrapperProps,
      [`&[data-required="true"] .${getStylesRef('labelText')}:after`]: {
        content: '""',
        position: 'absolute',
        top: 0,
        right: tooltipLabel ? 'auto' :`-0.8em`,
        width: '0.4em',
        height: '0.4em',
        borderRadius: '50%',
        backgroundColor: theme.colors.red[6],
        transition: '0.2s',
      },
    },
    rightSection: {
      color: theme.colors.gray[5],
      padding: '0px 2px',
      width: '2rem',
    },
    error: {
      position: 'absolute',
      bottom: 0,
      transform: 'translateY(calc(100% + 5px))',
    },
    tooltip: {
      padding: '16px 24px',
      borderRadius: '12px',
      backgroundColor: '#575757',
      backdropFilter: 'blur(8px)',
      color: theme.colors.customWhite[0],
      fontSize: '14px',
      lineHeight: '20px',
      maxWidth: '530px',
    },
    buttonTooltip: {
      // fontSize: 14,
      // textAlign: 'center',
      // height: 'auto',
      // padding: '2px 2px',
      // color: theme.colors.brandDark[9],
      // borderRadius: '50%',
      // '&:hover': {
      //   backgroundColor: theme.colors.brandGrey[1],
      // },
    },
  };
});
