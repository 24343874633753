import { FC } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Input, Text, useMantineTheme } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { formatedPhone } from '@/utils/formatedPhone';
import reCaptchaVerify from '@/utils/reCaptchaVerify';

import { SIGNIN_WITH_PHONE } from '@/constants/validationSchemas';

import { PhoneInput } from '@/ui/organisms/PhoneInput/PhoneInput';

import AuthFooter from '../AuthFooter/AuthFooter';

import { AppDispatch } from '@/store';
import { checkPhoneAction } from '@/store/slices/auth/asyncAction';
import {
  selectFetchingCheckPhone,
  selectUserPhone,
  setRegbyPhone,
  setUserPhone,
} from '@/store/slices/auth/auth';

const SignInWithPhone: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const phoneFetching = useSelector(selectFetchingCheckPhone);
  const userPhone = useSelector(selectUserPhone);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { colors } = useMantineTheme();

  const handleSubmit = (values: Record<string, string>) => {
    reCaptchaVerify(executeRecaptcha, 'checkPhone').then(async (recaptchaToken) => {
      if (recaptchaToken) {
        const sendData = {
          phone: formatedPhone(values.phone),
          recaptchaToken,
          sendAuthCode: false,
        };
        dispatch(setUserPhone(values.phone));
        try {
          await dispatch(checkPhoneAction(sendData));
          dispatch(setRegbyPhone(true));
        } catch {}
      }
    });
  };

  const form = useForm({
    initialValues: {
      phone: userPhone || '',
    },
    validate: yupResolver(SIGNIN_WITH_PHONE),
  });

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} noValidate={true}>
      <Input.Wrapper required mx="auto">
        <PhoneInput
          name="phone"
          type="input"
          label="Телефон"
          labelType="floating"
          size="md"
          autoComplete="off"
          {...form.getInputProps('phone')}
          unmask
        />
      </Input.Wrapper>
      <AuthFooter>
        <Button
          w={'100%'}
          loading={phoneFetching}
          disabled={!form.isValid()}
          type="submit"
          size="md"
          variant="filled"
          fullWidth
          fz={14}
          h={48}
        >
          Продолжить
        </Button>
      </AuthFooter>
    </form>
  );
};

export default SignInWithPhone;
