import { isRejectedWithValue } from '@reduxjs/toolkit';
import { Middleware } from 'redux';

import notify from '@/utils/notify';

import { openRequestErrorModal } from '@/ui/organisms/Modals/modals';

export const errornotifier: Middleware = () => (next) => (action) => {
  const ignoreErrors = action.meta?.baseQueryMeta?.ignoreErrors || false;

  if (isRejectedWithValue(action) && !ignoreErrors) {
    const errorMsg = action.meta?.baseQueryMeta?.errorMessage || action.payload;
    const errorType = action.meta?.baseQueryMeta?.errorType || 'notify';
    if (errorType === 'notify') notify({ message: errorMsg, type: 'error' });
    if (errorType === 'modal') openRequestErrorModal(errorMsg);
  }

  return next(action);
};
