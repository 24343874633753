import {
    IGetProductCheckArticleParams,
    IGetProductCheckArticleRes,
    IGetProductInstructionRes,
    IGetProductParams,
    IGetProductRes,
    IGetProductsBody,
    IGetProductStatusesRes,
    IOperationResponse,
    IPatchUpdateStatusBody,
    IPatchUpdateStatusRes,
    IPostPricesImport,
    IPostProductsImport
} from '@/types/api/product';

import api from '../instance';
import { productUrls } from '../urls/productUrls';

import { IGetProductsTemplateBody, IGetProductsTemplateRes } from './../../types/api/product';
import { tryAction } from './../tryAction';

const headersImport = {
    'Accept': 'application/json',
    'Content-Type': 'multipart/form-data'
}

export const tryGetProducts = (
    params: IGetProductParams,
    body?: IGetProductsBody,
) =>
    tryAction(
        api.post<IGetProductRes>(productUrls.getProducts, body, { params: params })
    )

export const tryGetDownloadProducts = (
    params: IGetProductParams,
    body?: IGetProductsBody,
) =>
    tryAction(
        api.post<IOperationResponse>(productUrls.postProductsExport, body, { params: params })
    )

export const tryGetProductStatuses = () =>
    tryAction(
        api.get<IGetProductStatusesRes[]>(productUrls.getProductStatuses)
    )

export const tryUpdateSaleRemove = (
    body: IPatchUpdateStatusBody[]
) =>
    tryAction(
        api.patch<IPatchUpdateStatusRes>(productUrls.patchSaleRemove, body)
    )

export const tryUpdateSaleReturn = (
    body: IPatchUpdateStatusBody[]
) =>
    tryAction(
        api.patch<IPatchUpdateStatusRes>(productUrls.patchSaleReturn, body)
    )

export const tryUpdateArchiveAdd = (
    body: IPatchUpdateStatusBody[]
) =>
    tryAction(
        api.patch<IPatchUpdateStatusRes>(productUrls.patchArchiveAdd, body)
    )

export const tryUpdateArchiveRestore = (
    body: IPatchUpdateStatusBody[]
) =>
    tryAction(
        api.patch<IPatchUpdateStatusRes>(productUrls.patchArchiveRestore, body)
    )

export const tryGetProductsTemplate = (
    body: IGetProductsTemplateBody
) =>
    tryAction(
        api.post<IGetProductsTemplateRes>(productUrls.getProductsTemplate, body)
    )

export const tryGetPricesTemplate = () =>
    tryAction(
        api.get<IGetProductsTemplateRes>(productUrls.getPricesTemplate)
    )


export const tryPostPricesImport = (
    body: IPostPricesImport
) =>
    tryAction(
        api.post<IGetProductsTemplateRes>(productUrls.postPricesImport, body, {
            headers: headersImport
        })
    )

export const tryPostProductsImport = (
    body: IPostProductsImport
) =>
    tryAction(
        api.post<IGetProductsTemplateRes>(productUrls.postProductsImport, body, {
            headers: headersImport
        })
    )

export const tryGetProductCheckArticle = (
    params: IGetProductCheckArticleParams
) =>
    tryAction(
        api.get<IGetProductCheckArticleRes>(productUrls.getCheckArticle, { params })
    )

export const tryGetProductInstruction = () =>
    tryAction(
        api.get<IGetProductInstructionRes>(productUrls.getProductInstruction)
    )


