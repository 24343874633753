import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: 'inherit',
  },
  text: {
    color: theme.colors.mainColor[8],
    fontWeight: 700,
    fontSize: '27px',
    lineHeight: '32px',
  },
  subtext: {
    color: theme.colors.text[7],
    marginLeft: '10px',
  },
}));
