import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  box: {
    background: theme.colors.customWhite[0],
    fontSize: '14px',
    border: `1px solid ${theme.colors.brandGrey[9]}`,
    // boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)',
    borderRadius: '16px',
    padding: '12px',
    width: '196px',
    height: 'auto',
    position: 'absolute',
    right: '45px',
    zIndex: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    fontSize: 14,
    textAlign: 'center',
    padding: '12px',
    width: '100%',
    color: theme.colors.brandDark[9],
    borderRadius: '12px',
    '&:hover': {
      backgroundColor: theme.colors.brandGrey[1],
    },
  },
  disabledButton: {
    fontSize: 14,
    textAlign: 'center',
    padding: '12px',
    width: '100%',
    color: theme.colors.brandGrey[8],
    borderRadius: '12px',
  },
}));
