import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { getDocumentMock } from '@/utils/mocks';

import Page from '@/ui/organisms/Page/Page';
import { ProductDocDetailsPage } from '@/ui/pages/ProductDocDetailsPage/ProductDocDetailsPage';

export const ProductDocDetails = () => {
  const { id } = useParams<{ id: string }>();
  const doc = useMemo(() => getDocumentMock(id), []);
  return (
    <Page>
      <ProductDocDetailsPage doc={doc} />
    </Page>
  );
};
