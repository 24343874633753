import { FC, PropsWithChildren } from 'react';
import { Box, BoxProps } from '@mantine/core';

interface CardProps extends BoxProps {}

export const Card: FC<PropsWithChildren<CardProps>> = ({ children, ...props }) => {
  return (
    <Box bg="white" p={24} {...props} sx={{ borderRadius: 12, ...props.sx }}>
      {children}
    </Box>
  );
};
