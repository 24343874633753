import { FC, ReactNode } from "react";
import { Box, useMantineTheme } from "@mantine/core";

interface IAuthFooter {
    children: ReactNode;
    border?: boolean;
}

const AuthFooter: FC<IAuthFooter> = ({ children, border = false }) => {
    const theme = useMantineTheme();
    return (
        <Box sx={{
            ...(border && { borderTop: `1px solid ${theme.colors.brandGrey[3]}` }),
            textAlign: 'center',
            padding: '32px 0px 20px'
        }}>
            {children}
        </Box>
    )
}

export default AuthFooter;