import { FC, MouseEventHandler } from 'react';
import { ButtonProps, UnstyledButton, useMantineTheme } from '@mantine/core';
import noop from 'lodash/noop';

interface AddProductsButtonProps extends ButtonProps {
  onClick?: () => void;
}

export const AddProductsButton: FC<AddProductsButtonProps> = ({ onClick = noop, ...props }) => {
  const { colors } = useMantineTheme();

  const clickHandler: MouseEventHandler = (e) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <UnstyledButton
      {...props}
      sx={{
        fontSize: 14,
        color: colors.blue[8],
        ['&:hover:not(:disabled)']: { textDecoration: 'underline' },
        ['&:disabled']: { color: colors.gray[5] },
      }}
      onClick={clickHandler}
    >
      Добавить
    </UnstyledButton>
  );
};
