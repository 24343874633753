import { ICategory, ICategoryTree } from '@/types/entites/category';

const findElementInTree = (categories: ICategoryTree[], code: string) => {
    let findElement = {} as ICategory;
    categories.forEach(({ tree }) => {
        tree.forEach((el) => {
            if (el.code == code) findElement = el
        });
    });
    return findElement;
}

export default findElementInTree;