import { IOptions } from '@/types/common/common';

import { FC, forwardRef, useMemo, useState } from 'react';
import { ActionIcon, clsx, Group, Select, SelectProps } from '@mantine/core';
import { Box } from '@mantine/core';
import { Text } from '@mantine/core';
import cn from 'classnames';
import omit from 'lodash/omit';
import { Check, X } from 'tabler-icons-react';

import { useStyles } from './styles';

import { ReactComponent as ArrowBlack } from '@/assets/icons/chevron_bottom.svg';

interface RightSectionProps {
  clearable: boolean;
  onClear?: () => void;
}

const RightSection: FC<RightSectionProps> = ({ clearable, onClear }) => {
  return (
    <Group sx={{ pointerEvents: 'none' }}>
      <ArrowBlack />
      {clearable && (
        <ActionIcon sx={{ pointerEvents: 'all', color: 'inherit' }} onClick={onClear}>
          <X size={22} />
        </ActionIcon>
      )}
    </Group>
  );
};

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  selected?: boolean;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(({ label, selected, ...rest }, ref) => {
  return (
    <Group ref={ref} {...rest} position="apart" spacing={16} display={'flex'} noWrap>
      <Text truncate>{label}</Text>
      {selected && <Check stroke="white" size={16} />}
    </Group>
  );
});

interface ISelectCustomProps extends Omit<SelectProps, 'data'> {
  className?: string;
  options: IOptions[];
  optionValue?: string | null;
  radius?: number;
  placeholder?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  disabled?: boolean;
  onChange?: (value: string | null) => void;
  withinPortal?: boolean;
  prefix?: string;
  tooltipText?: string;
}

const SelectCustom: FC<ISelectCustomProps> = ({
  options,
  placeholder,
  className,
  optionValue = null,
  size,
  radius,
  disabled,
  onChange,
  prefix,
  withinPortal,
  ...props
}) => {
  const [selectValue, setSelectValue] = useState<string | null>(optionValue || '');
  const defaultValue = useMemo(() => props.defaultValue || undefined, [props.defaultValue]);

  const displayValue = useMemo(() => {
    return selectValue || defaultValue || '';
  }, [defaultValue, selectValue]);

  const handlerChange = (value: string | null) => {
    setSelectValue(value);
    onChange?.(value);
  };
  const { tooltipText, ...selectProps } = props;

  const searchValue = useMemo(() => {
    const currentOption = options.find((opt) => opt.value === displayValue);
    if (!currentOption) return displayValue;
    return (prefix || '') + currentOption.label;
  }, [prefix, displayValue]);

  const showClearButton = useMemo(
    () => Boolean(props.clearable && displayValue),
    [props.clearable, displayValue]
  );

  const { classes } = useStyles({ size, showClearButton, placeholder: !!placeholder });

  const handleClear = () => {
    onChange?.(null);
    setSelectValue(null);
  };

  return (
    <Box className={classes.wrapper}>
      <Box
        className={classes.valueHolder}
        data-value={searchValue}
        data-disabled={disabled}
        data-error={Boolean(props.error)}
      />
      {props.label && (
        <label
          className={clsx(classes.label, props.classNames?.label)}
          data-required={props.required}
        >
          {props.label}
        </label>
      )}
      <Select
        {...omit(selectProps, 'label')}
        multiple
        itemComponent={SelectItem}
        radius={radius}
        placeholder={placeholder}
        onChange={(value) => handlerChange(value)}
        data-tooltip-text={tooltipText}
        defaultValue={defaultValue}
        value={displayValue}
        data={options}
        searchValue={searchValue}
        rightSection={<RightSection clearable={showClearButton} onClear={handleClear} />}
        classNames={{
          root: cn(classes.root, className),
          item: classes.selectItem,
          input: classes.selectInput,
          label: classes.label,
          icon: classes.icon,
          rightSection: classes.rightSection,
        }}
        disabled={disabled || false}
        withinPortal={withinPortal}
        creatable
        dropdownComponent={Box}
      />
    </Box>
  );
};

export default SelectCustom;
