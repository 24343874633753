import { Box } from '@mantine/core';
import { DefaultMantineColor, getStylesRef, MantineThemeOverride, Tuple } from '@mantine/core';
import { DeepPartial } from 'redux';

export const colors: Record<string, Tuple<string, 10>> = {
  text: [
    '#e6f4ff',
    '#c6daed',
    '#a3c2db',
    '#80a9cc',
    '#5d91bd',
    '#617381',
    '#345d80',
    '#1D364B',
    '#122839',
    '#000e18',
  ],
  brandGrey: [
    '#f4f8f9', //0
    '#ecf1f3', //1
    '#18448F1A', //2
    '#e8ecf0', //3
    '#adc0cc', //4
    '#8da2b5', //5
    '#0000005C', //6
    '#0000000A', //7
    '#00000080', //8
    '#F5F5F5', //9
  ],
  mainColor: [
    '#edfbff', //0
    '#d6F4ff', //1
    '#B5edff', //2
    '#83e4ff', //3
    '#48d2ff', //4
    '#1eB5ff', //5
    '#18448F', //6 - main
    '#4669a5', //7
    '#0865c5', //8
    '#18448F80', //9
  ],
  customWhite: [
    '#FFFFFF',
    '#EFEFEF',
    '#DCDCDC',
    '#BDBDBD',
    '#989898',
    '#7C7C7C',
    '#656565',
    '#525252',
    '#464646',
    '#3D3D3D',
  ],
  brandBlue: [
    '#dbf4ff',
    '#addbff',
    '#7cc2ff',
    '#4aaaff',
    '#1a91ff',
    '#0078e6',
    '#005db4',
    '#004282',
    '#002851',
    '#000e21',
  ],
  brandOrange: [
    '#f3c0b6',
    '#fcc3b8',
    '#f49c8a',
    '#ee745d',
    '#e84e30',
    '#e4644c',
    '#a22811',
    '#751b0b',
    '#470f04',
    '#FFEC98',
  ],
  brandGreen: [
    '#4BB05F',
    '#EDF9EF',
    '#a5d9ae',
    '#83c88e',
    '#60b86f',
    '#479f55',
    '#367b41',
    '#24582e',
    '#143619',
    '#32D74B',
  ],
  brandDark: [
    '#F6F6F6',
    '#E7E7E7',
    '#D1D1D1',
    '#B0B0B0',
    '#888888',
    '#6D6D6D',
    '#5D5D5D',
    '#4F4F4F',
    '#454545',
    '#000000',
  ],
  brandRed: [
    '#FEF4F2',
    '#FDE7E3',
    '#FDD3CB',
    '#FAB4A7',
    '#F68873',
    '#EC6248',
    '#D94529',
    '#B6371F',
    '#97301D',
    '#7D2E1F',
  ]
};

export const theme: DeepPartial<MantineThemeOverride> = {
  fontFamily: '"Inter", sans-serif',
  headings: {
    fontFamily: '"Inter", sans-serif',
    sizes: {
      h1: { fontSize: '32px', lineHeight: '36px' },
      h2: { fontSize: '24px', lineHeight: '28px' },
    },
  },
  cursorType: 'pointer',
  lineHeight: 1,
  globalStyles: () => ({
    body: {
      backgroundColor: colors.brandGrey[9],
      color: colors.brandDark[9],
    },
    a: {
      color: colors.mainColor[6],
    },
  }),
  focusRingStyles: {
    resetStyles: () => ({ outline: 'none' }),
    styles: ({ colors: themeColors }) => ({
      outline: `${themeColors.mainColor[6]}`,
    }),
    inputStyles: () => ({
      borderBottomColor: `${colors.mainColor[6]}`,
      outline: 'none',
    }),
  },
  activeStyles: {
    backgroundColor: 'mainColor.6',
  },
  colors,
  primaryColor: 'mainColor',
  components: {
    TextInput: {
      styles: () => ({
        error: { position: 'absolute', bottom: 0, transform: 'translateY(calc(100% + 5px))' },
        description: {
          marginTop: 32,
        },
      }),
    },
    Image: {
      defaultProps: {
        withPlaceholder: true,
        placeholder: <Box />,
      },
    },
    Button: {
      styles: (_, __, { variant }) => {
        const commonStyles = {
          borderRadius: 9999,
          ':not([data-disabled])': {
            transition: '0.3s',
          },
        };

        const outlineStyles = { border: `1px solid rgba(0, 0, 0, 0.12)`, color: 'black' };

        const rootStyles =
          variant === 'outline' ? { ...commonStyles, ...outlineStyles } : commonStyles;

        return {
          root: rootStyles,
          label: {
            fontWeight: 'normal',
          },
        };
      },
      defaultProps: {
        size: 'md',
      },
    },
    UnstyledButton: {
      styles: (t) => ({
        root: {
          color: t.colors[t.primaryColor][6],
          fontWeight: 'normal',
        },
      }),
    },
    Input: {
      styles: () => ({
        wrapper: {
          marginBottom: '0px !important',
        },
        error: {
          position: 'absolute',
        },
        input: {
          borderRadius: 0,
          border: 0,
          borderBottom: `1px solid rgba(0, 0, 0, 0.2)`,
          paddingLeft: 0,
          ':disabled': {
            backgroundColor: 'transparent',
          },
        },
      }),
      defaultProps: {
        size: 'md',
      },
    },
    Tabs: {
      styles: (t) => ({
        tabsList: {
          marginRight: '-8px',
          padding: '24px !important',
        },
        tabLabel: {
          fontSize: '14px',
          lineHeight: '22px',
          ref: getStylesRef('tab__label'),
        },
        tab: {
          borderRadius: 9999,
          border: `1px solid rgba(0, 0, 0, 0.2)`,
          borderWidth: '1px !important',
          marginRight: 8,
          padding: '12px 24px !important',
          [`.${getStylesRef('tab__required')}`]: {
            svg: {
              stroke: 'white',
            },
            backgroundColor: t.colors.brandOrange[5],
          },

          '&[class*="tab__unstyled"]': {
            border: 'none',
            backgroundColor: 'transparent',
            color: '#000000',

            svg: {
              fill: 'currentColor',
            },

            '&:hover': {
              backgroundColor: 'transparent',
              color: t.colors.mainColor[6],
            },
          },

          '&[data-active]': {
            border: 'none',
            backgroundColor: t.colors[t.primaryColor][6],
            color: 'white',
            [`.${getStylesRef('tab__required')}`]: {
              svg: {
                stroke: t.colors[t.primaryColor][6],
              },
              backgroundColor: 'white',
            },

            ':hover': {
              backgroundColor: t.colors[t.primaryColor][7],
            },

            '&[class*="tab__unstyled"]': {
              border: 'none',
              backgroundColor: 'transparent',
              color: t.colors.mainColor[6],

              '&:hover': {
                backgroundColor: 'transparent',
                [`.${getStylesRef('tab__label')}`]: {
                  backgroundColor: 'transparent',
                  color: t.colors.mainColor[6],
                },
              },
            },

          },
        },

      }),
    },
    Tooltip: {
      styles: () => ({
        tooltip: {
          whiteSpace: 'normal',
          lineHeight: 1.4,
          color: 'white',
          background: colors.mainColor[9],
          backdropFilter: 'blur(8px)',
          padding: 16,
        },
      }),
    },
    ScrollArea: {
      styles: (t) => ({
        thumb: {
          zIndex: 10,
          backgroundColor: t.colors.brandGrey[6],
        },
        scrollbar: {
          zIndex: 50,
        },
        viewport: {
          padding: 0,
        },
      }),
    },
    Select: {
      styles: () => ({
        error: { position: 'absolute', bottom: 0, transform: 'translateY(calc(100% + 5px))' },
        dropdown: {
          padding: '8px',
          backgroundСolor: colors.customWhite[0],
          zIndex: 10,
        },
        rightSection: {
          pointerEvents: 'none',
        },
      }),
    },
    Checkbox: {
      styles: () => ({
        body: {
          display: 'flex',
          alignItems: 'center',
        },
        input: {
          '&[type="checkbox"]': {
            borderRadius: '2px',
            minWidth: 18,
            minHeight: 18,
            '&:checked': {
              backgroundColor: colors.brandGreen[0],
              borderColor: colors.brandGreen[0],
            },
            '&:disabled': {
              backgroundColor: colors.brandGrey[1],
              borderColor: colors.brandGrey[1],
            },
            '& + svg': {
              color: colors.customWhite[0],
              fill: colors.customWhite[0],
              width: '45%',
            },
          },
        },
        inner: {
          minWidth: 'inherit',
          minHeight: 'inherit',
          width: 'inherit',
          height: 'inherit',
        },
        label: {
          ref: getStylesRef('labelCheckbox'),
          paddingLeft: '12px',
          fontSize: '14px',
          lineHeight: '18px',
          color: colors.brandDark[9],
        },
      }),
    },
    Table: {
      styles: () => ({
        th: {
          borderBottom: 'none',
        },
      }),
    },
    AppShell: {
      styles: () => ({
        root: {
          width: '100%',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
        },
        body: {
          flex: 1,
          maxHeight: 'inherit',
        },
        main: {
          minHeight: 'inherit',
          width: '100%',
        },
      }),
    },
    Modal: {
      styles: () => ({
        root: {
          position: 'relative',
          zIndex: 390,
        },
        header: {
          padding: '31px 10px 0px',
        },
        inner: {
          left: 0,
          right: 0,
        },
        content: {
          maxWidth: '1920px !important',
        },
        //will need to remove after refactoring of modals
        close: {
          display: 'none',
        },
      }),
    },
  },
  // components: {
  //   Anchor: {
  //     defaultProps: {
  //       color: 'mainColor.9',
  //     },
  //   },
  //   Button: {
  //     defaultProps: {
  //       color: 'mainColor.8',
  //     },
  //   },
  //   Tabs: {
  //     defaultProps: {
  //       color: 'mainColor.8',
  //     },
  //   },
  //   TabsTab: {
  //     defaultProps: {
  //       color: 'mainColor.8',
  //     },
  //   },
  // },
};

type ExtendedCustomColors = keyof typeof colors | DefaultMantineColor;

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, Tuple<string, 10>>;
  }
}
