import { SupplyCar } from '@/types/api/supplies';

import { FC } from 'react';
import { Stack, UnstyledButton, useMantineTheme } from '@mantine/core';
import { Text } from '@mantine/core';

import { openSupplyCarForm } from '@/ui/organisms/Modals/modals';
import Cell from '@/ui/organisms/TableCustom/components/Cell/Cell';

interface SupplyCarCellProps {
  id: string;
  canSetCars: boolean;
  onRefetch?: () => void;
  cars?: SupplyCar[] | null;
}

export const SupplyCarCell: FC<SupplyCarCellProps> = ({ id, canSetCars, cars, onRefetch }) => {
  const { colors } = useMantineTheme();

  return (
    <Cell>
      <Stack spacing={8}>
        {cars && cars[0] ? (
          <UnstyledButton
            onClick={() => openSupplyCarForm({ id, cars, onSubmit: onRefetch })}
          >
            <Text size={14}>Изменить</Text>
          </UnstyledButton>
        ) :
          <UnstyledButton
            disabled={!canSetCars}
            sx={{color: !canSetCars ? `${colors.brandGrey[6]}` : ''}}
            onClick={() => openSupplyCarForm({ id, cars, onSubmit: onRefetch })}
          >
            <Text size={14}>Добавить</Text>
          </UnstyledButton>
        }
        {cars && cars[0] && (
          <Text size={12} color={colors.gray[7]}>
            {`${cars[0].number}, ${cars[0].model}`}
          </Text>
        )}
      </Stack>
    </Cell>
  );
};
