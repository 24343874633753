import { IEmailConfirmRequest } from '@/types/api/auth';
import {
  IChangePhoneRequest,
  IConfirmPhoneRequest,
  IPutConsentEmailReqParam,
  ISendSmsRequest,
  IUser,
} from '@/types/api/user';
import { IAsyncThunkDataWrapper, IAsyncThunkTypeEnter } from '@/types/common/common';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  putUserName,
  tryChangePhone,
  tryConfirmCurrentPhone,
  tryConfirmEmail,
  tryConsentEmail,
  tryGetUser,
  trySendSms,
} from '@/api/users/user';

import { createAxiosThunk } from '@/utils/asyncRequest';
import notify from '@/utils/notify';

import { NOTIFY_ALREDY_CONFIRM_EMAIL, NOTIFY_WILL_CONFIRM_EMAIL } from '@/constants/notifyMessages';

import { RootState } from '@/store';

interface IUserState {
  status: {
    fetchingUser: boolean;
    updatingUser: boolean;
    updateUserError: string | null;
  };
  user: IUser | null;
  oldUser: IUser | null;
}

const initialState: IUserState = {
  status: {
    fetchingUser: false,
    updatingUser: false,
    updateUserError: null,
  },
  user: null,
  oldUser: null,
};

export const updateUserName = createAxiosThunk('/updateUser', putUserName);


export const fetchUserAction = createAsyncThunk(
  '/users/current',
  async (data: IAsyncThunkTypeEnter<null | string>, { rejectWithValue }) => {
    const result = await tryGetUser();

    if (result) {
      if (data.type === 'signUp') {
      }
      if (data.type === 'authByPhone' || data.type === 'authByEmail') {
      }
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUser: () => initialState,
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserAction.pending, (state) => {
        state.status.fetchingUser = true;
        state.oldUser = state.user ? { ...state.user } : null;
      })
      .addCase(fetchUserAction.fulfilled, (state, action) => {
        state.status.fetchingUser = false;
        state.user = action.payload;
      })
      .addCase(fetchUserAction.rejected, (state) => {
        state.status.fetchingUser = false;
      })
      .addCase(updateUserName.pending, (state) => {
        state.status.updateUserError = null;
        state.status.updatingUser = true;
      })
      .addCase(updateUserName.fulfilled, (state) => {
        state.status.updatingUser = false;
      })
      .addCase(updateUserName.rejected, (state) => {
        state.status.updateUserError = 'Изменения не были сохранены. Попробуйте позже.';
      });
  },
});

// Selectors
type TSelectorState = { user: IUserState };

// Status selectors
export const selectFetchingUser = (state: TSelectorState) => state?.user?.status?.fetchingUser;

export const selectUser = (state: TSelectorState) => state?.user?.user;
export const selectUserId = (state: TSelectorState) => state?.user?.user?.id;
export const selectUserUpdateError = (state: TSelectorState) => state.user.status.updateUserError;
export const selectUpdatingUser = (state: TSelectorState) => state.user.status.updatingUser;

// Reducers and actions
export const { resetUser, setUser } = userSlice.actions;

export default userSlice.reducer;


export const sendSmsAction = createAsyncThunk(
  '/users/sendSms',
  async (data: IAsyncThunkDataWrapper<ISendSmsRequest>, { rejectWithValue }) => {
    const result = await trySendSms(data.value);

    if (result) {
      if (data.onSuccess) {
        data.onSuccess();
      }
      return result;
    } else {
      if (data.onFail) {
        data.onFail();
      }
      return rejectWithValue(null);
    }
  }
);

export const confirmCurrentPhoneAction = createAsyncThunk(
  '/users/confirmCurrentPhone',
  async (data: IAsyncThunkDataWrapper<IConfirmPhoneRequest>, { rejectWithValue }) => {
    const result = await tryConfirmCurrentPhone(data.value);

    if (result) {
      if (data.onSuccess) {
        data.onSuccess();
      }
      return result;
    } else {
      if (data.onFail) {
        data.onFail();
      }
      return rejectWithValue(null);
    }
  }
);

export const confirmEmailAction = createAsyncThunk(
  '/users/confirmEmail',
  async (data: IEmailConfirmRequest, { rejectWithValue, getState, dispatch }) => {
    const state = getState() as RootState;
    const result = await tryConfirmEmail(data);

    if (result) {
      await dispatch(fetchUserAction({ type: null }));
      
      //ToDo
      if (result.alreadyConfirmed) {
        notify({ type: 'success', message: NOTIFY_ALREDY_CONFIRM_EMAIL });
        //if companyEmail is alreadyConfirmed then we reset - 'companyEmailInConfirmation' for 'SubmitButton'
        if (state.user.user) dispatch(setUser({ ...state.user.user, companyEmailInConfirmation: null }))
      } else {
        notify({ type: 'success', message: NOTIFY_WILL_CONFIRM_EMAIL });
        if (state.user.user) dispatch(setUser({ ...state.user.user, companyEmailInConfirmation: `${data.companyEmail}` }))
      }

      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const consentEmailAction = createAsyncThunk(
  '/users/consentEmail',
  async (data: IAsyncThunkDataWrapper<IPutConsentEmailReqParam>, { rejectWithValue, getState, dispatch }) => {
    const fetchUserPromise = new Promise<RootState>((resolve) => {
      dispatch(fetchUserAction({ type: null })).then(() => {
        const newState = getState() as RootState;
        resolve(newState);
      });
    });

    try {
      const result = await tryConsentEmail(data.value);
      if (result) {
        if (data.onSuccess) {
          data.onSuccess();
        }
      }
      return result;
    } catch (error: any) {
      const newState = await fetchUserPromise;

      const confirmEmailData = {
        ...(newState.user.user?.companyInn ?
          { companyEmail: data.value.email.toString().trim() }
          :
          { emailSellerLogin: data.value.email.toString().trim() }),
      }
      if (error.response.status !== 404) {
        await dispatch(confirmEmailAction(confirmEmailData));
      }
      if (error.response && error.response.status === 404) {
        if (data.onFail) {
          data.onFail();
        }
      }
      return rejectWithValue(null);
    }
  }
);

export const changePhoneAction = createAsyncThunk(
  '/users/changePhone',
  async (data: IAsyncThunkDataWrapper<IChangePhoneRequest>, { rejectWithValue }) => {
    const result = await tryChangePhone(data.value);

    if (result) {
      if (data.onSuccess) {
        data.onSuccess();
      }
      return result;
    } else {
      if (data.onFail) {
        data.onFail();
      }
      return rejectWithValue(null);
    }
  }
);
