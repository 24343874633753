import { createStyles, MantineTheme } from '@mantine/core';

export const useStyles = createStyles((theme: MantineTheme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '0px 8px',
    a: {
      color: 'black',
    },

    '& span': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 14px',
    position: 'relative',
    whiteSpace: 'nowrap',
    borderRadius: 9999,
    border: `1px solid rgba(0, 0, 0, 0.12)`,
    fontSize: 14,
    lineHeight: '24px',
    color: theme.colors.brandDark[9],
    height: '49px',
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.brandDark[9],
      textDecoration: 'none',
    },
  },
  button: {
    fontSize: '14px',
    height: '49px',
    minHeight: '49px',
    overflow: 'hidden',
  },
  chevron: {
    marginLeft: 8,
    lineHeight: 0,
  },
  linkActive: {
    position: 'relative',
    color: `${theme.colors.customWhite[0]} !important`,
    backgroundColor: theme.colors.mainColor[6],
  },
  dropdownWrapper: {
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 100,
  },
  dropdown: {
    marginTop: 12,
    backgroundColor: theme.colors.customWhite[0],
    boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.12)',
    padding: '12px',
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '12px',
    marginBottom: -2,
  },
  dropdownLink: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    marginBottom: '2px',
    borderRadius: '8px',
    padding: '12px 8px',
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.brandDark[9],
      backgroundColor: theme.colors.brandGrey[9],
    },
  },
  disdropdownLink: {
    fontSize: 14,
    whiteSpace: 'nowrap',
    marginBottom: '2px',
    borderRadius: '8px',
    padding: '12px 8px',
    cursor: 'not-allowed',
    color: theme.colors.brandGrey[8],
  },
  dropdownLinkActive: {
    '&::after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      bottom: '0px',
      top: '50%',
      transform: 'translateY(-50%)',
      left: '-4px',
      right: 'auto',
      height: '24px',
      width: '4px',
      borderRadius: '4px',
      backgroundColor: theme.colors.mainColor[8],
    },
  },
}));
