import { FC, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Text, UnstyledButton } from '@mantine/core';
import classNames from 'classnames';

import { useStyles } from './styles';

import { ReactComponent as ArrowFilter } from '@/assets/icons/arrow_filter.svg';
import { selectProductsActiveSort, selectProductsOrder } from '@/store/slices/products/selectors';
import { setProductActiveSort, setProductsOrder, setProductsSort } from '@/store/slices/products/slice';

interface IFilterCell {
  text: string;
  sortName: string;
  columnCode: string;
}

const FilterCell: FC<IFilterCell> = ({ text, sortName, columnCode }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const searchOrder = useSelector(selectProductsOrder);
  const activeSort = useSelector(selectProductsActiveSort);
  const [, setSearchParams] = useSearchParams();

  const hanldlerFilterChange = (sort: string) => {
    dispatch(setProductsSort(sort));
    dispatch(setProductActiveSort(columnCode))
    dispatch(setProductsOrder(searchOrder === 'asc' ? 'desc' : 'asc'));
    setSearchParams((params) => {
      params.set('offset', '0');
      params.set('page', '1');
      return [...params.entries()];
    });
  };

  const active = useMemo(() => searchOrder !== 'asc', [searchOrder]);

  return (
    <UnstyledButton className={classes.button} onClick={() => hanldlerFilterChange(sortName)}>
      <Text sx={{ marginRight: '9px' }}>{text}</Text>
      <ArrowFilter className={classNames(classes.icon, { [classes.icon_active]: (activeSort === columnCode) && active })} />
    </UnstyledButton>
  );
};

export default FilterCell;
