import { FC, ReactNode } from "react";
import { Box, ScrollArea } from "@mantine/core";

interface ISCategoryGroupProps {
    className?: any,
    children: ReactNode
}

const CategoryGroup: FC<ISCategoryGroupProps> = ({ className, children }) => {
    return (
        <Box sx={{
            width: '33.33%'
        }}>
            <ScrollArea
                classNames={{
                    root: className.rootScrollBar,
                    scrollbar: className.scrollbar,
                    thumb: className.thumb,
                    corner: className.corner,
                }}
                type='always'
                offsetScrollbars
            >
                {children}
            </ScrollArea>
        </Box>
    )
}

export default CategoryGroup;