import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Box, clsx, Group, Header } from '@mantine/core';

import { SELLER_STATUS_ACTIVE } from '@/constants/common';
import { useAuth } from '@/hooks/useAuth';

import { Notification } from '../Notification/Notification';

import HeaderControls from './components/HeaderControls/HeaderControls';
import HeaderLogo from './components/HeaderLogo/HeaderLogo';
import HeaderMenu from './components/HeaderMenu/HeaderMenu';
import ProfileInfo from './components/ProfileInfo/ProfileInfo';
import { useStyles } from './styles';

import { selectUser } from '@/store/slices/user/slice';

const LayoutHeader: FC = () => {
  const user = useSelector(selectUser);
  const isAuth = useAuth();

  const { classes } = useStyles();

  return (
    <Header height={'84px'} className={classes.wrapper}>
      <Notification />
      <Box p={24} h="100%">
        <Group h="100%" pos="relative" position="center" noWrap>
          <Group spacing="24px" className={clsx(classes.stick, classes.stickLeft)}>
            <HeaderLogo />
            {user?.sellerStatus !== SELLER_STATUS_ACTIVE && isAuth && <ProfileInfo />}
          </Group>
          {isAuth && (
            <Group className={clsx(classes.stick, classes.stickRight)}>
              <HeaderControls />
            </Group>
          )}
          {isAuth && <HeaderMenu />}
        </Group>
      </Box>
    </Header>
  );
};

export default LayoutHeader;
