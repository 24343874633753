import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  formElement: {
    margin: '0px 0px 16px',
  },
  formGroup: {
    position: 'relative',
  },
  dropdown: {
    background: theme.colors.brandGrey[9],
    // boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.12)',
    position: 'absolute',
    zIndex: 100,
    maxWidth: '100%',
    width: '100%',
    padding: '8px',
    top: '56px',
    overflow: 'hidden',
  },
  checkbox: {
    margin: '10px 0px 0px'
  },
}));
