import { ID } from '@/types/common/common';

import { FC, useEffect } from 'react';
import {
  Button,
  LoadingOverlay,
  Stack,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { ContextModalProps, modals } from '@mantine/modals';

import notify from '@/utils/notify';

import ModalCLose from '../ModalClose/ModalClose';
import ModalContent from '../ModalContent/ModalContent';
import ModalFooter from '../ModalFooter/ModalFooter';

import { ReactComponent as IconModalBusket } from '@/assets/icons/redesign/iconModalBusket.svg';
import { productDocsApi } from '@/store/slices/productDocs/api';


interface ConfirmRemoveProps {
  docId: ID;
  onConfirm?: () => void;
}

export const ModalConfirmRemove: FC<ContextModalProps<ConfirmRemoveProps>> = ({
  context,
  id,
  innerProps: { docId, onConfirm },
}) => {
  const { colors } = useMantineTheme();
  const [removeDocument, { isSuccess, isLoading }] = productDocsApi.useRemoveDocumentMutation();

  useEffect(() => {
    if (isSuccess) {
      modals.close(id);
      notify({ message: 'Документ удалён', type: 'success' });
      onConfirm?.();
    }
  }, [isSuccess]);

  return (
    <ModalContent boxSize={535}>
      <LoadingOverlay visible={isLoading} zIndex={100} />
      <ModalCLose onClose={() => context.closeModal(id)} />
      <Stack align="center" spacing={32}>
        <Text px={64} align="center" size={24} weight={700} color={colors.text[7]}>
          Уверены, что хотите удалить документ?
        </Text>
        <IconModalBusket style={{ maxWidth: '100%' }} />
        <Text>Это действие будет необратимо</Text>

        <ModalFooter align='center' variant='xs'>
          <Stack spacing={10} w="100%" align='center'>
            <Button size="md" miw={280} onClick={() => context.closeModal(id)}>
              Отмена
            </Button>
            <UnstyledButton
              onClick={() => removeDocument(docId)}
              sx={{
                color: colors.brandRed[6]
              }}
            >
              Удалить
            </UnstyledButton>
          </Stack>
        </ModalFooter>

      </Stack>
    </ModalContent>
  );
};
