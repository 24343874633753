export const MODAL_TITLE_CATEGORIES = 'Выбрать категорию';
export const MODAL_TITLE_EANS = 'Штрихкод товара';
export const MODAL_TITLE_ARCHIVE = 'Переместить в архив?';
export const MODAL_TITLE_UPLOAD = 'Загрузка каталога';
export const MODAL_TITLE_PRICES = 'Установить/обновить цены';

export const MODAL_ID_EANS = 'eans';
export const MODAL_ID_CATERORY = 'category';
export const MODAL_ID_ARCHIVE = 'archive';
export const MODAL_ID_UPLOAD = 'upload';
export const MODAL_ID_UPDATE_PRICES = 'update_prices';
export const MODAL_ID_ABOUT = 'about';
export const MODAL_ID_INSTRUCTION = 'instruction';
export const MODAL_ID_SUCCESS = 'success';