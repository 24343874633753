import { AuthStepsEnum } from '@/types/api/auth';

import { FC } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Input, UnstyledButton, useMantineTheme } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { formatedPhone } from '@/utils/formatedPhone';
import reCaptchaVerify from '@/utils/reCaptchaVerify';

import { PHONE_LENGTH, SIGNIN_WITH_PHONE_CODE } from '@/constants/validationSchemas';

import InputWithLabel from '@/ui/atoms/InputWithLabel/InputWithLabel';
import { PhoneInput } from '@/ui/organisms/PhoneInput/PhoneInput';

import AuthFooter from '../AuthFooter/AuthFooter';

import { AppDispatch } from '@/store';
import { authByPhoneAction } from '@/store/slices/auth/asyncAction';
import {
  selectFetchingAuthByPhone,
  selectRegByPhone,
  selectUserPhone,
  setAuthStep,
  setUserPhone,
} from '@/store/slices/auth/auth';

const SignInWithPhoneCode: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const authFetching = useSelector(selectFetchingAuthByPhone);
  const userPhone = useSelector(selectUserPhone);
  const regByPhone = useSelector(selectRegByPhone);
  const theme = useMantineTheme();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const sendData = async (recaptchaToken: string, values: Record<string, string>) => {
    const formattedUserPhone = formatedPhone(values.phone);
    const data = {
      phone: formattedUserPhone,
      password: values.password,
      recaptchaToken,
    };

    try {
      dispatch(setUserPhone(formattedUserPhone));
      await dispatch(authByPhoneAction({ value: data }));
    } catch (error) { }
  };

  const handleSubmit = (values: Record<string, string>) => {
    reCaptchaVerify(executeRecaptcha, 'authByPhoneAction').then((recaptchaToken) => {
      if (recaptchaToken) {
        sendData(recaptchaToken, values);
      }
    });
  };

  const form = useForm({
    initialValues: {
      phone: userPhone,
      password: '',
    },
    validateInputOnBlur: true,
    validateInputOnChange: true,
    validate: yupResolver(SIGNIN_WITH_PHONE_CODE),
  });

  const handleForgetPassword = () => {
    dispatch(setAuthStep(AuthStepsEnum.RESTORE_PASSWORD_PHONE_CODE));
    dispatch(setUserPhone(form.getInputProps('phone').value));
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} noValidate={true}>
      <Box>
        <Input.Wrapper required mx="auto">
          <PhoneInput
            labelType="floating"
            label="Телефон"
            type="input"
            size="md"
            fz={14}
            autoComplete="off"
            {...form.getInputProps('phone')}
            disabled={regByPhone}
            sx={{ margin: '0px 0px 16px', fontSize: '14px' }}
            unmask
          />
        </Input.Wrapper>
        <InputWithLabel
          label="Пароль"
          inputtype="password"
          size="md"
          autoComplete="off"
          {...form.getInputProps('password')}
        />
      </Box>
      <AuthFooter>
        <Button
          loading={authFetching}
          disabled={!form.isValid()}
          type="submit"
          size="lg"
          variant="filled"
          fullWidth
          h={48}
          fz={14}
          mb={16}
        >
          Войти
        </Button>

        {!form.getInputProps('phone').error &&
          form.getInputProps('phone').value.length == PHONE_LENGTH && (
            <UnstyledButton
              type="button"
              variant="subtle"
              onClick={handleForgetPassword}
              w={'100%'}
              ta={'center'}
              fz={14}
              color={theme.colors.mainColor[6]}
            >
              Не помню пароль
            </UnstyledButton>
          )}
      </AuthFooter>
    </form>
  );
};

export default SignInWithPhoneCode;
