import { FC, ReactNode } from 'react';
import { ActionIcon, Box, Grid, Text, Title, useMantineTheme } from '@mantine/core';
import { ArrowLeft } from 'tabler-icons-react';

interface IAuthHeaderProps {
  title?: ReactNode | string | number;
  description?: ReactNode | string | number;
  onBack?: () => void;
}

const AuthHeader: FC<IAuthHeaderProps> = ({ title, description, onBack }) => {
  const theme = useMantineTheme();

  const handleBack = () => {
    if (onBack) onBack();
  };

  return (
    <Grid pos={'relative'} justify="center">
      {onBack && (
        <ActionIcon
          title="Назад"
          onClick={handleBack}
          variant="subtle"
          pos={'absolute'}
          left={0}
          mr={'auto'}
        >
          <ArrowLeft color={theme.colors.brandGrey[5]} size={22} />
        </ActionIcon>
      )}

      <Box ta={'center'} p={'0px 20px'}>
        <Title order={3} mb={32}>
          {title}
        </Title>
        {description && (
          <Text fz={14} align="center" mb={32} lh={'20px'} color={theme.colors.brandGrey[5]}>
            {description}
          </Text>
        )}
      </Box>
    </Grid>
  );
};

export default AuthHeader;
