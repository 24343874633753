import { INotificationData } from '@/types/api/notificaton';

import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ActionIcon, Box, Flex, Text, useMantineTheme } from '@mantine/core';
import { getCookie, setCookie } from 'cookies-next';
import { X } from 'tabler-icons-react';

import { getCurrentISOTime } from '@/utils/getTime';

import { LIMIT_TEXT } from '@/constants/texts';

import { useStyles } from './styles';

import { ReactComponent as IconLightning } from '@/assets/icons/lightning.svg';
import { ReactComponent as IconArrow } from '@/assets/icons/redesign/iconRightArrow.svg';
import { selectNotification } from '@/store/slices/notification/slice';

interface INotificationsModal {
  notificationsToShow?: INotificationData[];
}

const NotificationModal: FC<INotificationsModal> = ({}) => {
  const { classes } = useStyles();
  const [hiddenNotifications, setHiddenNotifications] = useState<{ [key: number]: boolean }>({});
  const [isLoading, setIsLoading] = useState(true);
  const theme = useMantineTheme();
  const notification = useSelector(selectNotification);
  const [notificationsToShow, setNotificationsToShow] = useState<INotificationData[] | undefined>(
    []
  );

  const handleHideNotification = (index: number) => {
    setCookie(`notificationHidden${index}`, true);
    setHiddenNotifications((prev) => ({ ...prev, [index]: true }));
  };

  useEffect(() => {
    const hidden: { [key: number]: boolean } = {};
    notificationsToShow?.forEach((_, index) => {
      const cookieValue = getCookie(`notificationHidden${index}`);
      hidden[index] = cookieValue === true;
    });
    setHiddenNotifications(hidden);
    setIsLoading(false);
  }, [notificationsToShow]);

  useEffect(() => {
    const formattedDate = getCurrentISOTime();
    const newNotificationsToShow = notification?.notifications.filter((i) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { start_date, end_date } = i;
      return formattedDate >= start_date && formattedDate <= end_date;
    });

    setNotificationsToShow(newNotificationsToShow);
  }, [notification]);

  if (isLoading) {
    return null;
  }

  if (!notificationsToShow || notificationsToShow?.length == 0) {
    return null;
  }

  return (
    <Box className={classes.root}>
      {notificationsToShow?.map((i: INotificationData, index: number) => {
        const isHidden = hiddenNotifications[index];
        return !isHidden ? (
          <Flex
            key={index}
            style={{
              backgroundColor: theme.colors.brandOrange[9],
              borderRadius: 16,
              padding: '16px 40px 16px 40px',
              alignItems: 'flex-start',
              gap: 10,
              marginBottom: 16,
              boxShadow: '0px 4px 24px 0px #00000029',
              justifyContent: 'space-between',
            }}
          >
            <Flex>
              <IconLightning style={{ width: 19, height: 21 }} />
              <Box ml={12}>
                <Text mb={4} lh={'22px'}>
                  {i.text.length >= LIMIT_TEXT ? i.text.slice(0, LIMIT_TEXT) + '...' : i.text}
                </Text>
                <Link
                  to={`/${i.link}`}
                  onClick={() => handleHideNotification(index)}
                  className={classes.link}
                >
                  Перейти в раздел
                  <IconArrow className={classes.icon} />
                </Link>
              </Box>
            </Flex>
            <ActionIcon className={classes.close} onClick={() => handleHideNotification(index)}>
              <X size={24} />
            </ActionIcon>
          </Flex>
        ) : null;
      })}
    </Box>
  );
};

export default NotificationModal;
