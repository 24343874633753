export const NOTIFY_CHANGE_USER_DATA_SUCCESS = 'Изменения успешно сохранены';
export const NOTIFY_CHANGE_USER_DATA_ERROR = 'Не удалось изменить данные. Попробуйте позже';
export const NOTIFY_CHANGE_PASSWORD_SUCCESS = 'Пароль успешно изменен';
export const NOTIFY_CHANGE_PASSWORD_ERROR =
  'При смене пароля произошла ошибка. Проверьте данные и попробуйте еще раз';
export const NOTIFY_CHANGE_PHONE_SUCCESS = 'Телефон успешно изменен';
export const NOTIFY_CHANGE_PHONE_ERROR = 'Не удалось изменить телефон. Попробуйте позже';
export const NOTIFY_REGISTRATION_SUCCESS = 'Вы успешно зарегистрировались!';
export const NOTIFY_GET_SUBSCRIPTIONS_ERROR =
  'Произошла ошибка, не удалось получить данные о подписках. Попробуйте еще раз';
export const NOTIFY_CHANGE_SUBSCRIPTIONS_SECCESS = 'Статус подписки успешно обновлен';
export const NOTIFY_CHANGE_SUBSCRIPTIONS_ERROR =
  'Не удалось обновить статус подписки. Пожалуйста, попробуйте позже';
export const NOTIFY_EMAIL_CONFIRM_SUCCESS = 'Письмо с подтверждением отправлено на e-mail';
export const NOTIFY_EMAIL_CONFIRM_ERROR = 'Не удалось подтвердить e-mail, попробуйте позже';
export const NOTIFY_CARTS_MERGE_SUCCESS = 'Мы добавили товары из вашей прошлой корзины';
export const NOTIFY_FAVORITES_MERGE_SUCCESS = 'Мы добавили товары из вашего прошлого избранного';
export const NOTIFY_EMAIL_CONSENT = 'Успешно';
export const NOTIFY_EMAIL_CONSENT_DESCRIPTION = 'Ваш e-mail подтвержден';

export const LOCATION_ERROR = 'К сожалению, мы не сможем доставить в ваш регион';
export const NOTIFY_UPDATE_ADDRESS_SUCCESS = 'Адрес успешно сохранён';
export const NOTIFY_UPDATE_ADDRESS_ERROR = 'Ошибка отправки. Попробуйте позже';
export const NOTIFY_SEARCH_ADDRESS_ERROR = 'К сожалению, произошла ошибка и сейчас вы не можете добавить адрес. Попробуйте позже';
export const CHOOSE_ADDRESS_ERROR = 'Выберите адрес из подсказки';

export const PICKUP_SEARCH_ERROR = 'Ничего не найдено. Попробуйте снова';

export const CHECKOUT_DELIVERY_METHOD_ERROR = 'Выберите способ получения';

export const NO_RETURNS = 'У вас пока нет возвратов';

export const NOTIFY_LOAD_START = 'Загрузка началась. По завершению на сайте появится уведомление о статусе загрузки';
export const NOTIFY_DOWNLOAD_START = 'Скачивание началось. По завершению файл автоматически загрузится вам на устройство';

export const NOTIFY_ERROR_EMAIL = 'Ошибка. Вы не можете использовать данный e-mail';
export const NOTIFY_SUCCESS_EMAIL = 'Успешно. Ваш e-mail подтвержден';
export const NOTIFY_ALREDY_CONFIRM_EMAIL = 'Почта уже подтверждена';
export const NOTIFY_WILL_CONFIRM_EMAIL = 'Письмо с подтверждением отправлено на e-mail';
export const NOTIFY_NOT_CONFIRMED_EMAIL = 'Не удалось подтвердить e-mail, попробуйте позже';


