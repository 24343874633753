import { FC, useState } from 'react';
import { Button, NumberInput, Stack, useMantineTheme } from '@mantine/core';
import { Text } from '@mantine/core';
import { ContextModalProps, modals } from '@mantine/modals';

import { ModalLayout } from '@/ui/templates/Layouts/ModaLayout/ModalLayout';

import ModalFooter from '../ModalFooter/ModalFooter';

import { suppliesApi } from '@/store/slices/supplies/slice';

export interface SupplyPackagesProps {
  id: string;
  packages?: number;
  onSubmit?: () => void;
}

export const SupplyPackages: FC<ContextModalProps<SupplyPackagesProps>> = ({
  innerProps: { id, onSubmit, packages = 0 },
  ...modalProps
}) => {
  const { colors } = useMantineTheme();
  const [innerPackages, setPackages] = useState<number | ''>(packages || '');
  const [savePackages, { isLoading }] = suppliesApi.useSetPackagesMutation();

  const handleSubmit = async () => {
    if (!innerPackages) return;
    await savePackages({ id, packages: innerPackages });
    modals.close(modalProps.id);
    onSubmit?.();
  };

  const formatter = (value: string | '') => {
    const newValue = value
      .split('')
      .filter((char) => !/[\s]/.test(char))
      .filter((char) => !isNaN(Number(char)))
      .join('');

    if (Number(newValue) > 150) return '150';
    if (Number(newValue) < 0) return '0';
    return newValue;
  };

  return (
    <ModalLayout {...modalProps} loading={isLoading} title="Грузовые места">
      <Stack spacing={32} p={32} pb={160}>
        <NumberInput
          onChange={setPackages}
          placeholder="Общее количество грузовых мест"
          hideControls
          size="md"
          min={1}
          max={150}
          formatter={formatter}
          defaultValue={innerPackages}
        />
        <Text color={colors.gray[5]} size={14} style={{ lineHeight: 1.4 }}>
          Под грузовым местом подразумевается коробка, в которую уложен товар. Коробки можно уложить
          на паллету, но промаркировать нужно каждую коробку. Если товара на паллете больше чем 1,2
          куб. метра, то промаркировать можно только паллету. Подробнее в инструкции.
        </Text>
      </Stack>
      <ModalFooter align='center' variant='xs'>
        <Button h={54} w="100%" size="md" disabled={!innerPackages} onClick={handleSubmit}>
          Сохранить
        </Button>
      </ModalFooter>
    </ModalLayout>
  );
};
