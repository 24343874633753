import { createStyles } from '@mantine/core';

import { SELLER_STATUS_ACTIVE } from '@/constants/common';

export const useStyles = createStyles((
    theme,
    { status }
        : { status: string }) =>
({
    mark: {
        display: 'flex',
        alignItems: 'center',
        background: status === SELLER_STATUS_ACTIVE ? theme.colors.green[7] : theme.colors.brandGrey[5],
        borderRadius: '34px',
        minWidth: '81px',
        minHeight: '32px',
        padding: '4px 12px',
        marginLeft: '8px',
        color: theme.colors.customWhite[0],
    },
    text: {
        margin: '12px 0px 16px',
        color: theme.colors.brandGrey[8],
    },
    text2:{
      color: theme.colors.brandDark[9]  
    }
}));
