import { FC, ReactElement } from "react";
import { Box } from "@mantine/core";

import { useStyles } from "./styles";

interface ModalCustomFooterProps {
    children: ReactElement,
    align?: 'center' | 'left' | 'right';
    variant?: 'xs' | 'sm' | 'md' | 'ls';
}

const ModalFooter: FC<ModalCustomFooterProps> = ({
    children,
    align = 'center',
    variant = 'xs',
}) => {
    const { classes } = useStyles({ variant, align });

    return (
        <Box className={classes.root}>
            {children}
        </Box>
    )
}

export default ModalFooter;