import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Text } from '@mantine/core';

import ModalFooter from '@/ui/organisms/Modals/ModalFooter/ModalFooter';

import { useStyles } from './styles';

import { ReactComponent as IconModalFail } from '@/assets/icons/redesign/iconModalFail.svg';
import { AppDispatch } from '@/store';
import { setModalDataFill } from '@/store/slices/modal/slice';


const DataFill: FC = () => {
  const { classes } = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <Box className={classes.wrapper}>
        <IconModalFail className={classes.icon} />
        <Box>
          <Text mt={30} fz='inherit' lh='inherit'>
            Для начала продаж вам необходимо дозаполнить информацию в разделах:
          </Text>
          <Text lh='inherit' m='12px 0px'>
            О компании<br />
            Платёжные реквизиты<br />
            Документы
          </Text>
          <Text fz='inherit' lh='inherit'>
            Проверка займёт не более трех рабочих дней
          </Text>
        </Box>
      </Box>
      <ModalFooter align='center' variant='xs'>
        <Button
          className={classes.button}
          onClick={() => {
            dispatch(setModalDataFill(null));
            navigate('/profile');
          }}
          type="button"
          size="lg"
          variant="filled"
        >
          Заполнить данные
        </Button>

        {/* <Button
            onClick={handleClose}
            type="button"
            variant='outline'
            size="lg"
            fullWidth
            sx={{ margin: '10px 0px 0px' }}
          >
            Напомнить позже
          </Button> */}
      </ModalFooter>
    </>
  );
};

export default DataFill;
