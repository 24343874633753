import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: `${theme.colors.brandGrey[3]}`,
    borderRadius: '14px',
    padding: '8px 16px',
  },
  text: {
    fontSize: '14px',
    lineHeight: '1',
  },
  link: {
    marginTop: '2px',
    lineHeight: 1,
  },
}));
