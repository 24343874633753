import { ID, IStatus } from "@/types/common/common";
import { IProduct, IStatusAction } from "@/types/entites/product";

import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Button, Group } from "@mantine/core";

import { MODAL_ID_ARCHIVE } from "@/constants/modal";
import {
    PRODUCTS_STATUS_NAMES,
    TO_STATUS_ARCHIVED,
    TO_STATUS_IN_SALE,
    TO_STATUS_NOT_SALE,
    TO_STATUS_RESTORE
} from "@/constants/statuses";

import { AppDispatch } from "@/store";
import { setModalArchive } from "@/store/slices/modal/slice";
import {
    updateProductsArchiveRestore,
    updateProductsSaleRemove,
    updateProductsSaleReturn
} from "@/store/slices/products/asyncActions";
import { selectProductStatuses } from "@/store/slices/products/selectors";
import { setArchiveList, setSelected } from "@/store/slices/products/slice";

interface IMassChoiceProps {
    selectedItems: ID[];
    items: IProduct[];
}

const MassChoice: FC<IMassChoiceProps> = ({ selectedItems, items }) => {
    const dispatch = useDispatch<AppDispatch>();
    const statuses: IStatus[] = useSelector(selectProductStatuses);
    const [itemsActive, setItemsActive] = useState<IProduct[]>();

    const isStatus = (status: string) => {
        return itemsActive?.every((el) => el.status?.code === PRODUCTS_STATUS_NAMES[status]?.code)
    }

    const handlerClick = async (el: IStatusAction) => {
        const requestData: any = itemsActive?.map(item => {
            return { supplierProductId: item?.supplierProductId, identifier: item?.identifier }
        });
        if (el.status === TO_STATUS_NOT_SALE)
            await dispatch(updateProductsSaleRemove(requestData));
        if (el.status === TO_STATUS_IN_SALE)
            await dispatch(updateProductsSaleReturn(requestData));
        if (el.status === TO_STATUS_RESTORE)
            await dispatch(updateProductsArchiveRestore(requestData));
        if (el.status === TO_STATUS_ARCHIVED) {
            dispatch(setArchiveList(requestData));
            dispatch(setModalArchive(MODAL_ID_ARCHIVE));
        }

        if (el.status != TO_STATUS_ARCHIVED) {
            dispatch(setSelected([])); // remove checkbox
        }
    }

    useEffect(() => {
        setItemsActive(items.filter((el: IProduct) => selectedItems.includes(el.supplierProductId)));
    }, [selectedItems]);

    return (
        <Group spacing={4}>
            {statuses && statuses?.length > 0 && statuses?.map((status) => (
                isStatus(status.code) &&
                PRODUCTS_STATUS_NAMES[status.code]?.action?.map((element: IStatusAction, index: number) => (
                    <Button
                        sx={{
                            fontSize: '14px',
                            height: '48px',
                            minHeight: '48px',
                        }}
                        key={index}
                        variant="outline"
                        onClick={() => handlerClick(element)}
                    >
                        {element.label}
                    </Button>
                ))
            ))}
        </Group>
    )
}

export default MassChoice;