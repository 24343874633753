import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Skeleton, Text, useMantineTheme } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { getCookie } from 'cookies-next';
import capitalize from 'lodash/capitalize';
import { Check } from 'tabler-icons-react';

import { changePassword } from '@/api/users/user';

import { FormEffects, useCustomizedForm } from '@/utils/formHelpers';
import { saveToken } from '@/utils/token';

import { cookiesNames } from '@/constants/common';
import { VALIDATION_CHANGE_PASSWORD, VALIDATION_USER_FORM } from '@/constants/validationSchemas';

import FormGroup from '@/ui/atoms/FormGroup/FormGroup';
import InputWithLabel from '@/ui/atoms/InputWithLabel/InputWithLabel';

import { TPasswordForm, TUserForm } from '../../types';
import { getUserFormValues } from '../../utils/userForm';
import Section from '../Section/Section';

import { useAppDispatch } from '@/store';
import { selectUserToken } from '@/store/slices/auth/auth';
import { profileApi } from '@/store/slices/profile/slice';
import {
  selectUpdatingUser,
  selectUser,
  selectUserUpdateError,
  updateUserName,
} from '@/store/slices/user/slice';

const AccountManage: FC = () => {
  const theme = useMantineTheme();
  const user = useSelector(selectUser);
  const updateUserError = useSelector(selectUserUpdateError);
  const userUpdateFetching = useSelector(selectUpdatingUser);
  const dispatch = useAppDispatch();
  const [userFormSubmitted, setUserFormSubmitted] = useState(false);
  const [passwordFormSubmitted, setPasswordFormSubmitted] = useState(false);
  const [passwordUpdating, setPasswordUpdating] = useState(false);
  const [passwordUpdateError, setPasswordUpdateError] = useState<null | string>(null);
  const token = useSelector(selectUserToken);
  const { isFetching } = profileApi.useGetTabsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const userForm = useCustomizedForm({
    initialValues: getUserFormValues(user),
    validate: yupResolver(VALIDATION_USER_FORM),
    validateInputOnChange: true,
    transformValues: (values) => ({
      ...values,
      name: capitalize(values.name),
      lastName: capitalize(values.lastName),
      secondName: capitalize(values.secondName),
    }),
    effects: {
      name: {
        onBlur: FormEffects.capitalize,
      },
      lastName: {
        onBlur: FormEffects.capitalize,
      },
      secondName: {
        onBlur: FormEffects.capitalize,
      },
    },
  });

  const passwordForm = useForm({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmationNewPassword: '',
    },
    validateInputOnChange: true,
    validate: yupResolver(VALIDATION_CHANGE_PASSWORD),
  });

  useEffect(() => {
    userForm.setValues(getUserFormValues(user));
  }, [user]);

  const submitUserForm = (values: TUserForm) => {
    if (!user) return;
    setUserFormSubmitted(true);
    dispatch(updateUserName({ id: user?.id ? user.id.toString() : '', data: values }));
  };

  const handlePasswordSubmit = async (values: TPasswordForm) => {
    const tokenObj = JSON.parse(
      (getCookie(cookiesNames.NEXT_USER_TOKEN) as string) ?? '{"accessToken":"","refreshToken":""}'
    );
    if (!tokenObj.refreshToken) {
      setPasswordUpdateError('Попробуйте еще раз');
      return;
    }
    setPasswordUpdating(true);
    setPasswordUpdateError(null);
    setPasswordFormSubmitted(true);
    const res = await changePassword({
      ...values,
      refreshToken: tokenObj.refreshToken,
    });
    if (typeof res !== 'string' && res?.data?.token) {
      saveToken(res.data.token);
    } else {
      setPasswordUpdateError(res as string);
    }
    setPasswordUpdating(false);
  };
  return (
    <>
      <Section>
        <form onSubmit={userForm.onSubmit(submitUserForm)}>
          <Text mb={24} fw={700}>
            ФИО
          </Text>
          <FormGroup spacing={16}>
            <Skeleton visible={isFetching}>
              <InputWithLabel
                type={'text'}
                size={'lg'}
                label="Фамилия"
                radius={8}
                clearable
                {...userForm.getInputProps('lastName')}
              />
            </Skeleton>
          </FormGroup>
          <FormGroup spacing={16}>
            <Skeleton visible={isFetching}>
              <InputWithLabel
                type={'text'}
                size={'lg'}
                label="Имя"
                radius={8}
                clearable
                {...userForm.getInputProps('name')}
              />
            </Skeleton>
          </FormGroup>
          <FormGroup spacing={16}>
            <Skeleton visible={isFetching}>
              <InputWithLabel
                type={'text'}
                size={'lg'}
                label="Отчество"
                radius={8}
                clearable
                {...userForm.getInputProps('secondName')}
                mb={32}
              />
            </Skeleton>
          </FormGroup>
          <Button
            size="lg"
            w={300}
            fz={16}
            h={60}
            disabled={!userForm.isValid() || userUpdateFetching}
            type="submit"
          >
            <Text mr={12}>Сохранить</Text>
            <Check size={18} />
          </Button>
          {updateUserError && (
            <Text color={theme.colors.red[5]} mt={16}>
              {updateUserError}
            </Text>
          )}
          {!updateUserError && userFormSubmitted && !userUpdateFetching && (
            <Text color={theme.colors.green[5]} mt={16}>
              Данные сохранены
            </Text>
          )}
        </form>
      </Section>
      <Section last>
        <form onSubmit={passwordForm.onSubmit(handlePasswordSubmit)}>
          <Text mb={24} fw={700}>
            Изменение пароля
          </Text>
          <FormGroup spacing={16}>
            <Skeleton visible={isFetching}>
              <InputWithLabel
                inputtype={'password'}
                size={'lg'}
                label="Текущий пароль"
                radius={8}
                {...passwordForm.getInputProps('oldPassword')}
              />
            </Skeleton>
          </FormGroup>
          <FormGroup spacing={16}>
            <Skeleton visible={isFetching}>
              <InputWithLabel
                inputtype={'password'}
                size={'lg'}
                label="Новый пароль"
                radius={8}
                {...passwordForm.getInputProps('newPassword')}
              />
            </Skeleton>
          </FormGroup>
          <FormGroup spacing={16}>
            <Skeleton visible={isFetching}>
              <InputWithLabel
                inputtype={'password'}
                size={'lg'}
                label="Еще раз новый пароль"
                radius={8}
                {...passwordForm.getInputProps('confirmationNewPassword')}
              />
            </Skeleton>
          </FormGroup>
          <Button
            type="submit"
            size="lg"
            w={300}
            fz={16}
            h={60}
            disabled={!passwordForm.isValid() || passwordUpdating}
          >
            <Text mr={12}>Сохранить</Text>
            <Check size={18} />
          </Button>
          {passwordUpdateError && (
            <Text color={theme.colors.red[5]} mt={16}>
              {passwordUpdateError}
            </Text>
          )}
          {!passwordUpdateError && passwordFormSubmitted && !passwordUpdating && (
            <Text color={theme.colors.green[5]} mt={16}>
              Пароль изменён
            </Text>
          )}
        </form>
      </Section>
    </>
  );
};

export default AccountManage;
