import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  button: {
    fontSize: '14px',
    height: '48px',
    minHeight: '48px',
    color: theme.colors.text[7],
  },
  icon: {
    fill: theme.colors.text[7],
    margin: '5px',
    marginLeft: '13px',
    width: '14px',
    height: '14px',
  },
  categoryLabel: {
    maxWidth: '150px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginRight: '2px',
  },
  filter: {
    background: theme.colors.mainColor[8],
    borderRadius: '100%',
    color:theme.colors.customWhite[0],
    marginLeft: '8px',
    width: '22px',
    height: '22px',
    fontSize: '10px'
  },
}));
