import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Flex, Text } from '@mantine/core';

import { useProductContext } from '../../utils/ProductContext';

import { ReactComponent as IconLightning } from '@/assets/icons/lightning.svg';
import { ReactComponent as IconInfo } from '@/assets/icons/redesign/iconInfo.svg';
import { AppDispatch } from '@/store';
import { selectProductsCurrentStatus } from '@/store/slices/products/selectors';
import { setCurrentStatus } from '@/store/slices/products/slice';

export const StatusesBanner: FC = () => {
  const currentStatus = useSelector(selectProductsCurrentStatus);
  const dispatch = useDispatch<AppDispatch>();
  const { editingProductData, data } = useProductContext();

  useEffect(() => {
    const savedStatus = localStorage.getItem('currentStatus');
    if (savedStatus && !currentStatus) {
      const status = JSON.parse(savedStatus);
      dispatch(setCurrentStatus(status));
    }
  }, [currentStatus]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('currentStatus');
    };
  }, []);

  return (
    <>
      {currentStatus?.code === 'on_moderation' && data && (
        <Flex
          style={{
            backgroundColor: '#CECDEF',
            borderRadius: 16,

            padding: '16px 40px 16px 40px',
            alignItems: 'center',
            gap: 10,
            marginBottom: 20,
            boxShadow: '0px 4px 24px 0px #00000029',
          }}
        >
          <IconInfo style={{ width: 29, height: 28 }} />
          <Text>
            Этот товар находится на модерации, возможность редактирования ограничена. Чтобы
            отредактировать все поля, дождитесь окончания проверки
          </Text>
        </Flex>
      )}
      {currentStatus?.code === 'rejected' && data && (
        <Flex
          style={{
            backgroundColor: '#FFEC98',
            borderRadius: 16,

            padding: '16px 40px 16px 40px',
            alignItems: 'center',
            gap: 10,
            marginBottom: 20,
            boxShadow: '0px 4px 24px 0px #00000029',
          }}
        >
          <IconLightning />
          <Text>
            К сожалению, товар не прошел модерацию. Причина отклонения модерации:{' '}
            {editingProductData?.statusComment}
          </Text>
        </Flex>
      )}
    </>
  );
};
