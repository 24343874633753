import { AxiosBaseQuery, QueryArg, QueryConfig } from '@/types/api/common';

import axios, { AxiosError, AxiosProgressEvent, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getCookie } from 'cookies-next';
import isString from 'lodash/isString';
import noop from 'lodash/noop';
import omit from 'lodash/omit';

import { isJsonBlob } from '@/utils/common';

import { cookiesNames, DEFAULT_REQUEST_ERROR } from '@/constants/common';

import { usersUrls } from './urls/usersUrls';

const api = axios.create({
  baseURL: process.env.PUBLIC_API_URL,
});

export const objectToJson = (obj: Record<string, unknown>): string => {
  return JSON.stringify(obj, null, 0);
};

const requestMiddleware = async (config: AxiosRequestConfig) => {
  const cookiesToken =
    (getCookie(cookiesNames.NEXT_USER_TOKEN) as string) ||
    (getCookie(cookiesNames.NEXT_CUSTOMER_TOKEN) as string);
  let accessToken = null;

  if (cookiesToken) {
    accessToken = JSON.parse(cookiesToken).accessToken;
  }

  // при запросе на рефреш токен им нужен не access токен, который берется из куков
  // а рефреш токен, который прокидывается в конкретных запросах. По этому для них
  // мы accesstoken не прокидываем!
  const ifThrowToken =
    accessToken &&
    config.url !== usersUrls.auth.customerRefresh &&
    config.url !== usersUrls.signIn.authByRefresh;
  const tokenFromHeaders = ifThrowToken ? { Authorization: `Bearer ${accessToken}` } : undefined;

  // const deviceUuid = getCookie(cookiesNames.MINDBOX_DEVICE_UUID) as string;

  const savedLocation = getCookie(cookiesNames.LOCATION) as string;
  const parsedLocation = savedLocation ? JSON.parse(savedLocation) : undefined;

  return {
    ...config,
    ...{
      headers: {
        ...config.headers,
        ...tokenFromHeaders,
        // ...(deviceUuid && { deviceUuid }),
        ...(parsedLocation?.rusRegionId && { rusRegionId: parsedLocation.rusRegionId }),
      },
    },
  };
};

api.interceptors.request.use(
  (request) => {
    // Type request middleware here
    return requestMiddleware(request);
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    // Type response middleware here
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const getProgressHandler = (cb?: (payload: number) => void) => (e: AxiosProgressEvent) => {
  const percentage = Math.floor((e.loaded * 100) / (e.total || 1));

  cb?.(percentage);
};

export const getQueryArgs = (arg: string | QueryConfig): QueryArg => {
  if (isString(arg)) return {};

  return arg.options || {};
};

export const baseQuery: () => AxiosBaseQuery =
  () =>
  async (args, { signal, type }, _) => {
    const queryArgs = getQueryArgs(args);
    const omitArgs = Object.keys(queryArgs);
    const progressHandler = getProgressHandler(queryArgs.onUploadProgress || noop);
    let req: Promise<AxiosResponse<unknown, any>>;
    const preparedArgs = isString(args) ? args : omit(args, ...omitArgs);

    if (isString(args)) {
      req = api.get<unknown>(args, { signal });
    } else if (type === 'query') {
      req = api.request<unknown>({ ...preparedArgs, signal, onUploadProgress: progressHandler });
    } else {
      req = api.request<unknown>({ ...preparedArgs, signal, onUploadProgress: progressHandler });
    }

    try {
      const res = await req;
      return {
        data: res.data || {},
        meta: {
          headers: res.headers,
        },
      };
    } catch (e: any) {
      const castedErr = e as AxiosError;
      const data: AxiosResponse['data'] | undefined = isJsonBlob(castedErr.response?.data)
        ? JSON.parse((await castedErr.response?.data.text()) || '')
        : castedErr.response?.data;

      const error =
        (!isString(args) && args.options?.getError?.(castedErr)) ||
        data?.error ||
        data?.message ||
        data ||
        DEFAULT_REQUEST_ERROR;

      return {
        error,
        meta: { queryArgs, request: castedErr.request, response: castedErr.response },
      };
    }
  };

export default api;
