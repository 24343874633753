import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ITableState {
    scrollPosition: {
        current: number;
    }
}

const initialState: ITableState = {
    scrollPosition: {
        current: 0,
    }
};


export const tableSlice = createSlice({
    name: 'table',
    initialState,
    reducers: {
        setCurrentScroll: (state, action: PayloadAction<number>) => {
            state.scrollPosition.current = action.payload;
        },
    },
});

export const { setCurrentScroll } = tableSlice.actions;

export default tableSlice.reducer;