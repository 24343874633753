import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
    item: {
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        padding: '8px',
        fontSize: '14px',
        lineHeight: '20px',
        maxWidth: '100%',
        '&:hover': {
            background: theme.colors.brandGrey[7],
        },
    },
    textTop: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        color: theme.colors.brandDark[9],
        marginBottom: '4px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
    },
    textBottom: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
        color: theme.colors.brandGrey[8]
    },
    textColor: {
        display: 'inline',
        color: theme.colors.mainColor[6]
    }
}));
