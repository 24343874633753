import { SortState, SortType } from '@/types/api/common';

import { useState } from 'react';

function getNextSortState(code: string, sort: SortType): SortState {
  if (sort === 'desc') return { code, sort: 'asc' };
  if (sort === 'asc') return { code, sort: 'desc' };
  return { code, sort: 'desc' };
}

export const useSorting = (token: string) => {
  const [sortState, setSortState] = useState<SortState | undefined>(window.sort?.[token]);

  const update = (newState: SortState) => {
    setSortState(newState);
    window.sort = { ...window.sort, [token]: newState };
  };

  const toggleSort = (code: string) => {
    if (sortState?.code === code) {
      update(getNextSortState(code, sortState.sort));
    } else update({ code, sort: 'asc' });
  };

  return [sortState, toggleSort] as const;
};
