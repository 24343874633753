import { PaginationPayload } from '@/types/api/common';
import { SimpleTableConfig } from '@/types/common/common';

import { FC, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Group, Stack } from '@mantine/core';
import debounce from 'debounce';

import { useSorting } from '@/hooks/useSortable';

import { SimpleTable } from '@/ui/organisms/TableCustom';
import { TypedInput, TypedInputChangeEvent } from '@/ui/organisms/TypedInput/TypedInput';

import { ProductsCount } from '../ProductsCount/ProductsCount';

import { suppliesApi } from '@/store/slices/supplies/slice';

const TABLE_CONFIG: SimpleTableConfig = {
  columns: [
    { name: 'Артикул', code: 'supplierProductId' },
    { name: 'Фото', code: 'image', image: true },
    { name: 'Наименование товара', code: 'name', grow: true },
    { name: 'Заявлено', code: 'all', align: 'right' },
    { name: 'Принято', code: 'accepted', align: 'right' },
    { name: 'Излишки', code: 'surplus', align: 'right' },
    { name: 'Недостача', code: 'shortage', align: 'right' },
    { name: 'Брак', code: 'defect', align: 'right' },
    { name: 'Цена единицы товара, ₽', code: 'price', align: 'right' },
  ],
};

const SEARCH_TYPES = [
  { label: 'По артикулу', value: 'supplierProductId', placeholder: 'Введите артикул' },
  { label: 'По наименованию', value: 'name', placeholder: 'Введите наименование товара' },
];

export const ProductsList: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [sort, setSort] = useSorting('acceptance-products-list');
  const [search, setSearch] = useState<{ type: string; value: string } | undefined>();
  const [pagination, setPagination] = useState<PaginationPayload>({
    offset: 0,
    concat: false,
  });

  const { data, isSuccess } = suppliesApi.useGetAcceptanceProductsQuery(
    { id: id || '', ...pagination, limit: 50, sort: sort?.code, order: sort?.sort },
    {
      skip: !id,
      selectFromResult: ({ data: res, isSuccess: isLoaded, ...rest }) => {
        if (!isLoaded) return { isSuccess: isLoaded, data: res, ...rest };
        if (!search?.value) return { data: res, isSuccess: isLoaded, ...rest };
        return {
          data: {
            ...res,
            products: res?.products?.filter((item) =>
              item[search.type as keyof typeof item]
                .toString()
                .toLowerCase()
                .includes(search.value.toLowerCase())
            ),
          },
          isSuccess: isLoaded,
          ...rest,
        };
      },
    }
  );

  const handlePaginationChange = (e: PaginationPayload) => {
    setPagination((state) => ({
      ...state,
      ...e,
      concat: e.concat || false,
    }));
  };

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      setSearch({ type: 'supplierProductId', value });
    }, 500),
    []
  );

  return (
    <Stack>
      <Group position="apart" px={24}>
        <TypedInput
          types={SEARCH_TYPES}
          onChange={(e: TypedInputChangeEvent) => handleSearchChange(e.value)}
          miw={245}
          clearable
        />
        {isSuccess && <ProductsCount count={data.count} />}
      </Group>
      <SimpleTable
        config={TABLE_CONFIG}
        sortable={['all', 'accepted', 'surplus', 'shortage', 'defect', 'price']}
        data={data?.products}
        onSortChange={setSort}
        onPaginationChange={handlePaginationChange}
        sort={sort}
        limit={50}
        offset={pagination.offset}
        totalItems={data?.count}
      />
    </Stack>
  );
};
