import { FC, ReactNode } from "react";
import { Box } from "@mantine/core";

import { useStyles } from "./styles";

interface IPageTop {
    children: ReactNode,
    height?: string,
}

const PageTop: FC<IPageTop> = ({ children }) => {
    const { classes } = useStyles();

    return (
        <Box className={classes.wrapper}>
            {children}
        </Box>
    )
}

export default PageTop;