import { useSelector } from "react-redux";

import MarkInfo from "@/ui/atoms/MarkInfo/MarkInfo";

import { selectUser } from "@/store/slices/user/slice";

const ProfileInfo = () => {
    const user = useSelector(selectUser);

    return (
        <MarkInfo
            text={user?.sellerStatusText}
            link={user?.sellerStatusLink}
        />
    )
}

export default ProfileInfo;