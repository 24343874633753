import { createStyles,CSSObject } from "@mantine/core";

const paddingSizes: Record<string, string> = {
    xs: '12px 32px',
    sm: '24px 32px',
    md: '32px 32px',
    ls: '44px 32px',
};

export const useStyles = createStyles((
    theme,
    { variant, align }: { variant: string; align: 'center' | 'left' | 'right'; }): Record<string, CSSObject> => ({
        root: {
            position: 'fixed',
            bottom: 0,
            right: 0,
            left: 0,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: theme.colors.customWhite[0],
            textAlign: `${align}` || 'left',
            borderTop: `solid 1px ${theme.colors.brandGrey[3]}`,
            padding: variant ? `${paddingSizes[variant]}` : paddingSizes.md,
        },
    }));