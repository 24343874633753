import { FC, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Button, LoadingOverlay, Text } from '@mantine/core';
import { yupResolver } from '@mantine/form';
import capitalize from 'lodash/capitalize';

import { FormEffects, useCustomizedForm } from '@/utils/formHelpers';
import reCaptchaVerify from '@/utils/reCaptchaVerify';

import { routNames } from '@/constants/routNames';
import { VALIDATION_UPGRADE_TO_SELLER } from '@/constants/validationSchemas';

import InputWithLabel from '@/ui/atoms/InputWithLabel/InputWithLabel';

import AuthFooter from '../AuthFooter/AuthFooter';

import { AppDispatch } from '@/store';
import {
  checkSellerEmailAction,
  getUpgradeSeller,
  upgradeUserToSellerAction,
} from '@/store/slices/auth/asyncAction';
import {
  selectFetchingCheckEmail,
  selectUserEmail,
  selectUserSellerEmail,
  setUserSellerEmail,
} from '@/store/slices/auth/auth';
import { selectUser } from '@/store/slices/user/slice';

const UpgradeToSeller: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const user = useSelector(selectUser);
  const authEmail = useSelector(selectUserEmail);
  const sellerEmail = useSelector(selectUserSellerEmail);
  const [isLoading, setIsLoading] = useState(false);
  const isEmailCheckking = useSelector(selectFetchingCheckEmail);

  const handleSubmit = (values: Record<string, string>) => {
    reCaptchaVerify(executeRecaptcha, 'upgrade_to_seller').then((recaptchaToken) => {
      if (recaptchaToken) {
        const sendData = {
          name: values.name,
          lastName: values.lastName,
          email: values.email,
          recaptchaToken,
        };
        dispatch(setUserSellerEmail(values.email));
        // dispatch(setUserEmail(values.email));
        dispatch(upgradeUserToSellerAction(sendData));
      }
    });
  };

  const form = useCustomizedForm({
    initialValues: {
      name: user?.name ?? '',
      lastName: user?.lastName ?? '',
      email: (sellerEmail ?? authEmail) || '',
    },
    validateInputOnChange: true,
    validate: yupResolver(VALIDATION_UPGRADE_TO_SELLER),
    transformValues: (values) => ({
      ...values,
      name: capitalize(values.name),
      lastName: capitalize(values.lastName),
    }),
    effects: {
      name: {
        onBlur: FormEffects.capitalize,
      },
      lastName: {
        onBlur: FormEffects.capitalize,
      },
    },
  });

  const isCheckEmail = async () => {
    const email = form.getInputProps('email').value;
    const checkEmailData = { email };
    try {
      const resultAction = (await dispatch(
        checkSellerEmailAction({
          data: checkEmailData,
          isStep: false,
        })
      )) as any;
      return resultAction?.payload?.userExist;
    } catch (error) {
      console.error('Ошибка при проверке почты:', error);
      return false;
    }
  };

  const loadPreFilledEmail = async () => {
    setIsLoading(true);
    await dispatch(getUpgradeSeller());
    setIsLoading(false);
  };

  useEffect(() => {
    loadPreFilledEmail();
  }, []);

  useEffect(() => {
    if (!!authEmail) form.setFieldValue('email', authEmail);
  }, [authEmail]);

  return (
    <Box pos="relative">
      <LoadingOverlay visible={isLoading} />
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (await isCheckEmail()) return;
          form.onSubmit(handleSubmit)();
        }}
        noValidate={true}
      >
        <InputWithLabel
          label="Имя"
          autoComplete="off"
          maxLength={50}
          size="md"
          {...form.getInputProps('name')}
          mb={16}
        />
        <InputWithLabel
          label="Фамилия"
          autoComplete="off"
          maxLength={50}
          {...form.getInputProps('lastName')}
          size="md"
          mb={16}
        />
        <InputWithLabel
          label="E-mail"
          autoComplete="off"
          {...form.getInputProps('email')}
          size="md"
        />
        <Text fz={14} lh={'22px'} mt={32}>
          Нажимая кнопку «Продолжить» вы соглашаетесь c{' '}
          <Link to={routNames.SERVICES.OFFER} target="_blank">
            условиями пользования
          </Link>{' '}
          и{' '}
          <Link to={routNames.SERVICES.USER_AGREEMENT} target="_blank">
            политикой обработки персональных данных
          </Link>
        </Text>
        <AuthFooter>
          <Button
            disabled={!form.isValid() || isEmailCheckking || isLoading}
            type="submit"
            size="md"
            fullWidth
            fz={14}
            h={48}
          >
            Продолжить
          </Button>
        </AuthFooter>
      </form>
    </Box>
  );
};

export default UpgradeToSeller;
