import { IAuthByCodeRequest, INewCodeRequest, IUpgradeToSellerRequest } from '@/types/api/auth';
import { IToken } from '@/types/common/common';

import api from '../instance';
import { tryAction } from '../tryAction';
import { usersUrls } from '../urls/usersUrls';

export const tryAddUser = (data: IAuthByCodeRequest) => {
  const { recaptchaToken, ...send } = data;
  return tryAction(
    api.post<{ token: IToken }>(usersUrls.signUp.user, send, {
      headers: {
        recaptchaToken,
      },
    })
  );
};

export const tryNewCode = (data: INewCodeRequest) => {
  const { phone, recaptchaToken } = data;
  return tryAction(api.post(usersUrls.signUp.newCode, { phone }, { headers: { recaptchaToken } }));
};

export const tryUpgradeToSeller = () => {
  return tryAction(api.get<{ email?: string }>(usersUrls.upgrade, { headers: { source: 'web' } }));
};

export const tryUpgradeUser = (data: IUpgradeToSellerRequest) => {
  const { recaptchaToken, ...send } = data;
  return tryAction(
    api.patch<{ token: IToken }>(usersUrls.upgrade, send, {
      headers: {
        recaptchaToken,
      },
    })
  );
};
