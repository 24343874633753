import { FC, PropsWithChildren } from "react"
import { Box } from "@mantine/core"

const Page: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Box>
            {children}
        </Box>
    )
}

export default Page;