import { createStyles } from '@mantine/core';

export const useStyles = createStyles(
  (
    theme,
    { extraFile, typeComponent }: { extraFile: boolean; typeComponent: 'image' | 'file' }
  ) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: typeComponent === 'file' ? '24px 0px 24px' : '12px 0px 12px',
      margin: 0,
      borderBottom: `1px solid ${theme.colors.brandGrey[3]}`,
    },
    name: {
      fontSize: '16px',
      maxWidth: '300px',
      margin: '0px 0px 8px',
      position: 'relative',
      lineHeight: '22px',
      whiteSpace: extraFile ? 'nowrap' : 'pre-wrap',
      overflow: extraFile ? 'hidden' : 'visible',
      textOverflow: extraFile ? 'ellipsis' : 'initial',
    },
    uploadProgress: {
      fontSize: '14px',
      maxWidth: '300px',
      margin: '0px 0px 6px',
    },
    info: {
      marginLeft: '22px',
    },
    inner: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      fontSize: '14px',
      letterSpacing: '-0.01em',
      color: theme.colors.mainColor[6],
      display: 'flex',
      alignItems: 'center',
      svg: {
        width: 20,
        height: 20,
      },
    },
    buttonRemove: {
      color: theme.colors.brandGrey[5],
    },
    iconWrapper: {
      position: 'relative',
    },
    icon: {
      marginLeft: '8px',
    },
    iconRepeat: {
      marginLeft: '6px',
    },
    iconEdit: {
      position: 'relative',
      marginLeft: '8px',
      bottom: '2px',
    },
    iconTrash: {
      width: 'auto',
      height: '20px',
      position: 'relative',
      marginLeft: '6px',
    },
    iconClose: {
      fill: theme.colors.brandGrey[5],
    },
    iconPlus: {
      fill: theme.colors.brandDark[9],
      height: '18px',
      width: '18px',
      position: 'absolute',
      top: 0,
      right: 0,
      backgroundColor: theme.colors.customWhite[0],
      zIndex: 100,
      borderRadius: '40%',
    },
    required: {
      position: 'relative',
      top: '-5px',
      right: '-5px',
    },
    buttonDelete: {
      color: theme.colors.brandGrey[5],
      margin: '10px 0px 0px',
    },
    buttonChange: {
      color: theme.colors.mainColor[6],
      margin: '0px 0px 10px',
    },
    buttonCanceled: {
      color: theme.colors.brandGrey[5],
      svg: {
        width: 12,
        height: 12,
      },
    },
    errorCirlce: {
      position: 'absolute',
      right: '-10px',
      top: '-3px',
    },
    topDescription: {
      fontSize: 14,
      lineHeight: '22px',
      color: theme.colors.brandGrey[8],
    },
  })
);
