
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useToken } from '@/utils/token';

import { AppDispatch } from '@/store';
import { setTemplateOperations } from '@/store/slices/template/slice';

type WebSocketMessageHandler<T = string> = (message: T) => void;
type WebSocketCloseHandler = () => void;

const useWebSocketWithLocalStorage = <T = string>(
  url: string,
  key: string,
  onMessage?: WebSocketMessageHandler<T>,
  onClose?: WebSocketCloseHandler
) => {
  const dispatch: AppDispatch = useDispatch();
  const { token, kind } = useToken();
  const [socket, setSocket] = useState<WebSocket | null>(null);

  useEffect(() => {
    if (!token || kind !== 'user') {
      if (socket) {
        socket.close();
        setSocket(null);
      }
      return;
    }

    const newSocket = new WebSocket(`${url}?token=${encodeURIComponent(token.accessToken)}`);
    newSocket.onopen = () => { };

    newSocket.onmessage = (event: MessageEvent) => {
      const message = JSON.parse(event.data as string);
      const messages: any = JSON.parse(localStorage.getItem(key) || '[]');
      dispatch(setTemplateOperations(messages));

      onMessage?.(message);
    };

    newSocket.onclose = () => {
      if (onClose) {
        onClose();
      }
    };

    setSocket(newSocket);

    return () => {
      if (newSocket.readyState === WebSocket.OPEN) {
        newSocket.close();
      }
    };
  }, [token, kind]);

  return socket;
};


export default useWebSocketWithLocalStorage;
