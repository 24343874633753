import { ID } from '@/types/common/common';

export const getSeletedItems = (selected: ID[], id: ID) => {
    if (!id) return [];
    const selectedIndex = selected.indexOf(id);
    let newSelectedsArray: ID[] = [];

    if (selectedIndex === -1) {
        newSelectedsArray = [...selected, id];
    } else {
        newSelectedsArray = [
            ...selected.slice(0, selectedIndex),
            ...selected.slice(selectedIndex + 1)
        ];
    }

    return newSelectedsArray;
}
