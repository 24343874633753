import { TableConfig } from '@/types/common/common';

import { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Group, LoadingOverlay, Stack, Text, useMantineTheme } from '@mantine/core';
import debounce from 'debounce';

import { pluralize } from '@/utils/pluralize';

import { useSorting } from '@/hooks/useSortable';

import { Select } from '@/ui/atoms/Select/Select';
import {
  openAddProductsToSupplyModal,
  openChangeWarehouseModal,
  openSupplySlots,
} from '@/ui/organisms/Modals/modals';
import PaginationCustom from '@/ui/organisms/PaginationCustom/PaginationCustom';
import Cell from '@/ui/organisms/TableCustom/components/Cell/Cell';
import Row from '@/ui/organisms/TableCustom/components/Row/Row';
import TBody from '@/ui/organisms/TableCustom/components/TBody/TBody';
import THead from '@/ui/organisms/TableCustom/components/THead/THead';
import { TableCustom } from '@/ui/organisms/TableCustom/TableCustom';

import { SuppliesContext } from '../../utils/Suppliescontext';
import { SupplyActionButton } from '../SupplyActionButton/SupplyActionButton';
import { SupplyCarCell } from '../SupplyCarCell/SupplyCarCell';
import { SupplyDateCell } from '../SupplyDateCell/SupplyDateCell';
import { SupplyPackagesCell } from '../SupplyPackagesCell/SupplyPackagesCell';
import { SupplyStatusCell } from '../SupplyStatusCell/SupplyStatusCell';

import { suppliesApi } from '@/store/slices/supplies/slice';
import { selectUser } from '@/store/slices/user/slice';

const TABLE_CONFIG: TableConfig = {
  columns: [
    { name: 'Номер и дата', sortable: true, code: 'date' },
    { name: 'Статус' },
    { name: 'Склад' },
    { name: 'Дата и время поставки', sortable: true, code: 'supply_date' },
    { name: 'Товары' },
    { name: 'Грузовые места' },
    { name: 'Автомобиль, водитель' },
    { name: '' },
  ],
};

export const CreatedList = () => {
  const { refetchTabs } = useContext(SuppliesContext);
  const [selectedWarehouses, setSelectedWarehouses] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [concat, setConcat] = useState(false);
  const [sort, setSort] = useSorting('created');
  const { colors } = useMantineTheme();
  const {
    data: warehousesData,
    isFetching: isWarehousesFetching,
    refetch: refetchWarehouses,
  } = suppliesApi.useGetWarehousesQuery({ tab: 'created' });
  const user = useSelector(selectUser);
  const isActiveSeller = useMemo(() => user?.sellerStatus === 'active', [user]);

  const handleSelectWarehouse = (warehouses: string[]) => {
    setConcat(false);
    setSelectedWarehouses(warehouses);
  };

  const warehouseSelectData = useMemo(() => {
    if (!warehousesData) return [];
    const data = warehousesData.items.map((warehouse) => ({
      value: warehouse.id,
      label: warehouse.name,
    }));

    return [...data];
  }, [warehousesData]);

  const {
    data: supplies,
    isSuccess,
    isFetching: isSuppliesFetching,
    refetch,
  } = suppliesApi.useGetSuppliesQuery(
    {
      tab: 'created',
      offset: (currentPage - 1) * 50,
      limit: 50,
      warehouseId: selectedWarehouses.join(',') || undefined,
      sort: sort?.code,
      order: sort?.sort,
      concat,
    },
    {}
  );

  const getWarehouseName = useCallback(
    (id: string) => {
      const info = warehousesData?.items.find((warehouse) => warehouse.id === id);

      if (!info) return id;
      return info.name;
    },
    [warehousesData]
  );

  const handleRefetch = () => {
    setCurrentPage(0);
    setConcat(false);
    refetch();
    refetchTabs();
    refetchWarehouses();
  };

  const pageChangeHandler = (page: number, isConcat: boolean) => {
    setCurrentPage(page);
    setConcat(isConcat);
  };

  const handleSort = (code: string) => {
    setCurrentPage(1);
    setSort(code);
  };

  return (
    <Stack spacing={32} pos="relative">
      <LoadingOverlay visible={isSuppliesFetching || isWarehousesFetching} zIndex={100} />
      <Group position="apart" px={24}>
        <Select
          data={warehouseSelectData}
          label="Склад"
          multiple
          onChange={debounce(handleSelectWarehouse, 1000)}
          resetLabel="Все склады"
          clearable
        />
        {isActiveSeller && (
          <Button
            size="md"
            h={48}
            w={155}
            fz={14}
            onClick={() =>
              openChangeWarehouseModal({
                onWarehouseSelect: (id) =>
                  openAddProductsToSupplyModal({ warehouseId: id, onRefetch: handleRefetch }),
              })
            }
          >
            Создать заявку
          </Button>
        )}
      </Group>

      <Box>
        <TableCustom offsetScrollbars={false}>
          {() => (
            <>
              <THead>
                <Row>
                  {TABLE_CONFIG.columns.map((column) => (
                    <Cell
                      key={`title-${column.name}`}
                      th
                      sticky
                      sortable={column.sortable}
                      sortCode={column.code}
                      onSortClick={handleSort}
                      sort={sort?.code === column.code ? sort?.sort : null}
                    >
                      <Group>{column.name}</Group>
                    </Cell>
                  ))}
                </Row>
              </THead>
              <TBody>
                {isSuccess && supplies.items.length > 0 ? (
                  supplies.items.map((item) => (
                    <Row key={item.id}>
                      <Cell>
                        <Stack spacing={4}>
                          <Text>{item.number}</Text>
                          <Text size={12} color={colors.gray[6]}>
                            {item.date}
                          </Text>
                        </Stack>
                      </Cell>
                      <SupplyStatusCell
                        id={item.id}
                        updFolderId={item.updFolderId}
                        status={item.status}
                      />
                      <Cell>
                        <Text maw={200} sx={{ lineHeight: 1.4 }}>
                          {getWarehouseName(item.warehouse)}
                        </Text>
                      </Cell>
                      <SupplyDateCell
                        date={item.supplyDate}
                        onOpenSlots={() => openSupplySlots({ id: item.id, onSubmit: refetch })}
                      />
                      <Cell>
                        <Stack spacing={4}>
                          <Text style={{ whiteSpace: 'nowrap' }}>
                            {item.articlesCount}{' '}
                            {pluralize(item.articlesCount, ['артикул', 'артикула', 'артикулов'])}
                          </Text>
                          <Text size={12} color={colors.gray[7]}>
                            {item.productsTotalQuantity} шт.
                          </Text>
                        </Stack>
                      </Cell>
                      <SupplyPackagesCell
                        canSetPackages={item.canSetPackages}
                        id={item.id}
                        packages={item.packages}
                        onRefetch={handleRefetch}
                        boxesFolderId={item.boxesFolderId}
                      />
                      <SupplyCarCell
                        id={item.id}
                        canSetCars={item.canSetCars}
                        cars={item.cars}
                        onRefetch={handleRefetch}
                      />
                      <Cell>
                        <SupplyActionButton id={item.id} onRefetch={handleRefetch} cancellable />
                      </Cell>
                    </Row>
                  ))
                ) : (
                  <Row>
                    <Cell colspan={8} align="center">
                      <Text align="center" w="100%" color={colors.brandGrey[8]}>
                        Нет записей
                      </Text>
                    </Cell>
                  </Row>
                )}
              </TBody>
            </>
          )}
        </TableCustom>
        <PaginationCustom
          currentPage={currentPage}
          handler={pageChangeHandler}
          limitItems={50}
          totalItems={supplies?.count || 0}
          moreButton
          scrollOnChange
        />
      </Box>
    </Stack>
  );
};
