import { FC } from "react";
import { useDocumentTitle } from "@mantine/hooks";

import ProfilePage from "@/ui/pages/ProfilePage/ProfilePage";

const Profile: FC = () => {
    useDocumentTitle('Профиль');

    return (
        <ProfilePage title={'Профиль'} />
    )
}

export default Profile;