import { FC, MouseEventHandler, ReactNode, useRef } from 'react';

import { useStyles } from './styles';
interface IRow {
  children: ReactNode;
  tabindex?: number;
  onClick?: MouseEventHandler;
}

const Row: FC<IRow> = ({ children, tabindex, onClick }) => {
  const { classes } = useStyles({ interactive: Boolean(onClick) });
  const refTr = useRef<HTMLTableRowElement>(null);

  return (
    <tr ref={refTr} className={classes.row} tabIndex={tabindex} onClick={onClick}>
      {children}
    </tr>
  );
};

export default Row;
