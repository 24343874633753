import { INotificationData } from '@/types/api/notificaton';

import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionIcon, Box, Flex, Text, useMantineTheme } from '@mantine/core';
import { X } from 'tabler-icons-react';

import { useStyles } from './styles';

import { ReactComponent as IconLightning } from '@/assets/icons/lightning.svg';
import {
  closeStatusNotification,
  selectStatusNotification,
} from '@/store/slices/notification/slice';

interface INotificationsModal {
  notificationsToShow?: INotificationData[];
}

const NewStatusNotification: FC<INotificationsModal> = () => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const { text, isVisible } = useSelector(selectStatusNotification);
  const dispatch = useDispatch();

  if (!isVisible) {
    return null;
  }

  return (
    <Box className={classes.root} style={{ left: 0 }}>
      <Flex
        style={{
          backgroundColor: theme.colors.brandOrange[9],
          borderRadius: 16,
          padding: '16px 40px 16px 40px',
          alignItems: 'flex-start',
          gap: 10,
          marginBottom: 16,
          boxShadow: '0px 4px 24px 0px #00000029',
          justifyContent: 'space-between',
        }}
      >
        <Flex>
          <IconLightning style={{ width: 19, height: 21 }} />
          <Box ml={12}>
            <Text mb={4} lh={'22px'}>
              {text}
            </Text>
          </Box>
        </Flex>
        <ActionIcon className={classes.close} onClick={() => dispatch(closeStatusNotification())}>
          <X size={24} />
        </ActionIcon>
      </Flex>
    </Box>
  );
};

export default NewStatusNotification;
