import { ISummaryOrdersStatsTotal } from '@/types/api/summaryOrders';

import { Chart, ChartOptions, Plugin, TooltipItem } from 'chart.js';
import { Chart as ChartJS } from 'chart.js';

import { colors } from '@/utils/theme';

import { IChartDataItem } from '../SummaryOrdersPage';

import { daysOfWeek, ISNT_STATS_TEXT } from './constants';
import { getCurrentMonthStr, shortDayOfWeek } from './utils';

const withoutDataPlugin: Plugin = {
  id: 'withoutDataPlugin',
  afterDraw: (chart) => {
    const { ctx, chartArea } = chart;
    ctx.restore();
    ctx.font = '16px Arial';
    ctx.textAlign = 'center';
    ctx.fillStyle = colors.brandGrey[8];

    const hasData = chart.data.datasets.every((dataset) => dataset.data && dataset.data.length > 0);

    if (!hasData) {
      const { left, top, width, height } = chartArea;
      ctx.clearRect(left - 10, top, width + 20, height);
      ctx.fillText(`${ISNT_STATS_TEXT}`, (width + 60) / 2, (height + 120) / 2);
    }
    ctx.save();
  },
};

ChartJS.register(withoutDataPlugin);

const getChartOptions = (
  mergedItems: IChartDataItem[],
  total: ISummaryOrdersStatsTotal | null
): ChartOptions<'line'> | null => {
  if (mergedItems.length <= 0) return null;

  return {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
          font: {
            size: 10,
          },
          color: colors.brandDark[9],
          padding: 32,
          boxWidth: 8,
          boxHeight: 8,
          generateLabels: (chart) => {
            const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart);
            labels.forEach((label) => {
              if (label.datasetIndex !== undefined) {
                const dataset = chart.data.datasets[label.datasetIndex];
                const lineColor = dataset?.borderColor;
                label.fillStyle = lineColor?.toString();
              }
            });
            return labels;
          },
        },
      },
      tooltip: {
        position: 'average',
        backgroundColor: colors.mainColor[7],
        bodyFont: {
          size: 10,
          weight: '400',
          family: '"Inter", sans-serif',
        },
        displayColors: false,
        titleFont: {
          size: 10,
          family: '"Inter", sans-serif',
          weight: '600',
        },
        bodySpacing: 8,
        titleSpacing: 8,
        caretSize: 0,
        padding: 12,
        cornerRadius: 12,
        callbacks: {
          labelColor: () => {
            return {
              borderColor: 'transparent',
              backgroundColor: colors.customWhite[0],
            };
          },
          labelTextColor: () => colors.customWhite[0],
          title: (tooltipItem: TooltipItem<'line'>[]): string | string[] => {
            const index = tooltipItem[0].dataIndex;
            const item = mergedItems[index];
            const fullDate = new Date(item.date);
            const dayOfWeek = daysOfWeek[fullDate.getDay()];
            return `${getCurrentMonthStr(fullDate)}, ${dayOfWeek}`;
          },
          label: (tooltipItem: TooltipItem<'line'>): string | string[] => {
            const index = tooltipItem.dataIndex;
            const item = mergedItems[index];
            return [
              `Заказано: ${item.purchasedCount.toLocaleString()} шт ${item.purchasedSum.toLocaleString()} ₽`,
              `Выкуплено: ${item.soldCount.toLocaleString()} шт ${item.soldSum.toLocaleString()} ₽`,
            ];
          },
        },
      },
      ...withoutDataPlugin,
    },
    elements: {
      line: {
        tension: 0.2,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 1,
          callback: (tickValue: string | number, index: number) => {
            if (index % 2 === 0) {
              return '';
            } else {
              const date = new Date(mergedItems[index].date);
              return `${date.getDate()}, ${shortDayOfWeek(mergedItems[index].date)}`;
            }
          },
        },
      },
      y: {
        min: 0,
        max: Number(total?.step) * Number(total?.stepCount),
        ticks: {
          stepSize: total?.step || 0,
        },
      },
    },
  };
};

export default getChartOptions;
