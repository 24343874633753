import { TRefreshToken } from '@/types/common/common';

import api from '../instance';
import { tryAction } from '../tryAction';
import { usersUrls } from '../urls/usersUrls';

export const tryLogout = (refreshToken: TRefreshToken) =>
  tryAction(
    api.post(usersUrls.logout, { refreshToken })
  );
