import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  previewWrapper: {
    position: 'relative',
  },
  previewImageContainer: {
    width: 220,
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    right: 'calc(100% + 40px)',
  },

  fieldTitle: {
    fontSize: '14px',
    marginBottom: '6px',
    color: theme.colors.brandGrey[8],
  },
  fieldValue: {
    fontSize: '16px',
    color: '#000000',
  },
  fieldNameValue: {
    fontSize: '24px',
    lineHeight: '26px',
    fontWeight: 600,
  },
}));
