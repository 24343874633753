import isString from 'lodash/isString';

const UNITS_MAP: Record<string, number> = {
  КБ: 1000,
  МБ: 1000000,
};

export enum FileRejectionType {
  FILE_TOO_LARGE = 'file-too-large',
  SIZE_LIMIT_EXCEEDED = 'size-limit-exceeded',
  TOO_MANY_FILES = 'too-many-files',
  INVALID_EXTENSION = 'invalid-extension',
}

export type FileRejection = {
  error: FileRejectionType;
  file: File;
};

type FilterFn = (files: File[], arg: any, errors: FileRejection[]) => File[];

export const getStringFromFileSize = (size: number, fractionSize = 2) => {
  let unitKey = '';

  Object.keys(UNITS_MAP).forEach((unit) => {
    const multiplier = UNITS_MAP[unit];
    if (size > multiplier) unitKey = unit;
  });

  const [int, fraction] = (size / UNITS_MAP[unitKey]).toFixed(fractionSize).split('.');

  return fraction === Array(fractionSize).fill('0').join('')
    ? `${int} ${unitKey}`
    : `${int}.${fraction} ${unitKey}`;
};

export const getFileSizeFromString = (str: string) => {
  const [size, units] = str.split(' ');
  const numberSize = Number(size);
  let multiplier = 1;

  if (units in UNITS_MAP) multiplier = UNITS_MAP[units];
  if (isNaN(numberSize)) return 0;

  return numberSize * multiplier;
};

export const filterFilesByIndividualSize: FilterFn = (files, limit: number, errors) => {
  const acceptedFiles = files.filter((file) => {
    if (file.size <= limit) return true;
    else {
      errors.push({ error: FileRejectionType.FILE_TOO_LARGE, file: file });
      return false;
    }
  });

  return acceptedFiles;
};

export const filterFilesByTotalSize: FilterFn = (files, limit: number, errors) => {
  const acceptedFiles: File[] = [];
  files.reduce((remainingSize, file) => {
    if (file.size <= remainingSize) {
      acceptedFiles.push(file);
      return remainingSize - file.size;
    } else {
      errors.push({ error: FileRejectionType.SIZE_LIMIT_EXCEEDED, file: file });
      return remainingSize;
    }
  }, limit);

  return acceptedFiles;
};

export const filterFilesByCount: FilterFn = (files, limit: number, errors) => {
  const acceptedFiles = files.slice(0, limit);
  files
    .slice(limit)
    .forEach((file) => errors.push({ error: FileRejectionType.TOO_MANY_FILES, file }));
  return acceptedFiles;
};

export const filterFilesByExtension: FilterFn = (files, ext: string[] | string, errors) => {
  const acceptedFiles = files.filter((file) => {
    const mappedExtensions = isString(ext)
      ? ext.toLowerCase()
      : ext.map((extension) => extension.toLowerCase());

    const splittedName = file.name.split('.');
    const fileExt = splittedName.pop()?.toLowerCase();
    const fileName = splittedName.join('.');

    const VALID_NAME = Boolean(fileName.length) && Boolean(fileExt?.length);
    const VALID_EXTENSION = isString(mappedExtensions)
      ? mappedExtensions === fileExt
      : fileExt && mappedExtensions.includes(fileExt);

    if (VALID_NAME && VALID_EXTENSION) return true;
    else {
      errors.push({ error: FileRejectionType.INVALID_EXTENSION, file });
      return false;
    }
  });

  return acceptedFiles;
};

export const filterFilesByType: FilterFn = (files, types: string[] | string, errors) => {
  const acceptedFiles = files.filter((file) => {
    if (!file.type || (isString(types) && types !== file.type) || !types.includes(file.type)) {
      errors.push({ error: FileRejectionType.INVALID_EXTENSION, file });
      return false;
    } else return true;
  });

  return acceptedFiles;
};

export function fileFilterBuilder() {
  const filtersChain: [FilterFn, any][] = [];
  const controls = {
    bySize: (limit: number) => {
      filtersChain.push([filterFilesByIndividualSize, limit]);
      return controls;
    },
    byTotalSize: (limit: number) => {
      filtersChain.push([filterFilesByTotalSize, limit]);
      return controls;
    },
    byExt: (extensions: string | string[]) => {
      filtersChain.push([filterFilesByExtension, extensions]);
      return controls;
    },
    byType: (types: string | string[]) => {
      filtersChain.push([filterFilesByType, types]);
      return controls;
    },
    byCount: (count: number) => {
      filtersChain.push([filterFilesByCount, count]);
      return controls;
    },
    getFilter: () => (files: File[]) => {
      const errors: FileRejection[] = [];

      const acceptedFiles = filtersChain.reduce(
        (filteredFiles, filterConfig) => filterConfig[0](filteredFiles, filterConfig[1], errors),
        files
      );

      return [acceptedFiles, errors] as const;
    },
  };

  return controls;
}
