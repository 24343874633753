import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box, Loader } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import filterXSS from 'xss';

import { openProductUpload } from '@/ui/organisms/Modals/modals';
import { ModalLayout } from '@/ui/templates/Layouts/ModaLayout/ModalLayout';

import { useStyles } from './styles';

import { AppDispatch } from '@/store';
import { getProductInstruction } from '@/store/slices/products/asyncActions';
import { selectProductInstruction } from '@/store/slices/products/selectors';

const TemplateInstruction: FC<ContextModalProps> = ({ ...modalProps }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const optionsInstruction = useSelector(selectProductInstruction);

  const handlerClose = () => openProductUpload({});

  useEffect(() => {
    if (!optionsInstruction.length) {
      dispatch(getProductInstruction());
    }
  }, [optionsInstruction]);

  return (
    <ModalLayout title="" {...modalProps} withoutFooter onClose={handlerClose}>
      <Box className={classes.root}>
        {optionsInstruction && optionsInstruction.length > 0 ? (
          optionsInstruction.map((el, i) => (
            <Box key={i} dangerouslySetInnerHTML={{ __html: filterXSS(el.value) }} />
          ))
        ) : (
          <Loader />
        )}
      </Box>
    </ModalLayout>
  );
};

export default TemplateInstruction;
