import { IDadataParams } from "@/types/api/dadata";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { tryGetDadataInfo } from "@/api/users/user";

export const fetchDadataInfo = createAsyncThunk(
    '/users/dadata/organization/suggestion',
    async (params: IDadataParams) => {
        const result = await tryGetDadataInfo(params);

        if (result) {
            return result;
        } else {
            return [];
        }
    }
);