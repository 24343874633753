export const STATUSES_WITH_TOPBAR = ['order-report', 'sku-report', 'rest-report'];
export const ARTICLE_NOT_FOUND_TEXT = 'Артикул не найден в списке ваших товаров';

export const typeFilterOptions = [
  { label: 'Все', value: 'all' },
  {
    label: 'Сертификат соответствия',
    value: 'cert',
  },
  {
    label: 'Декларация',
    value: 'declaration',
  },
  {
    label: 'Свидетельство о гос. регистрации',
    value: 'registration',
  },
  {
    label: 'Регистрационное удостоверение',
    value: 'regid',
  },
  {
    label: 'Отказное письмо',
    value: 'reject-letter',
  },
  {
    label: 'Ветеринарно-сопроводительный документ',
    value: 'vet',
  },
  {
    label: 'Паспорт безопасности',
    value: 'passport',
  },
];

export const statusLabels = {
  moderation: 'На модерации',
  accepted: 'Подтверждён',
  rejected: 'Отклонён',
};

export const docTypes = {
  cert: 'Сертификат соответствия',
  declaration: 'Декларация',
  registration: 'Свидетельство о гос. регистрации',
  regid: 'Регистрационное удостоверение',
  'reject-letter': 'Отказное письмо',
  vet: 'Ветеринарно-сопроводительный документ',
  passport: 'Паспорт безопасности',
};
