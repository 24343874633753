import { FC, useMemo } from 'react';
import { Box, Button } from '@mantine/core';
import classNames from 'classnames';

import { useStyles } from './styles';
import { getAllPageArray, getPageCount, getVisiblePage } from './utils';

interface IPaginationCustomProps {
  totalItems: number;
  limitItems: number;
  currentPage: number;
  scrollOnChange?: boolean;
  moreButton?: boolean;
  handler: (value: number, more: boolean) => void;
}

const PaginationCustom: FC<IPaginationCustomProps> = ({
  totalItems,
  limitItems,
  currentPage,
  scrollOnChange = false,
  moreButton = true,
  handler,
}) => {
  const pageCount = getPageCount(totalItems, limitItems);
  const pageArray = getAllPageArray(pageCount);
  const { classes } = useStyles();

  const handlerChangePage = (page: number, more: boolean) => {
    if (!more && scrollOnChange && typeof window !== 'undefined')
      window.scrollTo({ top: 0, behavior: 'smooth' });
    if (handler) {
      handler(page, more);
    }
  };

  const showPagination = useMemo(() => totalItems > limitItems, [totalItems, limitItems]);

  return showPagination ? (
    <Box className={classes.container}>
      {moreButton && currentPage !== pageArray.length && (
        <Button
          variant={'outline'}
          className={classes.paginationShowMore}
          title="Показать еще"
          onClick={() => handlerChangePage(currentPage + 1, true)}
        >
          Показать еще
        </Button>
      )}
      <Box className={classes.pagination}>
        {currentPage !== 1 && (
          <Button
            variant={'subtle'}
            className={classes.paginationButton}
            title="Предыдущая"
            onClick={() => handlerChangePage(currentPage - 1, false)}
          >
            Предыдущая
          </Button>
        )}
        {pageArray.length > 1 && (
          <Box className={classes.paginationList}>
            {getVisiblePage(currentPage, pageArray).map((el: number) => {
              return (
                <Box key={`paginationPage${el}`}>
                  <Button
                    variant={'subtle'}
                    className={classNames(classes.paginationButton, {
                      [classes.paginationButtonActive]: currentPage === el,
                    })}
                    title={`${el}`}
                    onClick={() => handlerChangePage(el, false)}
                  >
                    {el}
                  </Button>
                </Box>
              );
            })}
          </Box>
        )}
        {currentPage !== pageArray.length && (
          <Button
            variant="subtle"
            className={classes.paginationButton}
            title="Следующая"
            onClick={() => handlerChangePage(currentPage + 1, false)}
          >
            Следующая
          </Button>
        )}
      </Box>
    </Box>
  ) : null;
};

export default PaginationCustom;
