import { FC, ReactNode } from "react";
import { Box, ScrollArea } from "@mantine/core"

interface IModalContent {
    children: ReactNode;
    fullScreen?: boolean;
    withoutPadding?: boolean;
    boxSize?: number;
    withoutFooter?: boolean;
}

const ModalContent: FC<IModalContent> = ({ children, fullScreen, withoutPadding, withoutFooter, boxSize }) => {
    return (
        <ScrollArea.Autosize sx={{
            maxHeight: !fullScreen ? `${boxSize}px` : 'auto',
            minHeight: !fullScreen ? `${boxSize}px` : '100%',
            paddingBottom: withoutFooter ? '0px' : '80px',
        }}>
            <Box pos="relative"
                sx={{
                    ...!withoutPadding && ({ padding: '32px 24px 20px' }),
                    left: 0,
                    right: 0,
            
                }}>

                {children}
            </Box>
        </ScrollArea.Autosize>
    )
}

export default ModalContent;