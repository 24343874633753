import { TFile, TProfileTabsResponse } from '@/types/api/profile';
import { IUser } from '@/types/api/user';

import { renamePropsToKamelCase } from '@/utils/kebabToCamelCase';

import api from '../instance';
import { usersUrls } from '../urls/usersUrls';

export const getCurrentUser = () => api.get<IUser>(usersUrls.user.current);
export const getTabs = () => api.get<TProfileTabsResponse>(usersUrls.seller.profile.tabs);
export const submitAbout = (values: Record<string, string | string[] | TFile | TFile[]>) => {
  const payload: typeof values = Object.keys(values).reduce(
    (res, key) =>
      key !== 'company_registration_form'
        ? { ...res, [key]: values[key] }
        : { ...res, registrationForm: values[key] },
    {}
  );
  return api.patch(usersUrls.seller.profile.submit.about, renamePropsToKamelCase(payload));
};
export const submitRequisites = (values: Record<string, string | string[] | TFile | TFile[]>) =>
  api.patch(usersUrls.seller.profile.submit.requisites, renamePropsToKamelCase(values));
export const submitDocuments = () => api.patch(usersUrls.seller.profile.submit.documents);
