export const productUrls = {
  getProducts: '/seller/products/list',
  getProductStatuses: '/seller/products/statuses',
  patchSaleRemove: '/seller/products/sale/remove',
  patchSaleReturn: '/seller/products/sale/return',
  patchArchiveAdd: '/seller/products/archive/add',
  patchArchiveRestore: '/seller/products/archive/restore',
  getProductsTemplate: '/seller/products/import/template',
  postProductsImport: '/seller/products/import',
  postProductsExport: '/seller/products/export',
  getPricesTemplate: '/seller/prices/import/template',
  postPricesImport: '/seller/prices/import',
  getCheckArticle: '/seller/products/check',
  getProductInstruction: '/seller/instructions/products',
  //createProducts
  getFormProduct: '/seller/products/form/new',
  getFormEditProduct: '/seller/products/form',
  postFormProductImages: '/seller/products/form/images',
  postSaveFormProduct: '/seller/products/form/save',
  getProductStatusWebSoket:
    'wss://wss-seller.farmperspektiva.aeroidea.ru/api/v1/seller/operations/ws',
};
