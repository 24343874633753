// eslint-disable-next-line import/no-anonymous-default-export
const WorkerFunc = () => {
  // eslint-disable-next-line no-restricted-globals
  let timerInterval: NodeJS.Timer;
  self.onmessage = function ({ data: { turn } }) {
    if (turn === 'off' && timerInterval) {
      clearInterval(timerInterval);
    }
    if (turn === 'on') {
      timerInterval = setInterval(() => {
        self.postMessage('');
      }, 1000);
    }
  };
};

export const workerBuilder = (worker: any) => {
  const code = worker.toString();
  const blob = new Blob([`(${code})()`]);
  return new Worker(URL.createObjectURL(blob));
};

export default WorkerFunc;
