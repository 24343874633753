import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, Text } from '@mantine/core';

import ModalFooter from '@/ui/organisms/Modals/ModalFooter/ModalFooter';

import { useStyles } from './styles';

import { ReactComponent as IconModalSuccess } from '@/assets/icons/redesign/iconModalSuccess.svg';
import { AppDispatch } from '@/store';
import { setModalSubmitted } from '@/store/slices/modal/slice';

export const ProfileSubmitted: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { classes } = useStyles();

  const handleClose = (): void => {
    dispatch(setModalSubmitted(null));
  };

  return (
    <>
      <Box className={classes.wrapper}>
        <IconModalSuccess className={classes.icon} />
        <Box mb={32}>
          <Text
            className={classes.text}
          >
            Данные были отправлены на&nbsp;рассмотрение модераторам. Ожидайте ответ в&nbsp;течение
            трёх&nbsp;рабочих дней
          </Text>
        </Box>
      </Box>
      <ModalFooter align='center' variant='xs'>
        <Button
          className={classes.button}
          type="button"
          size="lg"
          variant="filled"
          onClick={handleClose}
        >
          Хорошо
        </Button>
      </ModalFooter>
    </>
  );
};
