import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mantine/core';

import { useStyles } from './styles';

import { ReactComponent as Logo } from '@/assets/images/logo.svg';

const HeaderLogo: FC = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Logo />
    </Box>
  );
};

export default HeaderLogo;
