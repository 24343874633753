import { IOperations, TOperationsCodes } from '@/types/entites/template';

import { FC } from 'react';
import { Anchor, Box, Button, Stack, Text, useMantineTheme } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { X } from 'tabler-icons-react';

import ModalCLose from '../ModalClose/ModalClose';
import ModalContent from '../ModalContent/ModalContent';
import ModalFooter from '../ModalFooter/ModalFooter';
import { openProductUpload, openUpdatePrices } from '../modals';

interface IModalFailProducts {
  operation: IOperations,
  title: string;
  body: string;
  path: string;
  error: string;
}

export const ModalFailProducts: FC<ContextModalProps<IModalFailProducts>> = ({
  context,
  id,
  innerProps: { operation, title, body, path, error }
}) => {
  const { colors } = useMantineTheme();

  return (
    <ModalContent boxSize={535}>
      <ModalCLose onClose={() => context.closeModal(id)} />
      <Stack spacing={32} align="center">
        <Text
          size={24}
          weight={700}
          color={colors.gray[7]}
          align="center"
          px={48}
          sx={{ lineHeight: 1.5 }}
        >
          {title || 'Ошибка!'}
        </Text>
        <Box
          p={16}
          sx={{ border: `4px solid ${colors.red[7]}`, borderRadius: '50%' }}
          w={70}
          h={70}
        >
          <X size={30} stroke={colors.red[7]} strokeWidth={3} />
        </Box>
        <Stack spacing={12} align="center">
          {error ? (
            <Text align="center" sx={{ lineHeight: '22px' }}>
              {error}
            </Text>
          ) : (
            <>
              <Text align="center" sx={{ lineHeight: '22px' }}>
                {body || error}
              </Text>
              {path && <Anchor href={path} size={14} fw={500}>
                Скачать файл с ошибками
              </Anchor>
              }
            </>
          )}
        </Stack>
        <ModalFooter align='center' variant='xs'>
          <Button
            onClick={() => {
              context.closeModal(id);
              if (operation?.code === TOperationsCodes.PRICES_IMPORT) {
                openUpdatePrices({})
              } else if (operation?.code === TOperationsCodes.PRODUCTS_IMPORT) {
                openProductUpload({})
              }
            }}
            w="280px"
            size="lg"
            fz={14}
          >
            Попробовать еще раз
          </Button>
        </ModalFooter>
      </Stack>
    </ModalContent>
  );
};
