import { IFamily } from '@/types/common/common';
import { ICategory, ICategoryTree, TActiveCategories } from '@/types/entites/category';

import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import LoaderDots from '@/ui/atoms/LoaderDots/LoaderDots';
import SelectCustom from '@/ui/atoms/SelectCustom/SelectCustom';
import ModalFooter from '@/ui/organisms/Modals/ModalFooter/ModalFooter';
import CategorySelection from '@/ui/organisms/СategorySelection/CategorySelection';
import { rebuildDataForSelect } from '@/ui/pages/ProductsPage/utils/rebuildDataForSelect';
import { ModalLayout } from '@/ui/templates/Layouts/ModaLayout/ModalLayout';

import { categoriesApi } from '@/store/slices/category/api';
import { productsApi } from '@/store/slices/products/api';
import { setFormProductData } from '@/store/slices/products/slice';

export interface IChooseCategory {
  id: string;
}

export const updateActiveCategories = (
  category: ICategory,
  currentDepthLevel: number,
  categoriesActive: TActiveCategories
): TActiveCategories => {
  let newCategories = { ...categoriesActive };
  if (category.depthLevel < currentDepthLevel) delete newCategories[currentDepthLevel];
  if (category.depthLevel <= 1) delete newCategories[currentDepthLevel - 1];

  return {
    ...newCategories,
    [category.depthLevel]: category,
  };
};

const CreateProduct: FC<ContextModalProps<IChooseCategory>> = ({ ...modalProps }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentCategory, setCurrentCategory] = useState<ICategory | null>(null);
  const [activeCategories, setActiveCategories] = useState<TActiveCategories>({});
  const [currentTree, setCurrentTree] = useState<ICategoryTree[]>([]);
  const [selectedFamily, setSelectedFamily] = useState<IFamily | null>(null);

  const [getCategoriesTree, { data: tree, isFetching: isTreeFetching }] =
    categoriesApi.useLazyGetCategoriesTreeQuery();
  const [getCategoriesFamily, { data: categoryFamily, isFetching: isFetchingFamily }] =
    categoriesApi.useLazyGetCategoriesFamilyQuery();
  const [getFormOfNewProduct, { isFetching: isFetchingGetFormOfProduct }] =
    productsApi.useLazyGetFormProductQuery();

  const handleReset = () => {
    setCurrentTree(tree ? [{ tree }] : []);
    setActiveCategories({});
    setCurrentCategory(null);
    setSelectedFamily(null);
  };

  const families = useMemo(() => {
    if (categoryFamily && categoryFamily.length > 0) {
      return rebuildDataForSelect(categoryFamily);
    }
    return [];
  }, [categoryFamily]);

  const dataForProduct = useMemo(
    () => ({
      familyCode:
        selectedFamily?.code ||
        (categoryFamily && categoryFamily.length === 1 ? categoryFamily[0].code : null),
      categoryCode: currentCategory?.code,
    }),
    [categoryFamily, currentCategory, selectedFamily]
  );

  const handleSendCategory = () => {
    if (dataForProduct.familyCode && dataForProduct.categoryCode) {
      getFormOfNewProduct(dataForProduct).then((response) => {
        dispatch(setFormProductData(response.data));
        localStorage.removeItem(`uploadedImages`);
        localStorage.removeItem('currentStatus');
        navigate(`/products/create/common`);
      });
    }
  };

  const handlerOnChange = (value: string | null) => {
    const current = families?.find((el) => el.value === value);

    if (current) {
      setSelectedFamily({
        code: String(current?.value),
        label: String(current?.label),
      });
    }
  };

  const handleChange = (element: ICategory) => {
    getCategoriesFamily(element.code);
    setCurrentCategory(element);

    setActiveCategories((prevCategory) =>
      updateActiveCategories(element, element.depthLevel, prevCategory)
    );
  };

  useEffect(() => {
    if (currentTree.length <= 0) getCategoriesTree();
  }, [getCategoriesTree]);

  useEffect(() => {
    if (tree && tree.length > 0) setCurrentTree([{ tree }]);
  }, [tree]);

  useEffect(() => {
    if (categoryFamily && categoryFamily.length === 1) {
      setSelectedFamily({
        code: String(categoryFamily[0].code),
        label: String(categoryFamily[0].label),
      });
    } else {
      setSelectedFamily(null);
    }
  }, [categoryFamily]);

  return (
    <ModalLayout title="Выбрать категорию" {...modalProps}>
      <Box>
        <CategorySelection
          currentTree={currentTree}
          activeCategories={Object.values(activeCategories)}
          onChangeElement={handleChange}
          onCatergoriestTree={setCurrentTree}
          handleReset={handleReset}
          isLoading={isTreeFetching}
        />

        {families && families.length > 1 && (
          <SelectCustom
            mt={20}
            size="sm"
            onChange={handlerOnChange}
            placeholder="Пожалуйста уточните"
            options={families}
            optionValue={selectedFamily?.label}
            withinPortal={false}
          />
        )}
      </Box>
      <ModalFooter align="center" variant="xs">
        <Button
          pos={'relative'}
          size="lg"
          variant="filled"
          onClick={handleSendCategory}
          p={'13px, 24px'}
          disabled={!dataForProduct.categoryCode || !dataForProduct.familyCode}
          miw={280}
          fz={14}
        >
          {isFetchingFamily || isFetchingGetFormOfProduct ? (
            <LoaderDots loading={isFetchingFamily} bgr="blue" />
          ) : (
            'Применить'
          )}
        </Button>
      </ModalFooter>
    </ModalLayout>
  );
};

export default CreateProduct;
