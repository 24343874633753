import { createStyles } from "@mantine/core";

export const useStyles = createStyles((_) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '20px 32px 32px',
        textAlign: 'center',
    },
    icon: {
        maxWidth: '100%',
    },
    text: {
        marginTop: '32px',
        lineHeight: '24px'
    },
    button: {
        marginBottom: '16px',
        padding: '13px 24px',
        minWidth: '280px',
        fontSize: '14px'
    }
}));