import { createStyles } from '@mantine/core';

export const useButtonStyles = createStyles(() => ({
  root: {
    padding: 0,
    width: 'fit-content',
    background: 'transparent',
    ':not([data-disabled]):hover': {
      background: 'transparent',
    },
    ':disabled': {
      background: 'transparent',
    },
  },
}));
