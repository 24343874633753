export const getImageSize = (bits: number): string => {
  const KILOBIT = 1024;
  const MEGABIT = 1024 * 1024;

  let result: string;

  switch (true) {
    case bits < KILOBIT:
      result = `${bits} BIT`;
      break;
    case bits < MEGABIT:
      result = `${(bits / KILOBIT).toFixed(2)} KB`;
      break;
    default:
      result = `${(bits / MEGABIT).toFixed(2)} MB`;
      break;
  }

  return result;
};
