import { RootState } from '@/store';

export const selectProducts = (state: RootState) => state.products.products;
export const selectProductsLimit = (state: RootState) => state.products.limit;
export const selectProductsOffset = (state: RootState) => state.products.offset;

export const selectProductsMeta = (state: RootState) => state.products.meta;
export const selectProductsCount = (state: RootState) => state.products.meta.count;
export const selectProductsTotalCount = (state: RootState) => state.products.meta.totalCount;

export const selectProductStatuses = (state: RootState) => state.products.statuses;
export const selectProductsCurrentStatus = (state: RootState) => state.products.currentStatus;
export const selectSupplierProductId = (state: RootState) => state.products.supplierProductId;

export const selectProductsSelectedAll = (state: RootState) => state.products.selectedAll;
export const selectProductsSelected = (state: RootState) => state.products.selected;

export const selectProductsOrder = (state: RootState) => state.products.filters.order;
export const selectProductsSort = (state: RootState) => state.products.filters.sort;
export const selectProductsActiveSort = (state: RootState) => state.products.filters.sortActive;

export const selectProductsChangeStatus = (state: RootState) =>
  state.products.status.fetchingsChangeStatus;
export const selectFetchingProducts = (state: RootState) => state.products.status.fetchingProducts;
export const selectFetchingProductsTemplate = (state: RootState) =>
  state.products.status.fetchingTemplate;

export const selectArchiveList = (state: RootState) => state.products.archiveList;
export const selectEansList = (state: RootState) => state.products.eansList;

export const selectFetchingImport = (state: RootState) => state.products.status.fetchingImport;
export const selectFetchingPricesImport = (state: RootState) =>
  state.products.status.fetchingPricesImport;
export const selectFetchingExport = (state: RootState) => state.products.status.fetchingExport;
export const selectFetchingArticle = (state: RootState) => state.products.status.fetchingArticle;

export const selectOpertaionExport = (state: RootState) => state.products.opertaions.export;

export const selectProductArticleExist = (state: RootState) => state.products.existArticle;

export const selectProductInstruction = (state: RootState) => state.products.instruction;
export const selectFetchingProductInstruction = (state: RootState) =>
  state.products.status.fetchingInstruction;

export const selectIsExportActive = (state: RootState) => state.products.status.exportActive;

export const selectFormProductData = (state: RootState) => state.products.formProductData;
