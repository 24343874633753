import { FC, ReactNode } from "react";
import { Box } from "@mantine/core";

interface IFormGroupProps {
    children: ReactNode;
    spacing?: number;
    label?: string;
}

const FormGroup: FC<IFormGroupProps> = ({ children, spacing, label}) => {
    return (
        <Box mb={spacing} pos={'relative'}>
            {label &&
                <label>{label}</label>
            }
            {children}
        </Box>
    )
}

export default FormGroup;