import { keyframes } from "@emotion/react";
import { createStyles, MantineTheme } from "@mantine/core";

const dotAnimation = (theme: MantineTheme, bgr: string) =>
    keyframes({
        "0%": {
            boxShadow: `
                9984px 0 0 -1px ${bgr === 'blue' ? theme.colors.customWhite[0] : theme.colors.mainColor[6]},
                9999px 0 0 1px ${bgr === 'blue' ? theme.colors.customWhite[0] : theme.colors.mainColor[6]},
                10014px 0 0 -1px ${bgr === 'blue' ? theme.colors.customWhite[0] : theme.colors.mainColor[6]}
            `,
        },
        "50%": {
            boxShadow: `
                10014px 0 0 -1px ${bgr === 'blue' ? theme.colors.customWhite[0] : theme.colors.mainColor[6]},
                9984px 0 0 -1px ${bgr === 'blue' ? theme.colors.customWhite[0] : theme.colors.mainColor[6]},
                9999px 0 0 1px ${bgr === 'blue' ? theme.colors.customWhite[0] : theme.colors.mainColor[6]}
            `
        },
        "100%": {
            boxShadow: `
                9999px 0 0 1px ${bgr === 'blue' ? theme.colors.customWhite[0] : theme.colors.mainColor[6]},
                10014px 0 0 -1px ${bgr === 'blue' ? theme.colors.customWhite[0] : theme.colors.mainColor[6]},
                9984px 0 0 -1px ${bgr === 'blue' ? theme.colors.customWhite[0] : theme.colors.mainColor[6]}
            `,
        },
    });

export const useStyles = createStyles((theme: MantineTheme, bgrColor: { background: string }) => ({
    container: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        pointerEvents: 'none',
        borderRadius: '9999px'
    },
    overlay: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: `${bgrColor.background === 'blue' ? theme.colors.mainColor[6] : theme.colors.customWhite[0]} `,
        pointerEvents: 'none',
        borderRadius: '9999px'
    },
    dotAnimation: {
        position: "relative",
        left: "-9999px",
        width: "10px",
        height: "10px",
        borderRadius: '5px',
        backgroundColor: theme.colors.gray[1],
        color: theme.colors.gray[1],
        boxShadow: `9984px 0 0 0 ${theme.colors.gray[1]}, 9999px 0 0 0 ${theme.colors.gray[1]}, 10014px 0 0 0 ${theme.colors.gray[1]} `,
        animation: `${dotAnimation(theme, bgrColor.background)} 1.5s infinite linear`,
    },
}));
