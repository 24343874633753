import { AuthStepsEnum, AuthTypeEnum } from '@/types/api/auth';
import { IToken } from '@/types/common/common';

import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
// import { deleteCookie, setCookie } from '@/utils/cookie';
import { deleteCookie, setCookie } from 'cookies-next';

import { cookiesNames } from '@/constants/common';

import {
  addSellerAction,
  addUserAction,
  authByEmailAction,
  authByPhoneAction,
  changePasswordAction,
  changePasswordByEmailAction,
  changePasswordByEmailConfirmAction,
  changePasswordConfirmAction,
  checkEmailAction,
  checkPhoneAction,
  checkSellerEmailAction,
  getUpgradeSeller,
  logoutAction,
  resendCodeAction,
  upgradeUserToSellerAction,
} from './asyncAction';

interface IAuthState {
  status: {
    fetchingCheckEmail: boolean;
    fetchingCheckPhone: boolean;
    fetchingAuthByPhone: boolean;
    fetchingAuthByEmail: boolean;
    fetchingNewCode: boolean;
    fetchingAddUser: boolean;
    fetchingLogout: boolean;
    fetchingRestorePassword: boolean;
    fetchingChangePasswordConfirm: boolean;
    fetchingChangePasswordByEmail: boolean;
    fetchingChangePasswordByEmailConfirm: boolean;
  };
  userToken: IToken | null;
  authOpened: boolean;
  authType: AuthTypeEnum;
  authStatus: boolean;
  authStep: AuthStepsEnum;
  userExist: boolean;
  userPhone: string;
  userEmail: string;
  userSellerEmail: string;
  regByPhone: boolean;
}

const initialState: IAuthState = {
  status: {
    fetchingCheckEmail: false,
    fetchingCheckPhone: false,
    fetchingAuthByPhone: false,
    fetchingAuthByEmail: false,
    fetchingNewCode: false,
    fetchingAddUser: false,
    fetchingLogout: false,
    fetchingRestorePassword: false,
    fetchingChangePasswordConfirm: false,
    fetchingChangePasswordByEmail: false,
    fetchingChangePasswordByEmailConfirm: false,
  },
  userToken: null,
  authStep: AuthStepsEnum.SIGNIN_WITH_ONLY_EMAIL,
  authType: AuthTypeEnum.USER,
  authOpened: false,
  authStatus: false,
  userExist: false,
  userPhone: '',
  userEmail: '',
  userSellerEmail: '',
  regByPhone: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setRegbyPhone: (state, action: PayloadAction<boolean>) => {
      state.regByPhone = action.payload;
    },
    setAuthStep: (state: Draft<typeof initialState>, action: PayloadAction<AuthStepsEnum>) => {
      state.authStep = action.payload;
    },
    setUserPhone: (state: Draft<typeof initialState>, action: PayloadAction<string>) => {
      state.userPhone = action.payload;
    },
    setUserEmail: (state: Draft<typeof initialState>, action: PayloadAction<string>) => {
      state.userEmail = action.payload;
    },
    setUserSellerEmail: (state: Draft<typeof initialState>, action: PayloadAction<string>) => {
      state.userSellerEmail = action.payload;
    },
    setUserToken: (state: Draft<typeof initialState>, action: PayloadAction<IToken>) => {
      state.userToken = action.payload;
    },
    setAuthOpened: (state: Draft<typeof initialState>, action: PayloadAction<boolean>) => {
      state.authOpened = action.payload;
    },
    setAuthType: (state: Draft<typeof initialState>, action: PayloadAction<AuthTypeEnum>) => {
      state.authType = action.payload;
    },
    setAuthStatus: (state: Draft<typeof initialState>, action: PayloadAction<boolean>) => {
      state.authStatus = action.payload;
    },
    resetAuth: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkPhoneAction.pending, (state) => {
        state.status.fetchingCheckPhone = true;
        state.userExist = false;
      })
      .addCase(checkPhoneAction.fulfilled, (state, action) => {
        state.status.fetchingCheckPhone = false;
        state.userExist = action.payload.userExist;

        if (action.payload.userExist) {
          state.authStep = AuthStepsEnum.SIGNIN_WITH_PHONE_CODE ?? '';
        } else {
          state.authStep = AuthStepsEnum.SIGNUP ?? '';
        }
      })
      .addCase(checkPhoneAction.rejected, (state) => {
        state.status.fetchingCheckPhone = false;
      });
    //old builder
    builder
      .addCase(checkEmailAction.pending, (state) => {
        state.status.fetchingCheckEmail = true;
        state.userExist = false;
      })
      .addCase(checkEmailAction.fulfilled, (state, action) => {
        state.status.fetchingCheckEmail = false;
        state.userExist = action.payload.userExist;

        if (action.payload.userExist) {
          state.authStep = AuthStepsEnum.SIGNIN_WITH_EMAIL;
        } else {
          state.authStep = AuthStepsEnum.SIGNIN_WITH_PHONE;
        }
      })
      .addCase(checkEmailAction.rejected, (state) => {
        state.status.fetchingCheckEmail = false;
      });
    // /old builder/
    builder
      .addCase(checkSellerEmailAction.pending, (state) => {
        state.status.fetchingCheckEmail = true;
        state.userExist = false;
      })
      .addCase(checkSellerEmailAction.fulfilled, (state, action) => {
        state.status.fetchingCheckEmail = false;
        state.userExist = action.payload.userExist;
      })
      .addCase(checkSellerEmailAction.rejected, (state) => {
        state.status.fetchingCheckEmail = false;
      });
    builder
      .addCase(authByPhoneAction.pending, (state) => {
        state.status.fetchingAuthByPhone = true;
      })
      .addCase(authByPhoneAction.fulfilled, (state, action) => {
        state.status.fetchingAuthByPhone = false;
        state.userToken = action.payload.token;

        state.authStatus = true;
        state.authOpened = false;
        // state.authStep = AuthStepsEnum.SIGNIN_WITH_PHONE;
      })
      .addCase(authByPhoneAction.rejected, (state) => {
        state.status.fetchingAuthByPhone = false;
      });
    builder
      .addCase(authByEmailAction.pending, (state) => {
        state.status.fetchingAuthByEmail = true;
      })
      .addCase(authByEmailAction.fulfilled, (state, action) => {
        state.status.fetchingAuthByEmail = false;
        state.userToken = action.payload.token;

        state.authStatus = true;
        state.authOpened = false;
        // state.authStep = AuthStepsEnum.SIGNIN_WITH_PHONE;
      })
      .addCase(authByEmailAction.rejected, (state) => {
        state.status.fetchingAuthByEmail = false;
      });
    builder
      .addCase(addUserAction.pending, (state) => {
        state.status.fetchingAddUser = true;
      })
      .addCase(addUserAction.fulfilled, (state, action) => {
        state.status.fetchingAddUser = false;
        state.userToken = action.payload.token;

        state.authStatus = true;
        state.authOpened = false;
        // state.authStep = AuthStepsEnum.SIGNIN_WITH_PHONE;
      })
      .addCase(addUserAction.rejected, (state) => {
        state.status.fetchingAddUser = false;
      });

    builder
      .addCase(addSellerAction.pending, (state) => {
        state.status.fetchingAddUser = true;
      })
      .addCase(addSellerAction.fulfilled, (state, action) => {
        state.status.fetchingAddUser = false;
        state.userToken = action.payload.token;

        state.authStep = AuthStepsEnum.UPGRADE_TO_SELLER_NEW;
      })
      .addCase(addSellerAction.rejected, (state) => {
        state.status.fetchingAddUser = false;
      });

    builder
      .addCase(resendCodeAction.pending, (state) => {
        state.status.fetchingNewCode = true;
      })
      .addCase(resendCodeAction.fulfilled, (state) => {
        state.status.fetchingNewCode = false;
      })
      .addCase(resendCodeAction.rejected, (state) => {
        state.status.fetchingNewCode = false;
      });
    builder
      .addCase(logoutAction.pending, (state) => {
        state.status.fetchingLogout = true;
      })
      .addCase(logoutAction.fulfilled, (state, action) => {
        state.status.fetchingLogout = false;
        state.authStatus = false;
        state.authStep = AuthStepsEnum.SIGNIN_WITH_ONLY_EMAIL;
        state.userToken = action.payload.data.token;

        deleteCookie(cookiesNames.NEXT_USER_TOKEN, {
          path: '/',
          domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        });
        setCookie(
          cookiesNames.NEXT_CUSTOMER_TOKEN,
          JSON.stringify({
            ...action.payload.data.token,
            expiresAt: Date.now() + action.payload.data.token.expiresIn * 1000,
            refreshExpiresAt: Date.now() + action.payload.data.token.refreshExpiresIn * 1000,
          }),
          {
            path: '/',
            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
          }
        );
      })
      .addCase(logoutAction.rejected, (state) => {
        state.status.fetchingLogout = false;
      });
    builder
      .addCase(changePasswordAction.pending, (state) => {
        state.status.fetchingRestorePassword = true;
      })
      .addCase(changePasswordAction.fulfilled, (state) => {
        state.status.fetchingRestorePassword = false;
      })
      .addCase(changePasswordAction.rejected, (state) => {
        state.status.fetchingRestorePassword = false;
      });
    builder
      .addCase(changePasswordConfirmAction.pending, (state) => {
        state.status.fetchingChangePasswordConfirm = true;
      })
      .addCase(changePasswordConfirmAction.fulfilled, (state, action) => {
        state.status.fetchingChangePasswordConfirm = false;
        state.userToken = action.payload.token;

        state.authStatus = true;
        state.authOpened = false;
        // state.authStep = AuthStepsEnum.RESTORE_PASSWORD_EMAIL;
      })
      .addCase(changePasswordConfirmAction.rejected, (state) => {
        state.status.fetchingChangePasswordConfirm = false;
      });
    builder
      .addCase(changePasswordByEmailAction.pending, (state) => {
        state.status.fetchingChangePasswordByEmail = true;
      })
      .addCase(changePasswordByEmailAction.fulfilled, (state) => {
        state.status.fetchingChangePasswordByEmail = false;
        state.authStep = AuthStepsEnum.RESTORE_PASSWORD_EMAIL_CODE;
      })
      .addCase(changePasswordByEmailAction.rejected, (state) => {
        state.status.fetchingChangePasswordByEmail = false;
      });
    builder
      .addCase(changePasswordByEmailConfirmAction.pending, (state) => {
        state.status.fetchingChangePasswordByEmailConfirm = true;
      })
      .addCase(changePasswordByEmailConfirmAction.fulfilled, (state, action) => {
        state.status.fetchingChangePasswordByEmailConfirm = false;
        state.userToken = action.payload.token;

        state.authStatus = true;
        state.authOpened = false;
        // state.authStep = AuthStepsEnum.SIGNIN_WITH_PHONE;
      })
      .addCase(changePasswordByEmailConfirmAction.rejected, (state) => {
        state.status.fetchingChangePasswordByEmailConfirm = false;
      });
    builder
      .addCase(upgradeUserToSellerAction.pending, (state) => {
        state.status.fetchingChangePasswordByEmailConfirm = true;
      })
      .addCase(upgradeUserToSellerAction.fulfilled, (state, action) => {
        const { result, user } = action.payload;
        state.status.fetchingChangePasswordByEmailConfirm = false;
        state.userToken = result.token;
        state.authStatus = true;
        if (!user?.dateEmailSellerLoginConfirmation) state.authStep = AuthStepsEnum.CHECK_EMAIL;
        else state.authStep = AuthStepsEnum.COMPANY_INFORMATION;
        // state.authStep = AuthStepsEnum.CHECK_EMAIL;
      })
      .addCase(upgradeUserToSellerAction.rejected, (state) => {
        state.status.fetchingChangePasswordByEmailConfirm = false;
      });
    builder.addCase(getUpgradeSeller.fulfilled, (state, action) => {
      state.userEmail = action.payload.email || '';
    });
  },
});

// Selectors
type TSelectorState = { auth: IAuthState };

export const tokenSelector = (state: TSelectorState) => state?.auth.userToken;
// Selectors
export const selectFetchingCheckEmail = (state: TSelectorState) =>
  state?.auth?.status?.fetchingCheckEmail;
export const selectFetchingCheckPhone = (state: TSelectorState) =>
  state?.auth?.status?.fetchingCheckPhone;
export const selectFetchingAuthByPhone = (state: TSelectorState) =>
  state?.auth?.status?.fetchingAuthByPhone;
export const selectFetchingAuthByEmail = (state: TSelectorState) =>
  state?.auth?.status.fetchingAuthByEmail;
export const selectFetchingAddUser = (state: TSelectorState) =>
  state?.auth?.status?.fetchingAddUser;
export const selectFetchingRestorePassword = (state: TSelectorState) =>
  state?.auth?.status?.fetchingRestorePassword;
export const selectFetchingChangePasswordConfirm = (state: TSelectorState) =>
  state?.auth?.status?.fetchingChangePasswordConfirm;
export const selectFetchingchangePasswordByEmail = (state: TSelectorState) =>
  state?.auth?.status?.fetchingChangePasswordByEmail;
export const selectFetchingchangePasswordByEmailConfirm = (state: TSelectorState) =>
  state?.auth?.status?.fetchingChangePasswordByEmailConfirm;

export const selectAuthOpened = (state: TSelectorState) => state?.auth?.authOpened;
export const selectAuthType = (state: TSelectorState) => state?.auth?.authType;
export const selectAuthStatus = (state: TSelectorState) => state?.auth?.authStatus;
export const selectUserToken = (state: TSelectorState) => state?.auth?.userToken;
export const selectAuthStep = (state: TSelectorState) => state?.auth?.authStep;
export const selectUserPhone = (state: TSelectorState) => state.auth?.userPhone;
export const selectUserEmail = (state: TSelectorState) => state?.auth?.userEmail;
export const selectUserSellerEmail = (state: TSelectorState) => state?.auth?.userSellerEmail;
export const selectRegByPhone = (state: TSelectorState) => state?.auth?.regByPhone;

// Reducers and actions
export const {
  setAuthType,
  setAuthOpened,
  setUserToken,
  setAuthStep,
  setUserPhone,
  setUserEmail,
  setUserSellerEmail,
  setAuthStatus,
  setRegbyPhone,
  resetAuth,
} = authSlice.actions;

export const userLogout = () => ({ type: 'USER_LOGOUT' });

export const authReducer = authSlice.reducer;
