import { createStyles, getStylesRef } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  dropdown: {
    backgroundColor: theme.colors.gray[1],
  },
  item: {
    color: theme.colors.gray[6],
    '&:hover, &[data-selected], &[data-selected]:hover': {
      backgroundColor: theme.colors.gray[2],
    },
    '&[data-selected], &[data-selected]:hover': {
      color: theme.colors[theme.primaryColor][6],
    },
  },
  wrapper: {
    position: 'relative',
    '&:focus-within, &[data-has-value="true"]': {
      [`.${getStylesRef('label')}`]: { top: -8 },
      [`.${getStylesRef('input')}::placeholder`]: { opacity: 1 },
    },
  },
  label: {
    ref: getStylesRef('label'),
    position: 'absolute',
    top: '10px',
    transition: '0.1s',
    zIndex: 100,
    color: theme.colors.gray[6],
  },
  input: {
    ref: getStylesRef('input'),
    '&::placeholder': {
      opacity: 0,
      transition: '0.1s',
    },
  },
}));
