import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Group, Text, Title } from '@mantine/core';
import cn from 'classnames';

import { livetexShowWelcomeWindow } from '@/utils/livetex';

import { SELLER_STATUS_ACTIVE } from '@/constants/common';

import Section from '../Section/Section';

import { useStyles } from './styles';

import { profileApi } from '@/store/slices/profile/slice';
import { selectUser } from '@/store/slices/user/slice';

const ContractShow: FC = () => {
  const { data: profile } = profileApi.useGetTabsQuery();
  const user = useSelector(selectUser);
  const status = useMemo(
    () => (user?.sellerStatus === SELLER_STATUS_ACTIVE ? 'Активен' : 'Приостановленный'),
    [user]
  );
  const { classes } = useStyles({
    status: user?.sellerStatus || '',
  });

  return (
    <Box fz={14}>
      <Section>
        <Group>
          <Title order={2}>Договор</Title>
          <Text className={classes.mark}>{status}</Text>
        </Group>
        {profile?.docs?.offerName && profile?.docs?.offerDate && (
          <Text className={classes.text}>
            {profile?.docs?.offerName} от {profile?.docs?.offerDate?.split('T')[0]}
          </Text>
        )}
        {profile?.docs?.offerLink && (
          <Link to={profile?.docs?.offerLink} target="_blank">
            Условия оферты
          </Link>
        )}
      </Section>
      <Section last>
        <Title order={2}>Документы</Title>
        <Text className={cn(classes.text, classes.text2)}>
          Для редактирования обратитесь в<a style={{cursor: 'pointer'}} onClick={livetexShowWelcomeWindow}> службу поддержки</a>
        </Text>
      </Section>
    </Box>
  );
};

export default ContractShow;
