import { createStyles } from '@mantine/core';

const height = '40px';
export const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    background: theme.colors.customWhite[0],
    color: theme.colors.text[5],
    border: `1px solid ${theme.colors.brandGrey[5]}`,
    borderRadius: '4px',
    padding: '4px 4px',
  },
  content:{
    '@media (max-width: 627px)': {
    justifyContent: 'center'
    },
  },
  selectInput: {
    height: height,
    minHeight: height,
    background: theme.colors.mainColor[8],
    width: '165px',
    fontSize: '14px',
    color: theme.colors.customWhite[0],

    '&:not(:disabled)': {
      color: theme.colors.customWhite[0],
    },

    '&:placeholder': {
      color: theme.colors.customWhite[0],
    },
  },
  selectItem: {
    fontSize: '14px',
  },
  input: {
    height: height,
    minHeight: height,
    padding: '0px 16px',
    fontSize: '14px',
    color: 'inherit',
  },
  selectLargeInput: {
    height: '49px',
    minHeight: '49px',
    width: '200px',
    fontSize: '14px',
    color: 'inherit',
  },
  button: {
    fontSize: '14px',
    height: '49px',
    minHeight: '49px',
    overflow: 'hidden',
  },
  isHidePanel: {
    display: 'none',
  }
}));
