import { createStyles } from "@mantine/core";

export const useStyles = createStyles((_) => ({
    wrapper: {
        padding: '20px 32px 34px',
        fontSize: '16px',
        lineHeight: '24px',
    },
    icon: {
        maxWidth: '100%',
    },
    text: {
        margin: '32px 0px 0px',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        textAlign: 'center'
    },
    button: {
        padding: '13px 24px',
        minWidth: '280px',
        fontSize: '14px'
    }
}));