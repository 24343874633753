import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  close: {
    position: 'fixed',
    right: '24px',
    top: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    padding: '8px',
    backgroundColor: theme.colors.customWhite[0],
    border: `solid 1px ${theme.colors.brandGrey[7]}`,
    zIndex: 390,
    '& > svg': {
      fill: theme.colors.brandDark[9],
    },
  },
}));
