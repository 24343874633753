import { isRejected } from '@reduxjs/toolkit';
import { deleteCookie } from 'cookies-next';
import { Middleware } from 'redux';

import { cookiesNames } from '@/constants/common';

export const logoutMiddleWare: Middleware = () => (next) => (action) => {
  if (!isRejected(action)) return next(action);
  if (
    action.type === '/users/current/rejected' ||
    action.meta.baseQueryMeta?.response?.status === 401
  ) {
    deleteCookie(cookiesNames.NEXT_CUSTOMER_TOKEN);
    deleteCookie(cookiesNames.NEXT_USER_TOKEN, {
      path: '/',
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    });
  }

  return next(action);
};
