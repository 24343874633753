import { IDocumentsListResponse, IGetProductsResponse } from '@/types/api/productDocuments';

import { createSlice } from '@reduxjs/toolkit';

import { productDocsApi } from './api';

import { RootState } from '@/store';

type ProductDocsState = {
  docs: IDocumentsListResponse;
  products: IGetProductsResponse;
};

const initialState: ProductDocsState = {
  docs: {
    items: [],
    meta: {
      totalCount: 0,
      count: 0,
    },
  },
  products: {
    items: [],
    meta: {
      totalCount: 0,
      count: 0,
    },
  },
};

const slice = createSlice({
  initialState,
  name: 'productDocs',
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      productDocsApi.endpoints.getDocumentsList.matchFulfilled,
      (state, action) => {
        const { concat } = action.meta.arg.originalArgs;
        const data = action.payload;
        if (concat) {
          state.docs.items = state.docs.items.concat(data.items);
          state.docs.meta.count += data.meta.count;
          state.docs.meta.totalCount = data.meta.count;
        } else state.docs = data;
      }
    );
    builder.addMatcher(productDocsApi.endpoints.getProducts.matchFulfilled, (state, action) => {
      const { concat } = action.meta.arg.originalArgs;
      const data = action.payload;
      if (concat) {
        state.products.items = state.products.items.concat(data.items);
        state.products.meta.count += data.meta.count;
        state.products.meta.totalCount = data.meta.count;
      } else state.products = data;
    });
  },
});

export const { reducer: productDocsReducer } = slice;

export const selectProductDocs = (state: RootState) => state.productDocs.docs;
export const selectDocProducts = (state: RootState) => state.productDocs.products;
