import { IProfileFileField, TFile } from '@/types/api/profile';

import { AxiosProgressEvent } from 'axios';

import api from '@/api/instance';
import { usersUrls } from '@/api/urls/usersUrls';

import { TInputState } from '../types';

export function isSingleValue<T>(value: T | T[]): value is T {
  return !(value instanceof Array);
}

export type FileRejection = {
  errors: [
    {
      code: string;
    }
  ];
};

export const uploadFile = (
  type: string,
  file: File,
  onUploadProgress: (e: AxiosProgressEvent) => void,
  id?: string
) => {
  const controller = new AbortController();
  const formData = new FormData();
  formData.append('file', file);

  const request = api.post<IProfileFileField>(
    usersUrls.seller.profile.uploadFile(type, id),
    formData,
    {
      signal: controller.signal,
      onUploadProgress,
    }
  );

  return {
    controller,
    request,
  };
};

export const removeFile = (id: string) => {
  return api.delete(usersUrls.seller.profile.removeFile(id));
};

export const ERROR_MSG = 'Ошибка загрузки, попробуйте снова';
export const ERROR_MAX_SIZE = 'Размер файла слишком большой. Можно загрузить только 10 МБ';
export const ERROR_SIZE_EXCEEDED = 'Остальные файлы не загружены. Можно загрузить только 10 МБ';
export const ERROR_SIZE_EXCEEDED_ALL = 'Файлы не загружены. Можно загрузить только 10 МБ';
export const ERROR_SIZE_EXCEEDED_COMMON = 'Можно загрузить только 10 МБ';
export const ERROR_INVALID_TYPE = 'Можно загрузить только PDF-файлы';
export const ERROR_INVALID_IMAGES_TYPE = 'Можно загрузить только файлы с разрешением jpg, jpeg, png, webp';
export const ERR_PREFIX_ALL = 'Файлы не загружены. ';
export const ERR_PREFIX_SOME = 'Остальные файлы не загружены. ';

export const MAX_ADDITIONAL_FILES_SIZE = 10000000;

export enum FileErrorCodes {
  INVALID_TYPE = 'file-invalid-type',
  TOO_LARGE = 'file-too-large',
}

export const errorTextsByCode: Record<string, string> = {
  [FileErrorCodes.INVALID_TYPE]: ERROR_INVALID_TYPE,
  [FileErrorCodes.TOO_LARGE]: ERROR_SIZE_EXCEEDED_COMMON,
};

export const sizeUnits: Record<string, number> = {
  Б: 1,
  КБ: 1000,
  МБ: 1000000,
};

export const mapMultipleValuesToFilesMap = (values: TFile[]) =>
  values.reduce<Record<string, TFile>>((acc, value) => ({ ...acc, [value.id]: value }), {});

export const mapValuesToInputStates = (values: TFile[]) =>
  values.reduce<Record<string, TInputState>>(
    (acc, file) => ({ ...acc, [file.id]: { id: file.id, label: file.originalName, value: file } }),
    {}
  );
