import { IGetStatusesResponse } from '@/types/api/productDocuments';

import { FC } from 'react';
import { Group, Stack, Tooltip } from '@mantine/core';
import { Text } from '@mantine/core';
import { Tabs as MantineTabs, TabsProps as MantineTabsProps } from '@mantine/core';
import { InfoCircle } from 'tabler-icons-react';

import { useStyles } from './styles';

const tabs = [
  { value: 'all', label: 'Все' },
  { value: 'empty', label: 'Без товаров' },
  { value: 'moderation', label: 'На модерации' },
  {
    value: 'accepted',
    label: 'Подтверждены',
    tip: 'При проверке документа некоторые товары могли не пройти модерацию и были автоматически откреплены от документа. Если у вас есть товары в статусе “Товары с ошибками“, прикрепите документ к товарам повторно',
  },
  { value: 'rejected', label: 'Отклонены' },
];

interface TabsProps extends Omit<MantineTabsProps, 'children'> {
  statuses?: IGetStatusesResponse;
}

export const Tabs: FC<TabsProps> = ({ statuses, ...props }) => {
  const {
    classes: { tooltip, count, ...classes },
  } = useStyles();
  return (
    <Stack>
      <MantineTabs {...props}>
        <MantineTabs.List>
          {tabs.map((tab) =>
            tab.tip ? (
              <Tooltip
                label={tab.tip}
                color="white"
                width={470}
                position="bottom"
                classNames={{ tooltip }}
                key={tab.value}
              >
                <MantineTabs.Tab value={tab.value} className={classes.tab}>
                  <Group spacing={8}>
                    <Text size={16}>{tab.label}</Text>
                    <Text size={16} className={count}>
                      {statuses?.[tab.value]?.count}
                    </Text>
                    <InfoCircle size="16" />
                  </Group>
                </MantineTabs.Tab>
              </Tooltip>
            ) : (
              <MantineTabs.Tab key={tab.value} value={tab.value} className={classes.tab}>
                <Group spacing={8}>
                  <Text size={16}>{tab.label}</Text>
                  <Text size={16} className={count}>
                    {statuses?.[tab.value]?.count}
                  </Text>
                </Group>
              </MantineTabs.Tab>
            )
          )}
        </MantineTabs.List>
      </MantineTabs>
    </Stack>
  );
};
