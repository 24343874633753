import { IStatus } from '@/types/common/common';

import { FC, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Button, Flex, Popover, Text } from '@mantine/core';

import { colors } from '@/utils/theme';

import { STATUS_READY_FOR_SALE, STATUS_REJECTED } from '@/constants/statuses';
import { TEXT_GOODS_STATUS } from '@/constants/texts';
import { useOutsideClick } from '@/hooks/useOutsideClick';

import { useStyles } from './styles';

import { ReactComponent as IconInfo } from '@/assets/icons/redesign/iconInfo.svg';

interface IStatusCell {
  status: IStatus;
  statusComment?: string;
  strikethrough?: boolean;
}

const StatusCell: FC<IStatusCell> = ({ status, statusComment, strikethrough = false }) => {
  const { classes } = useStyles();
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);
  const positionRef = useRef<HTMLButtonElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const isReady =
    status.code === STATUS_READY_FOR_SALE ||
    (statusComment !== '' && status.code === STATUS_REJECTED);

  useOutsideClick(tooltipRef, (event: Event) => {
    if (positionRef.current && !positionRef.current.contains(event.target as Node)) {
      setOpenTooltip(false);
    }
  });

  return (
    <Flex>
      <Text
        strikethrough={strikethrough}
        style={{ color: strikethrough ? colors.brandGrey[8] : colors.brandDark[9] }}
      >
        {status?.label}
      </Text>
      {isReady && (
        <>
          <Popover opened={openTooltip} onChange={setOpenTooltip}>
            <Popover.Target>
              <Button
                ref={positionRef}
                className={classes.button}
                variant={'subtle'}
                onClick={() => setOpenTooltip((v) => !v)}
              >
                <IconInfo />
              </Button>
            </Popover.Target>
            <Popover.Dropdown className={classes.root}>
              {ReactHtmlParser(statusComment || TEXT_GOODS_STATUS)}
              {/* <UnstyledButton className={classes.close} onClick={() => setOpenTooltip(false)}>
                <IconClose className={classes.closeImage} />
              </UnstyledButton> */}
            </Popover.Dropdown>
          </Popover>
        </>
      )}
    </Flex>
  );
};

export default StatusCell;
