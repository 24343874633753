import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Button, LoadingOverlay, Stack, Text } from '@mantine/core';
import { Select } from '@mantine/core';
import { ContextModalProps, modals } from '@mantine/modals';
import dayjs from 'dayjs';

import notify from '@/utils/notify';

import { ModalLayout } from '@/ui/templates/Layouts/ModaLayout/ModalLayout';

import ModalFooter from '../ModalFooter/ModalFooter';

import { Calendar } from './Calendar/Calendar';

import { suppliesApi } from '@/store/slices/supplies/slice';

export interface SupplySlotModalProps {
  id: string;
  onSubmit: () => void;
}

export const SupplySlot: FC<ContextModalProps<SupplySlotModalProps>> = ({
  innerProps: { id, onSubmit },
  ...modalProps
}) => {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [slotIndex, setSlotIndex] = useState<number | null>(null);
  const { data: dates, isFetching, isError, error } = suppliesApi.useGetSlotsQuery(id);
  const [setSlot, { isLoading, error: saveError }] = suppliesApi.useSetSlotMutation();

  const availableDates = useMemo(() => {
    if (!dates) return;
    return Object.keys(dates).map((date) => {
      return dayjs(date).toDate();
    });
  }, [dates]);

  const slots = useMemo(() => {
    if (!selectedDate || !dates) return [];
    return dates[selectedDate];
  }, [selectedDate, selectedDate, dates]);

  const selectedSlot = useMemo(() => {
    if (!slots.length || slotIndex === null || !slots[slotIndex]) return null;
    return slots[slotIndex];
  }, [slotIndex, slots]);

  const slotOptions = useMemo(
    () => slots.map((slot, i) => ({ label: slot.value, value: i.toString() })),
    [slots]
  );

  const handleDateSelect = (e: Date | null) => {
    setSlotIndex(null);
    if (!e) {
      setSelectedDate(null);
      return;
    }
    const dateString = dayjs(e).format('YYYY-MM-DD');
    setSelectedDate(dateString);
  };

  const handleSlotSelect = (i?: string | null) => {
    if (!i) setSlotIndex(null);
    setSlotIndex(Number(i));
  };

  const handleSubmit = async () => {
    if (selectedSlot === null) return;
    await setSlot({ slot: selectedSlot, id });
    onSubmit?.();
    modals.close(modalProps.id);
  };

  useEffect(() => {
    if (Boolean(saveError)) notify({ message: saveError as string, type: 'error' });
  }, [saveError]);

  return (
    <ModalLayout title="Дата и время поставки" {...modalProps} scrollable={false}>
      <LoadingOverlay visible={isFetching || isLoading} />
      {!isError && (
        <>
          <Stack spacing={24} pt={24} pb={32}>
            {/* <Text align="center" style={{ lineHeight: 1.4 }}>
              Выберите доступную дату&nbsp;и&nbsp;время&nbsp;для&nbsp;поставки
            </Text> */}
            {!isFetching && (
              <Calendar availableDates={availableDates} onDateSelect={handleDateSelect} />
            )}
            <Select
              data={slotOptions}
              value={slotIndex?.toString() || null}
              placeholder="Выберите время поставки"
              onChange={handleSlotSelect}
              size="md"
              disabled={!slotOptions.length}
            />
          </Stack>
          <ModalFooter align="center" variant="xs">
            <Button
              size="lg"
              fz={14}
              w={280}
              disabled={selectedSlot === null}
              onClick={handleSubmit}
            >
              Сохранить
            </Button>
          </ModalFooter>
        </>
      )}
      {isError && (
        <>
          <Box p={32}>
            <Text style={{ lineHeight: 1.4 }}>
              {error ? (error as string) : 'Возникла ошибка. Попробуйте позже'}
            </Text>
          </Box>
          <ModalFooter align="center" variant="xs">
            <Button size="lg" fz={14} w={280} onClick={() => modals.close(modalProps.id)}>
              Хорошо
            </Button>
          </ModalFooter>
        </>
      )}
    </ModalLayout>
  );
};
