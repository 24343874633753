import { TOption } from '@/types/api/common';
import {
  IGetFormProductRes,
  IOperationResponse,
  IPatchUpdateStatusBody,
} from '@/types/api/product';
import { ID, IStatus } from '@/types/common/common';
import { IMeta, IProduct } from '@/types/entites/product';

import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import {
  DEFAULT_PRODUCT_OFFSET,
  DEFAULT_PRODUCT_ORDER,
  DEFAULT_PRODUCT_SORT,
} from '@/constants/common';
import { DEFAULT_PRODUCT_LIMIT } from '@/constants/common';

import {
  checkArticle,
  getProductInstruction,
  getProductsDownload,
  getProductsMore,
  getProductStatuses,
  getProductsTemplate,
  postPricesImport,
  postProductsImport,
  updateProductsSaleRemove,
} from './asyncActions';

import { updateProductsArchiveRestore } from '@/store/slices/products/asyncActions';
import { updateProductsArchiveAdd } from '@/store/slices/products/asyncActions';
import { updateProductsSaleReturn } from '@/store/slices/products/asyncActions';
import { getProducts } from '@/store/slices/products/asyncActions';

interface IProductsState {
  status: {
    fetchingProducts: boolean;
    fetchingStatuses: boolean;
    fetchingsChangeStatus: boolean;
    fetchingTemplate: boolean;
    fetchingImport: boolean;
    fetchingExport: boolean;
    fetchingPricesImport: boolean;
    fetchingInstruction: boolean;
    fetchingArticle: boolean;
    exportActive: boolean;
  };
  opertaions: {
    export: IOperationResponse | null;
  };
  products: IProduct[];
  statuses: IStatus[];
  selected: ID[];
  selectedAll: boolean;
  currentStatus: IStatus | null;
  supplierProductId: string | null;
  meta: IMeta;
  limit: number;
  offset: number;
  filters: {
    sortActive: string;
    sort: string;
    order: string;
  };
  existArticle: boolean;
  archiveList: IPatchUpdateStatusBody[];
  eansList: string[];
  instruction: TOption[];
  formProductData: IGetFormProductRes | null;
}

const initialState: IProductsState = {
  status: {
    fetchingProducts: false,
    fetchingStatuses: false,
    fetchingsChangeStatus: false,
    fetchingTemplate: false,
    fetchingImport: false,
    fetchingExport: false,
    fetchingPricesImport: false,
    fetchingInstruction: false,
    fetchingArticle: false,
    exportActive: false,
  },
  opertaions: {
    export: null,
  },
  products: [],
  statuses: [],
  selected: [],
  selectedAll: false,
  currentStatus: null,
  supplierProductId: null,
  meta: {
    count: 0,
    totalCount: 0,
    showButtonForUpdatingPrice: false,
  },
  limit: DEFAULT_PRODUCT_LIMIT,
  offset: DEFAULT_PRODUCT_OFFSET,
  filters: {
    sortActive: '',
    sort: DEFAULT_PRODUCT_SORT,
    order: DEFAULT_PRODUCT_ORDER,
  },
  existArticle: false,
  archiveList: [],
  eansList: [],
  instruction: [],
  formProductData: null,
};

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<IProduct[] | []>) => {
      state.products = action.payload;
    },
    setProductsLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setProductsOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setProductsSort: (state, action: PayloadAction<string>) => {
      state.filters.sort = action.payload;
    },
    setProductActiveSort: (state, action: PayloadAction<string>) => {
      state.filters.sortActive = action.payload;
    },
    setProductsOrder: (state, action: PayloadAction<string>) => {
      state.filters.order = action.payload;
    },
    setCurrentStatus: (state, action: PayloadAction<IStatus | null>) => {
      state.currentStatus = action.payload;
    },
    setSupplierProductId: (state, action: PayloadAction<string | null>) => {
      state.supplierProductId = action.payload;
    },
    setSelected: (state, action: PayloadAction<any>) => {
      state.selected = action.payload;
    },
    setSelectedAll: (state, action: PayloadAction<boolean>) => {
      state.selectedAll = action.payload;
    },
    setArchiveList: (state, action: PayloadAction<IPatchUpdateStatusBody[] | null>) => {
      state.archiveList = action.payload || [];
    },
    setCurrentEans: (state, action: PayloadAction<string[] | null>) => {
      state.eansList = action.payload || [];
    },
    setStatusOperationsExport: (state, action: PayloadAction<IOperationResponse | null>) => {
      state.opertaions.export = action.payload;
    },
    setExportState: (state, action: PayloadAction<boolean>) => {
      state.status.exportActive = action.payload;
    },
    setFormProductData: (state, action: PayloadAction<IGetFormProductRes | undefined>) => {
      if (action.payload) {
        state.formProductData = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        state.status.fetchingProducts = true;
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        state.status.fetchingProducts = false;
        state.products = action.payload?.items;
        state.meta = action.payload.meta;
      })
      .addCase(getProducts.rejected, (state) => {
        state.status.fetchingProducts = false;
      });
    builder
      .addCase(getProductsMore.pending, (state) => {
        state.status.fetchingProducts = true;
      })
      .addCase(getProductsMore.fulfilled, (state, action) => {
        state.status.fetchingProducts = false;
        if (state.products) {
          state.products = [...state.products, ...action.payload.items];
        }
      })
      .addCase(getProductsMore.rejected, (state) => {
        state.status.fetchingProducts = false;
      });
    builder
      .addCase(getProductStatuses.pending, (state) => {
        state.status.fetchingStatuses = true;
      })
      .addCase(getProductStatuses.fulfilled, (state, action) => {
        state.status.fetchingStatuses = false;
        state.status.fetchingStatuses = true;

        if (state.statuses) {
          const statusExistsInPayload = action.payload.some(
            (payloadStatus) => payloadStatus.code === state.currentStatus?.code
          );

          if (state.currentStatus && !statusExistsInPayload) {
            state.statuses = [state.currentStatus, ...action.payload];
          } else {
            state.statuses = action.payload;
          }
        } else if (state.currentStatus) {
          state.statuses = [state.currentStatus, ...action.payload];
        } else {
          state.statuses = action.payload;
        }
      })
      .addCase(getProductStatuses.rejected, (state) => {
        state.status.fetchingStatuses = false;
        state.status.fetchingStatuses = true;
      });
    builder
      .addCase(getProductsTemplate.pending, (state) => {
        state.status.fetchingTemplate = true;
      })
      .addCase(getProductsTemplate.fulfilled, (state) => {
        state.status.fetchingTemplate = false;
      })
      .addCase(getProductsTemplate.rejected, (state) => {
        state.status.fetchingTemplate = false;
      });
    builder
      .addCase(updateProductsSaleRemove.pending, (state) => {
        state.status.fetchingsChangeStatus = true;
      })
      .addCase(updateProductsSaleRemove.fulfilled, (state) => {
        state.status.fetchingsChangeStatus = true;
        state.status.fetchingsChangeStatus = false;
      })
      .addCase(updateProductsSaleRemove.rejected, (state) => {
        state.status.fetchingsChangeStatus = false;
      });
    builder
      .addCase(updateProductsSaleReturn.pending, (state) => {
        state.status.fetchingsChangeStatus = true;
      })
      .addCase(updateProductsSaleReturn.fulfilled, (state) => {
        state.status.fetchingsChangeStatus = true;
        state.status.fetchingsChangeStatus = false;
      })
      .addCase(updateProductsSaleReturn.rejected, (state) => {
        state.status.fetchingsChangeStatus = false;
      });
    builder
      .addCase(updateProductsArchiveAdd.pending, (state) => {
        state.status.fetchingsChangeStatus = true;
      })
      .addCase(updateProductsArchiveAdd.fulfilled, (state) => {
        state.status.fetchingsChangeStatus = true;
        state.status.fetchingsChangeStatus = false;
      })
      .addCase(updateProductsArchiveAdd.rejected, (state) => {
        state.status.fetchingsChangeStatus = false;
      });
    builder
      .addCase(updateProductsArchiveRestore.pending, (state) => {
        state.status.fetchingsChangeStatus = true;
      })
      .addCase(updateProductsArchiveRestore.fulfilled, (state) => {
        state.status.fetchingsChangeStatus = true;
        state.status.fetchingsChangeStatus = false;
      })
      .addCase(updateProductsArchiveRestore.rejected, (state) => {
        state.status.fetchingsChangeStatus = false;
      });
    builder
      .addCase(postProductsImport.pending, (state) => {
        state.status.fetchingImport = true;
      })
      .addCase(postProductsImport.fulfilled, (state) => {
        state.status.fetchingImport = true;
        state.status.fetchingImport = false;
      })
      .addCase(postProductsImport.rejected, (state) => {
        state.status.fetchingImport = false;
      });
    builder
      .addCase(postPricesImport.pending, (state) => {
        state.status.fetchingPricesImport = true;
      })
      .addCase(postPricesImport.fulfilled, (state) => {
        state.status.fetchingPricesImport = true;
        state.status.fetchingPricesImport = false;
      })
      .addCase(postPricesImport.rejected, (state) => {
        state.status.fetchingPricesImport = false;
      });
    builder
      .addCase(getProductsDownload.pending, (state) => {
        state.status.fetchingExport = true;
      })
      .addCase(getProductsDownload.fulfilled, (state, action) => {
        state.status.fetchingExport = true;
        state.status.fetchingExport = false;
        if (!!action.payload) state.opertaions.export = action.payload;
      })
      .addCase(getProductsDownload.rejected, (state) => {
        state.status.fetchingExport = false;
      });
    builder
      .addCase(checkArticle.pending, (state) => {
        state.status.fetchingArticle = true;
      })
      .addCase(checkArticle.fulfilled, (state, action) => {
        state.status.fetchingArticle = true;
        state.status.fetchingArticle = false;
        state.existArticle = action.payload.exist;
      })
      .addCase(checkArticle.rejected, (state) => {
        state.status.fetchingArticle = false;
      });
    builder
      .addCase(getProductInstruction.pending, (state) => {
        state.status.fetchingInstruction = true;
      })
      .addCase(getProductInstruction.fulfilled, (state, action) => {
        state.status.fetchingInstruction = true;
        state.status.fetchingInstruction = false;
        state.instruction = action.payload.options;
      })
      .addCase(getProductInstruction.rejected, (state) => {
        state.status.fetchingInstruction = false;
      });
  },
});

export const {
  setProducts,
  setProductsLimit,
  setProductsOffset,
  setCurrentStatus,
  setProductsSort,
  setArchiveList,
  setCurrentEans,
  setProductsOrder,
  setSelectedAll,
  setSelected,
  setStatusOperationsExport,
  setExportState,
  setProductActiveSort,
  setFormProductData,
  setSupplierProductId,
} = productsSlice.actions;

export default productsSlice.reducer;
