import { ICategory, TActiveCategories } from '@/types/entites/category';
import { ICategoryTree } from '@/types/entites/category';
import { IOperations } from '@/types/entites/template';

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ITemplatesState {
    download: string | null;
    categoriesActive: TActiveCategories[] | ICategory[];
    depthLevel: number;
    tree: ICategoryTree[];
    currentCategory: ICategory | null;
    operations: IOperations[];
}

const initialState: ITemplatesState = {
    download: null,
    depthLevel: 0,
    categoriesActive: [],
    tree: [],
    currentCategory: null,
    operations: []
}

export const templateSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {
        setDownloadTemplate: (state, action: PayloadAction<string | null>) => {
            state.download = action.payload;
        },
        setTemplateOperations: (state, action: PayloadAction<IOperations>) => {
            state.operations = [...state.operations, action.payload]
        },
        setCurrentCategoryTemplate: (state, action: PayloadAction<ICategory | null>) => {
            state.currentCategory = action.payload;
        },
        setTemplateTree: (state, action: PayloadAction<ICategoryTree[]>) => {
            state.tree = action.payload;
        },
        setActiveCategoriesTemplate: (state, action: PayloadAction<any>) => {
            //ToDo
            const tree = state.categoriesActive;
            if (action.payload.length === 0) {
                state.categoriesActive = [];
            }
            else {
                if (action.payload.depthLevel < state.depthLevel) {
                    delete tree[state.depthLevel];
                }
                if (action.payload.depthLevel <= 1) {
                    delete tree[state.depthLevel - 1];
                }
                state.categoriesActive = {
                    ...tree,
                    [action.payload.depthLevel]: action.payload
                }
            }

        },
        setCurrentDepthLevelTemplate: (state, action: PayloadAction<number>) => {
            state.depthLevel = action.payload;
        },
    },
});

export const {
    setDownloadTemplate,
    setActiveCategoriesTemplate,
    setCurrentCategoryTemplate,
    setCurrentDepthLevelTemplate,
    setTemplateTree,
    setTemplateOperations
} = templateSlice.actions;

export default templateSlice.reducer;

