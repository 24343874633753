

import { AuthStepsEnum } from '@/types/api/auth';

import CheckEmail from '../components/CheckEmail/CheckEmail';
import CompanyInformation from '../components/CompanyInformation/CompanyInformation';
import RestorePasswordEmail from '../components/RestorePasswordEmail/RestorePasswordEmail';
import RestorePasswordEmailCode from '../components/RestorePasswordEmailCode/RestorePasswordEmailCode';
import RestorePasswordPhoneCode from '../components/RestorePasswordPhoneCode/RestorePasswordPhoneCode';
import SignInWithEmail from '../components/SignInWithEmail/SignInWithEmail';
import SignInWithOnlyEmail from '../components/SignInWithOnlyEmail/SignInWithOnlyEmail';
import SignInWithPhone from '../components/SignInWithPhone/SignInWithPhone';
import SignInWithPhoneCode from '../components/SignInWithPhoneCode/SignInWithPhoneCode';
import SignUp from '../components/SignUp/SignUp';
import UpgradeToSeller from '../components/UpgradeToSeller/UpgrateToSeller';


export const getCurrentStep = (step: AuthStepsEnum): JSX.Element => {
  switch (step) {
    case AuthStepsEnum.SIGNIN_WITH_ONLY_EMAIL:
      return <SignInWithOnlyEmail />;
    case AuthStepsEnum.SIGNIN_WITH_PHONE:
      return <SignInWithPhone />;
    case AuthStepsEnum.SIGNIN_WITH_EMAIL:
      return <SignInWithEmail />;
    case AuthStepsEnum.RESTORE_PASSWORD_EMAIL:
      return <RestorePasswordEmail />;
    case AuthStepsEnum.RESTORE_PASSWORD_EMAIL_CODE:
      return <RestorePasswordEmailCode />;
    case AuthStepsEnum.SIGNIN_WITH_PHONE_CODE:
      return <SignInWithPhoneCode />;
    case AuthStepsEnum.RESTORE_PASSWORD_PHONE_CODE:
      return <RestorePasswordPhoneCode />;
    case AuthStepsEnum.SIGNUP:
      return <SignUp />;
    // case AuthStepsEnum.BLOCKED:
    // return <Blocked />;
    case AuthStepsEnum.UPGRADE_TO_SELLER:
    case AuthStepsEnum.UPGRADE_TO_SELLER_NEW:
      return <UpgradeToSeller />;
    case AuthStepsEnum.CHECK_EMAIL:
      return <CheckEmail />;
    case AuthStepsEnum.COMPANY_INFORMATION:
      return <CompanyInformation />;
    // case AuthStepsEnum.DATA_FILL:
      // return <DataFill />;
    default:
      return <SignInWithPhone />;
  }
};
