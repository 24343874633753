import {
  IGetFormProductByIDParams,
  IGetFormProductParams,
  IGetFormProductRes,
  IPostFormProductImagesParams,
  IPostFormProductRes,
  IPostSaveFormProductParams,
} from '@/types/api/product';

import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQuery } from '@/api/instance';
import { productUrls } from '@/api/urls/productUrls';

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getFormProduct: builder.query<IGetFormProductRes, IGetFormProductParams>({
      query: ({ ...params }) => ({
        url: productUrls.getFormProduct,
        method: 'GET',
        params,
      }),
    }),
    getFormProductById: builder.query<IGetFormProductRes, IGetFormProductByIDParams>({
      query: ({ supplierProductId }) => ({
        url: productUrls.getFormEditProduct,
        method: 'GET',
        params: {
          supplierProductId,
        },
      }),
    }),
    postFormProductImages: builder.mutation<IPostFormProductRes, IPostFormProductImagesParams>({
      query: ({ formId, files }) => {
        const formData = new FormData();
        files.forEach((file) => formData.append('files', file));
        return {
          url: productUrls.postFormProductImages,
          method: 'POST',
          data: formData,
          params: {
            formId,
          },
        };
      },
    }),
    postFormSaveProduct: builder.mutation<IPostFormProductRes, IPostSaveFormProductParams>({
      query: ({ id, familyCode, categoryCode, fields }) => ({
        url: productUrls.postSaveFormProduct,
        method: 'POST',
        data: {
          id,
          familyCode,
          categoryCode,
          fields,
        },
      }),
    }),
  }),
});
