import { FC, useEffect } from 'react';
import { Anchor, Button, Stack, Text, useMantineTheme } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import { pluralize } from '@/utils/pluralize';

import { PRODUCT_OPERATIONS_KEY } from '@/constants/common';

import ModalCLose from '../ModalClose/ModalClose';
import ModalContent from '../ModalContent/ModalContent';
import ModalFooter from '../ModalFooter/ModalFooter';
import { openAddProductModal } from '../modals';

import { ReactComponent as IconModalFail } from '@/assets/icons/redesign/iconModalFail.svg';


interface FailModalProps {
  uploaded: number;
  total: number;
  path: string;
  error: string;
  id: string;
}

export const ModalFail: FC<ContextModalProps<FailModalProps>> = ({
  context,
  id,
  innerProps: { uploaded, total, path, error, id: opId },
}) => {
  const { colors } = useMantineTheme();
  const productId = JSON.parse(localStorage.getItem(PRODUCT_OPERATIONS_KEY) || '{}')[opId];

  useEffect(() => {
    if (productId) {
      const map = JSON.parse(localStorage.getItem(PRODUCT_OPERATIONS_KEY) || '{}');
      delete map[opId];
      localStorage.setItem(PRODUCT_OPERATIONS_KEY, JSON.stringify(map));
    }
  }, [productId, opId]);

  return (
    <ModalContent boxSize={535}>
      <ModalCLose onClose={() => context.closeModal(id)} />
      <Stack spacing={32} align="center">
        <Text
          size={24}
          weight={700}
          color={colors.gray[7]}
          align="center"
          px={48}
          sx={{ lineHeight: 1.25 }}
        >
          Ошибка добавления!
        </Text>
        <IconModalFail style={{ maxWidth: '100%' }} />
        <Stack spacing={12} align="center">
          {error ? (
            <Text align="center" sx={{ lineHeight: 1.4 }}>
              {error}
            </Text>
          ) : (
            <>
              <Text align="center">
                {pluralize(uploaded, ['Загружен', 'Загружено', 'Загружено'])} {uploaded}{' '}
                {pluralize(uploaded, ['товар', 'товарв', 'товаров'])} из {total}
              </Text>

              {uploaded > 0 && (
                <Text align="center" sx={{ lineHeight: 1.4 }}>
                  Загруженные товары в ближайшее время будут переведены на модерацию
                </Text>
              )}
              {path && <Anchor href={path} size={14}>
                Скачать файлы с ошибками
              </Anchor>
              }
            </>
          )}
        </Stack>
      </Stack>
      <ModalFooter>
        <Button
          onClick={() => {
            context.closeModal(id);
            openAddProductModal(productId);
          }}
          w="100%"
          size="lg"
        >
          Попробовать еще раз
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
