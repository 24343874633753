import { FC, PropsWithChildren } from "react"
import { Box } from "@mantine/core"

import { useStyles } from "./styles";

const PageContent: FC<PropsWithChildren> = ({ children }) => {
    const { classes } = useStyles();

    return (
        <Box className={classes.wrapper}>
            {children}
        </Box>
    )
}

export default PageContent