import { Link } from 'react-router-dom';
import { ActionIcon, Box, clsx, Tooltip } from '@mantine/core';

import { colors } from '@/utils/theme';

import { HeaderTooltip } from '../HeaderTooltips';
import { useStyles } from '../styles';

import { ReactComponent as IconBook } from '@/assets/icons/book.svg';

const HeaderInfo = () => {
  const {
    classes: { button, outlined },
  } = useStyles();

  return (
    <Tooltip
      label={<HeaderTooltip content="Инструкция" />}
      width={'auto'}
      color={colors.mainColor[6]}
      offset={10}
      position="bottom-end"
    >
      <ActionIcon component={Link} className={clsx(button, outlined)} to={'/info'}>
        <Box pos={'relative'}>
          <IconBook color="black" />
        </Box>
      </ActionIcon>
    </Tooltip>
  );
};
export default HeaderInfo;
