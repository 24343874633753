import { FC, ReactNode } from 'react';
import { Button, Stack, Text, useMantineTheme } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import ModalCLose from '../ModalClose/ModalClose';
import ModalContent from '../ModalContent/ModalContent';
import ModalFooter from '../ModalFooter/ModalFooter';

import { ReactComponent as IconModalSuccess } from '@/assets/icons/redesign/iconModalSuccess.svg';


export interface ModalSuccessProps {
  title?: ReactNode;
  body?: ReactNode;
  onSubmit?: () => void;
  buttonText?: string;
}

export const ModalSuccess: FC<ContextModalProps<ModalSuccessProps>> = ({
  context,
  id,
  innerProps: { title, body, buttonText, onSubmit },
}) => {
  const { colors } = useMantineTheme();

  const handleSubmit = () => {
    context.closeModal(id);
    onSubmit?.();
  };

  return (
    <ModalContent boxSize={535}>
      <ModalCLose onClose={() => context.closeModal(id)} />
      <Stack spacing={32} align="center">
        <Text size={24} weight={700} maw={291} color={colors.gray[7]} align="center">
          {title || 'Успешно!'}
        </Text>
        <IconModalSuccess style={{ maxWidth: '100%' }} />
        <Text align="center" sx={{ lineHeight: 1.4 }}>
          {body}
        </Text>
      </Stack>
      <ModalFooter align='center' variant='xs'>
        <Button
          size="lg"
          onClick={handleSubmit}
          w="280"
          h={48}
          fz={14}
        >
          {buttonText || 'Подтвердить'}
        </Button>
      </ModalFooter>
    </ModalContent >
  );
};
