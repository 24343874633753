import { ICategory } from "@/types/entites/category";

import { FC } from "react";
import { Box, UnstyledButton } from "@mantine/core";
import cn from "classnames";

import { useStyles } from "./styles";

import { ReactComponent as IconRightArrow } from '@/assets/icons/redesign/iconRightArrow.svg';

interface ICategoryItemProps {
    code: string;
    label: string;
    depthChildren?: ICategory[];
    depthLevel: number;
    active: boolean;
    handler: (code: string, depthLevel: number, label: string) => void;
}

const CategoryItem: FC<ICategoryItemProps> = ({
    code,
    label,
    depthChildren,
    active,
    depthLevel,
    handler,
}) => {
    const { classes } = useStyles();
    const handlerClick = () => {
        if (handler) {
            handler(code, depthLevel, label);
        }
    }
    return (
        <Box>
            <UnstyledButton
                onClick={handlerClick}
                className={cn(classes.button, { [classes.button_active]: active })}
            >
                {label}
                {depthChildren ? <IconRightArrow className={classes.icon} /> : ''}
            </UnstyledButton>
        </Box >
    )
}

export default CategoryItem;