import { FC } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import reCaptchaVerify from '@/utils/reCaptchaVerify';

import { RESTORE_PASSWORD_EMAIL } from '@/constants/validationSchemas';

import InputWithLabel from '@/ui/atoms/InputWithLabel/InputWithLabel';

import AuthFooter from '../AuthFooter/AuthFooter';

import { AppDispatch } from '@/store';
import { changePasswordByEmailAction } from '@/store/slices/auth/asyncAction';
import { selectFetchingchangePasswordByEmail, setUserSellerEmail } from '@/store/slices/auth/auth';

const RestorePasswordEmail: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const fetching = useSelector(selectFetchingchangePasswordByEmail);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSubmit = (values: Record<string, string>) => {
    reCaptchaVerify(executeRecaptcha, 'changePasswordByEmailAction').then((recaptchaToken) => {
      if (recaptchaToken) {
        const sendData = {
          emailSellerLogin: values.email,
          recaptchaToken,
        };
        dispatch(setUserSellerEmail(values.email));
        // dispatch(setUserEmail(values.email));
        dispatch(changePasswordByEmailAction(sendData));
      }
    });
  };

  const form = useForm({
    initialValues: {
      email: '',
    },
    validateInputOnChange: true,
    validate: yupResolver(RESTORE_PASSWORD_EMAIL),
  });

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} noValidate={true}>
      <InputWithLabel
        label="e-mail"
        autoComplete="off"
        size="md"
        inputMode="email"
        {...form.getInputProps('email')}
        value={form.getInputProps('email').value}
      />
      <AuthFooter>
        <Button
          loading={fetching}
          disabled={!form.isDirty()}
          type="submit"
          size="lg"
          variant="filled"
          fullWidth
          fz={14}
          h={48}
        >
          Продолжить
        </Button>
      </AuthFooter>
    </form>
  );
};

export default RestorePasswordEmail;
