import { fakerRU as faker } from '@faker-js/faker';

import { docTypes, statusLabels } from '@/constants/documents';

export const getDocumentMock = (id?: string) => ({
  type: faker.helpers.objectKey(docTypes),
  num: id || faker.number.int(10000),
  status: faker.helpers.objectKey(statusLabels),
  startDate: faker.date.anytime().toISOString(),
  endDate: faker.date.anytime().toISOString(),
  products: Array(faker.number.int(25)),
});

export const getFileMock = (names?: string[], extensions?: string[]) => ({
  id: faker.string.uuid(),
  ext: extensions ? faker.helpers.arrayElement(extensions) : 'pdf',
  name: names ? faker.helpers.arrayElement(names) : faker.word.words({ count: { min: 1, max: 3 } }),
  size: `${faker.number.float({ min: 0.1, max: 10, precision: 0.1 })} МБ`,
  uploadDate: faker.date.anytime().toISOString(),
});

export const getProductMock = () => ({
  art: faker.number.int(10000000000),
  image: faker.image.url({ width: 48, height: 48 }),
  name: faker.word.words(5),
});
