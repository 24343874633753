import { createStyles } from "@mantine/core";

export const useStyles = createStyles((
    theme,
    {
        scrollbarBottom,
        hasHorizontalScroll
    }: {
        scrollbarBottom: boolean
        hasHorizontalScroll: boolean
    },
) => ({
    table: {
        width: '100%',
        overflowX: 'auto',
        borderCollapse: 'collapse',
        fontSize: '14px',
        lineHeight: '16px',
        position: 'relative',
        padding: '0px',
        zIndex: 20
    },
    root: {
        overflowX: 'auto',
    },
    scrollArea: {
        ...(hasHorizontalScroll && {
            zIndex: 10,
            paddingTop: scrollbarBottom ? 0 : '25px',
            paddingBottom: scrollbarBottom ? '24px' : 0,
            borderTop: `solid 1px ${theme.colors.brandGrey[9]}`,
            borderBottom: `solid 1px ${theme.colors.brandGrey[9]}`
        })
    },
    scrollbar: {
        ...(hasHorizontalScroll && {
            top: scrollbarBottom ? '' : '7px !important',
            bottom: !scrollbarBottom ? '' : '7px !important'
        })
    }
}));

