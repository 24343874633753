import {
  IAttachProductsRequest,
  IDocumentItem,
  IDocumentsListRequest,
  IDocumentsListResponse,
  IDocumentTypesResponse,
  IDownloadFilesRequest,
  IGetDocumentProductsRequest,
  IGetProductsResponse,
  IGetStatusesResponse,
  IOperationIdResponse,
  IProductDetailsResponse,
  IRawGetStatusesResponse,
  ITemplateResponse,
  SaveDocumentRequest,
} from '@/types/api/productDocuments';
import { ID } from '@/types/common/common';

import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQuery } from '@/api/instance';
import { productDocsUrls } from '@/api/urls/productDocsUrls';


export const productDocsApi = createApi({
  reducerPath: 'productDocsApi',
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getStatuses: builder.query<IGetStatusesResponse, void>({
      query: () => productDocsUrls.getStatuses,
      transformResponse: (raw: IRawGetStatusesResponse) => {
        let total = 0;
        const map = raw.items.reduce((acc, item) => {
          total += item.count;
          return { ...acc, [item.code]: item };
        }, {});

        return {
          ...map,
          all: {
            code: 'all',
            label: 'Все',
            count: total,
          },
        };
      },
    }),
    getDocumentsList: builder.query<IDocumentsListResponse, IDocumentsListRequest>({
      query: ({ limit = 5, offset = 0, search, filter }) => ({
        method: 'POST',
        url: `${productDocsUrls.getDocumentsList}?limit=${limit}&offset=${offset}`,
        data: search ? { search: { type: 'number', value: search }, filter } : { filter },
      }),
      forceRefetch: () => true,
      transformResponse: (raw: IDocumentsListResponse) => ({
        ...raw,
        meta: raw.meta || { count: 0, totalCount: 0 },
      }),
    }),
    getDocumentTypes: builder.query<IDocumentTypesResponse, void>({
      query: () => productDocsUrls.getDocumentTypes,
    }),
    getTemplate: builder.query<ITemplateResponse, void>({
      query: () => productDocsUrls.getTemplate,
    }),
    saveDocument: builder.mutation<IDocumentItem, SaveDocumentRequest>({
      query: ({ files, number, startDate, stopDate, type, actualFilesKeys, id }) => {
        const formData = new FormData();
        formData.append('number', number);
        formData.append('startDate', startDate);
        if (stopDate) formData.append('stopDate', stopDate);
        formData.append('type', type);

        files.forEach((file) => formData.append('files[]', file, file.name));

        if (id) {
          formData.append('actualFilesKeys', actualFilesKeys.join(','));

          return {
            method: 'PATCH',
            url: productDocsUrls.saveDocument(id.toString()),
            data: formData,
          };
        }

        return {
          method: 'POST',
          url: productDocsUrls.createDocument,
          data: formData,
        };
      },
    }),
    downloadFiles: builder.query<Blob, IDownloadFilesRequest>({
      query: ({ docId }) => ({
        url: productDocsUrls.downloadFiles(docId),
        responseType: 'blob',
      }),
      transformResponse: (raw: string) => {
        return new Blob([raw]);
      },
    }),
    getDocumentDetails: builder.mutation<IProductDetailsResponse, string>({
      // added as mutation to avoid caching
      query: (id) => ({
        url: productDocsUrls.getDocumentDetails(id),
        method: 'GET',
        options: { errorType: 'modal' },
      }),
    }),
    getProducts: builder.mutation<IGetProductsResponse, IGetDocumentProductsRequest>({
      query: ({ id, limit, offset, search }) => ({
        method: 'POST',
        url: `${productDocsUrls.getProducts(id)}?limit=${limit}&offset=${offset}`,
        data: search?.value ? { search } : undefined,
      }),
    }),
    attachProducts: builder.mutation<IOperationIdResponse, IAttachProductsRequest>({
      query: ({ file, id }) => {
        const data = new FormData();
        data.append('file', file);
        return {
          method: 'POST',
          url: productDocsUrls.attachProducts(id.toString()),
          data,
        };
      },
    }),
    removeDocument: builder.mutation<any, ID>({
      query: (id) => ({
        url: productDocsUrls.removeDocument(id),
        method: 'DELETE',
      }),
    }),
  }),
});
