import { combineReducers } from 'redux';

import { categoriesApi } from './slices/category/api';
import { documentsApi } from './slices/documents/api';
import { instructionsApi } from './slices/instructions/api';
import { productDocsApi } from './slices/productDocs/api';
import { productDocsReducer } from './slices/productDocs/slice';
import { productsApi } from './slices/products/api';
import { summaryOrdersApi } from './slices/summaryOrders/api';
import { suppliesApi } from './slices/supplies/slice';

import { authReducer } from '@/store/slices/auth/auth';
import categoryReducer from '@/store/slices/category/slice';
import dadataReducer from '@/store/slices/dadata/slice';
import documentsReducer from '@/store/slices/documents/slice';
import modalReducer from '@/store/slices/modal/slice';
import notificationReducer from '@/store/slices/notification/slice';
import productsReducer from '@/store/slices/products/slice';
import { profileApi, profileReducer } from '@/store/slices/profile/slice';
import searchReducer from '@/store/slices/search/slice';
import { sellerApi } from '@/store/slices/seller/slice';
import tableReducer from '@/store/slices/table/slice';
import templateReducer from '@/store/slices/template/slice';
import userReducer from '@/store/slices/user/slice';

export const rootReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  modal: modalReducer,
  products: productsReducer,
  dadata: dadataReducer,
  category: categoryReducer,
  documents: documentsReducer,
  search: searchReducer,
  template: templateReducer,
  table: tableReducer,
  profile: profileReducer,
  productDocs: productDocsReducer,
  notification: notificationReducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [sellerApi.reducerPath]: sellerApi.reducer,
  [productDocsApi.reducerPath]: productDocsApi.reducer,
  [categoriesApi.reducerPath]: categoriesApi.reducer,
  [suppliesApi.reducerPath]: suppliesApi.reducer,
  [documentsApi.reducerPath]: documentsApi.reducer,
  [summaryOrdersApi.reducerPath]: summaryOrdersApi.reducer,
  [productsApi.reducerPath]: productsApi.reducer,
  [instructionsApi.reducerPath]: instructionsApi.reducer
});

export const appReducer: typeof rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = {} as ReturnType<typeof rootReducer>;
  }
  return rootReducer(state, action);
};

export type IRootReducer = ReturnType<typeof appReducer>;
