import { SuppliesTab } from '@/types/api/supplies';

import { FC } from 'react';
import { Group, Tabs, Text } from '@mantine/core';

import { useStyles } from './styles';

interface SuppliesTabsProps {
  tabs?: SuppliesTab[];
}

export const SuppliesTabs: FC<SuppliesTabsProps> = ({ tabs }) => {
  const { classes } = useStyles();

  return (
    <Tabs.List p={24} style={{ borderBottomWidth: 0 }}>
      {tabs?.map((tab) => (
        <Tabs.Tab value={tab.code} key={tab.code} className={classes.tab}>
          <Group spacing={8}>
            <Text>{tab.label}</Text>
            {tab.count > 0 && <Text className={classes.count}>{tab.count}</Text>}
          </Group>
        </Tabs.Tab>
      ))}
    </Tabs.List>
  );
};
