import { INotification, IStatusNotificationState } from '@/types/api/notificaton';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store';

interface INotificationState {
  notification: INotification | null;
  statusNotification: IStatusNotificationState;
}

const initialState: INotificationState = {
  notification: null,
  statusNotification: {
    isVisible: false,
    text: '',
  },
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<INotification>) => {
      state.notification = action.payload;
    },
    openStatusNotification: (state, action: PayloadAction<string>) => {
      state.statusNotification.isVisible = true;
      state.statusNotification.text = action.payload;
    },
    closeStatusNotification: (state) => {
      state.statusNotification.isVisible = false;
      state.statusNotification.text = '';
    },
  },
});

export const { setNotifications, openStatusNotification, closeStatusNotification } =
  notificationSlice.actions;

export const selectNotification = (state: RootState) => state.notification.notification;
export const selectStatusNotification = (state: RootState) => state.notification.statusNotification;

export default notificationSlice.reducer;
