import { TFile } from '@/types/api/profile';

import { FC, useMemo } from 'react';
import { Box, Text, useMantineTheme } from '@mantine/core';

import { formatProductImgDate } from '@/utils/formatDate';

import { getImageSize } from '@/ui/pages/ProductDetailPage/utils/getImageSize';
interface IFileInfoProps {
  file?: TFile;
  error?: string;
  typeComponent?: string;
  uploadProgress?: number;
}

const separator = (theme: ReturnType<typeof useMantineTheme>) => (
  <Box
    sx={{
      width: '4px',
      height: '4px',
      background: theme.colors.text[5],
      margin: '0px 8px',
      borderRadius: '50%',
      flexShrink: 0,
    }}
  />
);

const FileInfo: FC<IFileInfoProps> = ({ file, error, uploadProgress, typeComponent }) => {
  const typeFile = file?.extension?.toUpperCase();
  const theme = useMantineTheme();

  const displayDate = useMemo(() => {
    if (file && file.date) {
      try {
        const options: Intl.DateTimeFormatOptions = {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        };
        return new Intl.DateTimeFormat('ru-RU', options).format(new Date(file.date));
      } catch (e) {
        return formatProductImgDate(file.date);
      }
    }
    return null;
  }, [file]);

  const displaySize = useMemo(() => {
    if (file?.size && typeComponent === 'image' && !/\s/.test(file.size)) {
      return getImageSize(Number(file.size));
    }
    return file?.size;
  }, [file, typeComponent]);

  return (
    <Box
      sx={{
        margin: '15px 0px 0px',
        fontSize: '14px',
        color: theme.colors.brandGrey[5],
      }}
    >
      {error && <Text sx={{ color: theme.colors.red[8] }}>Ошибка загрузки, попробуйте снова</Text>}
      {file && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {typeFile}
          {separator(theme)}
          {displaySize}
          {separator(theme)}
          {displayDate}
        </Box>
      )}
      {Boolean(uploadProgress) && <Text>Загрузка {uploadProgress}%</Text>}
    </Box>
  );
};

export default FileInfo;
