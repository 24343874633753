import { ICategory, ICategoryTree, TActiveCategories } from '@/types/entites/category';

import { FC, useMemo } from 'react';
import { Box } from '@mantine/core';

import findElementInTree from '@/utils/findElementInTree';
import getStringWithSeparator from '@/utils/getStringWithSeparator';

import Tag from '../../atoms/Tag/Tag';
import PageLoader from '../Page/components/PageLoader/PageLoader';

import CategoryGroup from './components/CategoryGroup/CategoryGroup';
import CategoryItem from './components/CategoryItem/CategoryItem';
import { useStyles } from './styles';

interface ICategorySelectionProps {
  showSelectCategories?: boolean;
  currentTree: ICategoryTree[];
  fetchFamily?: boolean;
  activeCategories: TActiveCategories[] | ICategory[];
  onChangeElement?: (element: ICategory) => void;
  onCatergoriestTree?: (tree: ICategoryTree[]) => void;
  handleReset?: () => void;
  isLoading?: boolean;
}

const CategorySelection: FC<ICategorySelectionProps> = ({
  showSelectCategories,
  activeCategories,
  onChangeElement,
  onCatergoriestTree,
  handleReset,
  currentTree,
  isLoading,
}) => {
  const { classes } = useStyles();

  const handlerResetCategories = () => {
    if (handleReset) handleReset();
  };

  const getCurrentElement = (code: string) => {
    if (activeCategories) {
      const categories = Object.values(activeCategories);
      const find = categories.find((el: ICategory) => el?.code === code);
      return !!find;
    }
    return false;
  };

  const categoriesStr = useMemo(() => {
    if (activeCategories && Object.values(activeCategories).length > 2) {
      const list = Object.values(activeCategories).map((el) => el?.label);
      return getStringWithSeparator(list, ' → ');
    }
    return '';
  }, [activeCategories]);

  const handlerClickElement = (code: string, depthLevel: number) => {
    const element = findElementInTree(currentTree, code);
    let result: ICategoryTree[] = [];

    if (onChangeElement && element) {
      onChangeElement(element);
    }

    result = currentTree.slice(0, depthLevel + 1);

    if (element?.childrens) {
      result.push({ ...element, tree: element.childrens });
    }

    if (onCatergoriestTree) {
      onCatergoriestTree(result);
    }
  };

  return (
    <>
      <div className={classes.root}>
        {isLoading && <PageLoader loading={isLoading} text="Загрузка дерева категорий" />}
        <Box className={classes.wrapper}>
          {currentTree.length > 0 &&
            currentTree.map((category, index) => (
              <CategoryGroup key={index} className={classes}>
                {category.tree.length > 0 &&
                  category.tree.map((el) => (
                    <CategoryItem
                      key={el?.code}
                      depthLevel={el?.depthLevel - 1}
                      active={getCurrentElement(el?.code)}
                      code={el?.code}
                      label={el?.label}
                      depthChildren={el?.childrens}
                      handler={handlerClickElement}
                    />
                  ))}
              </CategoryGroup>
            ))}
        </Box>
        {categoriesStr.length > 0 && showSelectCategories && (
          <Tag handlerReset={handlerResetCategories}>{categoriesStr}</Tag>
        )}
      </div>
    </>
  );
};

export default CategorySelection;
