import { IUser } from '@/types/api/user';

import { Flex, Text } from '@mantine/core';

interface HeaderProfileTooltipProps {
  user: IUser | null;
}

interface HeaderTooltipProps {
  content: string;
}

export const HeaderProfileTooltip = ({ user }: HeaderProfileTooltipProps) => (
  <Flex
    style={{ whiteSpace: 'nowrap', maxWidth: '220px' }}
    direction="column"
    gap="sm"
    justify="flex-end"
  >
    <Text size={12} truncate>
      {user?.lastName} {user?.name}
    </Text>
    <Text size={12} truncate>
      {user?.emailSellerLogin}
    </Text>
    <Text size={12} truncate>
      {user?.legalName}
    </Text>
  </Flex>
);

export const HeaderTooltip = ({ content }: HeaderTooltipProps) => <Text size={12}>{content}</Text>;
