import { createStyles } from '@mantine/core';

export const useStyles = createStyles(({ colors }) => ({
  tooltip: {
    padding: '16px 24px',
    borderRadius: '12px',
    backgroundColor: colors.mainColor[9],
    backdropFilter: 'blur(8px)',
    color: colors.customWhite[0],
    fontSize: '14px',
    lineHeight: '20px',
  },
  button: {
    border: `1px solid ${colors.gray[5]}`,
  },
}));
