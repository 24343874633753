import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getCookie } from 'cookies-next';

import { cookiesNames } from '@/constants/common';

import useNavigateToProfile from './useNavigateToProfile';

import { useAppDispatch } from '@/store';
import { selectFetchingUser, selectUser } from '@/store/slices/user/slice';

// const GUEST_URL_REGEXP = /^\/auth$/;
const GUEST_URL_REGEXP = /^\/(auth|instruction)$/;

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const token = getCookie(cookiesNames.NEXT_USER_TOKEN);
  const isUserFetching = useSelector(selectFetchingUser);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();
  const navigateToProfile = useNavigateToProfile(user);

  const isGuestUrl = useMemo(() => GUEST_URL_REGEXP.test(location.pathname), [location]);
  const isHomePage = useMemo(() => location.pathname === '/', [location]);

  const auth = useCallback(() => {
    if (user?.isSeller) {
      document.querySelector('html')?.classList.add('authenticated');
    } else {
      document.querySelector('html')?.classList.remove('authenticated');
    }
  }, [user]);

  const isAuth = useMemo(
    () => user?.companyInn && user?.isSeller && !!token,
    [token, user, navigate, dispatch, location]
  );

  useEffect(() => {
    if (isUserFetching || user === null) return;
    if (isAuth && (isGuestUrl || isHomePage)) navigateToProfile();
    if (!isAuth && (!isGuestUrl || isHomePage)) {
      navigate({ pathname: '/auth', search: params.toString() });
    }
    auth();
  }, [isAuth, isUserFetching, user, isGuestUrl]);

  return isAuth;
};
