import {
  IAuthByCodeRequest,
  IAuthByEmailRequest,
  IAuthByPhoneRequest,
  IChangePasswordByEmailConfirm,
  IChangePasswordByEmailRequest,
  IChangePasswordConfirmRequest,
  IChangePasswordRequest,
  ICheckEmailRequest,
  ICheckPhoneRequest,
  INewCodeRequest,
  IUpgradeToSellerRequest,
} from '@/types/api/auth';
import { IAsyncThunkDataWrapper, TRefreshToken } from '@/types/common/common';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { apiAuthCustomer } from '@/api/users/auth';
import {
  tryChangePassword,
  tryChangePasswordByEmail,
  tryChangePasswordByEmailConfirm,
  tryChangePasswordConfirm,
  tryChangePasswordProfile,
} from '@/api/users/changePassword';
import { tryLogout } from '@/api/users/logout';
import {
  tryAuthByEmail,
  tryAuthByPhone,
  tryCheckEmail,
  tryCheckPhone,
  tryCheckSellerEmailLogin,
} from '@/api/users/signIn';
import { tryAddUser, tryNewCode, tryUpgradeToSeller, tryUpgradeUser } from '@/api/users/signUp';

import notify from '@/utils/notify';
import { saveToken } from '@/utils/token';

import { NOTIFY_ERROR_EMAIL, NOTIFY_REGISTRATION_SUCCESS } from '@/constants/notifyMessages';

import { fetchUserAction } from '../user/slice';

import { RootState } from '@/store';

export const checkPhoneAction = createAsyncThunk(
  'users/checkPhone',
  async (data: ICheckPhoneRequest, { rejectWithValue }) => {
    const result = await tryCheckPhone(data);

    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const checkEmailAction = createAsyncThunk(
  'users/checkEmail',
  async (data: ICheckEmailRequest, { rejectWithValue }) => {
    const result = await tryCheckEmail(data);

    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const checkSellerEmailAction = createAsyncThunk(
  'users/checkSellerEmailAction',
  async ({ data, isStep }: { data: ICheckEmailRequest; isStep: boolean }, { rejectWithValue }) => {
    const result = await tryCheckSellerEmailLogin(data);

    if (result) {
      if (!data.recaptchaToken && !data.sendAuthCode && !isStep) {
        if (result.userExist) {
          notify({ type: 'error', message: NOTIFY_ERROR_EMAIL });
        }
      }
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const authByPhoneAction = createAsyncThunk(
  'users/authByPhone',
  async (data: IAsyncThunkDataWrapper<IAuthByPhoneRequest>, { rejectWithValue, dispatch }) => {
    const result = await tryAuthByPhone(data.value);
    const type = {
      type: 'authByPhone',
      method: 'phone',
    };
    if (result) {
      await saveToken(result.token);
      await dispatch(fetchUserAction({ type: null }));
      if (data.onSuccess) {
        data.onSuccess();
      }
      dispatch(fetchUserAction(type));
      return result;
    } else {
      if (data.onFail) {
        data.onFail();
      }
      return rejectWithValue(null);
    }
  }
);

export const resendCodeAction = createAsyncThunk(
  '/users/newCode',
  async (data: INewCodeRequest, { rejectWithValue }) => {
    const result = await tryNewCode(data);

    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const addUserAction = createAsyncThunk(
  '/users/user',
  async (data: IAsyncThunkDataWrapper<IAuthByCodeRequest>, { rejectWithValue, dispatch }) => {
    const result = await tryAddUser(data.value);
    const type = {
      type: 'signUp',
      method: 'phone',
    };
    if (result) {
      notify({ type: 'success', message: NOTIFY_REGISTRATION_SUCCESS });
      saveToken(result.token);
      await dispatch(fetchUserAction({ type: null }));
      if (data.onSuccess) {
        data.onSuccess();
      }
      dispatch(fetchUserAction(type));
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const addSellerAction = createAsyncThunk(
  '/users/seller',
  async (data: IAuthByCodeRequest, { rejectWithValue, dispatch }) => {
    const result = await tryAddUser(data);

    if (result) {
      notify({ type: 'success', message: NOTIFY_REGISTRATION_SUCCESS });
      saveToken(result.token);
      await dispatch(fetchUserAction({ type: null }));
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const logoutAction = createAsyncThunk(
  '/users/logout',
  async (data: TRefreshToken, { rejectWithValue }) => {
    const result = await tryLogout(data);
    const token = await apiAuthCustomer();

    if (result && token) {
      return token;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const changePasswordAction = createAsyncThunk(
  '/users/changePassword',
  async (data: IChangePasswordRequest, { rejectWithValue }) => {
    const result = await tryChangePassword(data);

    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const changePasswordConfirmAction = createAsyncThunk(
  '/users/changePasswordConfirm',
  async (data: IChangePasswordConfirmRequest, { rejectWithValue, dispatch }) => {
    const result = await tryChangePasswordConfirm(data);

    if (result) {
      saveToken(result.token);
      await dispatch(fetchUserAction({ type: null }));
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const authByEmailAction = createAsyncThunk(
  '/users/authByEmail',
  async (data: IAsyncThunkDataWrapper<IAuthByEmailRequest>, { rejectWithValue, dispatch }) => {
    const result = await tryAuthByEmail(data.value);
    const type = {
      type: 'authByEmail',
      method: 'email',
    };
    if (result) {
      saveToken(result.token);
      await dispatch(fetchUserAction({ type: null }));
      if (data.onSuccess) {
        data.onSuccess();
      }
      dispatch(fetchUserAction(type));
      return result;
    } else {
      if (data.onFail) {
        data.onFail();
      }
      return rejectWithValue(null);
    }
  }
);

export const changePasswordByEmailAction = createAsyncThunk(
  '/users/changePasswordByEmail',
  async (data: IChangePasswordByEmailRequest, { rejectWithValue }) => {
    const result = await tryChangePasswordByEmail(data);

    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const changePasswordByEmailConfirmAction = createAsyncThunk(
  '/users/changePasswordByEmailConfirm',
  async (data: IChangePasswordByEmailConfirm, { rejectWithValue, dispatch }) => {
    const result = await tryChangePasswordByEmailConfirm(data);

    if (result) {
      saveToken(result.token);
      await dispatch(fetchUserAction({ type: null }));
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const upgradeUserToSellerAction = createAsyncThunk(
  '/users/upgrade-to-seller',
  async (data: IUpgradeToSellerRequest, { rejectWithValue, dispatch, getState }) => {
    const result = await tryUpgradeUser(data);

    if (result) {
      saveToken(result.token);

      const fetchUserPromise = new Promise<RootState>((resolve) => {
        dispatch(fetchUserAction({ type: null })).then(() => {
          const newState = getState() as RootState;
          resolve(newState);
        });
      });
      const newState = await fetchUserPromise;

      return { result, user: newState.user.user };
    } else {
      return rejectWithValue(null);
    }
  }
);

export const getUpgradeSeller = createAsyncThunk(
  '/users/get/upgrade-to-seller',
  async (_, { rejectWithValue }) => {
    const result = await tryUpgradeToSeller();
    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const changePasswordProfileAction = createAsyncThunk(
  '/users/changePasswordProfile',
  async (data: IAsyncThunkDataWrapper<any>, { rejectWithValue }) => {
    const result = await tryChangePasswordProfile(data.value);

    if (result) {
      if (data.onSuccess) {
        data.onSuccess();
      }
      return result;
    } else {
      if (data.onFail) {
        data.onFail();
      }
      return rejectWithValue(null);
    }
  }
);
