import { createStyles, getStylesRef } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  wrapper: {
    ref: getStylesRef('menu__wrapper'),
    [`& > .${getStylesRef('menu__wrapper')} .${getStylesRef('menu__item')}`]: {
      paddingLeft: 36,
    },
    [`&[data-expanded="true"] > .${getStylesRef('menu__item')} .${getStylesRef('menu__chevron')}`]:
      {
        transform: 'rotate(180deg)',
      },
    overflow: 'hidden',
  },
  item: {
    ref: getStylesRef('menu__item'),
    position: 'relative',
    padding: '16px 24px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: theme.colors.gray[3],
    },
    '&[data-active="true"]:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: 2,
      backgroundColor: theme.colors[theme.primaryColor][6],
    },
  },
  inner: {
    backgroundColor: theme.colors.gray[1],
    maxHeight: 0,
    transition: '0.2s ease-in-out',
  },
  chevron: {
    ref: getStylesRef('menu__chevron'),
    transition: '0.2s ease-in-out',
  },
}));
