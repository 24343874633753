import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  notification: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: 15,
    fontSize: 25,
    minHeight: 100,
    background: theme.colors.mainColor[6],
    color: theme.colors.customWhite[0],
    alignItems: 'center',
    '@media (min-width: 1360px)': {
      display: 'none',
    },
  },
}));
