import { IDocumentItem } from '@/types/entites/documents';

import { FC, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Anchor, Group, Text, useMantineTheme } from '@mantine/core';

import { TableCustom } from '@/ui/organisms/TableCustom';
import Cell from '@/ui/organisms/TableCustom/components/Cell/Cell';
import Row from '@/ui/organisms/TableCustom/components/Row/Row';
import RowEmpty from '@/ui/organisms/TableCustom/components/RowEmpty/RowEmpty';
import TBody from '@/ui/organisms/TableCustom/components/TBody/TBody';
import THead from '@/ui/organisms/TableCustom/components/THead/THead';

import { ReactComponent as IconCheck } from '@/assets/icons/redesign/iconCheck.svg';
import { ReactComponent as IconDownload } from '@/assets/icons/redesign/iconDownload.svg';
import { ReactComponent as IconPrccess } from '@/assets/icons/redesign/iconProcess.svg';

interface IDocumentsTableProps {
  items: IDocumentItem[];
}

const DocumentsTable: FC<IDocumentsTableProps> = ({ items }) => {
  const { tab } = useParams();
  const theme = useMantineTheme();
  const linkRef = useRef<HTMLAnchorElement | null>(null);

  const getColorFromStatus = (statusCode: string) => {
    if (statusCode === 'success') return theme.colors.green[9];
    if (statusCode === 'error') return theme.colors.red[9];
    return theme.colors.brandGrey[8];
  };
  const isStatusField = useMemo(() => !!items?.find((item) => item?.status), [items]);

  return (
    <TableCustom offsetScrollbars={false} scrollbarType="never">
      {() => (
        <>
          <THead>
            <Row>
              <Cell width={250} th>
                Дата формирования
              </Cell>
              <Cell width={350} th>
                Название
              </Cell>
              {isStatusField && tab !== 'closing-report' && tab !== 'realization-report' && (
                <Cell th>Статус</Cell>
              )}
              <Cell width={150} th></Cell>
            </Row>
          </THead>
          <TBody>
            {items.length > 0 ? (
              items.map((item, index) => (
                <Row key={index}>
                  <Cell>{item.dateCreate}</Cell>
                  <Cell>{item.name}</Cell>
                  {isStatusField && tab !== 'closing-report' && tab !== 'realization-report' && (
                    <Cell>
                      <Group spacing={8}>
                        <Text color={getColorFromStatus(item.statusCode)}>{item.status}</Text>
                        {item.statusCode === 'success' ? (
                          <IconCheck size={24} fill={getColorFromStatus(item.statusCode)} />
                        ) : (
                          <IconPrccess fill={getColorFromStatus(item.statusCode)} />
                        )}
                      </Group>
                    </Cell>
                  )}
                  <Cell>
                    {item.downloadLink && (
                      <>
                        <Anchor
                          type="button"
                          href={item.downloadLink}
                          ref={linkRef}
                          fz={'14px'}
                          td={'none'}
                          download
                        >
                          <Group spacing={6} position="right">
                            <Text color={theme.colors.mainColor[6]}>Скачать</Text>
                            <IconDownload fill={theme.colors.mainColor[6]} />
                          </Group>
                        </Anchor>
                      </>
                    )}
                  </Cell>
                </Row>
              ))
            ) : (
              <RowEmpty text="У вас нет ни одного сформированного отчета" />
            )}
          </TBody>
        </>
      )}
    </TableCustom>
  );
};

export default DocumentsTable;
