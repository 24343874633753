import { IToken } from '@/types/common/common';

import { useEffect, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import isEqual from 'lodash/isEqual';

import { apiAuthCustomer, apiCustomerRefresh } from '@/api/users/auth';
import { apiAuthByRefresh } from '@/api/users/signIn';

import { cookiesNames, REFRESH_CHECK_TIMEOUT } from '@/constants/common';

import notify from './notify';

/**
 * @deprecated
 * Use `saveToken` or `useToken` instead
 */
export const tokenGetAndRefresh = async (): Promise<IToken | null> => {
  let customerToken: IToken | null = null;

  if (getCookie(cookiesNames.NEXT_USER_TOKEN)) {
    deleteCookie(cookiesNames.NEXT_CUSTOMER_TOKEN);

    const now = Date.now();
    const parsedToken = getCookie(cookiesNames.NEXT_USER_TOKEN) as string;
    const { expiresAt, refreshExpiresAt, refreshToken } = JSON.parse(parsedToken);

    if (expiresAt && now < expiresAt && now + REFRESH_CHECK_TIMEOUT < expiresAt) {
      return JSON.parse(parsedToken);
    }

    if (refreshExpiresAt && now < refreshExpiresAt) {
      try {
        const refreshedToken = await apiAuthByRefresh(refreshToken);

        if (!refreshedToken) return null;

        setCookie(
          cookiesNames.NEXT_USER_TOKEN,
          JSON.stringify({
            ...refreshedToken.token,
            expiresAt: Date.now() + refreshedToken.token.expiresIn * 1000,
            refreshExpiresAt: Date.now() + refreshedToken.token.refreshExpiresIn * 1000,
          }),
          { path: '/', domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN }
        );

        return refreshedToken.token;
      } catch (e) {
        const err = e as AxiosError;

        if (err?.response?.status === 401) {
          deleteCookie(cookiesNames.NEXT_USER_TOKEN);
          if (window && window.location) window.location.href = '/auth';
          notify({
            message:
              'Авторизуйтесь, пожалуйста, чтобы продолжить использовать личный кабинет селлера',
            type: 'error',
          });
        }
      }
    }

    // deleteCookie(cookiesNames.NEXT_USER_TOKEN, ctx);
    // deleteCookie(cookiesNames.NEXT_USER_TOKEN);
    deleteCookie(cookiesNames.NEXT_USER_TOKEN, {
      path: '/',
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    });
  }

  if (!getCookie(cookiesNames.NEXT_CUSTOMER_TOKEN)) {
    const { data } = await apiAuthCustomer();

    customerToken = data.token;
  }

  if (getCookie(cookiesNames.NEXT_CUSTOMER_TOKEN)) {
    const now = Date.now();
    const parsedToken = getCookie(cookiesNames.NEXT_CUSTOMER_TOKEN) as string;
    const { expiresAt, refreshExpiresAt, refreshToken } = JSON.parse(parsedToken);

    if (expiresAt && now < expiresAt && now + REFRESH_CHECK_TIMEOUT < expiresAt) {
      return JSON.parse(parsedToken);
    }

    if (refreshExpiresAt && now < refreshExpiresAt) {
      const refreshedToken = await apiCustomerRefresh(refreshToken);

      customerToken = refreshedToken?.token ?? null;
    } else {
      const { data: newToken } = await apiAuthCustomer();

      customerToken = newToken.token;
    }
  }

  setCookie(
    cookiesNames.NEXT_CUSTOMER_TOKEN,
    JSON.stringify({
      ...customerToken,
      expiresAt: Date.now() + (customerToken ? customerToken.expiresIn : 0) * 1000,
      refreshExpiresAt: Date.now() + (customerToken ? customerToken.refreshExpiresIn : 0) * 1000,
    }),
    {
      path: '/',
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    }
    // { path: '.localhost', sameSite: 'none', secure: true } // Does not work in Safari
  );

  return customerToken;
};

export const saveToken = async (token: IToken) => {
  setCookie(cookiesNames.NEXT_USER_TOKEN, JSON.stringify(token), {
    path: '/',
    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
  });
};

type TokenKind = 'user' | 'customer' | undefined;
type TokenInfo =
  | {
    token: IToken;
    kind: TokenKind;
  }
  | {
    token: undefined;
    kind: undefined;
  };

export const useToken = () => {
  const getUserToken = () => getCookie(cookiesNames.NEXT_USER_TOKEN) as string | undefined;
  const getCustomerToken = () => getCookie(cookiesNames.NEXT_CUSTOMER_TOKEN) as string | undefined;

  const [userToken, setUserToken] = useState(getUserToken());
  const [customerToken, setCustomerToken] = useState(getCustomerToken());

  const intervalRef = useRef<NodeJS.Timer>();
  const socketRef = useRef<WebSocket>();

  const [actualToken, setActualToken] = useState<TokenInfo>({ token: undefined, kind: undefined });

  useEffect(() => {
    const parseToken = async () => {
      const tokenKind: TokenKind = userToken ? 'user' : customerToken ? 'customer' : undefined;
      const token = userToken || customerToken;

      if (token) {
        setActualToken({
          token: JSON.parse(token) as IToken,
          kind: tokenKind,
        });
      } else {
        setActualToken({ token: undefined, kind: undefined });
      }
    };

    parseToken();
  }, [userToken, customerToken]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newUserToken = getUserToken();
      const newCustomerToken = getCustomerToken();

      if (!isEqual(userToken, newUserToken)) {
        setUserToken(newUserToken);
        if (socketRef.current) {
          socketRef.current.close();
          socketRef.current = undefined;
        }
      }
      if (!isEqual(customerToken, newCustomerToken)) {
        setCustomerToken(newCustomerToken);
        if (socketRef.current) {
          socketRef.current.close();
          socketRef.current = undefined;
        }
      }
    }, 100);

    intervalRef.current = interval;
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [userToken, customerToken]);

  return actualToken;
};


const getNewCustomerToken = async () => (await apiAuthCustomer())?.data?.token;
const refreshCustomerToken = async (token: string) => (await apiCustomerRefresh(token))?.token;
const refreshSellerToken = async (token: string) => (await apiAuthByRefresh(token))?.token;

export const useTokenRefresh = () => {
  const { token, kind } = useToken();
  let timeoutRef = useRef<NodeJS.Timeout>();

  const startRefreshTimeout = (expiresIn: number, fn: () => any) => {
    const nextTimeout = expiresIn >= 10000 ? expiresIn - 5000 : expiresIn;
    timeoutRef.current = setTimeout(fn, nextTimeout);
  };

  const refreshToken = async () => {
    let newToken: IToken | undefined;
    let cookieKey: string = cookiesNames.NEXT_CUSTOMER_TOKEN;

    switch (kind) {
      case 'customer':
        newToken = await refreshCustomerToken(token.refreshToken);
        break;
      case 'user':
        newToken = await refreshSellerToken(token.refreshToken);
        cookieKey = cookiesNames.NEXT_USER_TOKEN;
        break;
      default:
        newToken = await getNewCustomerToken();
    }

    if (!newToken) return;

    setCookie(cookieKey, JSON.stringify(newToken), {
      path: '/',
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    });

    const { expiresIn } = newToken;

    startRefreshTimeout(expiresIn * 1000, refreshToken);

    return expiresIn;
  };

  const startTokenRefresh = async () => {
    if (!token) {
      await refreshToken();
      return;
    }

    const expiresIn = token.expiresAt * 1000 - Date.now();

    startRefreshTimeout(expiresIn, refreshToken);
  };

  useEffect(() => {
    startTokenRefresh();
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [token, kind]);
};
