import { AuthStepsEnum } from '@/types/api/auth';

import { FC } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import reCaptchaVerify from '@/utils/reCaptchaVerify';

import { SIGNIN_WITH_EMAIL } from '@/constants/validationSchemas';

import InputWithLabel from '@/ui/atoms/InputWithLabel/InputWithLabel';

import AuthFooter from '../AuthFooter/AuthFooter';

import { AppDispatch } from '@/store';
import { authByEmailAction } from '@/store/slices/auth/asyncAction';
import {
  selectFetchingAuthByEmail,
  selectUserSellerEmail,
  setAuthStep,
  setUserEmail,
  setUserSellerEmail,
} from '@/store/slices/auth/auth';

const SignInWithEmail: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const fetching = useSelector(selectFetchingAuthByEmail);
  // const userEmail = useSelector(selectUserEmail);
  const userSellerEmail = useSelector(selectUserSellerEmail);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { colors } = useMantineTheme();

  const handleSendData = (recaptchaToken: string, values: Record<string, string>) => {
    const sendData = {
      emailSellerLogin: values.email,
      password: values.password,
      recaptchaToken,
    };
    // dispatch(setUserEmail(values.email));
    dispatch(setUserSellerEmail(values.email));
    dispatch(
      authByEmailAction({
        value: sendData,
      })
    );
  };

  const handleSubmit = (values: Record<string, string>) => {
    reCaptchaVerify(executeRecaptcha, 'authByEmailAction').then((recaptchaToken) => {
      if (recaptchaToken) {
        handleSendData(recaptchaToken, values);
      }
    });
  };

  const form = useForm({
    initialValues: {
      email: userSellerEmail ?? '',
      password: '',
    },
    validateInputOnChange: true,
    validate: yupResolver(SIGNIN_WITH_EMAIL),
  });

  const checkFormField = () => {
    const email = form.getInputProps('email').value;
    const password = form.getInputProps('password').value;

    if (email.length > 0 && password.length > 0) {
      if (form.isValid()) {
        return false;
      }
    }

    return true;
  };

  const handleForgetPassword = () => {
    dispatch(setAuthStep(AuthStepsEnum.RESTORE_PASSWORD_EMAIL));
    dispatch(setUserEmail(form.getInputProps('email').value));
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} noValidate={true}>
      <InputWithLabel
        name="email"
        label="e-mail"
        size="md"
        autoComplete="off"
        inputMode="email"
        {...form.getInputProps('email')}
        disabled={!!userSellerEmail}
        value={form.values.email}
        mb={16}
      />
      <InputWithLabel
        label="Пароль"
        size="md"
        inputtype="password"
        autoComplete="off"
        {...form.getInputProps('password')}
        value={form.values.password}
      />
      <AuthFooter>
        <Button
          loading={fetching}
          disabled={checkFormField()}
          type="submit"
          size="lg"
          variant="filled"
          fullWidth
          fz={14}
          h={48}
        >
          Войти
        </Button>
        {!form.getInputProps('email').error && form.getInputProps('email').value.length != 0 && (
          <UnstyledButton
            type="button"
            variant="subtle"
            onClick={handleForgetPassword}
            mt={16}
            fz={14}
          >
            Не помню пароль
          </UnstyledButton>
        )}
      </AuthFooter>
    </form>
  );
};

export default SignInWithEmail;
