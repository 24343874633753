import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  currentMonthLabel: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
    pointerEvents: 'none',
    userSelect: 'none',
  },
  table: {
    borderCollapse: 'collapse',
    '& td, & th': {
      width: 52,
      height: 52,
      lineHeight: 1,
      textAlign: 'center',
      fontWeight: 'normal',
      userSelect: 'none',
    },
    '& td': {
      border: `1px solid ${theme.colors.gray[4]}`,
      fontSize: 0,
      button: {
        padding: 0,
        textAlign: 'center',
        transition: '0.1s',
        ':not(:disabled):hover': {
          backgroundColor: theme.colors.gray[2],
        },
        ':disabled': {
          cursor: 'not-allowed',
        },
        '&[data-day]:disabled': {
          backgroundColor: theme.colors.gray[2],
          color: theme.colors.gray[5],
        },
        '&[data-selected="true"]': {
          backgroundColor: theme.colors.blue[6],
          color: 'white',
        },
      },
    },
  },
}));
