import { IGetNotification } from '@/types/api/notificaton';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { tryGetNotification } from '@/api/notification/notification';

import { setNotifications } from './slice';

export const getNotification = createAsyncThunk(
  '/seller/notification',
  async (params: IGetNotification, { rejectWithValue, dispatch }) => {
    const result = await tryGetNotification(params);

    if (result) {
      dispatch(setNotifications(result));

      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);
