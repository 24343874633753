import {
  IProfile,
  IPRofilePhoneField,
  ProfileFieldType,
  SuggestionProps,
  TFile,
  TProfileTabsResponse,
} from '@/types/api/profile';
import { ProfileTabCode } from '@/types/api/profile';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQuery } from '@/api/instance';
import { usersUrls } from '@/api/urls/usersUrls';
import { getTabs } from '@/api/users/profile';

import { createAxiosThunk } from '@/utils/asyncRequest';
import { renamePropsToKamelCase } from '@/utils/kebabToCamelCase';

interface IProfileState {
  requires: { [index in ProfileTabCode | 'account']: boolean };
  statuses: {
    inactive: boolean;
    consideration: boolean;
    suspended: boolean;
  };
}

const initialState: IProfileState = {
  requires: {
    account: true,
    about: true,
    requisites: false,
    documents: false,
  },
  statuses: {
    inactive: false,
    consideration: false,
    suspended: false,
  },
};

type SubmitPayload = Record<string, string | string[] | TFile | TFile[]>;

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getTabs: builder.query<IProfile, void>({
      // First generic - request return type, second - query params type
      query: () => usersUrls.seller.profile.tabs, // Accepts query params (mentioned above), returns url string or axios request config
      transformResponse: (raw: TProfileTabsResponse) => {
        const { offerDate, offerLink, offerName } = raw;
        return {
          // Response tranformation logic from reducer went here
          ...raw,
          docs: {
            offerDate,
            offerLink,
            offerName,
          },
          tabs: raw.tabs.map((tab) => {
            return {
              ...tab,
              fields: tab.fields.map((field) => {
                if (tab.confirmed) {
                  return {
                    ...field,
                    disabled: true,
                    ...((field.code === 'companyPhone' && {
                      type: ProfileFieldType.PHONE,
                    }) as IPRofilePhoneField),
                  };
                } else if (tab.code === 'about' && field.code === 'companyPhone') {
                  return {
                    ...field,
                    type: ProfileFieldType.PHONE,
                    disabled: field.disabled,
                  } as IPRofilePhoneField;
                } else {
                  return field;
                }
              }),
            };
          }),
        };
      },
    }),

    getSuggestions: builder.query<Record<string, string>[], SuggestionProps>({
      query: ({ endpoint, params }) => {
        switch (endpoint) {
          case 'bank':
            return usersUrls.dadata.bank(params.queryString as string);
          default:
            return usersUrls.dadata.bank(params.queryString as string);
        }
      },
    }),

    submitAbout: builder.mutation<any, SubmitPayload>({
      query: (data) => {
        const payload: SubmitPayload = Object.keys(data).reduce(
          (res, key) =>
            key !== 'company_registration_form'
              ? { ...res, [key]: data[key] }
              : { ...res, registrationForm: data[key] },
          {}
        );

        return {
          data: renamePropsToKamelCase(payload),
          method: 'PATCH',
          url: usersUrls.seller.profile.submit.about,
        };
      },
    }),

    submitRequisites: builder.mutation<any, SubmitPayload>({
      query: (data) => ({
        method: 'PATCH',
        data: renamePropsToKamelCase(data),
        url: usersUrls.seller.profile.submit.requisites,
      }),
    }),

    submitDocs: builder.mutation<any, SubmitPayload>({
      query: () => ({
        method: 'PATCH',
        url: usersUrls.seller.profile.submit.documents,
      }),
    }),
  }),
});

export const fetchProfileTabs = createAxiosThunk('/tabs', getTabs);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (state, action: PayloadAction<boolean>) => {
      state.requires.account = action.payload;
    },
  },
});

export const {
  actions: { setProfile },
  reducer: profileReducer,
} = profileSlice;
