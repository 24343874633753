import { FC } from 'react';
import { Group } from '@mantine/core';

import HeaderHelper from '../HeaderHelper/HeaderHelper';
import HeaderInfo from '../HeaderInfo/HeaderInfo';
// import HeaderNotify from '../HeaderNotify/HeaderNotify';
import HeaderProfile from '../HeaderProfile/HeaderProfile';

const HeaderControls: FC = () => {
  return (
    <Group spacing={'1rem'} align="center">
      <HeaderInfo />
      {/* <HeaderNotify /> */}
      <HeaderHelper />
      <HeaderProfile />
    </Group>
  );
};

export default HeaderControls;
