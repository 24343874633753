import { IDocumentForm, IRawDocumentForm } from '@/types/api/productDocuments';

import { ChangeEventHandler, FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { ContextModalProps, modals } from '@mantine/modals';
import dayjs from 'dayjs';
import noop from 'lodash/noop';

import { dateFromString, formatDate } from '@/utils/formatDate';

import { VALIDATION_PRODUCT_DOC_FORM } from '@/constants/validationSchemas';

import { EnhancedInput } from '@/ui/atoms/EnhancedInput/EnhancedInput';
import SelectCustom from '@/ui/atoms/SelectCustom/SelectCustom';
import { CalendarInput } from '@/ui/organisms/CalendarInput/CalendarInput';

import 'dayjs/locale/ru';

import ModalCLose from '../ModalClose/ModalClose';
import ModalContent from '../ModalContent/ModalContent';
import ModalFooter from '../ModalFooter/ModalFooter';
import { openLoadingError, openSuccessModal } from '../modals';

import { FilesInput } from './components/FilesInput';

import { productDocsApi } from '@/store/slices/productDocs/api';

const DATE_DORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

interface ModalAddDocumentProps {
  docId?: string;
  onSubmit?: () => void;
}

export const ModalAddDocument: FC<ContextModalProps<ModalAddDocumentProps>> = ({
  context,
  id,
  innerProps: { docId = '', onSubmit = noop },
}) => {
  const [filesError, setFilesError] = useState(false);
  const [saveDocument, { data: savedDocument, isSuccess: docSaved, isLoading: isSaving }] =
    productDocsApi.useSaveDocumentMutation();
  const [fetchDetails, { data: docInfo, isLoading: isDetailsLoading, isError }] =
    productDocsApi.useGetDocumentDetailsMutation();
  const { colors } = useMantineTheme();
  const { data: docTypes, isLoading, isSuccess } = productDocsApi.useGetDocumentTypesQuery();
  const navigate = useNavigate();

  const form = useForm<IRawDocumentForm, (values: IRawDocumentForm) => IDocumentForm>({
    initialValues: {
      type: '',
      number: '',
      startDate: null,
      stopDate: null,
      files: [],
      stopDateLock: false,
      actualFilesKeys: [],
    },
    validate: yupResolver(VALIDATION_PRODUCT_DOC_FORM),
    transformValues: (values) => ({
      type: values.type,
      number: values.number,
      files: values.files,
      actualFilesKeys: values.actualFilesKeys,
      startDate: formatDate(values.startDate || new Date(), DATE_DORMAT_OPTIONS),
      stopDate: values.stopDate ? formatDate(values.stopDate, DATE_DORMAT_OPTIONS) : null,
    }),
  });

  const docTypesOptions = useMemo(
    () =>
      isSuccess ? docTypes.items.map((item) => ({ label: item.label, value: item.code })) : [],
    [isSuccess, docTypes]
  );

  const handleStopDateCheckboxChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const checked = e.currentTarget.checked;
    form.setFieldValue('stopDateLock', checked);
    if (checked) form.setFieldValue('stopDate', null);
  };

  const handleFormSubmit = (values: IDocumentForm) => {
    saveDocument({ ...values, id: docInfo?.id });
  };

  const handleRemoveUploadedFile = (fileKey: string) => {
    form.setFieldValue(
      'actualFilesKeys',
      form.values.actualFilesKeys.filter((key) => key !== fileKey)
    );
  };

  useEffect(() => {
    if (!docInfo) return;
    form.setValues({
      actualFilesKeys: docInfo?.files?.map((file) => file.key || '') || [],
      type: docInfo?.type?.code,
      number: docInfo?.number,
      startDate: dateFromString(docInfo?.startDate),
      stopDate: dateFromString(docInfo?.stopDate),
      stopDateLock: !docInfo.stopDate,
    });
  }, [docInfo]);

  useEffect(() => {
    if (docId) fetchDetails(docId);
    if (docSaved) {
      onSubmit();
      context.closeContextModal(id);
      openSuccessModal({
        title: '',
        body: docId
          ? 'Документ успешно обновлён'
          : 'Документ создан. Осталось привязать к нему товары в таблице',
        onClose: () => {
          navigate(`/docs/details/${savedDocument?.id}`);
        },
      });
    }
  }, [docSaved]);

  useEffect(() => {
    if (form.values.type === 'rejection_letter') {
      form.setFieldValue('stopDate', null);
    }
  }, [form.values.type]);

  useEffect(() => {
    if (isError) {
      modals.closeAll();
      openLoadingError({ id: 'loadingError' });
    }
  }, [isError]);

  const maxStartDate = useMemo(() => {
    const today = dayjs().subtract(1, 'day');
    if (!form.values.stopDate) return today.toDate();
    const stopDate = dayjs(form.values.stopDate);
    const constraint = stopDate.date(1).subtract(1, 'day');
    return constraint.isBefore(today) ? constraint.toDate() : today.toDate();
  }, [form.values.stopDate]);

  const minStopDate = useMemo(() => {
    if (!form.values.startDate) return;
    const startDate = dayjs(form.values.startDate);
    return startDate.add(1, 'month').date(1).toDate();
  }, [form.values.startDate]);

  return (
    <ModalContent fullScreen>
      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        <LoadingOverlay visible={isLoading || isDetailsLoading || isSaving} zIndex={100} />
        <ModalCLose onClose={() => context.closeModal(id)} />
        <Box>
          <Stack spacing={40} px={24}>
            <Stack spacing={18}>
              <Text px={64} align="center" size={24} weight={700} color={colors.text[8]}>
                {docId ? 'Изменение' : 'Добавление'} документа
              </Text>
              <Text align="center" size={16}>
                {!docId && 'Добавьте документ, а затем привяжите к нему товары в таблице'}
              </Text>
            </Stack>
            <Box>
              <Text size={16} mb={24} weight={700}>
                Основные данные
              </Text>
              <Stack spacing={16}>
                <Group spacing={16}>
                  <SelectCustom
                    options={docTypesOptions}
                    label="Тип документа"
                    size="lg"
                    {...form.getInputProps('type')}
                    optionValue={form.values.type}
                    clearable
                    required
                    sx={{ flex: '1' }}
                    miw={225}
                  />
                  <EnhancedInput
                    label="Номер документа"
                    labelType="floating"
                    sx={{ flex: '1' }}
                    size="md"
                    height={60}
                    {...form.getInputProps('number')}
                    miw={225}
                    required
                  />
                  <CalendarInput
                    miw={280}
                    h={60}
                    placeholder={'Дата выдачи (ДД.ММ.ГГГГ)'}
                    sx={{ flex: '1 0 0' }}
                    maxDate={maxStartDate}
                    clearable
                    {...form.getInputProps('startDate')}
                    inline
                    required
                    allowDeselect={false}
                  />
                  <CalendarInput
                    miw={280}
                    h={60}
                    placeholder={'Дата окончания (ДД.ММ.ГГГГ)'}
                    sx={{ flex: '1 0 0' }}
                    minDate={minStopDate}
                    clearable
                    disabled={form.values.type === 'rejection_letter' || form.values.stopDateLock}
                    {...form.getInputProps('stopDate')}
                    required={
                      !(form.values.type === 'rejection_letter' || form.values.stopDateLock)
                    }
                    inline
                  />
                  <Checkbox
                    label="Бессрочно"
                    disabled={Boolean(form.values.stopDate)}
                    checked={form.values.stopDateLock || form.values.type === 'rejection_letter'}
                    onChange={handleStopDateCheckboxChange}
                  />
                </Group>
              </Stack>
            </Box>
            <Stack spacing={24} align="flex-start">
              <Text size={16} weight={700} pos="relative">
                Загрузите документ
                <Box
                  pos="absolute"
                  top={0}
                  right={-12}
                  bg={colors.red[6]}
                  w={6}
                  h={6}
                  sx={{ borderRadius: '50%' }}
                />
              </Text>
              <FilesInput
                uploaded={docInfo?.files?.filter((file) =>
                  form.values.actualFilesKeys.includes(file.key)
                )}
                value={form.getInputProps('files').value}
                onChange={form.getInputProps('files').onChange}
                onRemoveUploaded={handleRemoveUploadedFile}
                onErrorChange={setFilesError}
              />
            </Stack>
          </Stack>
        </Box>
        <ModalFooter variant="xs" align="center">
          <Button
            type="submit"
            size="lg"
            fz={14}
            miw={280}
            disabled={!form.isValid() || filesError}
          >
            {docInfo ? 'Сохранить документ' : 'Добавить документ'}
          </Button>
        </ModalFooter>
      </form>
    </ModalContent>
  );
};
