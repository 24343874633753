import { ID } from '@/types/common/common';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IModalSameFilesNamesState {
  id: ID | null;
  fileName: string | null;
}

type ModalID = ID | null;

interface IModalState {
  modalSuccesID: ModalID;
  modalErrorID: ModalID;
  modalUploadID: ModalID;
  modalCategoryID: ModalID;
  modalArchiveID: ModalID;
  modalEansID: ModalID;
  modalInstructionID: ModalID;
  modalDataFillId: ModalID;
  modalUpdatePrices: ModalID;
  profileSubmitted: ModalID;
  modalFillDisplayed: ModalID;
  modalSameFilesNames: IModalSameFilesNamesState | null;
}

const initialState: IModalState = {
  modalSuccesID: null,
  modalErrorID: null,
  modalUploadID: null,
  modalCategoryID: null,
  modalArchiveID: null,
  modalEansID: null,
  modalInstructionID: null,
  modalDataFillId: null,
  modalUpdatePrices: null,
  profileSubmitted: null,
  modalFillDisplayed: null,
  modalSameFilesNames: null,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalSuccess: (state, action: PayloadAction<ID | null>) => {
      state.modalSuccesID = action.payload;
    },
    setModalError: (state, action: PayloadAction<ID | null>) => {
      state.modalErrorID = action.payload;
    },
    setModalUpload: (state, action: PayloadAction<ID | null>) => {
      state.modalUploadID = action.payload;
    },
    setModalCategory: (state, action: PayloadAction<ID | null>) => {
      state.modalCategoryID = action.payload;
    },
    setModalArchive: (state, action: PayloadAction<ID | null>) => {
      state.modalArchiveID = action.payload;
    },
    setModalEans: (state, action: PayloadAction<ID | null>) => {
      state.modalEansID = action.payload;
    },
    setModalInstruction: (state, action: PayloadAction<ID | null>) => {
      state.modalInstructionID = action.payload;
    },
    setModalDataFill: (state, action: PayloadAction<ID | null>) => {
      if (!state.modalFillDisplayed || !Boolean(action.payload))
        state.modalDataFillId = action.payload;
      if (Boolean(action.payload)) state.modalFillDisplayed = 'true';
    },
    setModalUpdatePrices: (state, action: PayloadAction<ID | null>) => {
      state.modalUpdatePrices = action.payload;
    },
    setModalSubmitted: (state, action: PayloadAction<ID | null>) => {
      state.profileSubmitted = action.payload;
    },
    setModalSameFilesNames: (state, action: PayloadAction<IModalSameFilesNamesState | null>) => {
      state.modalSameFilesNames = action.payload;
    },
    //ToDo добавить позже
    // setModal(state, action: PayloadAction<{ key: string; value: ID | null }>) {
    //     const { key, value } = action.payload;
    //     state[key] = value;
    // },
  },
});

export const {
  setModalSuccess,
  setModalError,
  setModalEans,
  setModalArchive,
  setModalUpload,
  setModalCategory,
  setModalInstruction,
  setModalDataFill,
  setModalUpdatePrices,
  setModalSubmitted,
  setModalSameFilesNames,
} = modalSlice.actions;

export default modalSlice.reducer;
