import { IInfoRes } from "@/types/api/instructions";

import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { baseQuery } from "@/api/instance";
import { instructionsUrls } from "@/api/urls/instructionsUrls";


export const instructionsApi = createApi({
    reducerPath: 'instructionsApi',
    baseQuery: baseQuery(),
    endpoints: (builder) => ({
        getInstuctionsInfo: builder.query<IInfoRes[], void>({
            query: () => ({
                url: instructionsUrls.getInstuctionsInfo,
                method: 'GET',
            }),
        }),
    })
})