import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs, useMantineTheme } from '@mantine/core';

import NotificationModal from '@/ui/organisms/Notification/NewStatusNotification';
import PageContent from '@/ui/organisms/Page/components/PageContent/PageContent';
import PageHeader from '@/ui/organisms/Page/components/PageHeader/PageHeader';
import Page from '@/ui/organisms/Page/Page';

import ProductDetailPanels from './components/ProductDetailPanels/ProductDetailPanels';
import ProductDetailTabs from './components/ProductDetailTabs/ProductDetailTabs';
import { StatusesBanner } from './components/StatusesBanners/StatusesBanners';
import { ProductProvider } from './utils/ProductContext';
import { useStyles } from './styles';

import { closeStatusNotification } from '@/store/slices/notification/slice';
import { selectFormProductData } from '@/store/slices/products/selectors';

const ProductDetailPage: React.FC = () => {
  const { tab } = useParams<{ tab: string }>();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { colors } = useMantineTheme();
  const formData = useSelector(selectFormProductData);
  const [productName, setProductName] = useState<string>('');
  const [supplierProductId, setSupplierProductId] = useState<string>('');
  const dispatch = useDispatch();

  const tabChangeHandler = (nextTab: string) => {
    navigate(`/products/create/${nextTab}`);
  };

  useEffect(() => {
    const name = formData?.tabs[0].fields.find((item) => item.code === 'name')?.value;
    setProductName(name || '');

    const supplierId = formData?.tabs[0].fields.find(
      (item) => item.code === 'supplierProductId'
    )?.value;
    setSupplierProductId(supplierId || '');
  }, [formData]);

  useEffect(() => {
    return () => {
      dispatch(closeStatusNotification());
    };
  }, []);

  return (
    <ProductProvider>
      <Page>
        <PageHeader title={productName || supplierProductId} />
        <StatusesBanner />
        <PageContent>
          <Tabs
            color={colors.mainColor[8]}
            defaultValue="common"
            value={tab}
            classNames={{ ...classes }}
            onTabChange={tabChangeHandler}
            keepMounted={false}
          >
            <ProductDetailTabs />
            <ProductDetailPanels />
          </Tabs>
        </PageContent>
      </Page>
      <NotificationModal />
    </ProductProvider>
  );
};

export default ProductDetailPage;
