import { createStyles } from '@mantine/core';

export const useStyles = createStyles((_) => ({
    root: {
        borderRadius: 0
    },
    tabsList: {
        borderBottom:'none',
    },
}));
