import { IPatchUpdateStatusBody } from "@/types/api/product";

import { FC } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Text, UnstyledButton } from "@mantine/core";
import { ContextModalProps, modals } from '@mantine/modals';

import ModalFooter from "@/ui/organisms/Modals/ModalFooter/ModalFooter";
import { ModalLayout } from "@/ui/templates/Layouts/ModaLayout/ModalLayout";

import { useStyles } from "./styles";

import { ReactComponent as IconModalMove } from '@/assets/icons/redesign/iconModalMove.svg';
import { AppDispatch } from "@/store";
import { updateProductsArchiveAdd } from "@/store/slices/products/asyncActions";
import { setSelected } from "@/store/slices/products/slice";

export interface IProductToArchive extends IPatchUpdateStatusBody {
    id: string;
}

const ProductToArchive: FC<ContextModalProps<IProductToArchive>> = ({
    innerProps: { supplierProductId, identifier },
    ...modalProps
}) => {
    const { classes } = useStyles();
    const dispatch = useDispatch<AppDispatch>();

    const handlerClose = () => {
        modals.close(modalProps.id);
    }

    const handlerToArchive = () => {
        dispatch(updateProductsArchiveAdd([{ supplierProductId, identifier }]));
        dispatch(setSelected([])); // remove checkbox
        handlerClose();
    }

    return (
        <ModalLayout title="Переместить в архив?" {...modalProps}>
            <Box className={classes.root}>
                <IconModalMove className={classes.icon} />
                <Text className={classes.text}>
                    Товар будет перемещен в архив. Перестанет отображаться в общем списке.
                    Восстановить товар из архива можно в любое время
                </Text>
            </Box>
            <ModalFooter variant="xs" align="center">
                <>
                    <Button
                        className={classes.button}
                        variant={'filled'}
                        onClick={handlerToArchive}
                    >
                        Переместить в архив
                    </Button>
                    <UnstyledButton onClick={handlerClose} fz={14}>
                        Отмена
                    </UnstyledButton>
                </>
            </ModalFooter>
        </ModalLayout>
    )
}

export default ProductToArchive;