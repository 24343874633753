import React from 'react';
import { createRoot } from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import { MantineProvider } from '@mantine/core';

import { theme } from './utils/theme';
import App from './App';
import { store } from './store';

import './assets/styles/index.scss';

const container = document.getElementById('app') as HTMLElement;
const root = createRoot(container);
const recaptchaKey = process.env.RECAPTCHA_V3_SITE_KEY as string;

if (container) {
  root.render(
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey} language={'ru'}>
      <Provider store={store}>
        <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
          <App />
        </MantineProvider>
      </Provider>
    </GoogleReCaptchaProvider>
  );
} else {
  throw new Error('Root element not found.');
}
