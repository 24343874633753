import { FC } from "react";
import { Flex, UnstyledButton } from "@mantine/core";

import { pluralize } from "@/utils/pluralize";

import { MULTIPLE_EANS_TITLES } from "@/constants/common";

import { openProductEans } from "@/ui/organisms/Modals/modals";


interface IEansCell {
    elements: string[] | string
}

const EansCell: FC<IEansCell> = ({ elements }) => {
    const data = Array.isArray(elements) ? elements : [elements];
    const firstEans = data[0];
    const countShow = data?.length - 1;

    return (
        <Flex direction={'column'} align={'flex-start'}>
            {firstEans}
            {data.length > 1 && (
                <UnstyledButton
                    fz={14}
                    mt={4}
                    p={0}
                    h={'auto'}
                    variant={'subtle'}
                    onClick={() => openProductEans({ eans: data })}
                >
                    +{countShow} {pluralize(countShow, MULTIPLE_EANS_TITLES)}
                </UnstyledButton>
            )}
        </Flex>
    );
};

export default EansCell;