import { TableConfig } from '@/types/common/common';

import { useCallback, useState } from 'react';
import { Box, Group, LoadingOverlay, Stack, Text, useMantineTheme } from '@mantine/core';

import { pluralize } from '@/utils/pluralize';

import { useSorting } from '@/hooks/useSortable';

import PaginationCustom from '@/ui/organisms/PaginationCustom/PaginationCustom';
import Cell from '@/ui/organisms/TableCustom/components/Cell/Cell';
import Row from '@/ui/organisms/TableCustom/components/Row/Row';
import TBody from '@/ui/organisms/TableCustom/components/TBody/TBody';
import THead from '@/ui/organisms/TableCustom/components/THead/THead';
import { TableCustom } from '@/ui/organisms/TableCustom/TableCustom';

import { SupplyActionButton } from '../SupplyActionButton/SupplyActionButton';
import { SupplyDateCell } from '../SupplyDateCell/SupplyDateCell';
import { SupplyStatusCell } from '../SupplyStatusCell/SupplyStatusCell';

import { suppliesApi } from '@/store/slices/supplies/slice';

const TABLE_CONFIG: TableConfig = {
  columns: [
    { name: 'Номер и дата', sortable: true, code: 'date' },
    { name: 'Результат приемки' },
    { name: 'Склад' },
    { name: 'Дата и время поставки', sortable: true, code: 'supply_date' },
    { name: 'Товары' },
    { name: '' },
  ],
};

export const FinishedList = () => {
  const [sort, setSort] = useSorting('finished');
  const [currentPage, setCurrentPage] = useState(1);
  const [concat, setConcat] = useState(false);
  const { colors } = useMantineTheme();
  const { data: warehousesData, isFetching: isWarehousesFetching } =
    suppliesApi.useGetWarehousesQuery({ tab: 'finished' });

  const {
    data: supplies,
    isSuccess,
    isFetching: isSuppliesFetching,
  } = suppliesApi.useGetSuppliesQuery(
    {
      tab: 'finished',
      limit: 50,
      offset: (currentPage - 1) * 50,
      sort: sort?.code,
      order: sort?.sort,
      concat,
    },
    {}
  );

  const getWarehouseName = useCallback(
    (id: string) => {
      const info = warehousesData?.items.find((warehouse) => warehouse.id === id);

      if (!info) return id;
      return info.name;
    },
    [warehousesData]
  );

  const pageChangeHandler = (page: number, isConcat: boolean) => {
    setCurrentPage(page);
    setConcat(isConcat);
  };

  const handleSort = (code: string) => {
    setCurrentPage(1);
    setSort(code);
  };

  return (
    <Box pt={32} pos="relative">
      <LoadingOverlay visible={isSuppliesFetching || isWarehousesFetching} />
      <Box>
        <TableCustom offsetScrollbars={false}>
          {() => (
            <>
              <THead>
                <Row>
                  {TABLE_CONFIG.columns.map((column) => (
                    <Cell
                      key={`title-${column.name}`}
                      th
                      sticky
                      sortable={column.sortable}
                      sortCode={column.code}
                      onSortClick={handleSort}
                      sort={sort?.code === column.code ? sort?.sort : null}
                    >
                      <Group>{column.name}</Group>
                    </Cell>
                  ))}
                </Row>
              </THead>
              <TBody>
                {isSuccess && supplies.items.length > 0 ? (
                  supplies.items.map((item) => (
                    <Row>
                      <Cell>
                        <Stack spacing={4}>
                          <Text>{item.number}</Text>
                          <Text size={12} color={colors.gray[6]}>
                            {item.date}
                          </Text>
                        </Stack>
                      </Cell>
                      <SupplyStatusCell
                        id={item.id}
                        status={item.status}
                        displayGobutton
                        hasAcceptance={item.hasAcceptance}
                        updFolderId={item.updFolderId}
                      />
                      <Cell>
                        <Text maw={200} sx={{ lineHeight: 1.4 }}>
                          {getWarehouseName(item.warehouse)}
                        </Text>
                      </Cell>

                      <SupplyDateCell date={item.supplyDate} />
                      <Cell>
                        <Stack spacing={4}>
                          <Text style={{ whiteSpace: 'nowrap' }}>
                            {item.articlesCount}{' '}
                            {pluralize(item.articlesCount, ['артикул', 'артикула', 'артикулов'])}
                          </Text>
                          <Text size={12} color={colors.gray[6]}>
                            {item.productsTotalQuantity} шт.
                          </Text>
                        </Stack>
                      </Cell>
                      <Cell>
                        <SupplyActionButton id={item.id} />
                      </Cell>
                    </Row>
                  ))
                ) : (
                  <Row>
                    <Cell colspan={8} align="center">
                      <Text align="center" w="100%" color={colors.brandGrey[8]}>
                        Нет записей
                      </Text>
                    </Cell>
                  </Row>
                )}
              </TBody>
            </>
          )}
        </TableCustom>
        <PaginationCustom
          currentPage={currentPage}
          handler={pageChangeHandler}
          limitItems={50}
          totalItems={supplies?.count || 0}
          moreButton
          scrollOnChange
        />
      </Box>
    </Box>
  );
};
