import { AuthStepsEnum } from '@/types/api/auth';

import { FC } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Flex, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import reCaptchaVerify from '@/utils/reCaptchaVerify';

import { SIGNIN_WITH_ONLY_EMAIL } from '@/constants/validationSchemas';

import InputWithLabel from '@/ui/atoms/InputWithLabel/InputWithLabel';

import AuthFooter from '../AuthFooter/AuthFooter';

import { AppDispatch } from '@/store';
import { checkSellerEmailAction } from '@/store/slices/auth/asyncAction';
import {
  selectFetchingCheckEmail,
  selectUserSellerEmail,
  setAuthStep,
  setRegbyPhone,
  setUserPhone,
  setUserSellerEmail,
} from '@/store/slices/auth/auth';

const SignInWithOnlyEmail: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const fetching = useSelector(selectFetchingCheckEmail);
  const { colors } = useMantineTheme();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const form = useForm({
    initialValues: {
      email: useSelector(selectUserSellerEmail) ?? '',
    },
    validateInputOnChange: true,
    validate: yupResolver(SIGNIN_WITH_ONLY_EMAIL),
  });

  const handleSubmit = async (values: Record<string, string>) => {
    reCaptchaVerify(executeRecaptcha, 'checkEmail').then(async (recaptchaToken) => {
      if (recaptchaToken) {
        const sendData = {
          email: values.email,
          recaptchaToken,
          sendAuthCode: false,
        };
        dispatch(setUserSellerEmail(values.email));
        const res = await dispatch(
          checkSellerEmailAction({
            data: sendData,
            isStep: true,
          })
        ).unwrap();

        if (res.userExist) {
          dispatch(setAuthStep(AuthStepsEnum.SIGNIN_WITH_EMAIL));
        } else {
          dispatch(setAuthStep(AuthStepsEnum.SIGNIN_WITH_PHONE));
        }
        // dispatch(setUserEmail(values.email));
        // dispatch(checkEmailAction(sendData));
      }
    });
  };

  const checkFormField = () => {
    const email = form.getInputProps('email').value;
    if (email.length > 0) {
      if (form.isValid()) return false;
    }
    return true;
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} noValidate={true}>
      <InputWithLabel
        label="e-mail"
        size="md"
        autoComplete="off"
        inputMode="email"
        {...form.getInputProps('email')}
        value={form.getInputProps('email').value}
      />
      <AuthFooter>
        <Button
          loading={fetching}
          disabled={checkFormField()}
          type="submit"
          size="lg"
          variant="filled"
          fullWidth
          h={48}
          fz={14}
        >
          Войти
        </Button>
        <UnstyledButton
          color={colors.mainColor[6]}
          fz={14}
          mt={16}
          type="button"
          variant="subtle"
          onClick={() => {
            dispatch(setUserPhone(''));
            dispatch(setRegbyPhone(false));
            dispatch(setAuthStep(AuthStepsEnum.SIGNIN_WITH_PHONE_CODE));
          }}
        >
          Войти по телефону
        </UnstyledButton>
      </AuthFooter>
    </form>
  );
};

export default SignInWithOnlyEmail;
