import { ProfileTabCode } from '@/types/api/profile';

import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, LoadingOverlay, Tabs, Text } from '@mantine/core';
import filterXSS from 'xss';

import { getinteractiveDescription } from '@/utils/livetex';
import notify from '@/utils/notify';

import { SELLER_STATUS_ACTIVE, SELLER_STATUS_REJECTED } from '@/constants/common';
import { NOTIFY_WILL_CONFIRM_EMAIL } from '@/constants/notifyMessages';

import { useStyles } from '../../styles';
import AccountManage from '../AccountManage/AccountManage';
import ContractShow from '../ContractShow/ContractShow';
import Head from '../Head/Head';
import { ProfileFormBuilder } from '../ProfileFormBuilder/ProfileFormBuilder';

import { profileApi } from '@/store/slices/profile/slice';
import { selectUser } from '@/store/slices/user/slice';

export const ProfilePanels = () => {
  const { classes } = useStyles();
  const user = useSelector(selectUser);
  const { data: profile, isFetching } = profileApi.useGetTabsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const showOffer = useMemo(
    () =>
      user?.sellerStatus === SELLER_STATUS_ACTIVE || user?.sellerStatus === SELLER_STATUS_REJECTED,
    [user]
  );

  useEffect(() => {
    if (
      user?.companyEmailInConfirmation &&
      user?.companyEmailConfirmationLinkValid &&
      !user.dateEmailSellerLoginConfirmation
    ) {
      notify({ message: NOTIFY_WILL_CONFIRM_EMAIL, type: 'success' });
    }
  }, []);

  return (
    <Box className={classes.inner}>
      <Tabs.Panel value="account">
        <Head title="Управление аккаунтом" />
        <AccountManage />
      </Tabs.Panel>

      {profile?.tabs.map((tab) => (
        <Tabs.Panel value={tab.code} key={tab.code}>
          {tab.code === ProfileTabCode.DOCUMENTS && (
            <>
              {showOffer ? (
                <ContractShow />
              ) : (
                <>
                  <Box mb={24}>
                    <Head title={tab.title}>
                      <Text lh="20px" mb={24}>
                        <Box>
                          <Box
                            dangerouslySetInnerHTML={{
                              __html: filterXSS(getinteractiveDescription(tab.description) || ''),
                            }}
                          />{' '}
                        </Box>
                      </Text>
                    </Head>
                  </Box>
                  <Text weight={700} mt={60} mb={16}>
                    Основные документы
                  </Text>
                  <Box pos="relative">
                    {isFetching ? (
                      <LoadingOverlay visible />
                    ) : (
                      <ProfileFormBuilder
                        fields={tab.fields}
                        code={tab.code}
                        confirmed={tab.confirmed}
                        offerLink={profile?.docs.offerLink || ''}
                      />
                    )}
                  </Box>
                </>
              )}
            </>
          )}

          {tab.code !== ProfileTabCode.DOCUMENTS && (
            <>
              <Box mb={24}>
                <Head title={tab.title}>
                  <Text lh="20px" fs="14px" mb={60}>
                    <Box
                      dangerouslySetInnerHTML={{
                        __html: filterXSS(getinteractiveDescription(tab.description) || ''),
                      }}
                    />{' '}
                  </Text>
                </Head>
              </Box>
              <Box pos="relative">
                {isFetching ? (
                  <LoadingOverlay visible />
                ) : (
                  <ProfileFormBuilder
                    fields={tab.fields}
                    code={tab.code}
                    confirmed={tab.confirmed}
                  />
                )}
              </Box>
            </>
          )}
        </Tabs.Panel>
      ))}
    </Box>
  );
};
