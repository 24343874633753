import { SortType } from '@/types/api/common';
import { Alignment } from '@/types/common/common';

import { FC, ReactNode, useEffect, useState } from 'react';
import { Box, Group, px, UnstyledButton, useMantineTheme } from '@mantine/core';
import classNames from 'classnames';
import { ArrowDown, ArrowUp } from 'tabler-icons-react';

import { useStyles } from './styles';

interface ICell {
  children?: ReactNode;
  width?: number | string;
  align?: Alignment;
  className?: string;
  th?: boolean;
  size?: 'md' | 'xl' | 'auto';
  sticky?: boolean;
  colspan?: number;
  firstCell?: boolean;
  lastCell?: boolean;
  scrollValues?: { current: number };
  hasHorizontalScroll?: boolean;
  grow?: boolean;
  maxWidth?: string | number;
  minWidth?: string | number;
  sortable?: boolean;
  onSortClick?: (code: string) => void;
  sortCode?: string;
  sort?: SortType | null;
  nowrap?: boolean;
}

const Cell: FC<ICell> = ({
  th,
  children,
  width,
  align,
  className,
  size = 'auto',
  sticky,
  colspan,
  firstCell,
  lastCell,
  scrollValues,
  maxWidth,
  hasHorizontalScroll,
  minWidth,
  grow,
  sortable,
  onSortClick,
  sortCode,
  sort,
  nowrap,
}) => {
  const { classes } = useStyles({ sticky, minWidth, maxWidth });
  const [isStickyLeft, setIsStickyLeft] = useState(false);
  const [isStickyRight, setIsStickyRight] = useState(false);
  const { colors } = useMantineTheme();


  useEffect(() => {
    if (hasHorizontalScroll && firstCell && scrollValues?.current && scrollValues.current > 0) {
      setIsStickyLeft(true);
    } else {
      setIsStickyLeft(false);
    }

    if (hasHorizontalScroll && lastCell && scrollValues?.current !== undefined) {
      if ((scrollValues.current < px(maxWidth) || 0) || (!scrollValues.current && maxWidth === 0)) {
        setIsStickyRight(true);
      } else {
        setIsStickyRight(false);
      }
    } else {
      setIsStickyRight(false);
    }
  }, [hasHorizontalScroll, firstCell, lastCell, scrollValues, maxWidth]);

  const classCell = () => {
    return classNames(
      classes.td,
      className,
      { [classes.md]: size === 'md' },
      { [classes.xl]: size === 'xl' },
      { [classes.sticky]: sticky },
      { [classes.th]: th },
      { [classes.tbody_td]: !th },
      { [classes.stickyLeft]: isStickyLeft },
      { [classes.stickyRight]: isStickyRight }
    );
  };
  const CellComponent = th ? 'th' : 'td';

  const toggleSort = () => {
    onSortClick?.(sortCode || '');
  };

  return (
    <CellComponent
      className={classCell()}
      align={align}
      width={width}
      colSpan={colspan}
      data-grow={!!grow}
      data-nowrap={!!nowrap}
    >
      {sortable ? (
        <UnstyledButton
          onClick={toggleSort}
          lh={0}
          sx={{ fontWeight: 700, fontSize: 14 }}
          color={colors.brandDark[9]}
        >
          <Group align="center" w="fit-content" spacing={8} noWrap>
            {(!sort || sort === 'asc') && (
              <ArrowUp size={20} stroke={sort ? colors.mainColor[6] : colors.gray[6]} />
            )}
            {sort === 'desc' && (
              <ArrowDown size={20} stroke={sort ? colors.mainColor[6] : colors.gray[6]} />
            )}
            <Box>{children}</Box>
          </Group>
        </UnstyledButton>
      ) : (
        children
      )}
    </CellComponent>
  );
};

export default Cell;
