import { FC, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Group,
  LoadingOverlay,
  ScrollArea,
  Stack,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import ModalCLose from '../ModalClose/ModalClose';
import ModalContent from '../ModalContent/ModalContent';
import ModalFooter from '../ModalFooter/ModalFooter';

import { WarehouseSelect } from './components/WarehouseSelect/WarehouseSelect';
import { useStyles } from './styles';

import { suppliesApi } from '@/store/slices/supplies/slice';

export interface ChangeSupplyWarehouseProps {
  onWarehouseSelect: (warehouseId: string) => void;
}

export const ChangeSupplyWarehouse: FC<ContextModalProps<ChangeSupplyWarehouseProps>> = ({
  context,
  id,
  innerProps: { onWarehouseSelect },
}) => {
  const theme = useMantineTheme();
  const [selection, setSelection] = useState<string | undefined>();

  const { colors } = useMantineTheme();
  const { classes } = useStyles();
  const [getWarehouses, { data: warehouses, isFetching }] = suppliesApi.useLazyGetWarehousesQuery();

  const currentWarehouse = useMemo(
    () => selection || warehouses?.items?.[0]?.id || undefined,
    [selection, warehouses]
  );

  const handleRegionChange = (v?: string) => {
    if (!v) return;

    getWarehouses({ region: v });
  };

  const handleSubmit = () => {
    context.closeModal(id);
    onWarehouseSelect(currentWarehouse || '');
  };

  useEffect(() => {
    if (warehouses?.items.length) setSelection(warehouses.items[0].id);
  }, [warehouses]);

  return (
    <ModalContent boxSize={535}>
      <LoadingOverlay visible={isFetching} zIndex={100} />
      <ModalCLose onClose={() => context.closeModal(id)} />
      <Stack spacing={30} pb={24} align="center">
        <Text weight={700} size={24}>
          Заявка на поставку
        </Text>
        <Text size={16} align="center" maw={512} style={{ lineHeight: 1.4 }}>
          Для создания заявки необходимо указать склад поставки, товары и их количество
        </Text>
      </Stack>
      <Grid h={288}>
        <Grid.Col span={6}>
          <WarehouseSelect onSelect={handleRegionChange} />
        </Grid.Col>
        <Grid.Col span={6} pt={12}>
          <Text size={14} color={theme.colors.gray[6]}>
            Склад
          </Text>
          <ScrollArea
            classNames={{
              root: classes.warehousesSection,
              scrollbar: classes.warehousesScrollbar,
              corner: classes.warehousesCorner,
            }}
            h="234px"
            mt={14}
          >
            <Stack spacing={8} p={16} pr={32}>
              {warehouses?.items.length ? (
                warehouses?.items?.map((item) => (
                  <UnstyledButton
                    data-selected={currentWarehouse === item.id}
                    className={classes.item}
                    key={item.id}
                    onClick={() => setSelection(item.id)}
                  >
                    <Group noWrap align="start" spacing={10}>
                      <Stack spacing={8}>
                        <Group position="apart">
                          <Text weight={700} color="black">
                            {item.name}
                          </Text>
                        </Group>
                        <Text
                          title={item.address}
                          color={colors.text[5]}
                          size={14}
                          lh={'20px'}
                          maw={600}
                          sx={{
                            wordWrap: 'break-word',
                          }}
                        >
                          Адрес: {item.address}
                        </Text>
                      </Stack>
                      <Checkbox
                        checked={currentWarehouse === item.id}
                        classNames={{ input: classes.checkbox }}
                      />
                    </Group>
                  </UnstyledButton>
                ))
              ) : (
                <Box className={classes.placeholder}>
                  <Text sx={{ whiteSpace: 'nowrap' }} className={classes.text} color={theme.colors.gray[6]}>
                    Сначала укажите регион
                  </Text>
                </Box>
              )}
            </Stack>
          </ScrollArea>
        </Grid.Col>
      </Grid>

      <ModalFooter align="center" variant="xs">
        <Button
          size="md"
          onClick={handleSubmit}
          w="100%"
          maw={280}
          h={48}
          disabled={!currentWarehouse}
        >
          Продолжить
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
