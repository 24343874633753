export const intPage = (val: number, by: number): number => {
    return (val - (val % by)) / by;
};

export const getPageCount = (total: number, limit: number): number => {
    return intPage(total, limit) + (total % limit > 0 ? 1 : 0);
};

export const getAllPageArray = (pageCount: number): number[] => {
    return Array.from({ length: pageCount }, (_, i) => i + 1);
};

export const getVisiblePage = (currentPage: number, pageArray: number[]): number[] => {
    if (!(pageArray.length > 5)) return pageArray;

    const newArr = pageArray.slice(
        currentPage <= 3
            ? 0
            : pageArray.length - currentPage >= 2
                ? currentPage - 3
                : pageArray.length - 5,
        currentPage <= 3 ? 5 : pageArray.length - currentPage >= 2 ? currentPage + 2 : pageArray.length
    );

    return newArr;
};
