import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { AppShell, LoadingOverlay, useMantineTheme } from '@mantine/core';

import { useAuth } from '@/hooks/useAuth';
import useEmailConsent from '@/hooks/useEmailConsent';

import { Notification } from '../Layout/components/Notification/Notification';

import { selectFetchingUser, selectUser } from '@/store/slices/user/slice';

const LayoutAuth: FC = () => {
  const theme = useMantineTheme();
  const user = useSelector(selectUser);
  const isUserFetching = useSelector(selectFetchingUser);
  const isEmailConfirmationPending = useEmailConsent();

  useAuth();

  return (
    <AppShell
      fixed={false}
      styles={{
        main: {
          background: `${theme.colors.customWhite[0]}`,
          display: 'flex',
          padding: 0,
          margin: 0,
          justifyContent: 'center',
          alignItems: 'stretch',
          height: '100vh',
        },
      }}
      header={<Notification />}
    >
      <LoadingOverlay
        visible={(isUserFetching && !user) || isEmailConfirmationPending}
        overlayOpacity={1}
      />
      <Outlet />
    </AppShell>
  );
};

export default LayoutAuth;
