const checkStand = (url: string) => {
  // return process.env.NODE_ENV !== 'development'
  //   ? process.env.MAIN_SITE_PROD_URL + url
  //   : process.env.MAIN_SITE_DEV_URL + url

  return process.env.MAIN_SITE_PROD_URL + url
}

const USER_AGREEMENT = checkStand('/services/user-agreement');
const OFFER = checkStand('/services/offer');
const REFUND = checkStand('/services/refund');
const LOYALTY = checkStand('/services/loyalty');
const FAQ = checkStand('/services/faq');

export const routNames = {
  SERVICES: {
    USER_AGREEMENT,
    OFFER,
    REFUND,
    LOYALTY,
    FAQ,
  },
};
