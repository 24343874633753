export const getOffset = (page: number | string, limit: number | string): string => {
  let position = 0;
  let numberPage = Number(page);
  const numberLimit = Number(limit);

  if (numberPage === 1) return '0';

  for (; numberPage > 1; numberPage--) {
    position = position + numberLimit;
  }

  return String(position);
};
