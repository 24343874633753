import { FC } from 'react';
import { useDocumentTitle } from '@mantine/hooks';

import ProductsPage from '@/ui/pages/ProductsPage/ProductsPage';

const Products: FC = () => {
    useDocumentTitle('Список товаров');
    return (
        <ProductsPage title={'Список товаров'} />
    )
}

export default Products;