import { FC } from 'react';
import { Button, Stack, Text, useMantineTheme } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import ModalCLose from '../ModalClose/ModalClose';
import ModalContent from '../ModalContent/ModalContent';
import ModalFooter from '../ModalFooter/ModalFooter';

interface ILoadingErrorModalrops {
  id: string;
  height?: string;
}

export const LoadingErrorModal: FC<ContextModalProps<ILoadingErrorModalrops>> = ({
  innerProps: { height },
  context,
  id,
}) => {
  const { colors } = useMantineTheme();

  return (
    <ModalContent boxSize={height == 'xs' ? 225 : 535}>
      <ModalCLose onClose={() => context.closeModal(id)} />
      <Stack spacing={32} align="center" justify="start">
        <Text
          size={24}
          weight={700}
          color={colors.gray[7]}
          align="center"
          px={48}
          sx={{ lineHeight: 1.25 }}
        >
          Ошибка
        </Text>
        <Text fz={16} lh={'24px'}>
          Ошибка загрузки, попробуйте позже
        </Text>
      </Stack>
      <ModalFooter>
        <Button
          onClick={() => {
            context.closeModal(id);
          }}
          w="100%"
          size="lg"
        >
          Закрыть
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
