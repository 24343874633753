import { IOperations, TOperationsCodes, TOperationsStatuses } from '@/types/entites/template';

import { FC } from 'react';
import { useSelector } from 'react-redux';

import notify from '@/utils/notify';
import { useOperation } from '@/utils/wsOperations';

import { openErrorProuctsModal, openSuccessModal } from '@/ui/organisms/Modals/modals';

import { getModalErrorText } from './utils/getModalErrorText';
import { getModalSuccessText } from './utils/getModalSuccessText';
import { getModalTitle } from './utils/getModalTitle';

import { useAppDispatch } from '@/store';
import { selectOpertaionExport } from '@/store/slices/products/selectors';
import { setExportState } from '@/store/slices/products/slice';

interface ICheckOperationsProps {
  onRefetch?: () => void;
}

const ProductsOperationsController: FC<ICheckOperationsProps> = ({ onRefetch }) => {
  const selectExport = useSelector(selectOpertaionExport);
  const dispatch = useAppDispatch();

  const handleOperationChange = (operation: IOperations) => {
    if (
      operation.status === TOperationsStatuses.FINISHED ||
      operation.status === TOperationsStatuses.ERROR
    ) {
      if (operation.errorCount === 0 && operation.successCount > 0) {
        if (operation.code === TOperationsCodes.PRODUCTS_EXPORT && operation.path) {
          dispatch(setExportState(false));
          const downloadLink = document.createElement('a');
          downloadLink.href = operation.path;
          downloadLink.download = 'products.csv';
          downloadLink.click();
        }
        openSuccessModal({
          title: getModalTitle(operation, 'success'),
          body: getModalSuccessText(operation),
          onClose: onRefetch,
        });
      } else {
        if (operation.code === TOperationsCodes.PRODUCTS_EXPORT) {
          notify({ message: operation.notificationMessage, type: 'error' });
          dispatch(setExportState(false));
        } else {
          openErrorProuctsModal(
            operation,
            getModalTitle(operation, 'error'),
            getModalErrorText(operation),
            operation.path,
            operation.errorMessage,
          );
        }
      }
    }
  };

  useOperation({
    id: selectExport?.operationId || 'null',
    code: TOperationsCodes.PRODUCTS_EXPORT,
    onOperationChange: handleOperationChange,
    // skip: !selectExport?.supplierProductId,
  });

  useOperation({
    code: TOperationsCodes.PRODUCTS_IMPORT,
    onOperationChange: handleOperationChange,
  });

  useOperation({
    code: TOperationsCodes.PRICES_IMPORT,
    onOperationChange: handleOperationChange,
  });

  return null;
};

export default ProductsOperationsController;
