import { IOperations } from "@/types/entites/template";

import { pluralize } from "@/utils/pluralize";

import { MULTIPLE_PRODUCT_TITLES } from "@/constants/common";
import { PRICES_IMPORT, PRODUCTS_IMPORT } from "@/constants/operationStatuses";

export const getModalErrorText = (operation: IOperations) => {
    if (operation.code === PRODUCTS_IMPORT) {
        return operation.errorMessage ||
            `Загружено/обновлено ${operation.successCount} 
            ${pluralize(operation.successCount, MULTIPLE_PRODUCT_TITLES)} 
            из ${operation.toProcessCount}`;
    } else if (operation.code === PRICES_IMPORT) {
        return `Установлены/обновлены цены на ${operation.successCount}
                ${pluralize(operation.successCount, MULTIPLE_PRODUCT_TITLES)} 
                из ${operation.toProcessCount}`;
    }
    return '';
};