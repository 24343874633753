import { IGetFormProductRes, IPostFormProductRes } from '@/types/api/product';
import { IProductTab, IProudctField } from '@/types/entites/product';

import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Tabs, Text } from '@mantine/core';
import { LoadingOverlay } from '@mantine/core';

import notify from '@/utils/notify';

import FormGroup from '@/ui/atoms/FormGroup/FormGroup';
import Head from '@/ui/pages/ProfilePage/components/Head/Head';

import { useStyles } from '../../styles';
import { useProductContext } from '../../utils/ProductContext';
import ProductPreview from '../ProductPreview/ProductPreview';
import ProductRenderFIeld from '../ProductRenderField/ProductRenderFIeld';
import SaveButton from '../SaveButton/SaveButton';

import { productsApi } from '@/store/slices/products/api';
import { selectSupplierProductId } from '@/store/slices/products/selectors';

export interface IFieldError extends Pick<IPostFormProductRes, 'errors'> {}

const imagesDecription = (
  <Text style={{ fontSize: 16 }}>Допустимы файлы с расширением jpg, jpeg, png, webp</Text>
);

const ProductDetailPanels: FC = () => {
  const [formData, setFormData] = useState<IGetFormProductRes>();
  const [formFetching, setFormFetching] = useState<boolean>();

  const {
    data,
    editingProductData,
    currentTab,
    fieldValues,
    changedFields,
    errors,
    setErrors,
    setFieldValues,
    setChangedFields,
    isFetchingGetFormOfProduct,
    isFetchingGetFormOfEditingProduct,
  } = useProductContext();
  const { classes } = useStyles();

  const dataOfTab = useMemo(
    () => data?.tabs.find(({ code }) => code == currentTab),
    [currentTab, data]
  );
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [postSaveFormProduct, { data: errorsResponse, isLoading: isLoadingSave, isError }] =
    productsApi.usePostFormSaveProductMutation();

  const handleFieldChange = (code: string, value: string) => {
    setFieldValues((prevVal: any) => ({
      ...prevVal,
      [code]: value,
    }));
    setChangedFields((prevVal: any) => ({
      ...prevVal,
      [code]: value,
    }));
  };

  const supplierProductId = useSelector(selectSupplierProductId);

  const handleSubmit = async () => {
    setHasSubmitted(true);
    const fieldsArray = Object.entries(changedFields).map(([code, value]) => ({ code, value }));

    if (!Object.keys(changedFields).includes('supplierProductId')) {
      fieldsArray.push({
        code: 'supplierProductId',
        value: supplierProductId,
      });
    }

    await postSaveFormProduct({
      id: String(data?.id),
      familyCode: String(data?.familyCode),
      categoryCode: String(data?.categoryCode),
      fields: fieldsArray,
    });
  };

  useEffect(() => {
    const errorData: { [key: string]: string } = {};
    if (hasSubmitted && !isLoadingSave) {
      if (errorsResponse && Array.isArray(errorsResponse.errors)) {
        errorsResponse.errors.forEach((e) => (errorData[e.code] = e.error));
        setErrors(errorData);
      } else if (hasSubmitted && !isError) {
        notify({
          message: 'Товар успешно сохранен',
          type: 'success',
        });
      }
    }
  }, [isLoadingSave, hasSubmitted, errorsResponse]);

  useEffect(() => {
    const statusString = localStorage.getItem('currentStatus');
    const currentStatus = statusString ? JSON.parse(statusString) : null;

    if (currentStatus) {
      setFormData(editingProductData);
      setFormFetching(isFetchingGetFormOfEditingProduct);
    } else {
      setFormData(data);
      setFormFetching(isFetchingGetFormOfProduct);
    }
  }, [data, editingProductData, localStorage.getItem('currentStatus')]);

  return (
    <>
      <LoadingOverlay visible={(!formFetching && isLoadingSave) || !data} zIndex={100} />
      <Box className={classes.inner}>
        {formData?.tabs.map((tab: IProductTab) => (
          <Tabs.Panel value={tab.code} key={tab.code}>
            <Head
              title={dataOfTab?.title === undefined ? '' : String(dataOfTab?.title)}
              children={tab.code === 'images' ? imagesDecription : null}
            />
            {tab.fields?.map((field: IProudctField) => (
              <FormGroup spacing={field.type !== 'file' ? 26 : 0} key={field.code}>
                <ProductRenderFIeld
                  field={field}
                  fieldValues={fieldValues}
                  handleFieldChange={handleFieldChange}
                  error={errors[field.code]}
                />
              </FormGroup>
            ))}
          </Tabs.Panel>
        ))}
        <Tabs.Panel value={'preview'}>
          <ProductPreview />
        </Tabs.Panel>

        {formData && <SaveButton onClick={handleSubmit} />}
      </Box>
    </>
  );
};

export default ProductDetailPanels;
