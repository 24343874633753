import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: theme.colors.customWhite[0],
        borderRadius: '24px',
        position: 'relative'  
    }
}));