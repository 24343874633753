import { ID } from "@/types/common/common";

export const productDocsUrls = {
    getDocumentsList: '/seller/products-document/list',
    getDocumentTypes: '/seller/products-document/types',
    getDocumentDetails: (id: string) => `/seller/products-document/${id}`,
    createDocument: '/seller/products-document',
    saveDocument: (id: string) => `/seller/products-document/${id}`,
    getTemplate: '/seller/products-document/products/attach/template',
    attachProducts: (id: string) => `/seller/products-document/${id}/products/attach`,
    removeDocument: (id: ID) => `/seller/products-document/${id}`,
    downloadFiles: (id: ID) => `/seller/products-document/${id}/files/download`,
    getProducts: (id: ID) => `/seller/products-document/${id}/products`,
    getStatuses: `/seller/products-document/statuses`,
}