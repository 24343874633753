import parse from 'date-fns/parse';
import * as Yup from 'yup';

import { validationTexts } from './validationTexts';

// const NUMERIC_STRING_REGEXP = /^[0-9].*$/gm;
export const NAME_PART_REGEXP = /^[а-яёa-z]+$/i;
const CAR_NUMBER_REGEXP = /^[a-zA-Zа-яА-ЯёЁ0-9\-]*$/gm;
const CAR_MODEL_REGEXP = /^[a-zA-Zа-яА-ЯёЁ\s\-]*$/gm;
const STRING_REGEXP = /^([a-zA-Zа-яА-ЯёЁ0-9\s,.!?;:"'`№«»\/\-]*)$/gm;
const ADDRESS_REGEXP = /^([a-zA-Zа-яА-ЯёЁ0-9\s,.!?;:"'`№«»“„\`\"\(\)\/\-]*)$/gm;
const NAME_REGEXP = /^(?! )[а-яa-zЁё]+(?:[- а-яa-zЁё]*[а-яa-zЁё])*(?<! )$/i;
const ADDRESS_PART_REGEXP = /^([a-zA-Zа-яА-ЯёЁ0-9\s\/\\-]+)$/gm;
const EMAIL_LIGHT_REGEXP = /.+@.+\..+/;
const PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]{6,}$/;
// export const POSITIVE_INTEGER = /(?<!-)(?<!\d)[1-9][0-9]*/;
export const POSITIVE_INTEGER = /^[0-9]\d*$/;
export const NUM_REGEX = /^[0-9]*$/;

export const PHONE_LENGTH = 11;
const EMAIL_MAX_LENGTH = 320;
const STRING_MIN_LENGTH = 2;
const STRING_MAX_LENGTH = 50;
const CODE_LENGTH = 4;
export const SEARCH_ADDRESS_MAX_LENGTH = 300;
export const ADDRESS_PART_MAX_LENGTH = 10;
export const COMMENT_MAX_LENGTH = 200;
export const PROMOCODE_MAX_LENGTH = 60;
export const RETURN_COMMENT_MAX_LENGTH = 1000;

export const namePartValidator = Yup.string().matches(
  NAME_PART_REGEXP,
  validationTexts.INCORRECT_NAME
);

const getStringValidator = (
  minLength = STRING_MIN_LENGTH,
  maxLength = STRING_MAX_LENGTH,
  regexp = STRING_REGEXP,
  regexpError = validationTexts.STRING
) =>
  Yup.string()
    .min(minLength, validationTexts.MIN_LENGTH(minLength))
    .max(maxLength, validationTexts.MAX_LENGTH(maxLength))
    .matches(regexp, regexpError);

export const VALIDATION_PHONE = Yup.string()
  .length(PHONE_LENGTH, validationTexts.PHONE)
  .required(validationTexts.REQUIRED);

export const VALIDATION_EMAIL = Yup.string()
  .required(validationTexts.REQUIRED)
  .matches(EMAIL_LIGHT_REGEXP, validationTexts.EMAIL)
  .max(EMAIL_MAX_LENGTH, validationTexts.MAX_LENGTH(EMAIL_MAX_LENGTH))
  .email(validationTexts.EMAIL);

const VALIDATION_PASSWORD = Yup.string()
  .matches(PASSWORD_REGEXP, validationTexts.PASSWORD_FORMAT_INCORRECT)
  .required(validationTexts.REQUIRED);

const VALIDATION_CONFIRMATION_PASSWORD = VALIDATION_PASSWORD.oneOf(
  [Yup.ref('newPassword')],
  validationTexts.PASSWORD_NOT_CONFIRM
);

const VALIDATION_REQUIRED_STRING = Yup.string()
  .required(validationTexts.REQUIRED)
  .matches(STRING_REGEXP, validationTexts.STRING)
  .min(STRING_MIN_LENGTH, validationTexts.MIN_LENGTH(STRING_MIN_LENGTH))
  .max(STRING_MAX_LENGTH, validationTexts.MAX_LENGTH(STRING_MAX_LENGTH));

const VALIDATION_LONG_STRING = Yup.string()
  .required(validationTexts.REQUIRED)
  // .matches(STRING_REGEXP, validationTexts.STRING)
  .min(STRING_MIN_LENGTH, validationTexts.MIN_LENGTH(STRING_MIN_LENGTH))
  .max(SEARCH_ADDRESS_MAX_LENGTH, validationTexts.MAX_LENGTH(SEARCH_ADDRESS_MAX_LENGTH));

const getNameValidator = (required = false, error = validationTexts.INCORRECT_NAME) =>
  required
    ? getStringValidator(STRING_MIN_LENGTH, STRING_MAX_LENGTH, NAME_REGEXP, error).required(
        validationTexts.REQUIRED
      )
    : Yup.lazy((value) => {
        if (!value.length) return Yup.string();
        return getStringValidator(STRING_MIN_LENGTH, STRING_MAX_LENGTH, NAME_REGEXP, error);
      });

const VALIDATION_STRING = Yup.string()
  .matches(STRING_REGEXP, validationTexts.STRING)
  .min(STRING_MIN_LENGTH, validationTexts.MIN_LENGTH(STRING_MIN_LENGTH))
  .max(STRING_MAX_LENGTH, validationTexts.MAX_LENGTH(STRING_MAX_LENGTH));

const REQUIRED_FIELD = Yup.string().required(validationTexts.REQUIRED);

const VALIDATION_CODE = Yup.string()
  .length(CODE_LENGTH, validationTexts.CODE)
  .required(validationTexts.REQUIRED);

const VALIDATION_PROFILE_PHONE = Yup.string().required(validationTexts.REQUIRED);

const VALIDATION_DATE = Yup.date()
  .min(new Date(1900, 0, 1), 'Слишком ранняя дата')
  .max(new Date(Date.now() - 567648000000), 'Вам должно быть минимум 18')
  .transform(function (value, originalValue) {
    const result = parse(originalValue, 'dd.MM.yyyy', new Date());
    return result;
  })
  .typeError('Пожалуйста, введите корректную дату');

// Forms
export const SIGNIN_WITH_PHONE = Yup.object().shape({
  phone: VALIDATION_PHONE,
});

export const SIGNIN_WITH_ONLY_EMAIL = Yup.object().shape({
  email: VALIDATION_EMAIL,
});

export const SIGNIN_WITH_EMAIL = Yup.object().shape({
  email: VALIDATION_EMAIL,
  // password: VALIDATION_PASSWORD,
});

export const RESTORE_PASSWORD_EMAIL = Yup.object().shape({
  email: VALIDATION_EMAIL,
});

export const RESTORE_PASSWORD_EMAIL_CODE = Yup.object().shape({
  email: VALIDATION_EMAIL,
  // password: VALIDATION_PASSWORD,
});

export const SIGNIN_WITH_PHONE_CODE = Yup.object().shape({
  phone: VALIDATION_PHONE,
  // password: VALIDATION_PASSWORD,
});

export const VALIDATION_USER_DATA_PROFILE = Yup.object().shape({
  name: getNameValidator(true),
  lastName: getNameValidator(true, validationTexts.INCORRECT_LASTNAME),
  secondName: getNameValidator(false, validationTexts.INCORRECT_SECONDNAME),
  email: VALIDATION_EMAIL,
  birthDate: VALIDATION_DATE,
});

export const VALIDATION_USER_FORM = Yup.object().shape({
  name: getNameValidator(true),
  lastName: getNameValidator(true, validationTexts.INCORRECT_LASTNAME),
  secondName: getNameValidator(false, validationTexts.INCORRECT_SECONDNAME),
});

export const VALIDATION_CHANGE_PASSWORD = Yup.object().shape({
  // oldPassword: VALIDATION_PASSWORD,
  newPassword: VALIDATION_PASSWORD,
  confirmationNewPassword: VALIDATION_CONFIRMATION_PASSWORD,
});

export const VALIDATION_CHANGE_USER_PHONE_CODE = Yup.object().shape({
  smsCode: REQUIRED_FIELD,
});

export const VALIDATION_CHANGE_USER_PHONE = Yup.object().shape({
  phone: VALIDATION_PROFILE_PHONE,
});

export const SIGNUP = Yup.object().shape({
  phone: VALIDATION_PHONE,
  code: VALIDATION_CODE,
});

const VALIDATION_ADDRESS_PART = Yup.string().matches(
  ADDRESS_PART_REGEXP,
  validationTexts.INCORRECT_SYMBOL
);

export const VALIDATION_ADDRESS = Yup.object().shape({
  address: Yup.string().required(validationTexts.REQUIRED),
  flat: Yup.string()
    .when('noFlat', {
      is: true,
      then: (schema) => schema,
      otherwise: (schema) => schema.required(validationTexts.FLAT_REQUIRED),
    })
    .matches(ADDRESS_PART_REGEXP, validationTexts.INCORRECT_SYMBOL),
  entrance: VALIDATION_ADDRESS_PART,
  floor: VALIDATION_ADDRESS_PART,
  entranceCode: VALIDATION_ADDRESS_PART,
  noFlat: Yup.boolean(),
  comment: Yup.string(),
});

export const VALIDATION_UPGRADE_TO_SELLER = Yup.object().shape({
  name: getNameValidator(true),
  lastName: getNameValidator(true, validationTexts.INCORRECT_LASTNAME),
  email: VALIDATION_EMAIL,
});

export const VALIDATION_COMPANY_INFORMATION = Yup.object().shape({
  registrationForm: VALIDATION_REQUIRED_STRING,
});

export const VALIDATION_COMPANY_INFORMATION_IP = Yup.object().shape({
  inn: Yup.string()
    .required(validationTexts.REQUIRED)
    .matches(/^([0-9\s,.!?;:"'`-]+)$/gm, validationTexts.NUMBER)
    .test('inn', validationTexts.INN_IP_CORRECT_LENGTH, function (value) {
      const regForm = this.resolve(Yup.ref('registrationForm'));
      const expectedLength = regForm === 'ИП' || regForm === 'Самозанятый' ? 12 : 10;
      return value.length === expectedLength;
    }),
  registrationForm: VALIDATION_REQUIRED_STRING,
  legalName: getStringValidator(STRING_MIN_LENGTH, 100),
  legalAddress: VALIDATION_LONG_STRING,
  mailingAddress: VALIDATION_LONG_STRING,
  shopName: VALIDATION_REQUIRED_STRING,
});

export const VALIDATION_COMPANY_INFORMATION_OTHER = Yup.object().shape({
  inn: Yup.string()
    .required(validationTexts.REQUIRED)
    .matches(/^([0-9\s,.!?;:"'`-]+)$/gm, validationTexts.NUMBER)
    .test('inn', validationTexts.INN_OTHER_FORM_CORRECT_LENGTH, function (value) {
      const regForm = this.resolve(Yup.ref('registrationForm'));
      const expectedLength = regForm === 'ИП' || regForm === 'Самозанятый' ? 12 : 10;
      return value.length === expectedLength;
    }),
  registrationForm: VALIDATION_REQUIRED_STRING,
  legalName: getStringValidator(STRING_MIN_LENGTH, 100),
  managementName: getStringValidator(STRING_MIN_LENGTH, 200),
  legalAddress: VALIDATION_LONG_STRING,
  mailingAddress: VALIDATION_LONG_STRING,
  shopName: VALIDATION_REQUIRED_STRING,
});

export const VALIDATION_CHECKOUT_USER = Yup.object().shape({
  name: VALIDATION_REQUIRED_STRING,
  lastName: VALIDATION_STRING,
  phone: Yup.string().required(validationTexts.REQUIRED),
  email: Yup.string()
    .min(STRING_MIN_LENGTH, validationTexts.MIN_LENGTH(STRING_MIN_LENGTH))
    .max(EMAIL_MAX_LENGTH, validationTexts.MAX_LENGTH(EMAIL_MAX_LENGTH))
    .email(validationTexts.EMAIL),
});

export const VALIDATION_CHECKOUT_RECEIVER = Yup.object().shape({
  name: VALIDATION_REQUIRED_STRING,
  phone: VALIDATION_PHONE,
});

export const VALIDATION_BONUSES = (maxAmount: number | undefined) => {
  if (!maxAmount) {
    return Yup.object().shape({
      value: Yup.number()
        .typeError(validationTexts.INCORRECT_VALUE)
        .required(validationTexts.REQUIRED)
        .positive(validationTexts.INCORRECT_VALUE)
        .integer(validationTexts.INCORRECT_VALUE),
    });
  } else {
    return Yup.object().shape({
      value: Yup.number()
        .typeError(validationTexts.INCORRECT_VALUE)
        .required(validationTexts.REQUIRED)
        .positive(validationTexts.INCORRECT_VALUE)
        .integer(validationTexts.INCORRECT_VALUE)
        .max(maxAmount, validationTexts.INCORRECT_VALUE),
    });
  }
};

export const VALIDATION_PROMOCODE = Yup.object().shape({
  value: Yup.string().required(validationTexts.REQUIRED),
});

export const VALIDATION_SUBSCRIPTIONS = Yup.object().shape({
  email: VALIDATION_EMAIL,
});

export const VALIDATION_SIMPLE_ADDRESS = Yup.string()
  .max(300, validationTexts.MAX_LENGTH(300))
  .matches(ADDRESS_REGEXP, validationTexts.INCORRECT_SYMBOL);

export const fixedNumber = (length: number) =>
  Yup.string()
    .matches(NUM_REGEX, validationTexts.NUMBER)
    .length(length, validationTexts.LENGTH(length));

export const VALIDATION_KPP = fixedNumber(9);
export const VALIDATION_OGRN = fixedNumber(13);
export const VALIDATION_BIK = fixedNumber(9);
export const VALIDATION_RS = fixedNumber(20);
export const VALIDATION_KS = fixedNumber(20);
export const VALIDATION_BANK = VALIDATION_SIMPLE_ADDRESS;
export const VALIDATION_BANK_ADDRESS = VALIDATION_SIMPLE_ADDRESS;

export const getDefaultValidator = (required?: boolean, isString = true) => {
  const string = Yup.string();
  const mixed = Yup.mixed();
  if (isString) return required ? string.required(validationTexts.REQUIRED) : string;
  else return required ? mixed.required() : mixed;
};

export const VALIDATION_COMPANY_MANAGEMENT = getStringValidator(
  STRING_MIN_LENGTH,
  200,
  STRING_REGEXP
).required();

export const customProfileValidators: Record<string, Yup.Schema> = {
  company_kpp: VALIDATION_KPP,
  company_ogrn: VALIDATION_OGRN,
};

export const VALIDATION_PRODUCT_DOC_FORM = Yup.object().shape({
  type: Yup.string().required(validationTexts.REQUIRED),
  number: Yup.string().required(validationTexts.REQUIRED),
  stopDateLock: Yup.boolean(),
  startDate: Yup.date().nullable().required(validationTexts.REQUIRED),
  stopDate: Yup.date()
    .nullable()
    .when(['stopDateLock', 'type'], ([lock, type], schema) =>
      lock || type === 'rejection_letter'
        ? schema.notRequired()
        : schema.required(validationTexts.REQUIRED)
    ),
  actualFilesKeys: Yup.array().of(Yup.string()),
  files: Yup.array()
    .of(Yup.mixed())
    .when('actualFilesKeys', ([keys], schema) =>
      keys.length ? schema.min(0) : schema.min(1, validationTexts.REQUIRED).max(10)
    ),
});

export const CAR_FORM = Yup.object().shape({
  number: Yup.string()
    .matches(CAR_NUMBER_REGEXP, validationTexts.INCORRECT_VALUE)
    .required(validationTexts.EMPTY),
  model: Yup.string()
    .matches(CAR_MODEL_REGEXP, validationTexts.INCORRECT_VALUE)
    .required(validationTexts.EMPTY),
  driverPhone: Yup.string().min(11, validationTexts.PHONE).required(validationTexts.EMPTY),
  driver: Yup.string()
    .required(validationTexts.EMPTY)
    .test('is-full-name', validationTexts.INCORRECT_NAME, (str) => {
      const splittedStr = str.split(/[-\s]/);

      for (let substr of splittedStr) {
        if (!NAME_PART_REGEXP.test(substr)) return false;
      }

      return true;
    }),
});
