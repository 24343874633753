import { IToken } from '@/types/common/common';

import api from '../instance';
import { tryAction } from '../tryAction';
import { usersUrls } from '../urls/usersUrls';

export const apiAuthCustomer = () => api.post<{ token: IToken }>(usersUrls.auth.customerAuth);

export const apiCustomerRefresh = (refreshToken: string) =>
  tryAction(
    api.post<{ token: IToken }>(
      usersUrls.auth.customerRefresh,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }
    )
  );
