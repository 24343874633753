import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';

import 'dayjs/locale/ru';

import { useUser } from './hooks/useUser';
import Auth from './pages/Auth/Auth';
import Documents from './pages/Documents/Douments';
import { Homepage } from './pages/Homepage/Homepage';
import { Info } from './pages/Info/Info';
import NotFound from './pages/NotFound/NotFound';
import { ProductDocDetails } from './pages/ProducDocDetails/ProducDocDetails';
import { ProductDocs } from './pages/ProductDocs/ProductDocs';
import Goods from './pages/Products/Products';
import Profile from './pages/Profile/Profile';
import SummaryOrders from './pages/SummaryOrders/SummaryOrders';
import { Supplies } from './pages/Supplies/Supplies';
import { SupplyDetails } from './pages/SupplyDetails/SupplyDetails';
import { setAuthStep } from './store/slices/auth/auth';
import { selectModalDataFillId } from './store/slices/modal/selectors';
import { setModalDataFill } from './store/slices/modal/slice';
import { getNotification } from './store/slices/notification/api';
import { selectUser } from './store/slices/user/slice';
import { AuthStepsEnum } from './types/api/auth';
import ModalCustom from './ui/organisms/ModalCustom/ModalCustom';
import { modalsList } from './ui/organisms/Modals/modalsList';
import NotificationModal from './ui/organisms/Notification/Notification';
import DataFill from './ui/pages/AuthPage/components/DataFill/DataFill';
import { ErrorBoundary } from './ui/pages/ErrorBoundary/ErrorBoundary';
import ProductDetailPage from './ui/pages/ProductDetailPage/ProductDetailPage';
import InfoLayout from './ui/templates/Layouts/InfoLayout/InfoLayout';
import Layout from './ui/templates/Layouts/Layout/Layout';
import LayoutAuth from './ui/templates/Layouts/LayoutAuth/LayoutAuth';
import { getTimestamp, getTimezone } from './utils/getTime';
import { useTokenRefresh } from './utils/token';
import { useOperations } from './utils/wsOperations';
import { AppDispatch } from './store';

const App: FC = () => {
  useOperations();
  useTokenRefresh();
  useUser();

  const dispatch: AppDispatch = useDispatch();
  const modalDataFillID = useSelector(selectModalDataFillId);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user?.isSeller) {
      const params = {
        limit: 2,
        timestamp: getTimestamp(),
        timezone: getTimezone(),
      };

      dispatch(getNotification(params));
      if (!user.companyInn) {
        if (!user.dateEmailSellerLoginConfirmation) {
          dispatch(setAuthStep(AuthStepsEnum.CHECK_EMAIL));
        } else {
          dispatch(setAuthStep(AuthStepsEnum.COMPANY_INFORMATION));
        }
      }
    }
    if (user?.id && !user?.isSeller) {
      dispatch(setAuthStep(AuthStepsEnum.UPGRADE_TO_SELLER_NEW));
    }
  }, [user]);

  return (
    <ErrorBoundary>
      <BrowserRouter>
        <ModalsProvider modals={modalsList}>
          <NotificationModal />
          <Notifications position="top-right" />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route element={<LayoutAuth />}>
              <Route index path="/auth" element={<Auth />} />
            </Route>
            <Route element={<Layout />}>
              <Route path="/products">
                <Route path="" element={<Goods />} />
                <Route path="create/:tab" element={<ProductDetailPage />} />
              </Route>
              <Route path="/main" element={<SummaryOrders />} />
              <Route path="/docs">
                <Route path="" element={<ProductDocs />} />
                <Route path="details/:id" element={<ProductDocDetails />} />
              </Route>
              <Route path="/profile/:tab?" element={<Profile />} />
              <Route path="/documents/:tab?" element={<Documents />} />
              <Route path="/supplies/list/:tab?" element={<Supplies />}></Route>
              <Route path={'/supplies/details/:id'} element={<SupplyDetails />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/info" element={<Info />} />
            </Route>
            <Route element={<InfoLayout />}>
              <Route path="/instruction" element={<Info />} />
            </Route>
          </Routes>
        </ModalsProvider>

        <ModalCustom
          opened={!!modalDataFillID}
          title="Профиль не активен"
          size="xs"
          onClose={() => {
            dispatch(setModalDataFill(null));
          }}
        >
          <DataFill />
        </ModalCustom>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
