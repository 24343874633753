import { ID } from '../common/common';

export enum TOperationsStatuses {
  CREATED = 'created',
  IN_PROGRESS = 'in_progress',
  ERROR = 'error',
  FINISHED = 'finished',
}

export enum TOperationsCodes {
  PRODUCTS_IMPORT = 'products_import',
  PRODUCTS_EXPORT = 'products_export',
  PRICES_IMPORT = 'prices_import',
  ATTACH_PRODUCTS = 'attach_products',
  VALIDATE_SUPPLY_PRODUCTS = 'validate_supply_products',
}

export interface IOperations {
  id: ID;
  status: TOperationsStatuses;
  code: TOperationsCodes;
  toProcessCount: number;
  successCount: number;
  errorCount: number;
  errorMessage: string;
  notificationMessage: string;
  path: string;
  title?: string;
  message?: string;
}
