import { FC } from 'react';
import { PDF_MIME_TYPE } from '@mantine/dropzone';

import { IFileInputProps } from '../../types';
import { isSingleValue, uploadFile } from '../../utils/files';
import FileItem from '../FileItem/FileItem';
import UploadFiles from '../UploadFiles/UploadFiles';

export const FileInput: FC<IFileInputProps> = ({ value, disabled, description, ...props }) =>
  isSingleValue(value) ? (
    <FileItem
      {...props}
      value={value}
      disabled={disabled}
      uploader={uploadFile}
      accept={PDF_MIME_TYPE}
    />
  ) : (
    <UploadFiles confirmed={disabled} value={value} description={description} {...props} />
  );
