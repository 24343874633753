import { Concatinable, FileResponse, Trackable } from '@/types/api/common';
import {
  GetWarehousesRequest,
  GetWarehousesSuggestionsRequest,
  SuppliesGetTabsResponse,
  SuppliesListParams,
  SuppliesListResponse,
  SupplyAcceptance,
  SupplyCancelResponse,
  SupplyConfirmRequest,
  SupplyCreateResponse,
  SupplyGetBoxesRequest,
  SupplyGetDocsResponse,
  SupplyProductsListParams,
  SupplyProductsListResponse,
  SupplySetcarsRequest,
  SupplySetPackagesRequest,
  SupplySetSlotRequest,
  SupplySlotsResponse,
  SupplyTemplatePathRaw,
  SupplyValidateRequest,
  SupplyValidateResponse,
  WarehousesListResponse,
} from '@/types/api/supplies';

import { createApi } from '@reduxjs/toolkit/dist/query/react';
import isEqual from 'lodash/isEqual';

import { baseQuery } from '@/api/instance';
import { supplyUrls } from '@/api/urls/supplyUrls';

export const suppliesApi = createApi({
  reducerPath: 'suppliesApi',
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getTabs: builder.query<SuppliesGetTabsResponse, void>({
      query: () => ({
        url: supplyUrls.getTabs,
        method: 'GET',
        options: {
          getError: (err) => {
            if (err.response?.status === 403) return 'Вам недоступен этот раздел личного кабинета';
            return;
          },
        },
      }),
    }),
    getWarehousesSuggestions: builder.query<
      WarehousesListResponse,
      GetWarehousesSuggestionsRequest | void
    >({
      query: (args) => ({
        url: supplyUrls.getWarehouses,
        params: {
          region: args?.region ?? '',
        },
      }),
    }),
    getWarehouses: builder.query<WarehousesListResponse, GetWarehousesRequest>({
      query: ({ tab, region }) => ({
        url: supplyUrls.getWarehouses,
        params: {
          tab,
          region,
        },
      }),
    }),
    getSupplies: builder.query<SuppliesListResponse, Concatinable<SuppliesListParams>>({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ({ concat, ...params }) => ({
        url: supplyUrls.getSupplies,
        params,
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      forceRefetch: ({ currentArg, previousArg }) => !isEqual(currentArg, previousArg),
      merge: (cache, data, { arg: { concat } }) => {
        if (concat) return { ...cache, items: cache.items.concat(data.items) };
        return data;
      },
    }),
    getTemplateUrl: builder.query<string, void>({
      query: () => supplyUrls.getTemplate,
      transformResponse: (res: SupplyTemplatePathRaw) => res.path,
    }),
    getSlots: builder.query<SupplySlotsResponse, string>({
      query: (id) => ({
        url: supplyUrls.getSlots(id),
        method: 'GET',
      }),
    }),
    getProducts: builder.query<FileResponse, string>({
      query: (id) => ({
        method: 'POST',
        url: supplyUrls.downloadProducts(id),
        responseType: 'blob',
      }),
      transformResponse: (file: Blob, meta) => ({
        file,
        name: meta?.headers?.['content-disposition']?.split('=').pop(),
      }),
    }),
    getDocuments: builder.query<SupplyGetDocsResponse, SupplyGetBoxesRequest>({
      query: ({ id, ...params }) => ({
        method: 'POST',
        url: supplyUrls.getDocs(id),
        data: params,
        responseType: 'blob',
      }),
      transformResponse: (file: Blob, meta) => ({
        file,
        name: meta?.headers?.['content-disposition']?.split('=').pop(),
      }),
    }),
    getAcceptance: builder.query<SupplyAcceptance, string>({
      query: (id) => supplyUrls.getAcceptance(id),
    }),
    getAcceptanceProducts: builder.query<
      SupplyProductsListResponse,
      Concatinable<SupplyProductsListParams>
    >({
      query: ({ id, ...params }) => ({
        method: 'POST',
        url: supplyUrls.getAcceptProducts(id),
        data: params,
      }),
      serializeQueryArgs: ({ endpointName }) => endpointName,
      forceRefetch: ({ currentArg, previousArg }) => !isEqual(currentArg, previousArg),
      merge: (cache, data, { arg: { concat } }) => {
        if (concat) return { ...cache, products: cache.products.concat(data.products) };
        return data;
      },
    }),
    getAcceptanceDoc: builder.query<FileResponse, string>({
      query: (id) => ({
        url: supplyUrls.getAcceptDoc(id),
        method: 'GET',
        responseType: 'blob',
      }),
      transformResponse: (file: Blob, meta) => ({
        file,
        name: meta?.headers?.['content-disposition']?.split('=').pop(),
      }),
    }),
    confirm: builder.mutation<void, SupplyConfirmRequest>({
      query: ({ id, accepted }) => ({
        url: supplyUrls.confirm(id),
        params: { accepted },
        method: 'PATCH',
      }),
    }),
    validateProducts: builder.mutation<SupplyValidateResponse, Trackable<SupplyValidateRequest>>({
      query: ({ file, warehouseId, onUploadProgress }) => {
        const data = new FormData();
        data.append('file', file);

        return {
          method: 'POST',
          url: supplyUrls.validate,
          params: { warehouseId },
          data,
          options: { onUploadProgress },
        };
      },
    }),
    createSupply: builder.mutation<SupplyCreateResponse, string>({
      query: (operationId) => ({
        method: 'POST',
        url: supplyUrls.create,
        params: { operationId },
      }),
    }),
    setSlot: builder.mutation<void, SupplySetSlotRequest>({
      query: ({ slot, id }) => ({
        method: 'PATCH',
        url: supplyUrls.setSlot(id),
        data: slot,
      }),
    }),
    setPackages: builder.mutation<void, SupplySetPackagesRequest>({
      query: ({ id, packages }) => ({
        method: 'PATCH',
        url: supplyUrls.setPackages(id),
        data: { packages },
      }),
    }),
    setCars: builder.mutation<void, SupplySetcarsRequest>({
      query: ({ id, cars }) => ({
        method: 'PATCH',
        url: supplyUrls.setCars(id),
        data: { cars },
      }),
    }),
    cancelSupply: builder.mutation<SupplyCancelResponse, string>({
      query: (id) => ({
        method: 'PATCH',
        url: supplyUrls.cancelSupply(id),
      }),
    }),
  }),
});
