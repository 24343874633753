import isString from 'lodash/isString';

import { trycatch } from './trycatch';

interface IOptions extends Partial<Intl.DateTimeFormatOptions> {}

export const dateFromString = (str?: string) => {
  if (!str) return null;
  const [day, month, year] = str.split('.').map((part) => Number(part));
  const date = new Date(year, month - 1, day);

  return date;
};

export const formatDate = (date: string | Date, opts?: IOptions): string => {
  const defaultOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const res = trycatch(
    () => {
      const dateToFormat = isString(date) ? dateFromString(date) : date;
      return new Intl.DateTimeFormat('ru-RU', { ...defaultOptions, ...opts }).format(
        dateToFormat || new Date()
      );
    },
    { fallback: '', append: `recieved: ${date}` }
  );

  return res;
};

export const formatProductImgDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  };
  if (dateString.includes(', ')) {
    const [datePart, timePart] = dateString.split(', ');
    const [day, month, year] = datePart.split('.');
    const date = new Date(`${year}-${month}-${day}T${timePart}`);

    return new Intl.DateTimeFormat('ru-RU', options).format(date);
  } else {
    const [day, month, year] = dateString.split('.');
    const date = new Date(`${year}-${month}-${day}`);

    return new Intl.DateTimeFormat('ru-RU', options).format(date);
  }
};
