import { InputModeType } from '@/types/common/common';

import { ClipboardEventHandler, FC, FocusEvent, useMemo, useState } from 'react';
import {
  ActionIcon,
  Box,
  Input,
  PasswordInput,
  PasswordInputProps,
  TextareaProps,
  TextInput,
  TextInputProps,
  useMantineTheme,
} from '@mantine/core';
import { noop } from '@mantine/utils';
import { X } from 'tabler-icons-react';

import { useStyles } from './styles';

import { ReactComponent as IconEyeCross } from '@/assets/icons/redesign/iconEyeCross.svg';
import { ReactComponent as IconEyeOpen } from '@/assets/icons/redesign/iconEyeOpen.svg';
import { ReactComponent as IconLock } from '@/assets/icons/redesign/iconLock.svg';

interface RightSectionProps {
  disabled: boolean;
  clearable: boolean;
  onClear?: () => void;
}

const RightSection: FC<RightSectionProps> = ({ disabled, clearable, onClear }) => {
  const theme = useMantineTheme();
  return disabled ? (
    <IconLock size={24} />
  ) : clearable ? (
    <ActionIcon onClick={onClear}>
      <X size={20} color={theme.colors.brandDark[9]} />
    </ActionIcon>
  ) : null;
};

export type TInputTypes = 'text' | 'password' | 'textarea' | 'input';

export type TInputWithLabelProps<T extends TInputTypes> = {
  type?: T;
  inputtype?: T;
  description?: string;
  label: string;
  clearable?: boolean;
};

export type TInputPropsMap = {
  text: TextInputProps;
  password: PasswordInputProps;
  textarea: TextareaProps;
  input: TextInputProps;
  trimOnPaste?: boolean;
  inputMode?: InputModeType;
};

type TInputWithLabel = <T extends TInputTypes = 'text'>(
  props: TInputWithLabelProps<T> & TInputPropsMap[T] & Record<string, any>
) => JSX.Element;

const InputWithLabel: TInputWithLabel = ({
  label,
  size = 'xs',
  onFocus = noop,
  onBlur = noop,
  clearable,
  trimOnPaste = true,
  inputMode,
  ...props
}) => {
  const { tooltipText, ...textInputProps } = props;
  const [focused, setFocused] = useState(false);
  const { classes } = useStyles({
    floating: (props.value && props.value.toString().trim().length !== 0) || focused,
    focus: focused,
    disabled: props.disabled || false,
    size: (size === 'md' && 48) || (size === 'lg' && 60) || (size === 'xl' && 140) || 38,
    top: size === 'md' ? 24 : 30,
    fontSize: size === 'lg' ? 16 : 14,
    error: props.error,
  });

  const focusHandler = (e: FocusEvent<any>, state: boolean) => {
    setFocused(state);
    if (state) onFocus(e);
    else onBlur(e);
  };

  const handleClear = () => {
    props.onChange?.('');
  };

  const pasteHandler: ClipboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    e.preventDefault();
    const pastedValue = e.clipboardData.getData('text');
    const newValue = trimOnPaste ? pastedValue.trim() : pastedValue;
    // e.clipboardData.setData('text', newValue);

    e.currentTarget.value = newValue;
    props.onChange?.(e);
  };

  const isShowClerButton = useMemo(
    () => !!(props.value?.length > 0 && clearable),
    [clearable, props.value]
  );

  return (
    <>
      {props.inputtype === 'password' && (
        <Box className={classes.root}>
          <PasswordInput
            label={label}
            classNames={{
              ...classes,
              innerInput: classes.input,
            }}
            visibilityToggleIcon={({ reveal }) => (reveal ? <IconEyeOpen className={classes.icon} /> : <IconEyeCross />)}
            onFocus={(e) => focusHandler(e, true)}
            onBlur={(e) => focusHandler(e, false)}
            onPaste={pasteHandler}
            {...props}
          />
        </Box>
      )}

      {(props.type === 'text' || !props.type) && !props.inputtype && (
        <Box className={classes.root}>
          <TextInput
            wrapperProps={{ 'data-tooltip-text': tooltipText }}
            label={label}
            classNames={classes}
            onFocus={(e) => focusHandler(e, true)}
            onBlur={(e) => focusHandler(e, false)}
            onPaste={pasteHandler}
            inputMode={inputMode}
            rightSection={
              <RightSection
                disabled={props.disabled || false}
                clearable={isShowClerButton}
                onClear={handleClear}
              />
            }
            {...textInputProps}
          />
        </Box>
      )}

      {props.type === 'textarea' && (
        <Box>
          <Input.Wrapper
            label={label}
            classNames={{ label: classes.label, root: classes.textareaWrapper }}
          >
            <Input
              autosize="true"
              component="textarea"
              classNames={{
                input: classes.textarea,
                wrapper: classes.textareaInputWrapper,
              }}
              onFocus={(e) => focusHandler(e, true)}
              onBlur={(e) => focusHandler(e, false)}
              error={props.error}
              onPaste={pasteHandler}
              inputMode="text"
              rightSection={
                <RightSection
                  disabled={props.disabled || false}
                  clearable={isShowClerButton}
                  onClear={handleClear}
                />
              }
              {...props}
            />
          </Input.Wrapper>
          {props.error && <Input.Error mt={5}>{props.error}</Input.Error>}
        </Box>
      )}

      {props.type === 'input' && (
        <Input.Wrapper classNames={classes} label={label}>
          <Input<any>
            onFocus={(e: FocusEvent<HTMLInputElement>) => focusHandler(e, true)}
            onBlur={(e: FocusEvent<HTMLInputElement>) => focusHandler(e, false)}
            classNames={{ input: classes.input }}
            inputMode={inputMode}
            rightSection={
              <RightSection
                disabled={props.disabled || false}
                clearable={isShowClerButton}
                onClear={handleClear}
              />
            }
            onPaste={pasteHandler}
            {...props}
          />
        </Input.Wrapper>
      )}
    </>
  );
};

export default InputWithLabel;
