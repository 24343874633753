import { FC } from "react";
import { Tabs } from "@mantine/core";

import { documentsApi } from "@/store/slices/documents/api";

const DocumentsTabs: FC = () => {
    const { data: types } = documentsApi.useGetDocumentTypesQuery();

    return (
        <Tabs.List>
            {types?.items?.map((type) => (
                <Tabs.Tab
                    value={type.code}
                    key={type.code}
                >
                    {type.name}
                </Tabs.Tab>
            ))}
        </Tabs.List>
    )
}

export default DocumentsTabs;