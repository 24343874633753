import { FC, useState } from 'react';
import { Box } from '@mantine/core';
import { ActionIcon, Popover, useMantineTheme } from '@mantine/core';
import { Text } from '@mantine/core';

import { useStyles } from './styles';

import { ReactComponent as IconInfo } from '@/assets/icons/redesign/iconInfo.svg'

interface StatusInfoButtonProps {
  tooltip: string;
}

export const StatusInfoButton: FC<StatusInfoButtonProps> = ({ tooltip }) => {
  const { classes } = useStyles();
  const { colors } = useMantineTheme();
  const [opened, setOpened] = useState(false);


  return (
    <Popover opened={opened} position='bottom' onChange={setOpened}>
      <Popover.Target>
        <ActionIcon onClick={() => setOpened(!opened)}>
          <IconInfo fill={colors.blue[6]} stroke="white" />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown maw={300} className={classes.wrapper}>
        <Box pos="relative">
          <Text size={14} lh={'19px'}>{tooltip}</Text>
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};
