import { FC, ReactNode } from 'react';

interface ILoaderIconProps {
  children: ReactNode;
  flood: string;
  width: number;
  height: number;
  progress: number;
  id: string;
}

const LoaderIcon: FC<ILoaderIconProps> = ({ children, flood, width, height, progress, id }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
      <defs>
        <filter
          id={`fullFill${id.toLowerCase()}`}
          primitiveUnits="objectBoundingBox"
          x="0%"
          y="0%"
          width="100%"
          height="100%"
        >
          {progress > 0 && (
            <feFlood x="0%" y="0%" width={`${progress}%`} height="100%" floodColor={flood} />
          )}
          <feOffset>
            <animate attributeName="dx" from="0" to="1" />
          </feOffset>
          <feComposite operator="in" in2="SourceGraphic" />
          <feComposite operator="over" in2="SourceGraphic" />
        </filter>
      </defs>
      <g filter={`url(#fullFill${id.toLowerCase()})`}>{children}</g>
    </svg>
  );
};

export default LoaderIcon;
