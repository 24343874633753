import { TFile } from '@/types/api/profile';
import { IProudctField } from '@/types/entites/product';

import { FC, useEffect, useState } from 'react';
import { IMAGE_MIME_TYPE } from '@mantine/dropzone';

import CustomSwitch from '@/ui/atoms/CustomSwitch/CustomSwitch';
import { EnhancedInput } from '@/ui/atoms/EnhancedInput/EnhancedInput';
import FormGroup from '@/ui/atoms/FormGroup/FormGroup';
import { Select } from '@/ui/atoms/Select/Select';
import SelectCustom from '@/ui/atoms/SelectCustom/SelectCustom';
import FileItem from '@/ui/pages/ProfilePage/components/FileItem/FileItem';

import { FieldType } from '../../types';
import { getTransformedOptions } from '../../utils/getTransformedOptions';
import { useProductContext } from '../../utils/ProductContext';

import { productsApi } from '@/store/slices/products/api';

interface IProductRenderFIeldProps {
  field: IProudctField;
  fieldValues: { [key: string]: string };
  handleFieldChange: (code: string, value: string) => void;
  error?: string;
}

interface ExtendedTFile extends TFile {
  url?: string;
}

const photoDescription = [
  'Является основным фото.',
  'Если его нет, то основным будет считаться фронтальная проекция',
];

const ProductRenderFIeld: FC<IProductRenderFIeldProps> = ({
  field,
  fieldValues,
  handleFieldChange,
  error,
}) => {
  const { data } = useProductContext();
  const [postFormProductImages] = productsApi.usePostFormProductImagesMutation();
  const [fileState, setFileState] = useState<ExtendedTFile | null>(null);

  const handleSelectMultuplyChange = (value: string | string[]) => {
    if (Array.isArray(value)) handleFieldChange(field.code, value.join(','));
    else handleFieldChange(field.code, value);
  };

  const handleFileChange = (code: string, value: string) => {
    handleFieldChange(field.code, value);
  };

  const uploadProduct = async ({ formId, files }: { formId: string; files: File[] }) => {
    const file = files[0];
    const url = URL.createObjectURL(file);

    setFileState({
      id: formId,
      originalName: file.name,
      extension: file.type,
      size: file.size.toString(),
      date: new Date().toLocaleString(),
      imageUrl: url,
    });

    return postFormProductImages({ formId, files }).unwrap();
  };

  useEffect(() => {
    const savedImages = localStorage.getItem('uploadedImages');
    if (savedImages) {
      const imagesFromStorage = JSON.parse(savedImages);
      setFileState(imagesFromStorage.find((img: any) => img.fieldCode === field.code));
    }
  }, [field.code]);

  useEffect(() => {
    if (fileState) {
      const existingImages = JSON.parse(localStorage.getItem('uploadedImages') || '[]');
      const otherImages = existingImages.filter((img: any) => img.fieldCode !== field.code);
      const newImages = [...otherImages, { ...fileState, fieldCode: field.code }];
      localStorage.setItem('uploadedImages', JSON.stringify(newImages));
    }
  }, [fileState, field.code]);

  useEffect(() => {
    if (field.fileData) {
      setFileState({
        id: field.fileData.id.toLocaleString(),
        originalName: field.fileData.originalName,
        extension: field.fileData.extension,
        size: field.fileData.size,
        date: field.fileData.date,
        imageUrl: field.fileData.downloadLink,
      });
    }
  }, [field.fileData]);

  switch (field.type) {
    case FieldType.MULTI_SELECT:
      return (
        <Select
          key={field.code}
          label={field.title}
          data={getTransformedOptions(field.options)}
          value={
            field.value
              ? field.value.split(',')
              : fieldValues[field.code]
                ? fieldValues[field.code].split(',')
                : []
          }
          onChange={(value) => handleSelectMultuplyChange(String(value))}
          error={error}
          disabled={field.disabled}
          clearable
          multiple
        />
      );
    case FieldType.SELECT:
      return (
        <SelectCustom
          key={field.code}
          label={field.title}
          options={getTransformedOptions(field.options)}
          optionValue={fieldValues[field.code] || ''}
          onChange={(value) => handleFieldChange(field.code, value || '')}
          required={field.required}
          withAsterisk={field.required}
          error={error}
          disabled={field.disabled}
          defaultValue={field.value || fieldValues[field.code] || ''}
        />
      );
    case FieldType.BOOLEAN:
      return (
        <CustomSwitch
          label={field.title}
          checked={fieldValues[field.code] === 'true' || field.value === 'true'}
          onChange={(checked) => handleFieldChange(field.code, String(checked))}
          disabled={field.disabled}
          error={error}
          value={field.value}
        />
      );
    case FieldType.IMAGE:
      return (
        <FileItem
          id={String(data?.id)}
          key={field.code}
          label={field.title}
          value={fileState}
          onChange={(value) => handleFileChange(field.code, value?.originalName || '')}
          type="file"
          required={field.required}
          disabled={field.disabled}
          uploaderQuery={uploadProduct}
          accept={IMAGE_MIME_TYPE}
          topDescription={field.code === 'products_images_3' ? photoDescription : ''}
          typeComponent={'image'}
        />
      );
    default:
      if (field.code === 'supplierProductId') {
        return (
          <>
            <FormGroup spacing={26}>
              <EnhancedInput
                label={'Категория товара'}
                tooltipPosition={'label'}
                labelType="floating"
                clearable
                size="md"
                disabled={true}
                defaultValue={data?.categoryLabel}
              />
            </FormGroup>
            <EnhancedInput
              key={field.code}
              label={field.title}
              tooltipText={field.tooltip}
              tooltipPosition={'label'}
              required={field.required}
              onChange={(event) => handleFieldChange(field.code, event.target.value)}
              labelType="floating"
              clearable
              error={error}
              size="md"
              disabled={field.disabled}
              defaultValue={field.value || fieldValues[field.code] || ''}
            />
          </>
        );
      }
      return (
        <EnhancedInput
          key={field.code}
          label={field.title}
          tooltipText={field.tooltip}
          tooltipPosition={'label'}
          required={field.required}
          onChange={(event) => handleFieldChange(field.code, event.target.value)}
          labelType="floating"
          clearable
          error={error}
          size="md"
          disabled={field.disabled}
          defaultValue={field.value || fieldValues[field.code] || ''}
        />
      );
  }
};

export default ProductRenderFIeld;
