import { AuthStepsEnum } from "@/types/api/auth";

export const getBackStep = (step: AuthStepsEnum): AuthStepsEnum => {
  switch (step) {

    case AuthStepsEnum.SIGNIN_WITH_PHONE:
      return AuthStepsEnum.SIGNIN_WITH_ONLY_EMAIL;
    case AuthStepsEnum.SIGNIN_WITH_EMAIL:
      return AuthStepsEnum.SIGNIN_WITH_ONLY_EMAIL;
    case AuthStepsEnum.RESTORE_PASSWORD_EMAIL:
      return AuthStepsEnum.SIGNIN_WITH_EMAIL;
    case AuthStepsEnum.RESTORE_PASSWORD_EMAIL_CODE:
      return AuthStepsEnum.RESTORE_PASSWORD_EMAIL;
    case AuthStepsEnum.SIGNIN_WITH_PHONE_CODE:
      return AuthStepsEnum.SIGNIN_WITH_ONLY_EMAIL;
    case AuthStepsEnum.RESTORE_PASSWORD_PHONE_CODE:
      return AuthStepsEnum.SIGNIN_WITH_ONLY_EMAIL;
    case AuthStepsEnum.SIGNUP:
      return AuthStepsEnum.SIGNIN_WITH_ONLY_EMAIL;
    case AuthStepsEnum.CHECK_EMAIL:
      return AuthStepsEnum.UPGRADE_TO_SELLER;
    case AuthStepsEnum.COMPANY_INFORMATION:
      return AuthStepsEnum.UPGRADE_TO_SELLER;
    case AuthStepsEnum.UPGRADE_TO_SELLER:
    case AuthStepsEnum.UPGRADE_TO_SELLER_NEW:
      return AuthStepsEnum.SIGNIN_WITH_ONLY_EMAIL;
    default:
      return AuthStepsEnum.SIGNIN_WITH_ONLY_EMAIL;
  }
};
