import { FC } from "react";
import { Grid, Text, useMantineTheme } from "@mantine/core";
import { Box } from "@mantine/core";
import { ContextModalProps } from "@mantine/modals";

import { ModalLayout } from "@/ui/templates/Layouts/ModaLayout/ModalLayout";

import { ReactComponent as EansIcon } from '@/assets/icons/eans.svg';

export interface IProductEans {
    id?: string,
    eans: string[]
}

const ProductEans: FC<ContextModalProps<IProductEans>> = ({
    innerProps: { eans },
    ...modalProps
}) => {
    const theme = useMantineTheme();

    return (
        <ModalLayout title="Штрихкод товара" {...modalProps}>
            <Box pt={20} mx={'-24px'}>
                {eans?.length > 0 &&
                    eans?.map((item, index: number) => (
                        <Grid
                            key={index}
                            maw={'100%'}
                            p={'26px 32px'}
                            sx={{ borderBottom: `1px solid ${theme.colors.brandGrey[3]}` }}
                        >
                            <EansIcon />
                            <Text ml={12} color={theme.colors.text[7]}>{item}</Text>
                        </Grid>
                    ))}
            </Box>
        </ModalLayout>
    )
}

export default ProductEans;