import { SupplyAcceptance } from '@/types/api/supplies';

export const getCommoninfo = (acceptance: SupplyAcceptance): [string, string][] => [
  ['Статус акта', acceptance.status],
  ['Номер заявки', acceptance.number],
  ['Дата создания заявки', acceptance.dateCreate.replace(/-/g, '.')],
  ['Дата и время поставки', acceptance.supplyDate],
  ['Склад поставки', acceptance.warehouse],
];

export const getProductsInfo = (acceptance: SupplyAcceptance): [string, string][] => [
  ['Заявлено', `${acceptance.summary.all}`],
  ['Принято на склад', `${acceptance.summary.accepted}`],
  ['Излишки', `${acceptance.summary.surplus}`],
  ['Недостача', `${acceptance.summary.shortage}`],
  ['Брак', `${acceptance.summary.defect}`],
];
