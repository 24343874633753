import { FC, useEffect, useState } from 'react';

import useIsSellerStatusActive from '@/hooks/useIsSellerStatusActive';

import Dropdown from '@/ui/atoms/Dropdown/Dropdown';
import { openCreateProduct, openProductUpload } from '@/ui/organisms/Modals/modals';

type LinkChild = {
  name: string;
  onClick: () => void;
  disabled?: boolean;
};

type Link = {
  name: string;
  children: LinkChild[];
};

const AddPropduct: FC = () => {
  const [links, setLinks] = useState<Link[]>([]);
  const isActiveSeller = useIsSellerStatusActive();
  // const fetchingImport = useSelector(selectFetchingImport);

  useEffect(() => {
    setLinks([
      {
        name: 'Добавить товары',
        children: [
          {
            name: 'Создать вручную',
            onClick: () => openCreateProduct({}),
            disabled: !isActiveSeller,
          },
          { name: 'Загрузить шаблоном', onClick: () => openProductUpload({}) },
        ],
      },
    ]);
  }, [isActiveSeller]);

  return <Dropdown links={links} />;
};

export default AddPropduct;
