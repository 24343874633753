import { IOperations, TOperationsCodes, TOperationsStatuses } from '@/types/entites/template';

import { FC, useCallback, useState } from 'react';
import { Box, Button, Group, Loader, Text } from '@mantine/core';
import { ContextModalProps, modals } from '@mantine/modals';

import { downloadFromUrl } from '@/utils/downloadFromUrl';
import { pluralize } from '@/utils/pluralize';
import { useOperation } from '@/utils/wsOperations';

import { FileInput } from '@/ui/organisms/FileInput/FileInput';
import ModalStep from '@/ui/organisms/ModalCustom/components/ModalStep/ModalStep';
import { ModalLayout } from '@/ui/templates/Layouts/ModaLayout/ModalLayout';

import ModalFooter from '../ModalFooter/ModalFooter';
import { openAddProductsToSupplyModal, openFailModal, openSuccessModal } from '../modals';

import { suppliesApi } from '@/store/slices/supplies/slice';

export interface AddProductsToSupplyProps {
  warehouseId: string;
  onRefetch?: () => void;
}

type AbortableRequest = {
  abort: () => void;
};

export const AddProductsToSupply: FC<ContextModalProps<AddProductsToSupplyProps>> = ({
  context,
  id,
  innerProps: { warehouseId, onRefetch },
}) => {
  const [opId, setOpId] = useState<string | null>(null);
  const [isValidation, setIsValidation] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [getTemplatePath, { isFetching: isTemplateFetching, isError }] =
    suppliesApi.useLazyGetTemplateUrlQuery();
  const [createSupply] = suppliesApi.useCreateSupplyMutation();
  const [req, setReq] = useState<AbortableRequest | null>(null);

  const handleOperationChange = useCallback(
    (e: IOperations) => {
      if (e.id !== opId) return;
      modals.close(id);
      setIsValidation(false);
      if (e.status === TOperationsStatuses.FINISHED && !e.errorCount)
        openSuccessModal({
          title: 'Успешно',
          body: 'Все товары из XLSХ-файла прошли проверку',
          buttonText: 'Сохранить в заявку',
          onSubmit: async () => {
            await createSupply(opId);
            onRefetch?.();
          },
        });
      else {
        const showProcessedCount =
          e.toProcessCount !== 0 || e.errorCount !== 0 || e.successCount !== 0;

        const body = showProcessedCount
          ? `Успешно прошли проверку ${e.successCount}\u00A0${pluralize(e.successCount, [
            'товар',
            'товара',
            'товаров',
          ])}\u00A0из\u00A0${e.toProcessCount}`
          : e.errorMessage;

        openFailModal({
          title: 'Ошибка!',
          body,
          buttonText: 'Попробовать еще раз',
          onSubmit: () => openAddProductsToSupplyModal({ warehouseId, onRefetch }),
          errorReportPath: e.path,
          errorReportLabel: 'Скачать файл с ошибками',
        });
      }
    },
    [opId]
  );

  useOperation({
    id: opId || undefined,
    code: TOperationsCodes.VALIDATE_SUPPLY_PRODUCTS,
    onOperationChange: handleOperationChange,
  });

  const [validate, { isLoading: isUploading }] = suppliesApi.useValidateProductsMutation();

  const [file, setFile] = useState<File | null>(null);

  const handleDownloadTemplate = async () => {
    const path = await getTemplatePath().unwrap();
    downloadFromUrl(path);
  };

  const handleSubmit = async () => {
    if (!file) return;

    setUploadProgress(null);

    try {
      const request = validate({
        file,
        warehouseId,
        onUploadProgress: setUploadProgress,
      });
      setReq(request);
      const { operationId } = await request.unwrap();
      setUploadProgress(null);
      setIsValidation(true);
      setOpId(operationId);
    } catch {
      setIsValidation(false);
      setUploadProgress(null);
      setOpId(null);
    }
  };

  return (
    <ModalLayout
      {...{ context, id }}
      title="Заявка на поставку"
      loading={!isUploading && isValidation}
    >
      <Box ml={-24} mr={-24}>
        <ModalStep titleSub='Шаг 1. Скачайте шаблон и заполните его' borderBottom>
          <Text mb={24} size={14}>
            Укажите, какие товары вы планируете привезти и в каком количестве
          </Text>
          <Button size="md" onClick={handleDownloadTemplate} w={180}>
            <Group position="center">
              {isTemplateFetching ? <Loader size={24} color="white" /> : 'Скачать шаблон'}
            </Group>
          </Button>
        </ModalStep>
        <ModalStep titleSub='Шаг 2. Загрузите заполненный шаблон'>
          <Box mt={20}>
            <FileInput
              required
              onChange={setFile}
              size={10000000}
              title="Заполненный шаблон"
              description="Файл не более 10 Мб в формате XLSX"
              type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              mb={48}
              error={isError ? 'Ошибка загрузки, попробуйте снова' : null}
              customErrors={{
                'invalid-extension': 'Загрузить можно только XLSX-документ',
                'file-too-large': 'Размер файла слишком большой. Можно загрузить только 10 МБ',
              }}
              uploadProgress={uploadProgress}
              isUploading={isUploading}
              onAbort={req?.abort}
              direction='row'
            />
          </Box>
        </ModalStep>
      </Box>
      <ModalFooter align='center' variant='xs'>
        <Button
          size="md"
          onClick={handleSubmit}
          w="100%"
          maw={280}
          fz={14}
          h={48}
          disabled={!file || isUploading || isValidation}
        >
          {isUploading ? <Loader size={24} color="white" /> : 'Продолжить'}
        </Button>
      </ModalFooter>
    </ModalLayout>
  );
};
