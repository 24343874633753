import { ComponentProps } from 'react';
import InputMask, { InputMaskProps } from 'react-input-mask';

import { EnhancedInput } from '@/ui/atoms/EnhancedInput/EnhancedInput';

type Props = InputMaskProps<ComponentProps<typeof EnhancedInput>>;

export const MaskedInput = ({
  mask,
  beforeMaskedValueChange,
  inputRef,
  alwaysShowMask,
  formatChars,
  maskChar,
  onChange,
  onBlur,
  onPaste,
  onMouseDown,
  onFocus,
  value,
  disabled,
  readOnly,
  inputMode,
  ...rest
}: Props) => (
  <InputMask
    {...{
      mask,
      beforeMaskedValueChange,
      inputRef,
      alwaysShowMask,
      formatChars,
      onChange,
      maskChar,
      onBlur,
      onPaste,
      onMouseDown,
      onFocus,
      value,
      disabled,
      readOnly,
    }}
  >
    {(props) => {
      return <EnhancedInput {...rest} {...props} disabled={disabled} inputMode={inputMode} tooltipLabel  />;
    }}
  </InputMask>
);
