import { FC, PropsWithChildren } from "react";
import { Box } from "@mantine/core";

const PageBody: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Box sx={{position: 'relative'}}>
            {children}
        </Box>
    )
}

export default PageBody;