import { ID } from '@/types/common/common';
import { IOperations } from '@/types/entites/template';

import { ReactNode } from 'react';
import { modals } from '@mantine/modals';

import { IChooseCategory } from '@/ui/organisms/Modals/ChooseCategory/ChooseCategory';
import { IProductEans } from '@/ui/organisms/Modals/ProductEans/ProductEans';
import { IProductToArchive } from '@/ui/organisms/Modals/ProductToArchive/ProductToArchive';

import { AddProductsToSupplyProps } from './AddProductsToSupply/AddProductsToSupply';
import { ChangeSupplyWarehouseProps } from './ChangeSupplyWarehouse/ChangeSupplyWarehouse';
import { ErrorModalProps } from './Error/Error';
import { PreviewModalProps } from './PreviewProduct/PreviewProduct';
import { SetSupplyCarProps } from './SetSupplyCar/SetSupplyCar';
import { ModalSuccessProps } from './Success/ModalSuccess';
import { SupplyPackagesProps } from './SupplyPackages/SupplyPackages';
import { SupplySlotModalProps } from './SupplySlot/SupplySlot';

type ModalProps<T extends Object> = {
  onClose?: () => void;
} & T;

export const openConfirmRemoveModal = (id: ID, onConfirm?: () => void) => {
  modals.openContextModal({
    modal: 'confirmRemove',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    size: 400,
    styles: { body: { padding: 0 } },
    innerProps: { docId: id, onConfirm },
  });
};

interface AddDocModalArgs {
  id?: ID;
  onSubmit?: () => void;
}

export const openRequestErrorModal = (message: string) => {
  modals.closeAll();
  modals.openContextModal({
    modal: 'errorModal',
    withCloseButton: false,
    radius: 'lg',
    styles: { body: { padding: 0 } },
    innerProps: { title: 'Ошибка', body: message, buttonText: 'Закрыть', displayIcon: false },
  });
};

export const openAddDocModal = (args?: AddDocModalArgs) => {
  modals.openContextModal({
    modal: 'addDocument',
    withCloseButton: false,
    closeOnEscape: true,
    fullScreen: true,
    radius: 'lg',
    size: '100%',
    styles: { body: { padding: 0 } },
    innerProps: { docId: args?.id, onSubmit: args?.onSubmit },
  });
};

export const openAddProductModal = (docId: number) =>
  modals.openContextModal({
    modal: 'addProduct',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    size: 800,
    styles: { body: { padding: 0 } },
    innerProps: { docId },
  });

export const openSuccessModal = ({ onClose, ...props }: ModalProps<ModalSuccessProps>) =>
  modals.openContextModal({
    modal: 'success',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    size: 400,
    onClose,
    styles: { body: { padding: 0 } },
    innerProps: props,
  });

export const openFailModal = ({ onClose, ...props }: ModalProps<ErrorModalProps>) =>
  modals.openContextModal({
    modal: 'errorModal',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    size: 400,
    onClose,
    styles: { body: { padding: 0 } },
    innerProps: props,
  });

export const openErrorModal = (
  id: string,
  uploaded: number,
  total: number,
  path: string,
  error: string
) =>
  modals.openContextModal({
    modal: 'fail',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    size: 400,
    styles: { body: { padding: 0 } },
    innerProps: {
      id,
      uploaded,
      total,
      path,
      error,
    },
  });

export const openErrorProuctsModal = (
  operation: IOperations,
  title?: ReactNode,
  body?: ReactNode,
  path?: string,
  error?: string,
  onClose?: () => void
) =>
  modals.openContextModal({
    modal: 'failProducts',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    size: 400,
    onClose,
    styles: { body: { padding: 0 } },
    innerProps: {
      operation,
      title,
      body,
      path,
      error,
    },
  });

export const openChangeWarehouseModal = ({
  onClose,
  ...props
}: ModalProps<ChangeSupplyWarehouseProps>) =>
  modals.openContextModal({
    modal: 'selectWarehouse',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    onClose,
    styles: { body: { padding: 0 } },
    size: 800,
    innerProps: props,
  });

export const openAddProductsToSupplyModal = ({
  onClose,
  ...props
}: ModalProps<AddProductsToSupplyProps>) => {
  modals.openContextModal({
    modal: 'addProductsToSupply',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    onClose,
    styles: { body: { padding: 0 } },
    size: 800,
    innerProps: props,
  });
};

export const openSupplySlots = ({ onClose, ...props }: ModalProps<SupplySlotModalProps>) => {
  modals.openContextModal({
    modal: 'supplySlot',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    onClose,
    styles: { body: { padding: 0 } },
    size: 422,
    innerProps: props,
  });
};

export const openSupplyPackages = ({ onClose, ...props }: ModalProps<SupplyPackagesProps>) => {
  modals.openContextModal({
    modal: 'supplyPackages',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    onClose,
    styles: { body: { padding: 0 } },
    size: 422,
    innerProps: props,
  });
};

export const openSupplyCarForm = ({ onClose, ...props }: ModalProps<SetSupplyCarProps>) => {
  modals.openContextModal({
    modal: 'setSupplyCar',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    onClose,
    styles: { body: { padding: 0 } },
    size: 800,
    innerProps: props,
  });
};

export const openProductToArchive = ({
  id,
  supplierProductId,
  identifier,
}: ModalProps<IProductToArchive>) => {
  modals.openContextModal({
    modal: 'productToArchive',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    styles: { body: { padding: 0 } },
    size: 435,
    innerProps: { id, supplierProductId, identifier },
  });
};

export const openChooseCategory = ({ ...props }: ModalProps<IChooseCategory>) => {
  modals.openContextModal({
    modal: 'categoriesModal',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    styles: { body: { padding: 0 } },
    size: 800,
    innerProps: { props },
  });
};

export const openChooseFilter = ({ ...props }: ModalProps<IChooseCategory>) => {
  modals.openContextModal({
    modal: 'chooseFilter',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    styles: { body: { padding: 0 } },
    size: 435,
    innerProps: { props },
  })
};

export const openProductEans = ({ eans, ...props }: ModalProps<IProductEans>) => {
  modals.openContextModal({
    modal: 'productEans',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    styles: { body: { padding: 0 } },
    size: 435,
    innerProps: { props, eans },
  });
};

export const openProductUpload = ({ ...props }) => {
  modals.openContextModal({
    modal: 'productUpload',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    styles: { body: { padding: 0 } },
    fullScreen: true,
    innerProps: { props },
  });
};

export const openTemplateInstrtuction = ({ ...props }) => {
  modals.openContextModal({
    modal: 'productInstruction',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    onClose: () => openProductUpload({}),
    styles: { body: { padding: 0 } },
    size: 800,
    innerProps: { props },
  });
};

export const openUpdatePrices = ({ ...props }) => {
  modals.openContextModal({
    modal: 'productUpdatePrices',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    styles: { body: { padding: 0 } },
    size: 800,
    innerProps: { props },
  });
};

export const openLoadingError = ({ ...props }) => {
  modals.openContextModal({
    modal: 'loadingError',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    size: 435,
    styles: { body: { padding: 0 } },
    innerProps: props,
  });
};

export const openCreateProduct = ({ ...props }) => {
  modals.openContextModal({
    modal: 'createProduct',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'lg',
    styles: { body: { padding: 0 } },
    size: 800,
    innerProps: { props },
  });
};

export const openPreviewProduct = ({ img, ...props }: ModalProps<PreviewModalProps>) => {
  modals.openContextModal({
    modal: 'previewProduct',
    withCloseButton: false,
    closeOnEscape: true,
    radius: 'md',
    styles: { body: { padding: 0 }, root: { height: '420 !important' } },
    size: 420,
    innerProps: { props, img },
  });
};
