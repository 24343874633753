import {
  IGetProductCheckArticleParams,
  IGetProductsTemplateBody,
  IPostPricesImport,
  IPostProductsImport,
} from '@/types/api/product';
import { IGetProductParams, IPatchUpdateStatusBody } from '@/types/api/product';

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  tryGetDownloadProducts,
  tryGetPricesTemplate,
  tryGetProductCheckArticle,
  tryGetProductInstruction,
  tryGetProductsTemplate,
  tryPostPricesImport,
  tryPostProductsImport,
} from '@/api/product/product';
import { tryGetProducts } from '@/api/product/product';
import {
  tryGetProductStatuses,
  tryUpdateArchiveAdd,
  tryUpdateArchiveRestore,
  tryUpdateSaleRemove,
  tryUpdateSaleReturn,
} from '@/api/product/product';

import notify from '@/utils/notify';
import { pluralize } from '@/utils/pluralize';

import {
  MULTIPLE_PRODUCT_TITLES,
  MULTIPLE_PRODUCT_TITLES_REMOVE,
  MULTIPLE_PRODUCT_TITLES_RESTORE,
  MULTIPLE_PRODUCT_TITLES_RETURN,
} from '@/constants/common';
import { NOTIFY_DOWNLOAD_START, NOTIFY_LOAD_START } from '@/constants/notifyMessages';

import { setDownloadTemplate } from '../template/slice';

export const getProductStatuses = createAsyncThunk(
  'products/statuses',
  async (_, { rejectWithValue }) => {
    const result = await tryGetProductStatuses();
    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const getProductsMore = createAsyncThunk(
  'products/list/more',
  async (params: IGetProductParams, { rejectWithValue }) => {
    const result = await tryGetProducts(params);
    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const getProductsDownload = createAsyncThunk(
  '/products/export',
  async (params: [IGetProductParams, any], { rejectWithValue }) => {
    const result = await tryGetDownloadProducts(params[0], params[1]);

    if (result) {
      notify({
        type: 'success',
        message: NOTIFY_DOWNLOAD_START,
      });
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const getProducts = createAsyncThunk(
  '/products/list',
  async (params: [IGetProductParams, any], { rejectWithValue }) => {
    const result = await tryGetProducts(params[0], params[1]);
    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const updateProductsSaleRemove = createAsyncThunk(
  'products/sale/remove',
  async (data: IPatchUpdateStatusBody[], { rejectWithValue }) => {
    const result = await tryUpdateSaleRemove(data);
    if (result) {
      notify({
        type: 'success',
        message: `${result.successCount}
           ${pluralize(result.successCount, MULTIPLE_PRODUCT_TITLES)}
           ${pluralize(result.successCount, MULTIPLE_PRODUCT_TITLES_REMOVE)}
           с продажи`,
      });
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const updateProductsSaleReturn = createAsyncThunk(
  'products/sale/return',
  async (data: IPatchUpdateStatusBody[], { rejectWithValue }) => {
    const result = await tryUpdateSaleReturn(data);
    if (result) {
      if (result.successCount > 0) {
        notify({
          type: 'success',
          message: `${result.successCount}
             ${pluralize(result.successCount, MULTIPLE_PRODUCT_TITLES)}
             ${pluralize(result.successCount, MULTIPLE_PRODUCT_TITLES_RETURN)}
             в продажу`,
        });
      }
      if (result.errorCount > 0) {
        notify({
          type: 'error',
          message: `${result.errorCount}
             ${pluralize(result.errorCount, MULTIPLE_PRODUCT_TITLES)}
             не ${pluralize(result.errorCount, MULTIPLE_PRODUCT_TITLES_RETURN)}
             в продажу. Попробуйте еще раз или обратитесь в техподдержку`,
        });
      }
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const updateProductsArchiveAdd = createAsyncThunk(
  'products/archive/add',
  async (data: IPatchUpdateStatusBody[], { rejectWithValue }) => {
    const result = await tryUpdateArchiveAdd(data);
    if (result) {
      if (result.successCount > 0) {
        notify({
          type: 'success',
          message: `Товар переведен в архив`,
        });
      }
      if (result.errorCount > 0) {
        notify({
          type: 'error',
          message: `Товар не переведен в архив. Попробуйте еще раз или обратитесь в техподдержку`,
        });
      }
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const updateProductsArchiveRestore = createAsyncThunk(
  'products/archive/restore',
  async (data: IPatchUpdateStatusBody[], { rejectWithValue }) => {
    const result = await tryUpdateArchiveRestore(data);
    if (result) {
      if (result.successCount > 0) {
        notify({
          type: 'success',
          message: `
          ${result.successCount}
          ${pluralize(result.successCount, MULTIPLE_PRODUCT_TITLES)}
          ${pluralize(result.successCount, MULTIPLE_PRODUCT_TITLES_RESTORE)}`,
        });
      }
      if (result.errorCount > 0) {
        notify({
          type: 'error',
          message: `
          ${result.errorCount}
          ${pluralize(result.errorCount, MULTIPLE_PRODUCT_TITLES)}
          не ${pluralize(result.successCount, MULTIPLE_PRODUCT_TITLES_RESTORE)}
          .Попробуйте еще раз или обратитесь в техподдержку`,
        });
      }
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const getProductsTemplate = createAsyncThunk(
  '/products/imports/template',
  async (data: IGetProductsTemplateBody, { rejectWithValue, dispatch }) => {
    const result = await tryGetProductsTemplate(data);
    if (result) {
      const { path } = result;
      dispatch(setDownloadTemplate(path));
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const getPricesTemplate = createAsyncThunk(
  '/products/imports/template',
  async (_, { rejectWithValue }) => {
    const result = await tryGetPricesTemplate();
    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const postProductsImport = createAsyncThunk(
  '/products/import/',
  async (data: IPostProductsImport, { rejectWithValue }) => {
    const result = await tryPostProductsImport(data);
    if (result) {
      notify({ type: 'success', message: NOTIFY_LOAD_START });
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const postPricesImport = createAsyncThunk(
  '/products/prices/import/',
  async (data: IPostPricesImport, { rejectWithValue }) => {
    const result = await tryPostPricesImport(data);
    if (result) {
      notify({ type: 'success', message: NOTIFY_LOAD_START });

      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const checkArticle = createAsyncThunk(
  '/products/check/',
  async (data: IGetProductCheckArticleParams, { rejectWithValue }) => {
    const result = await tryGetProductCheckArticle(data);
    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);

export const getProductInstruction = createAsyncThunk(
  'products/instruction',
  async (_, { rejectWithValue }) => {
    const result = await tryGetProductInstruction();
    if (result) {
      return result;
    } else {
      return rejectWithValue(null);
    }
  }
);
