import { IOperationToProductIdMap } from '@/types/api/productDocuments';

import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  ActionIcon,
  Box,
  Button,
  FileButton,
  Group,
  LoadingOverlay,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { ContextModalProps, modals } from '@mantine/modals';
import { InfoCircle, X } from 'tabler-icons-react';

import { fileFilterBuilder } from '@/utils/file';
import notify from '@/utils/notify';

import { PRODUCT_OPERATIONS_KEY } from '@/constants/common';

import LoaderDots from '@/ui/atoms/LoaderDots/LoaderDots';
import ModalStep from '@/ui/organisms/ModalCustom/components/ModalStep/ModalStep';

import ModalCLose from '../ModalClose/ModalClose';
import ModalContent from '../ModalContent/ModalContent';
import ModalFooter from '../ModalFooter/ModalFooter';

import { productDocsApi } from '@/store/slices/productDocs/api';

interface ModalAddProductProps {
  docId: number;
}

export const ModalAddProducts: FC<ContextModalProps<ModalAddProductProps>> = ({
  context,
  id,
  innerProps: { docId },
}) => {
  const { colors } = useMantineTheme();
  const { data, isSuccess: isTemplateLoaded, isLoading: isDownload } = productDocsApi.useGetTemplateQuery();
  const [attachProducts, { data: operation, isLoading: isSaving, isSuccess: isProductsSaved }] =
    productDocsApi.useAttachProductsMutation();
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const resetRef = useRef<() => void>(null);

  const fileFilter = useMemo(() => fileFilterBuilder().byExt('xlsx').getFilter(), []);

  const clearFile = () => {
    setFile(null);
    resetRef.current?.();
  };

  const uploadProducts = () => {
    if (!file) return;
    attachProducts({
      id: docId,
      file,
    });
  };

  const handleChangeFile = (newFile: File) => {
    const [accepted, rejections] = fileFilter([newFile]);
    if (rejections.length) setError('Неверный формат файла');
    else setError(null);
    if (accepted.length) setFile(accepted[0]);
    else {
      setFile(null);
      resetRef.current?.();
    }
  };

  useEffect(() => {
    if (isProductsSaved) {
      modals.close(id);
      if (operation) {
        const map: IOperationToProductIdMap = JSON.parse(
          localStorage.getItem(PRODUCT_OPERATIONS_KEY) || '{}'
        );
        map[operation?.operationId] = docId.toString();
        localStorage.setItem(PRODUCT_OPERATIONS_KEY, JSON.stringify(map));
      }
      notify({
        message:
          'Загрузка началась. По завершению на сайте появится уведомление о статусе загрузки',
        type: 'success',
      });
    }
  }, [isProductsSaved]);

  const handlerDownload = () => {
    const link = document.createElement('a');
    link.href = data?.path || '';
    link.click();
  }

  return (
    <ModalContent boxSize={535}>
      <LoadingOverlay visible={!isTemplateLoaded || isSaving} zIndex={100} />
      <ModalCLose onClose={() => context.closeModal(id)} />
      <Box ml={-24} mr={-24}>
        <Text size={24} align='center' color={colors.text[7]} weight={700}>
          Добавление товаров
        </Text>
        <ModalStep
          title='Шаг 1'
          titleSub='Cкачайте шаблон и заполните его'
          borderBottom
        >
          <Button
            mt={12}
            fz={14}
            h={36}
            onClick={handlerDownload}
          >
            {isDownload
              ? <LoaderDots loading={isDownload} />
              : 'Скачать шаблон'}
          </Button>
        </ModalStep>
        <ModalStep
          title='Шаг 2'
          titleSub='Загрузите заполненый шаблон'
          description='XLSX-файл должен содержать только артикулы товаров'
        >
          <Group mt={20}>
            <FileButton
              resetRef={resetRef}
              onChange={handleChangeFile}
              accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            >
              {(props) => (
                <Button fz={14} h={36} {...props} variant="outline">
                  {file ? 'Изменить файл' : 'Выбрать файл'}
                </Button>
              )}
            </FileButton>
            {!file && <Text size={14}>Файл не выбран</Text>}
            {file !== null && (
              <Group
                spacing={12}
                px={16}
                py={8}
                bg={colors.gray[3]}
                sx={{
                  borderRadius: '36px'
                }}
              >
                <Text size={14} color={colors.gray[7]}>
                  {file.name.split('.').slice(0, -1).join('.')}
                </Text>
                <ActionIcon size={20} onClick={clearFile}>
                  <X size={20} color={colors.brandGrey[8]} />
                </ActionIcon>
              </Group>
            )}
          </Group>
          {error && (
            <Group spacing={4}>
              <InfoCircle fill={colors.red[5]} stroke="white" size={16} />
              <Text size={12} color={colors.red[5]}>
                {error}
              </Text>
            </Group>
          )}
        </ModalStep>
      </Box>
      <ModalFooter align='center' variant='xs'>
        <Button size="md" fz={14} miw={280} onClick={uploadProducts} disabled={!Boolean(file)}>
          Продолжить
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
