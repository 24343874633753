import { FC, MouseEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Anchor, Box, Group, LoadingOverlay } from '@mantine/core';
import cn from 'classnames';
import isUndefined from 'lodash/isUndefined';
import { ChevronDown } from 'tabler-icons-react';

import { MAIN_MENU, MenuItem } from '@/constants/menu';

import { useStyles } from './styles';

import { useAppDispatch } from '@/store';

const HeaderMenu: FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [busyId, setBusyId] = useState<string | null>(null);

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  useEffect(() => {
    return () => setActiveIndex(null);
  }, [navigate]);

  const isActive = (path: string) => location.pathname.startsWith(path);

  const isParentActive = (parent: any) => {
    if (isActive(parent.href)) return true;
    return parent.children?.some((child: any) => isActive(child.href));
  };

  const handleMenuClick = async (
    e: MouseEvent,
    id: MenuItem['id'],
    preFn: MenuItem['preNavigate'],
    href: MenuItem['href']
  ) => {
    e.preventDefault();

    if (!!busyId) return;

    if (!preFn) {
      navigate(href);
      return;
    }

    if (id) setBusyId(id);
    const res = await preFn(dispatch);
    if (isUndefined(res) || !!res) navigate(href);
    setBusyId(null);
  };

  return (
    <Box className={classes.root}>
      {MAIN_MENU.map((menuItem, index) => (
        <Group
          key={index}
          onMouseEnter={() => setActiveIndex(index)}
          onMouseLeave={() => setActiveIndex(null)}
          pos="relative"
          align="center"
        >
          <Anchor
            onClick={(e) => handleMenuClick(e, menuItem.id, menuItem.preNavigate, menuItem.href)}
            className={`${classes.link}
              ${isParentActive(menuItem) ? classes.linkActive : ''}
              ${isActive(menuItem.href) ? classes.linkActive : ''}`}
            unstyled
          >
            {menuItem.name}
            {menuItem.children && (
              <ChevronDown width={20} height={20} className={classes.chevron} />
            )}
            <LoadingOverlay loaderProps={{ size: 16 }} visible={busyId === menuItem.id} />
          </Anchor>
          {activeIndex === index && menuItem.children && (
            <Box className={classes.dropdownWrapper}>
              <Box className={classes.dropdown}>
                {menuItem.children.map((child, childIndex) => (
                  <Anchor
                    key={childIndex}
                    href={child.href}
                    onClick={(e) => handleMenuClick(e, child.id, child.preNavigate, child.href)}
                    className={cn(classes.dropdownLink)}
                    unstyled
                  >
                    {child.name}
                    <LoadingOverlay loaderProps={{ size: 16 }} visible={busyId === child.id} />
                  </Anchor>
                ))}
              </Box>
            </Box>
          )}
        </Group>
      ))}
    </Box>
  );
};

export default HeaderMenu;
