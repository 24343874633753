import { FC, useState } from 'react';
import { Flex, UnstyledButton, useMantineTheme } from '@mantine/core';

import Timer from '@/ui/organisms/Timer/Timer';

interface IResendCode {
  timer?: number;
  timerText?: string;
  buttonText?: string;
  className?: string;
  resendHandler?: () => void;
}

const ResendCode: FC<IResendCode> = ({
  timer = 0,
  timerText,
  buttonText,
  resendHandler,
}) => {
  const theme = useMantineTheme();
  const [timeout, setTimeout] = useState(false);

  const timerCallback = () => {
    setTimeout(true);
  };

  const buttonHandler = () => {
    setTimeout(false);

    if (resendHandler) resendHandler();
  };

  return (
    <Flex justify='left'>
      {!timeout && (
        <Timer
          callback={timerCallback}
          time={timer}
          text={timerText}
        />
      )}
      {timeout && (
        <UnstyledButton
          mt={8}
          fz={14}
          color={theme.colors.mainColor[8]}
          onClick={buttonHandler}
        >
          {buttonText}
        </UnstyledButton>
      )}
    </Flex>
  );
};

export default ResendCode;
