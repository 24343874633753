import { FC } from 'react';
import { Group, Tabs } from '@mantine/core';

import { TabRightSection } from '@/ui/pages/ProfilePage/components/TabRightSection/TabRightSection';

import { useStyles } from '../../styles';
import { useProductContext } from '../../utils/ProductContext';

import { ReactComponent as IconEyeOpen } from '@/assets/icons/redesign/iconEyeOpen.svg';

interface IProductTab {
  code: string;
  title: string;
}

const ProductDetailTabs: FC = () => {
  const { data, fieldValues, errors } = useProductContext();
  const { classes } = useStyles();

  const isRequiredFieldsFilled = (tab: IProductTab): boolean | undefined => {
    return data?.tabs
      .find((tabFields) => tabFields === tab)
      ?.fields?.every((field) => !field.required || fieldValues[field.code]);
  };

  const hasErrorsTab = (tab: IProductTab): boolean => {
    return (
      data?.tabs
        .find((tabFields) => tabFields === tab)
        ?.fields?.some((field) => errors[field.code]) || false
    );
  };

  return (
    <Tabs.List>
      {data?.tabs
        .filter((tab) => tab.fields !== null)
        .map((tab: IProductTab) => (
          <Tabs.Tab
            value={tab.code}
            key={tab.code}
            rightSection={
              <TabRightSection state={!isRequiredFieldsFilled(tab) || hasErrorsTab(tab)} />
            }
          >
            {tab.title}
          </Tabs.Tab>
        ))}
      <Tabs.Tab ml="auto" value="preview" className={classes.tabUnstyled}>
        <Group spacing={10} align="center">
          <IconEyeOpen />
          Предварительный просмотр
        </Group>
      </Tabs.Tab>
    </Tabs.List>
  );
};

export default ProductDetailTabs;
