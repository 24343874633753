import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({
  wrapper: {
    borderRadius: `0 0 1rem 1rem`,
    borderBottom: 'none',
    '@media (max-width: 1360px)': {
      minHeight: 100,
      height: '100%',
      maxHeight: '100%',
    },
  },

  stick: {
    position: 'absolute',
    top: `50%`,
    transform: `translateY(-50%)`,
    '@media (max-width: 1000px)': {
      top: `10%`,
    },
  },
  stickLeft: {
    left: 0,
  },
  stickRight: {
    right: 0,
  },
}));
