import { FC, ReactNode } from "react"

interface ITHeadProps {
    children: ReactNode
}

const THead: FC<ITHeadProps> = ({ children }) => {
    return (
        <thead>
            {children}
        </thead>
    )
}

export default THead;