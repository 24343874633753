import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { AppShell } from '@mantine/core';

import LayoutHeader from '../Layout/components/LayoutHeader/LayoutHeader';

import LiveTexScript from '@/scripts/LiveTexScript';

const InfoLayout: FC = () => {
  return (
    <AppShell fixed={false} header={<LayoutHeader />}>
      <LiveTexScript />
      <Outlet />
    </AppShell>
  );
};

export default InfoLayout;
