import { ID, IStatus } from '@/types/common/common';
import { IProduct } from '@/types/entites/product';

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Flex, Group } from '@mantine/core';

import { productUrls } from '@/api/urls/productUrls';

import { getSeletedItems } from '@/utils/getSelectedItems';

import { SORT_ARTICLE, SORT_DATE, SORT_HAS_EMPTY_ATTRIBUTES, SORT_NAME } from '@/constants/common';
import { STATUS_ARCHIVED } from '@/constants/statuses';
import useWebSocketWithLocalStorage from '@/hooks/useWebSocketWithLocalStorage';

import Cell from '@/ui/organisms/TableCustom/components/Cell/Cell';
import CheckboxCell from '@/ui/organisms/TableCustom/components/CheckboxCell/СheckboxCell';
import EansCell from '@/ui/organisms/TableCustom/components/EansCell/EansCell';
import FilterCell from '@/ui/organisms/TableCustom/components/FilterCell/FIlterCell';
import ImageCell from '@/ui/organisms/TableCustom/components/ImageCell/ImageCell';
import OptionsCell from '@/ui/organisms/TableCustom/components/OptionsCell/OptionsCell';
import Row from '@/ui/organisms/TableCustom/components/Row/Row';
import RowEmpty from '@/ui/organisms/TableCustom/components/RowEmpty/RowEmpty';
import StatusCell from '@/ui/organisms/TableCustom/components/StatusCell/StatusCell';
import TBody from '@/ui/organisms/TableCustom/components/TBody/TBody';
import THead from '@/ui/organisms/TableCustom/components/THead/THead';
import { TableCustom } from '@/ui/organisms/TableCustom/TableCustom';

import { AppDispatch } from '@/store';
import {
  selectProductsCurrentStatus,
  selectProductsSelected,
  selectProductsSelectedAll,
} from '@/store/slices/products/selectors';
import { setSelected, setSelectedAll } from '@/store/slices/products/slice';
import { selectTableScrollValues } from '@/store/slices/table/selectors';

interface IProudctTableProps {
  items: IProduct[];
  handlerCheckboxAll: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  currentPage: number;
}

interface IStatusInfo {
  currentStatus: IStatus;
  previousStatus?: IStatus;
}

const ProductsTable: FC<IProudctTableProps> = ({ items, handlerCheckboxAll, currentPage }) => {
  const [statuses, setStatuses] = useState<Record<string, IStatusInfo>>({});

  const dispatch = useDispatch<AppDispatch>();
  const selectedAll = useSelector(selectProductsSelectedAll);
  const selected = useSelector(selectProductsSelected);
  const currentStatus = useSelector(selectProductsCurrentStatus);
  const scrollValues = useSelector(selectTableScrollValues);
  const itemsRef = useRef(items);
  const [hasNotification, setHasNotification] = useState(false);

  const changeFavicon = (iconURL: string) => {
    const link =
      (document.querySelector("link[rel*='icon']") as HTMLLinkElement) ||
      (document.createElement('link') as HTMLLinkElement);
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = `${iconURL}?v=${new Date().getTime()}`;
    document.getElementsByTagName('head')[0].appendChild(link);
  };

  useWebSocketWithLocalStorage<IProduct>(
    process.env.WEB_SOCKET_URL || '',
    'productStatusUpdates',
    (productUpdate) => {
      const currentItems = itemsRef.current;
      const productIndex = currentItems.findIndex(
        (item) => item.supplierProductId === productUpdate.supplierProductId
      );

      if (productIndex !== -1) {
        const currentProduct = currentItems[productIndex];
        if (currentProduct.status.code !== productUpdate.status.code) {
          setStatuses((prev) => ({
            ...prev,
            [productUpdate.supplierProductId]: {
              currentStatus: productUpdate.status,
              previousStatus: currentProduct.status,
            },
          }));
          setHasNotification(true);
          if (document.visibilityState === 'hidden') {
            changeFavicon('notificationsFavicon.ico');
          }
        }
      }
    }
  );

  const newSelected = useCallback(
    (id: ID) => {
      return getSeletedItems(selected, id);
    },
    [selected]
  );

  const isSelected = (id: ID) => selected.indexOf(id) !== -1;

  const handlerCheckbox = (event: React.MouseEvent<HTMLButtonElement>, id: ID) => {
    event.stopPropagation();
    const updatedSelected = newSelected(id);
    dispatch(setSelected(updatedSelected));
  };

  const handlEditStatusComment = (statusComment: string) =>
    statusComment?.replace(/(?:\r\n|\r|\n)/g, '<br />');

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        if (hasNotification) {
          changeFavicon('favicon.ico');
          setHasNotification(false);
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [hasNotification]);

  useEffect(() => {
    if (items.length > 0 && selected.length === items.length) {
      dispatch(setSelectedAll(true));
    } else {
      dispatch(setSelectedAll(false));
    }
  }, [selected.length]);

  useEffect(() => {
    itemsRef.current = items;
  }, [items]);

  return (
    <TableCustom elements={items}>
      {({ maxScrollValue, hasHorizontalScroll }) => (
        <React.Fragment>
          <THead>
            <Row>
              <Cell
                th
                sticky
                firstCell
                scrollValues={scrollValues}
                maxWidth={165}
                minWidth={165}
                hasHorizontalScroll={hasHorizontalScroll}
              >
                <Group spacing={12} noWrap>
                  <CheckboxCell
                    isChecked={selectedAll}
                    handler={(event) => handlerCheckboxAll(event)}
                  />
                  <FilterCell sortName={SORT_ARTICLE} text="Артикул" columnCode="article" />
                </Group>
              </Cell>
              <Cell th>Фото</Cell>
              <Cell th maxWidth={240} minWidth={240}>
                <FilterCell sortName={SORT_NAME} text="Наименование товара" columnCode={'name'} />
              </Cell>
              <Cell th>Штрихкод</Cell>
              <Cell th>Категория</Cell>
              {currentStatus?.code != STATUS_ARCHIVED && <Cell th>Статус</Cell>}
              <Cell th>Цена, ₽</Cell>
              <Cell th>Цена со скидкой, ₽</Cell>
              <Cell th>
                <FilterCell sortName={SORT_DATE} text="Дата создания" columnCode="date" />
              </Cell>
              <Cell th>
                <FilterCell
                  sortName={SORT_HAS_EMPTY_ATTRIBUTES}
                  text="Незаполненные характеристики"
                  columnCode="has_empty_attributes"
                />
              </Cell>
              <Cell
                th
                sticky
                lastCell
                scrollValues={scrollValues}
                maxWidth={maxScrollValue}
                minWidth={68}
                hasHorizontalScroll={hasHorizontalScroll}
              />
            </Row>
          </THead>
          <TBody>
            {items.length > 0 ? (
              items?.map((el) => {
                const statusInfo = statuses[el.supplierProductId];

                return (
                  <Row key={`${el.supplierProductId}-${currentPage}`} tabindex={-1}>
                    <Cell
                      sticky
                      firstCell
                      scrollValues={scrollValues}
                      maxWidth={165}
                      minWidth={165}
                      hasHorizontalScroll={hasHorizontalScroll}
                    >
                      <CheckboxCell
                        text={el.supplierProductId}
                        isChecked={isSelected(el.supplierProductId)}
                        item={el}
                        handler={(event) => handlerCheckbox(event, el.supplierProductId)}
                      />
                    </Cell>
                    <Cell>
                      <ImageCell src={el.image} width={37} height={48} />
                    </Cell>
                    <Cell minWidth={300} maxWidth={500}>
                      {el.name}
                    </Cell>
                    <Cell>
                      <EansCell elements={el?.eans} />
                    </Cell>
                    <Cell minWidth={300} maxWidth={350}>
                      {el.category?.label}
                    </Cell>
                    {currentStatus?.code != STATUS_ARCHIVED && (
                      <Cell minWidth={220} maxWidth={220}>
                        <Flex direction={'column'} gap={'xs'}>
                          <StatusCell
                            status={statusInfo ? statusInfo.currentStatus : el.status}
                            statusComment={handlEditStatusComment(el.statusComment)}
                          />
                          {statusInfo && statusInfo.previousStatus && (
                            <StatusCell
                              status={statusInfo.previousStatus}
                              statusComment={handlEditStatusComment(el.statusComment)}
                              strikethrough
                            />
                          )}
                        </Flex>
                      </Cell>
                    )}
                    <Cell minWidth={120} maxWidth={200}>
                      {el?.price}
                    </Cell>
                    <Cell minWidth={120} maxWidth={200}>
                      {el?.discountPrice}
                    </Cell>
                    <Cell>{el?.dateCreate}</Cell>
                    <Cell>{String(el?.hasEmptyAttributes)}</Cell>
                    <Cell
                      sticky
                      lastCell
                      scrollValues={scrollValues}
                      maxWidth={68}
                      minWidth={68}
                      hasHorizontalScroll={hasHorizontalScroll}
                    >
                      <OptionsCell
                        status={el.status}
                        id={el.supplierProductId}
                        identifier={el.identifier}
                        stockQuantity={el.stockQuantity}
                      />
                    </Cell>
                  </Row>
                );
              })
            ) : (
              <RowEmpty text={'Нет записей'} />
            )}
          </TBody>
        </React.Fragment>
      )}
    </TableCustom>
  );
};

export default ProductsTable;
