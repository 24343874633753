import { PaginationPayload, SortState } from '@/types/api/common';
import { SimpleTableConfig } from '@/types/common/common';

import { useMemo } from 'react';
import { Image, Stack } from '@mantine/core';
import { Text } from '@mantine/core';

import PaginationCustom from '../PaginationCustom/PaginationCustom';

import Cell from './components/Cell/Cell';
import Row from './components/Row/Row';
import THead from './components/THead/THead';
import { TableCustom } from './TableCustom';

interface SimpleTableProps<S extends string> {
  config: SimpleTableConfig;
  data?: Record<string, any>[];
  onSortChange: (v: S) => void;
  onPaginationChange: (v: PaginationPayload) => void;
  sortable: S[];
  sort?: SortState;
  limit: number;
  offset?: number;
  totalItems?: number;
  pagination?: boolean;
}

export function SimpleTable<S extends string = string>({
  config,
  data,
  sortable,
  sort,
  onSortChange,
  onPaginationChange,
  limit = 50,
  offset = 0,
  pagination,
  totalItems = data?.length || 0,
}: SimpleTableProps<S>) {
  const sortableMap = useMemo(
    () =>
      sortable.reduce<Record<string, true>>(
        (acc, current) => ({ ...acc, [current]: true }),
        {} as Record<string, true>
      ),
    [sortable]
  );

  const changePageHandler = (page: number, concat: boolean) => {
    onPaginationChange({ offset: (page - 1) * limit, concat });
  };

  return (
    <Stack spacing={0}>
      <TableCustom offsetScrollbars={false}>
        {() => (
          <>
            <THead>
              <Row>
                {config.columns.map(({ name, code }) => (
                  <Cell
                    th
                    sort={sort?.code === code ? sort.sort : null}
                    sortable={sortableMap[code] || false}
                    onSortClick={() => onSortChange(code as S)}
                    sortCode={code}
                    key={code}
                  >
                    {name}
                  </Cell>
                ))}
              </Row>
              {data?.map((item, i) => (
                <Row key={i}>
                  {config.columns.map(({ code: key, image, align, name: _, ...props }) => (
                    <Cell key={key} {...props}>
                      {image ? (
                        <Image src={item[key]} width={40} height={40} w={40} h={40} />
                      ) : (
                        <Text align={align}>{item[key]}</Text>
                      )}
                    </Cell>
                  ))}
                </Row>
              ))}
            </THead>
          </>
        )}
      </TableCustom>
      {pagination && data && data?.length > limit && (
        <PaginationCustom
          handler={changePageHandler}
          limitItems={limit}
          currentPage={Math.floor(offset / limit) + 1}
          totalItems={totalItems}
          moreButton
        />
      )}
    </Stack>
  );
}
