import { FC } from 'react';
import { Button, useMantineTheme } from '@mantine/core';
import { Text } from '@mantine/core';

import { useProductContext } from '../../utils/ProductContext';

interface ISaveButtonProps {
  onClick: () => void;
}

const SaveButton: FC<ISaveButtonProps> = ({ onClick }) => {
  const { colors } = useMantineTheme();
  const { isFieldsFilledAllTab } = useProductContext();
  return (
    <>
      {isFieldsFilledAllTab && (
        <Text fz={10} lh={'16px'} maw={300} mt={32} color={colors.brandGrey[8]}>
          Нажимая на кнопку “Сохранить”, ваш товар отправится на проверку и вы не сможете внести
          изменения пока он не пройдет модерацию
        </Text>
      )}
      <Button
        type="submit"
        variant="filled"
        size="lg"
        w={300}
        mt={8}
        fz={16}
        h={60}
        onClick={onClick}
        disabled={!isFieldsFilledAllTab}
      >
        Сохранить
      </Button>
    </>
  );
};

export default SaveButton;
