import { OperationsMessage } from '@/types/common/common';

export const hasValue = <
  E extends HTMLElement,
  T extends any = E extends HTMLInputElement ? E['value'] : string
>(
  e: any
): e is React.BaseSyntheticEvent<{ value: T }> => {
  if (e.hasOwnProperty('currentTarget') && e.currentTarget.hasOwnProperty('value')) return true;
  return false;
};

export const isNodeWithValue = (el: Element): el is HTMLInputElement | HTMLTextAreaElement => {
  const acceptableTags = ['input', 'textarea', 'select'];

  for (let i in acceptableTags) {
    const tag = acceptableTags[i];
    const currentTag = el.tagName.toLowerCase();

    if (currentTag === tag) return true;
  }

  return false;
};

export const isBlob = (v: any): v is Blob => v instanceof Blob;
export const isJsonBlob = (v: any): v is Blob => isBlob(v) && v.type.startsWith('application/json');
export const isOperationMessage = (message: MessageEvent): message is OperationsMessage =>
  message.data.type === 'operations';

/**
 * for future-proof use
 */
export const isClient = typeof window !== 'undefined';

export const getRegex = (substr: string) =>
  new RegExp(substr.trim().replace(/([^a-zа-яё0-9])/g, '\\$1'), 'gi');
