import { createStyles, getStylesRef } from "@mantine/core";

export const useStyles = createStyles((theme, _params) => ({
    track: {
        ref: getStylesRef('switch_track'),
        height: '31px',
        width: '60px',
        border: 'none'
    },
    thumb: {
        ref: getStylesRef('switch_thumb'),
        width: '27px',
        height: '27px',
        backgroundColor: '#fff',
    },
    input: {
        [`&:checked + .${getStylesRef('switch_track')}`]: {
            backgroundColor: theme.colors.brandGreen[9],
        },
        [`&:checked + .${getStylesRef('switch_track')} .${getStylesRef('switch_thumb')}`]: {
            transform: 'translateX(-13px)',
        },
    },
}));
