
import { ICompanyInfoVariants } from '@/types/api/seller';
import { ICompanyInfo } from '@/types/api/seller';

import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQuery } from '@/api/instance';
import { usersUrls } from '@/api/urls/usersUrls';

export const sellerApi = createApi({
  reducerPath: 'sellerApi',
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    fetchCompanyInfo: builder.query<ICompanyInfoVariants, void>({
      query: () => usersUrls.seller.companyInfo,
    }),
    setCompanyInfo: builder.mutation<void, ICompanyInfo>({
      query: (data) => ({
        url: usersUrls.seller.companyInfo,
        method: 'PATCH',
        data: data,
      }),
    }),
  }),
});
