import { FC } from "react"
import { Image } from "@mantine/core";

interface IImageCellProps {
    src: string;
    width: string | number;
    height: string | number;
}

const ImageCell: FC<IImageCellProps> = ({ src, width, height }) => {
    return (
        <Image src={src} width={width} height={height} fit={'contain'}/>
    )
}

export default ImageCell