import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Group, Stack } from '@mantine/core';
import { Text } from '@mantine/core';
import { ChevronDown } from 'tabler-icons-react';

import { MenuContext } from '../../utils/MenuContext';

import { useStyles } from './styles';

interface MenuItemProps {
  id?: string;
  title: string;
  inner?: [string, string][];
  onSelect?: (id: string) => void;
}

export const MenuItem: FC<MenuItemProps> = ({ id = '', title, inner, onSelect }) => {
  const [open, setOpen] = useState(false);
  const { classes } = useStyles();
  const { active } = useContext(MenuContext);
  const innerRef = useRef<HTMLDivElement>(null);

  const isActive = useMemo(() => active === id, [active]);
  const hasChildren = useMemo(() => inner && inner?.length > 0, [inner]);

  const handleClick = () => {
    if (hasChildren) setOpen(!open);
    else onSelect?.(id);
  };

  useEffect(() => {
    if (!innerRef.current) return;

    const innerEl = innerRef.current;

    if (open) {
      innerEl.style.maxHeight = `${innerEl.scrollHeight}px`;
    } else {
      innerEl.style.maxHeight = '0px';
    }
  }, [open]);

  return (
    <Stack className={classes.wrapper} data-expanded={open} spacing={0}>
      <Group
        className={classes.item}
        aria-selected={isActive}
        data-active={isActive}
        id={id || title}
        role="tab"
        aria-controls="info-panel"
        aria-expanded={open}
        onClick={handleClick}
        noWrap
        position="apart"
      >
        <Text>{title}</Text>
        {hasChildren && <ChevronDown className={classes.chevron} />}
      </Group>
      {hasChildren && (
        <div className={classes.inner} ref={innerRef}>
          {inner?.map((item) => (
            <MenuItem id={item[1]} title={item[0]} onSelect={onSelect} />
          ))}
        </div>
      )}
    </Stack>
  );
};
