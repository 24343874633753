import { SupplyCarForm } from '@/types/api/supplies';

import { FC, useEffect, useMemo } from 'react';
import { Grid } from '@mantine/core';
import { yupResolver } from '@mantine/form';
import capitalize from 'lodash/capitalize';

import { useCustomizedForm } from '@/utils/formHelpers';

import { CAR_FORM } from '@/constants/validationSchemas';

import { EnhancedInput } from '@/ui/atoms/EnhancedInput/EnhancedInput';
import { PhoneInput } from '@/ui/organisms/PhoneInput/PhoneInput';

interface CarFormProps {
  initialValues?: SupplyCarForm;
  onChange?: (v: SupplyCarForm | null) => void;
}

export const CarForm: FC<CarFormProps> = ({ initialValues, onChange }) => {
  const form = useCustomizedForm({
    initialValues: {
      id: initialValues?.id || undefined,
      number: initialValues?.number || '',
      driver: initialValues?.driver || '',
      model: initialValues?.model || '',
      driverPhone: initialValues?.driverPhone || '',
    },
    transform: {
      number: (v) => v.toUpperCase(),
      driver: (v) => v.split(' ').map(capitalize).join(' '),
      model: capitalize,
    },
    validate: yupResolver(CAR_FORM),
    validateInputOnBlur: true,
  });

  const isValid = useMemo(() => form.isValid(), [form]);
  const currentFormValue = useMemo(() => form.values, [form]);
  useEffect(() => {
    if (!isValid) onChange?.(null);
    else onChange?.(currentFormValue);
  }, [isValid, currentFormValue]);

  return (
    <Grid gutter={18}>
      <Grid.Col span={6}>
        <EnhancedInput
          {...form.getInputProps('number')}
          label="Номер автомобиля"
          labelType="floating"
          size="md"
          maxLength={100}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <EnhancedInput
          {...form.getInputProps('driver')}
          label="ФИО водителя"
          labelType="floating"
          size="md"
          maxLength={100}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <EnhancedInput
          {...form.getInputProps('model')}
          label="Марка автомобиля"
          labelType="floating"
          size="md"
          maxLength={100}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <PhoneInput
          {...form.getInputProps('driverPhone')}
          label="Телефон водителя"
          labelType="floating"
          size="md"
          type="tel"
          unmask
        />
      </Grid.Col>
    </Grid>
  );
};
