import { errorHandler } from './errorHandler';

const reCaptchaVerify = async (
  executeRecaptcha: ((action?: string | undefined) => Promise<string>) | undefined,
  action: string
) => {
  if (!executeRecaptcha) {
    errorHandler('Execute recaptcha not yet available');
    return;
  }

  const token = await executeRecaptcha(action);

  return token;
};

export default reCaptchaVerify;
