import notify from './notify';

interface Opts<T> {
  append?: string;
  silent?: boolean;
  fallback: T;
}

interface Trycatch {
  <T>(fn: () => T, { fallback: T }: Opts<T>): T;
  <T>(fn: () => T, opts?: Partial<Opts<T>>): T | undefined;
}

export const trycatch: Trycatch = (fn, opts) => {
  try {
    return fn();
  } catch (e) {
    if (!opts?.silent) {
      const errStr = `${e}`;
      console.error(errStr, `(${opts?.append})`);
      notify({ message: errStr, type: 'error' });
    }
  }
  return opts?.fallback;
};
