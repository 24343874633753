import { ProfileFieldType, TProfileField } from '@/types/api/profile';

import { memo, ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox } from '@mantine/core';

import { isChangeEvent } from '@/utils/isChangeEvent';

import { validationTexts } from '@/constants/validationTexts';

import FormGroup from '@/ui/atoms/FormGroup/FormGroup';
import InputWithLabel from '@/ui/atoms/InputWithLabel/InputWithLabel';
import { InputWithLabelHoc } from '@/ui/atoms/InputWithLabelHoc/InputWithLabelHoc';

import {
  changeableInputCodes,
  inputsMap,
  profileBuilderConfig,
} from '../../../utils/formBuilderHelpers';
import { EMAIL_DESCRIPTION, EMAIL_DESCRIPTION_CHANGED } from '../../../utils/texts';
import { Suggestions } from '../../Suggestions/Suggestions';
import { useFormBuilderContext } from '../utils/FormContext';
import { getInputmode } from '../utils/GetInputmode';
import { useLinkedFieldsContext } from '../utils/LinkedFieldsContext';

import { selectUser } from '@/store/slices/user/slice';

type TFieldTypes =
  | ProfileFieldType.FILE
  | ProfileFieldType.SELECT
  | ProfileFieldType.STRING
  | ProfileFieldType.PHONE;

interface FormBuilderProps<T extends TFieldTypes> {
  fieldType: T;
  fieldOptions: TProfileField;
}
type TFormBuilderInput = <T extends TFieldTypes>(
  props: FormBuilderProps<T>
) => ReactElement<any, any> | null;

const EnhancedInput = memo(InputWithLabelHoc(InputWithLabel));

export const FormBuilderInput: TFormBuilderInput = ({ fieldType, fieldOptions }) => {
  const form = useFormBuilderContext();
  const {
    toggleLink,
    hasLinked,
    isLinkDisabled,
    linkedFields,
    isLinked,
    onCheckClick,
    fieldConfirmed,
    tooltipText,
  } = useLinkedFieldsContext(fieldOptions, form);
  const user = useSelector(selectUser);
  const handleSuggestionSubmit = (suggestion: Record<string, string>) => {
    Object.keys(suggestion).forEach((key) => {
      form.setFieldValue(key, suggestion[key]);
    });
    form.resetTouched();
  };

  const handleChange = (e: any) => {
    let newValue: any;
    const code = fieldOptions.code;

    if (isChangeEvent(e)) {
      newValue = e.currentTarget.value;
    } else newValue = e;

    if (profileBuilderConfig.inputFilters[code]) {
      const config = profileBuilderConfig.inputFilters[code];
      const isValid = config.filter(newValue);
      if (newValue !== '' && !isValid) {
        form.setFieldError(code, config.error || validationTexts.INCORRECT_SYMBOL);
        return;
      }
    }

    const linkedOption = linkedFields.find((tuple) => tuple[1] === code);

    if (linkedOption) {
      const linkedField = linkedOption[0];
      form.setFieldValue(linkedField, newValue);
    }

    form.getInputProps(code).onChange(e);
  };
  const isActiveCheckButton = useMemo(() => {
    return user?.companyEmailConfirmationLinkValid;
  }, [user]);

  return isLinked ? null : (
    <FormGroup spacing={fieldType != 'file' ? 26 : 0}>
      <Suggestions
        code={fieldOptions.code}
        value={form.values[fieldOptions.code]}
        touched={form.isTouched(fieldOptions.code)}
        onSubmit={handleSuggestionSubmit}
      >
        {!changeableInputCodes.includes(fieldOptions.code) ? (
          inputsMap[fieldType](fieldOptions)({
            ...form.getInputProps(fieldOptions.code),
            onChange: handleChange,
            description: fieldOptions.description,
            label: fieldOptions.name,
            disabled: fieldOptions.disabled || isLinked,
            inputMode: getInputmode(fieldOptions.code),
            tooltipText: tooltipText,
          })
        ) : (
          <EnhancedInput
            label={fieldOptions.name}
            description={EMAIL_DESCRIPTION}
            changeDescription={EMAIL_DESCRIPTION_CHANGED}
            {...form.getInputProps(fieldOptions.code)}
            value={form.getInputProps(fieldOptions.code).value}
            onChange={(e: any) => handleChange(e)}
            disabled={isActiveCheckButton || fieldOptions.disabled || isLinked}
            onCheckClick={() => onCheckClick?.(form.values[fieldOptions.code])}
            isActiveCheckButton={isActiveCheckButton}
            disabledChangeButton={fieldConfirmed}
            inputMode={getInputmode(fieldOptions.code)}
          />
        )}
      </Suggestions>
      {fieldOptions.linkedWith && (
        <Checkbox
          disabled={isLinkDisabled}
          checked={hasLinked}
          onChange={toggleLink}
          label={fieldOptions.linkedWith.text}
          mt={26}
        />
      )}
    </FormGroup>
  );
};
