import { FC } from 'react';
import { Text } from '@mantine/core';

import { useStyles } from '../styles';

interface RowProps {
  label: string;
  value: string;
}

export const Row: FC<RowProps> = ({ label, value }) => {
  const { classes } = useStyles();
  return (
    <>
      <Text className={classes.label}>{label}</Text>
      <Text className={classes.value}>{value}</Text>
    </>
  );
};
