import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    container: {
        borderBottom: `1px solid ${theme.colors.gray[1]}`,
        width: '100%',
        padding: '0px 24px 24px'
    },
    input: {
        height: '48px',
        minHeight: '48px',
        margin: '0px',
        width: '300px',
        fontSize: '14px',
        '&[data-with-icon]': {
            padding: '0px 30px 0px 0px'
        }
    },
    button: {
        height: '48px',
        minWidth: '158px',
        fontSize: '14px',
        padding: '12px 16px',
    },
    icon: {
        right: 0,
        left: 'auto',
        color: theme.colors.dark[4]
    }
}));