import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button } from '@mantine/core';
import { ContextModalProps, modals } from '@mantine/modals';

import DownloadPricesTemplate from '@/ui/organisms/DownloadPricesTemplate/DownloadPricesTemplate';
import ModalFooter from '@/ui/organisms/Modals/ModalFooter/ModalFooter';
import ModalStep from '@/ui/organisms/Modals/ModalStep/ModalStep';
import UploadTemplate from '@/ui/organisms/UploadTemplate/UploadTemplate';
import { ModalLayout } from '@/ui/templates/Layouts/ModaLayout/ModalLayout';

import { AppDispatch } from '@/store';
import { postPricesImport } from '@/store/slices/products/asyncActions';

const UpdatePrices: FC<ContextModalProps> = ({ ...modalProps }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [excelFile, setExcelFile] = useState<File | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [isFilesReady, setIsFilesReady] = useState<boolean>(true);

  const handlerSubmitTemplate = async () => {
    if (!excelFile) {
      return;
    }
    modals.close(modalProps.id);
    const formData = new FormData();
    if (excelFile) {
      formData.append('file', excelFile);
    }
    await dispatch(
      postPricesImport({
        file: formData.get('file'),
      })
    );
  };

  useEffect(() => {
    setIsFilesReady(!excelFile || !excelFile);
  }, [excelFile]);

  return (
    <ModalLayout title="Установить/обновить цены" {...modalProps}>
      <Box mx={'-24px'}>
        <ModalStep titleSub="Шаг 1. Скачайте шаблон и заполните его" borderBottom>
          <DownloadPricesTemplate />
        </ModalStep>
        <ModalStep titleSub="Шаг 2. Загрузите заполненный шаблон">
          <UploadTemplate setExcelFile={setExcelFile} setIsError={setIsError} />
        </ModalStep>
      </Box>
      <ModalFooter variant="xs" align="center">
        <Button
          size="lg"
          variant="filled"
          onClick={handlerSubmitTemplate}
          disabled={isFilesReady || isError}
          fz={14}
          w={280}
        >
          Продолжить
        </Button>
      </ModalFooter>
    </ModalLayout>
  );
};

export default UpdatePrices;
