import { TFilterProduct } from '@/types/api/product';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, Flex, Group, LoadingOverlay, Text } from '@mantine/core';
import debounce from 'debounce';
import omit from 'lodash/omit';

import { getOffset } from '@/utils/getOffset';
import { getPageInUrl } from '@/utils/getPageInUrl';

import useIsSellerStatusActive from '@/hooks/useIsSellerStatusActive';

import LoaderDots from '@/ui/atoms/LoaderDots/LoaderDots';
import { openProductUpload, openUpdatePrices } from '@/ui/organisms/Modals/modals';
import PageBody from '@/ui/organisms/Page/components/PageBody/PageBody';
import PageContent from '@/ui/organisms/Page/components/PageContent/PageContent';
import PageHeader from '@/ui/organisms/Page/components/PageHeader/PageHeader';
import PageTop from '@/ui/organisms/Page/components/PageTop/PageTop';
import Page from '@/ui/organisms/Page/Page';
import PaginationCustom from '@/ui/organisms/PaginationCustom/PaginationCustom';
import SelectCategory from '@/ui/organisms/SelectCategory/SelectCategory';
import SelectStatus from '@/ui/organisms/SelectStatus/SelectStatus';
import MassChoice from '@/ui/organisms/TableCustom/components/MassChoice/MassChoice';
import { TypedInput, TypedInputChangeEvent } from '@/ui/organisms/TypedInput/TypedInput';

import AddPropduct from './components/AddProducts/AddPropduct';
import DownloadProducts from './components/DownloadProducts/DownloadProducts';
import ProductsOperationsController from './components/ProductsOperationsController/ProductsOperationsController';
import ProductsTable from './components/ProductsTable/ProductsTable';
import { getPluralizeTitle } from './utils/getPluralizeTitle';
import { useStyles } from './styles';

import { AppDispatch } from '@/store';
import { getProducts, getProductsMore } from '@/store/slices/products/asyncActions';
import {
  selectFetchingImport,
  selectFetchingPricesImport,
  selectFetchingProducts,
  selectProducts,
  selectProductsChangeStatus,
  selectProductsLimit,
  selectProductsMeta,
  selectProductsOffset,
  selectProductsOrder,
  selectProductsSelected,
  selectProductsSort,
} from '@/store/slices/products/selectors';
import { setSelected } from '@/store/slices/products/slice';
import { selectFilterProducts, selectSearchProducts } from '@/store/slices/search/selectors';
import { setSearchProducts } from '@/store/slices/search/slice';

interface IProductPageProps {
  title: string;
}

const SEARCH_TYPES = [
  {
    label: 'По артикулу',
    value: 'supplierProductId',
    placeholder: 'Введите артикул',
    inputMode: 'numeric' as const,
  },
  {
    label: 'По названию',
    value: 'name',
    placeholder: 'Введите название',
    inputMode: 'text' as const,
  },
  {
    label: 'По штрихкоду',
    value: 'eans',
    placeholder: 'Введите штрихкод',
    inputMode: 'numeric' as const,
  },
];

const ProductsPage: FC<IProductPageProps> = ({ title }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const [more, setMore] = useState<boolean>(false);
  const [search, setSearch] = useState<{ type: string; value: string } | undefined>();
  const products = useSelector(selectProducts);
  const productsMeta = useSelector(selectProductsMeta);
  const productsLimit = useSelector(selectProductsLimit);
  const productsOffset = useSelector(selectProductsOffset);
  const productsOrder = useSelector(selectProductsOrder);
  const productsSort = useSelector(selectProductsSort);
  const changeStatus = useSelector(selectProductsChangeStatus);
  const fetchingProducts = useSelector(selectFetchingProducts);
  const fetchingImport = useSelector(selectFetchingImport);
  const searchData = useSelector(selectSearchProducts);
  const filterData = useSelector(selectFilterProducts);
  const selected = useSelector(selectProductsSelected);
  const fetchingPricesImport = useSelector(selectFetchingPricesImport);
  const [searchParams, setSearchParams] = useSearchParams();
  const isActiveSeller = useIsSellerStatusActive();

  const filters = useMemo(() => Object.values(filterData), [filterData]);
  const isSelecteditems = useMemo(() => selected.length > 0, [selected]);

  const params = useMemo(
    () => ({
      limit: searchParams.get('limit') || productsLimit,
      offset: searchParams.get('offset') || productsOffset,
      page: searchParams.get('page') || 1,
    }),
    [searchParams]
  );

  const isActivePriceButton = useMemo(
    () => fetchingPricesImport || !isActiveSeller,
    [fetchingPricesImport, isActiveSeller]
  );

  const handlerChangePage = (page: number, isMore: boolean) => {
    const newParams = {
      page: page.toString(),
      limit: productsLimit.toString(),
      offset: getOffset(page, productsLimit),
    };

    setMore(isMore);
    setSearchParams(newParams);
    dispatch(setSelected([]));
  };

  const handlerSelectAllChecbox = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const element = event.target as HTMLInputElement;
    if (element?.checked) {
      const newSelectedsArray = products.map((el) => el.supplierProductId);
      dispatch(setSelected(newSelectedsArray));
      return;
    }
    dispatch(setSelected([]));
  };

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      setSearch({ type: 'supplierProductId', value: value });
    }, 1000),
    []
  );
  const getParam = (paramName: string) =>
    searchParams.get(paramName) ? String(searchParams.get(paramName)) : null;

  const onloadRefetch = useCallback(() => {
    const statusCategories = getParam('categories');
    const status = getParam('status');
    const statusNameCategories = getParam('nameCategories');
    const hasEmptyAttributes = getParam('has_empty_attributes');
    const includesKeys = ['categories', 'status', 'has_empty_attributes', 'nameCategories'];

    let requestBody: TFilterProduct = {};

    searchParams.forEach((value, key) => {
      if (includesKeys.includes(key)) {
        const filterExists = filters.some((filter) => filter.fieldName === key);
        if (!filterExists) {
          filters.push({ fieldName: key, fieldValue: value });
        }
      }
    });

    const newParams = {
      ...omit(params, 'page'),
      sort: searchParams.get('sort') || productsSort,
      order: searchParams.get('order') || productsOrder,
      ...(statusCategories && { categories: statusCategories }),
      ...(status && { status: status }),
      ...(hasEmptyAttributes && { has_empty_attributes: hasEmptyAttributes }),
      ...(statusNameCategories && { nameCategories: statusNameCategories }),
    };

    if (searchData?.type || searchData?.value) requestBody.search = searchData;
    if (filters.length > 0 && filters.every((val) => val.fieldValue))
      requestBody.filter = [...filters];

    requestBody.filter = filters.filter((filter) => {
      if (filter.fieldName === 'categories' && (!statusCategories || statusCategories === null)) {
        return false;
      }

      if (filter.fieldName === 'status' && (!status || status === null)) {
        return false;
      }
      if (
        filter.fieldName === 'has_empty_attributes' &&
        (!hasEmptyAttributes || hasEmptyAttributes === null)
      ) {
        return false;
      }
      return true;
    });

    if (more) {
      dispatch(getProductsMore(newParams));
      setMore(false);
    } else {
      const reqBody = Object.keys(requestBody).length ? requestBody : null;
      dispatch(getProducts([newParams, reqBody]));
    }

  }, [searchData, filters, changeStatus, params, productsSort, productsOrder]);

  useEffect(() => {
    onloadRefetch();
    return () => {
      dispatch(setSelected([]));
    };
  }, [searchData, filters, changeStatus, params, productsSort, productsOrder]);

  useEffect(() => {
    if (search && search.value) {
      dispatch(
        setSearchProducts({
          type: search.type,
          value: search.value,
        })
      );
    } else {
      dispatch(setSearchProducts(null));
    }
  }, [search]);

  return (
    <Box pos={'relative'}>
      <Page>
        <Flex justify={'space-between'}>
          <PageHeader title={title}>
            {getPluralizeTitle(productsMeta.count, productsMeta.totalCount)}
          </PageHeader>
          <Group mt={18} mb={24}>
            <Button
              variant="filled"
              disabled={fetchingImport || !isActiveSeller}
              classNames={{ root: classes.button }}
              onClick={() => openProductUpload({})}
            >
              <LoaderDots loading={fetchingImport} bgr="blue" />
              Загрузить каталог
            </Button>
            <Button
              variant="outline"
              disabled={isActivePriceButton}
              classNames={{ root: classes.button }}
              onClick={() => openUpdatePrices({})}
            >
              <LoaderDots loading={fetchingPricesImport} />
              Обновить цены
            </Button>
            <DownloadProducts />
          </Group>
        </Flex>
        <PageContent>
          <LoadingOverlay visible={changeStatus || fetchingProducts} zIndex={100} />
          <PageTop>
            <Group w={'100%'} className={isSelecteditems ? '' : classes.isHidePanel}>
              <Group position="apart" w={'100%'}>
                <Group>
                  <Text mr={10}>Выбрано: {selected.length}</Text>
                  <Button
                    variant="outline"
                    classNames={{ root: classes.button }}
                    onClick={(event) => handlerSelectAllChecbox(event)}
                  >
                    Очистить выбор
                  </Button>
                </Group>
                <Group>
                  <MassChoice selectedItems={selected} items={products} />
                  <DownloadProducts />
                </Group>
              </Group>
            </Group>
            <Group
              position="apart"
              w={'100%'}
              className={isSelecteditems ? classes.isHidePanel : ''}
            >
              <Group spacing={8} className={classes.content}>
                <TypedInput
                  types={SEARCH_TYPES}
                  onChange={(e: TypedInputChangeEvent) => handleSearchChange(e.value)}
                />
                <SelectStatus />
                <SelectCategory />
              </Group>
              <Group spacing={8}>
                <AddPropduct />
                <Button
                  variant="outline"
                  disabled={isActivePriceButton}
                  classNames={{ root: classes.button }}
                  onClick={() => openUpdatePrices({})}
                >
                  <LoaderDots loading={fetchingPricesImport} />
                  Обновить цены
                </Button>
                <DownloadProducts />
              </Group>
            </Group>
          </PageTop>

          <PageBody>
            <ProductsTable
              items={products}
              handlerCheckboxAll={handlerSelectAllChecbox}
              currentPage={Number(getPageInUrl(searchParams)) ?? Number(params?.page)}
            />
          </PageBody>

          <PaginationCustom
            limitItems={Number(params.limit)}
            totalItems={Number(productsMeta.count)}
            currentPage={Number(getPageInUrl(searchParams)) ?? Number(params?.page)}
            handler={handlerChangePage}
            scrollOnChange
          />
        </PageContent>
      </Page>

      <ProductsOperationsController onRefetch={onloadRefetch} />
    </Box>
  );
};

export default ProductsPage;
