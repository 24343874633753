import { FC, ReactNode } from 'react';
import { Button, Stack, Text, useMantineTheme } from '@mantine/core';
import { Anchor } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import ModalCLose from '../ModalClose/ModalClose';
import ModalContent from '../ModalContent/ModalContent';
import ModalFooter from '../ModalFooter/ModalFooter';

import { ReactComponent as IconModalFail } from '@/assets/icons/redesign/iconModalFail.svg';

export interface ErrorModalProps {
  title?: ReactNode;
  body?: ReactNode;
  buttonText?: string;
  onSubmit?: () => void;
  errorReportPath?: string;
  errorReportLabel?: string;
  displayIcon?: boolean;
}

export const ModalError: FC<ContextModalProps<ErrorModalProps>> = ({
  context,
  id,
  innerProps: { title, body, buttonText, onSubmit, errorReportPath, errorReportLabel },
}) => {
  const { colors } = useMantineTheme();

  const handleSubmit = () => {
    context.closeModal(id);
    onSubmit?.();
  };

  const handleClose = () => {
    context.closeModal(id);
  };

  return (
    <ModalContent boxSize={535}>
      <ModalCLose onClose={handleClose} />
      <Stack spacing={32} align="center">
        <Text size={24} weight={700} color={colors.gray[7]} align="center">
          {title || 'Успешно!'}
        </Text>
        <IconModalFail style={{ maxWidth: '100%' }} />
        <Text align="center" sx={{ lineHeight: 1.4 }}>
          {body}
        </Text>
        {errorReportPath && (
          <Anchor href={errorReportPath} color={colors.mainColor[6]}>
            {errorReportLabel ? errorReportLabel : 'Скачать товары с ошибками'}
          </Anchor>
        )}
        <ModalFooter align="center" variant="xs">
          <Button onClick={handleSubmit} w="100%" fz={14} miw={280} size="lg">
            {buttonText || 'Подтвердить'}
          </Button>
        </ModalFooter>
      </Stack>
    </ModalContent>
  );
};
