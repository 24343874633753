import { RootState } from '@/store';

export const selectModalEansID = (state: RootState) => state.modal.modalEansID;
export const selectModalArchiveID = (state: RootState) => state.modal.modalArchiveID;
export const selectModalUploadID = (state: RootState) => state.modal.modalUploadID;
export const selectModalCategoryID = (state: RootState) => state.modal.modalCategoryID;
export const selectModalInstructionID = (state: RootState) => state.modal.modalInstructionID;
export const selectModalSuccessID = (state: RootState) => state.modal.modalSuccesID;
export const selectModalErrorID = (state: RootState) => state.modal.modalErrorID;
export const selectModalDataFillId = (state: RootState) => state.modal.modalDataFillId;
export const selectModalUpdatePrices = (state: RootState) => state.modal.modalUpdatePrices;
export const selectProfileSubmittedModal = (state: RootState) => state.modal.profileSubmitted;
export const selectModalSameFilesNames = (state: RootState) => state.modal.modalSameFilesNames;
export const selectModals = (state: RootState) => state.modal;
