import { createStyles } from '@mantine/core';

export const useStyles = createStyles(({ colors }) => ({
  wrapper: {
    position: 'relative',
    maxWidth: '220px',
    paddingRight: '0px !important'
  },
  selectItem: {
    fontSize: '12px',
    lineHeight: '20px'
  },
  selectLargeInput: {
    height: '48px',
    minHeight: '48px',
    width: '220px',
    fontSize: '14px',
    lineHeight: '20px',
    color: 'inherit',
    border: 'none',
  },
  close: {
    fill: colors.text[7],
    flexShrink: 0,
    width: '14px',
    height: '14px',
    cursor: 'pointer',
  },
  arrow: {
    flexShrink: 0,
    width: '14px',
    height: '14px',
  },
  rightSection: {
    width: 'auto',
    padding: '0px 12px',
    justifyContent: 'flex-end',
  },
}));
