import { IChangePasswordByEmailConfirm } from '@/types/api/auth';

import { FC, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Button } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import reCaptchaVerify from '@/utils/reCaptchaVerify';

import { RESEND_CODE_TIME } from '@/constants/common';
import { routNames } from '@/constants/routNames';
import { RESTORE_PASSWORD_EMAIL_CODE } from '@/constants/validationSchemas';

import InputWithLabel from '@/ui/atoms/InputWithLabel/InputWithLabel';
import ResendCode from '@/ui/organisms/ResendCode/ResendCode';

import AuthFooter from '../AuthFooter/AuthFooter';

import { AppDispatch } from '@/store';
import {
  changePasswordByEmailAction,
  changePasswordByEmailConfirmAction,
} from '@/store/slices/auth/asyncAction';
import {
  selectFetchingchangePasswordByEmailConfirm,
  selectUserSellerEmail,
} from '@/store/slices/auth/auth';

const RestorePasswordEmailCode: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const fetching = useSelector(selectFetchingchangePasswordByEmailConfirm);
  const userSellerEmail = useSelector(selectUserSellerEmail);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const getNewCode = useCallback(() => {
    reCaptchaVerify(executeRecaptcha, 'changePasswordByEmailAction').then((recaptchaToken) => {
      if (recaptchaToken) {
        const sendData = {
          emailSellerLogin: userSellerEmail,
          recaptchaToken,
        };
        dispatch(changePasswordByEmailAction(sendData));
      }
    });
  }, [executeRecaptcha, userSellerEmail]);

  const handleSubmit = (values: Record<string, string>) => {
    reCaptchaVerify(executeRecaptcha, 'changePasswordByEmailConfirmAction').then(
      (recaptchaToken) => {
        if (recaptchaToken) {
          const sendData: IChangePasswordByEmailConfirm = {
            emailSellerLogin: values.email,
            password: values.password,
            recaptchaToken,
            actionSource: 'web',
          };
          dispatch(changePasswordByEmailConfirmAction(sendData));
        }
      }
    );
  };

  const form = useForm({
    initialValues: {
      email: userSellerEmail,
      password: '',
    },
    validateInputOnChange: true,
    validate: yupResolver(RESTORE_PASSWORD_EMAIL_CODE),
  });

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} noValidate={true}>
      <Box>
        <InputWithLabel
          name="email"
          label="e-mail"
          autoComplete="off"
          size="md"
          inputMode="email"
          {...form.getInputProps('email')}
          value={form.getInputProps('email').value}
          disabled={!!userSellerEmail}
          mb={16}
        />
        <InputWithLabel
          // type="password"
          inputtype="password"
          label="Пароль"
          autoComplete="off"
          size="md"
          {...form.getInputProps('password')}
          value={form.getInputProps('password').value}
        />
        <ResendCode
          resendHandler={getNewCode}
          timer={RESEND_CODE_TIME}
          timerText="Отправить пароль повторно можно через"
          buttonText="Отправить пароль повторно"
        />
      </Box>
      <AuthFooter>
        <Box>
          <Button
            loading={fetching}
            disabled={!form.isDirty}
            type="submit"
            size="lg"
            variant="filled"
            fullWidth
            fz={14}
            h={48}
          >
            Подтвердить
          </Button>
        </Box>
        <Box mt={16} fz={14}>
          <Link target={'_blank'} to={routNames.SERVICES.FAQ}>
            Не приходит пароль
          </Link>
        </Box>
      </AuthFooter>
    </form>
  );
};

export default RestorePasswordEmailCode;
