import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme, { fullSize }: { fullSize: boolean }) => ({
    root: {
        maxWidth: '100%',
    },
    overlay: {
        zIndex: 401,
    },
    inner: {
        zIndex: 402,
    },
    body: {
        padding: '0px',
    },
    header: {
        position: 'relative',
        justifyContent: 'center',
        padding: '28px 0px 0px',
        background: theme.colors.customWhite[0],
    },
    title: {
        fontSize: '22px',
        lineHeight: '28px',
        padding: '0px 75px',
        fontWeight: 600,
    },
    close: {
        position: 'absolute',
        width: '40px',
        height: '40px',
        color: theme.colors.text[7],
        fontSize: '20px',
        right: '32px',
        top: '27px',
        outline: 'none',
        '&:focus': {
            outline: 'none'
        },
        overflow: 'hidden'
    },
    content: {
        borderRadius: !fullSize ? '20px' : '0px',
        width: '100%',
        maxWidth: '100%',
    },

}));
