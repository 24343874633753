import dayjs from 'dayjs';

export const dateParser: (str: string) => Date | null = (str: string) => {
  if (!str) return null;

  const parts = str
    .split('.')
    .filter((part) => part.length > 0)
    .map((substr) => Number(substr))
    .filter((value) => value && !isNaN(value));

  const currentDate = dayjs();

  return dayjs()
    .date(parts[0] || currentDate.date())
    .month(parts[1] ? parts[1] - 1 : currentDate.month())
    .year(
      (parts[2] && parts[2].toString().length <= 2 ? 2000 + parts[2] : parts[2]) ||
        currentDate.year()
    )
    .toDate();
};
