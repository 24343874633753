import ProductEans from '@/ui/organisms/Modals/ProductEans/ProductEans';
import ChooseCategory from '@/ui/organisms/Modals/ChooseCategory/ChooseCategory';
import ProductToArchive from '@/ui/organisms/Modals/ProductToArchive/ProductToArchive';
import TemplateInstruction from '@/ui/organisms/Modals/TemplateInstruction/TemplateInstruction';
import UpdatePrices from '@/ui/organisms/Modals/UpdatePrices/UpdatePrices';
import UploadProducts from '@/ui/organisms/Modals/UploadProducts/UploadProducts';

import { ModalAddDocument } from './AddDocument/ModalAddDocument';
import { ModalAddProducts } from './AddProducts/ModalAddProducts';
import { AddProductsToSupply } from './AddProductsToSupply/AddProductsToSupply';
import { ChangeSupplyWarehouse } from './ChangeSupplyWarehouse/ChangeSupplyWarehouse';
import { ModalConfirmRemove } from './ConfirmRemove/ModalConfirmRemove';
import CreateProduct from './CreateProduct/CreateProduct';
import { ModalError } from './Error/Error';
import { ModalFail } from './Fail/ModalFail';
import { LoadingErrorModal } from './LoadingErrorModal/LoadingErrorModal';
import { ModalFailProducts } from './ModalFailProducts/ModalFailProducts';
import { ModalPreviewProduct } from './PreviewProduct/PreviewProduct';
import { SetSupplyCar } from './SetSupplyCar/SetSupplyCar';
import { ModalSuccess } from './Success/ModalSuccess';
import { SupplyPackages } from './SupplyPackages/SupplyPackages';
import { SupplySlot } from './SupplySlot/SupplySlot';

export const modalsList = {
  addProduct: ModalAddProducts,
  success: ModalSuccess,
  fail: ModalFail,
  confirmRemove: ModalConfirmRemove,
  addDocument: ModalAddDocument,
  failProducts: ModalFailProducts,
  selectWarehouse: ChangeSupplyWarehouse,
  addProductsToSupply: AddProductsToSupply,
  errorModal: ModalError,
  supplySlot: SupplySlot,
  supplyPackages: SupplyPackages,
  setSupplyCar: SetSupplyCar,
  productToArchive: ProductToArchive,
  productEans: ProductEans,
  productInstruction: TemplateInstruction,
  productUpload: UploadProducts,
  productUpdatePrices: UpdatePrices,
  categoriesModal: ChooseCategory,
  chooseFilter: ChooseCategory,
  loadingError: LoadingErrorModal,
  createProduct: CreateProduct,
  previewProduct: ModalPreviewProduct,
};
