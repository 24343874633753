type DateConstraint = [number, number] | null;

export const sortDates = (dates: Date[]) => {
  return dates.sort((a, b) => a.getTime() - b.getTime());
};

export const getConstraint = (date?: Date) =>
  (date ? [date.getMonth(), date.getFullYear()] : null) as DateConstraint;

export const getMinMaxConstraints: (dates: Date[]) => [DateConstraint, DateConstraint] = (
  dates: Date[]
) => {
  if (!dates.length) return [null, null];
  const sortedDates = sortDates(dates);
  const minDate = sortedDates[0];
  const maxDate = sortedDates[sortedDates.length - 1];

  return [getConstraint(minDate), getConstraint(maxDate)];
};

type DayValue = number | null;

const getDaysInMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();

export const getWeeks = (startDate: [number, number]) => {
  const weeks: DayValue[][] = [];
  const [month, year] = startDate;

  const date = new Date(year, month, 1);
  const daysInMonth = getDaysInMonth(date);

  while (date.getMonth() === month) {
    const dayOfMonth = date.getDate();
    const dayOfWeek = (7 + date.getDay() - 1) % 7;
    const preOffset = dayOfWeek;
    const postOffset = Math.max(dayOfMonth + 6 - daysInMonth, 0);

    const week: DayValue[] = [
      ...Array(preOffset).fill(null),
      ...Array.from({ length: 7 - preOffset - postOffset }, (_, i) => i + dayOfMonth),
      ...Array(postOffset).fill(null),
    ];
    weeks.push(week);
    const toNextWeek = date.getDate() + 7 - preOffset;
    date.setDate(toNextWeek);
  }

  return weeks;
};

export type DateTuple = [number, number];

export const next = (monthYear: DateTuple): DateTuple => {
  const [month, year] = monthYear;

  if (month === 11) return [0, year + 1];
  return [month + 1, year];
};

export const prev = (monthYear: DateTuple): DateTuple => {
  const [month, year] = monthYear;

  if (month === 0) return [11, Math.max(year - 1, 0)];
  return [month - 1, year];
};

const getNumberFromDateTuple = (tuple: DateTuple) => {
  let yearPart = tuple[1].toString();
  let monthPart = tuple[0].toString();
  if (monthPart.length < 2) monthPart = `${0}${monthPart}`;

  const res = Number(`${yearPart}${monthPart}`);
  return isNaN(res) ? 0 : res;
};

export const isBefore = (tupleA: DateTuple, tupleB: DateTuple, inclusive?: boolean) => {
  const numberViewA = getNumberFromDateTuple(tupleA);
  const numberViewB = getNumberFromDateTuple(tupleB);

  return inclusive ? numberViewA <= numberViewB : numberViewA < numberViewB;
};
