import { ProfileTabCode } from '@/types/api/profile';

import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Loader } from '@mantine/core';
import { Text } from '@mantine/core';
import { Check } from 'tabler-icons-react'

import { selectUser } from '@/store/slices/user/slice';

interface SubmitButtonProps {
  isFormValid: boolean;
  tabCode: string;
  isOfferAccepted: boolean;
  isSaving: boolean;
}

export const SubmitButton: FC<SubmitButtonProps> = ({
  isFormValid,
  isOfferAccepted,
  isSaving,
  tabCode,
}) => {
  const user = useSelector(selectUser);

  const isButtonDisabled = useMemo(() => {
    if (tabCode === ProfileTabCode.ABOUT) {
      if (!user?.companyEmail && user?.companyEmailInConfirmation) {
        return true;
      }
    }
    if (tabCode !== ProfileTabCode.DOCUMENTS) {
      if (!isFormValid || isSaving) {
        return true;
      }
    } else {
      if (!isFormValid || !isOfferAccepted || isSaving) {
        return true;
      }
    }
    return false;
  }, [tabCode, isFormValid, isOfferAccepted, isSaving, user]);

  return (
    <Button
      type="submit"
      variant="filled"
      size="lg"
      mt={32}
      w={300}
      fz={16}
      h={60}
      // disabled={
      //   tabCode !== ProfileTabCode.DOCUMENTS
      //     ? !isFormValid || isSaving
      //     : !isFormValid || !isOfferAccepted || isSaving
      // }
      disabled={isButtonDisabled}
    >
      {isSaving ? <Loader size={24} /> :
        <>
          <Text mr={12}>
            Подтвердить
          </Text>
          <Check size={18} />
        </>
      }
    </Button>
  )
};
