import { SupplyCar, SupplyCarForm } from '@/types/api/supplies';

import { FC, useMemo, useState } from 'react';
import { Box, Button, Text } from '@mantine/core';
import { ContextModalProps, modals } from '@mantine/modals';

import { ModalLayout } from '@/ui/templates/Layouts/ModaLayout/ModalLayout';

import ModalFooter from '../ModalFooter/ModalFooter';

import { CarForm } from './CarForm/CarForm';

import { suppliesApi } from '@/store/slices/supplies/slice';

export interface SetSupplyCarProps {
  id: string;
  onSubmit?: () => void;
  cars?: SupplyCar[] | null;
}

export const SetSupplyCar: FC<ContextModalProps<SetSupplyCarProps>> = ({
  innerProps: { id, onSubmit, cars },
  ...modalProps
}) => {
  const [setCars, { isLoading }] = suppliesApi.useSetCarsMutation();
  const [forms, setForms] = useState<(SupplyCarForm | null)[]>(cars || [null]);

  const handleChange = (form: SupplyCarForm | null, i: number) => {
    setForms((value) => {
      const nextValue = [...value];
      nextValue[i] = form;
      return nextValue;
    });
  };

  const isValid = useMemo(() => {
    return forms.every((form) => form !== null);
  }, [forms]);

  const handleSubmit = async () => {
    const payload = forms.filter((form): form is SupplyCarForm => form !== null);
    await setCars({ id, cars: payload });
    modals.close(modalProps.id);
    onSubmit?.();
  };

  return (
    <ModalLayout title="Автомобиль, водитель" loading={isLoading} {...modalProps}>
      <Box p={32}>
        <Text mb={32} align="center">
          Укажите информацию об автомобиле и данные водителя
        </Text>
        {forms.map((form, i) => (
          <CarForm
            key={i}
            initialValues={form ? form : undefined}
            onChange={(e) => {
              handleChange(e, i);
            }}
          />
        ))}
      </Box>
      <ModalFooter align='center' variant='xs'>
        <Button size="md" disabled={!isValid || isLoading} onClick={handleSubmit}>
          Сохранить
        </Button>
      </ModalFooter>
    </ModalLayout>
  );
};
