import { createStyles, getStylesRef } from '@mantine/core';

export const useStyles = createStyles(
  (
    theme,
    {
      size = 'xs',
      showClearButton,
      placeholder,
    }: { size?: 'xs' | 'sm' | 'md' | 'lg'; showClearButton: boolean; placeholder: boolean }
  ) => {
    let height = 38;

    if (size === 'xs') height = 38;
    if (size === 'sm') height = 20;
    if (size === 'md') height = 48;
    if (size === 'lg') height = 60;

    return {
      valueHolder: {
        position: 'absolute',
        height: height,
        top: 1,
        left: 1,
        zIndex: 99,
        width: '100%',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        [`&:not([data-value=""]) + .${getStylesRef('select-custom-label')}`]: {
          color: theme.colors.gray[8],
          top: '0.4em',
          transform: 'translateY(0) scale(0.8)',
          [':after']: {
            display: 'none',
          },
        },
        [`&[data-value=""][data-error="true"] + .${getStylesRef('select-custom-label')}`]: {
          transform: 'translateY(calc(-50% - 10px))',
        },
        ['&:after']: {
          paddingLeft: 0,
          paddingRight: showClearButton ? 80 : 16,
          paddingTop: placeholder ? 0 : '0.8em',
          alignItems: 'center',
          fontSize: 14,
          content: 'attr(data-value)',
          pointerEvents: 'none',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          flexGrow: 0,
          width: '100%',
          overflow: 'hidden',
        },
        ["&[data-disabled='true']:after"]: {
          color: theme.colors.gray[5],
        },
      },
      wrapper: {
        position: 'relative',
        flex: 1,
      },
      root: {
        fontSize: 14,
      },
      selectItem: {
        fontSize: '14px',
      },
      selectInput: {
        height: `${height}px`,
        padding: '0px 32px 0px 0px',
        fontSize: '14px',
        color: 'transparent',
        paddingRight: showClearButton ? 80 : 48,
        ['&[data-disabled]']: {
          color: 'transparent',
        },
      },
      label: {
        ref: getStylesRef('select-custom-label'),
        position: 'absolute',
        zIndex: 1,
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        color: theme.colors.gray[5],
        fontSize: 14,
        transformOrigin: 'top left',
        pointerEvents: 'none',
        ['&[data-required="true"]:after']: {
          content: '""',
          position: 'absolute',
          top: 0,
          right: `-0.8em`,
          width: '0.4em',
          height: '0.4em',
          borderRadius: '50%',
          backgroundColor: theme.colors.red[6],
        },
      },
      icon: {
        color: 'inherit',
        fontSize: 'inherit',
      },
      rightSection: {
        pointerEvents: 'none',
        width: showClearButton ? 80 : 48,
        color: theme.colors.gray[5],
      },
    };
  }
);
