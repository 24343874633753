import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  title: {
    display: 'inline',
    position: 'relative',
    width: 'min-content',
    '&[data-required="true"]::after': {
      content: '""',
      position: 'absolute',
      top: 2,
      right: -12,
      width: 6,
      height: 6,
      borderRadius: '50%',
      backgroundColor: theme.colors.red[6],
    },
  },
}));
