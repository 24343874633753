import { IEmailConfirmRequest, IEmailConfirmResponse } from "@/types/api/auth";
import { IDadataParams } from "@/types/api/dadata";
import {
  IChangePhoneRequest,
  IConfirmPhoneRequest,
  IPutConsentEmailReqParam,
  ISendSmsRequest,
  IUser
} from "@/types/api/user";
import { IToken } from "@/types/common/common";

import api from "../instance";
import { tryAction } from "../tryAction";
import { usersUrls } from "../urls/usersUrls";

import { tryActionStatus } from './../tryAction';

export const putUserName = (params: {
  id: string;
  data: Pick<IUser, "name" | "lastName" | "secondName">;
}) => api.put(usersUrls.user.detail(params.id), params.data);

export const changePassword = (params: {
  oldPassword: string;
  newPassword: string;
  confirmationNewPassword: string;
  refreshToken: string;
}) =>
  api
    .patch<{ token: IToken }>(
      usersUrls.changePassword.changePasswordProfile,
      params
    )
    .catch((err) => {
      if (err.response) return err.response.data.message as string;
      return "Пароль не был изменён. Попробуйте позже";
    });

export const trySendSms = (data: ISendSmsRequest) =>
  tryActionStatus(api.post(usersUrls.user.sendSms, data));

export const tryConfirmCurrentPhone = (data: IConfirmPhoneRequest) =>
  tryActionStatus(api.patch(usersUrls.user.confirmCurrentPhone, data));

export const tryChangePhone = (data: IChangePhoneRequest) =>
  tryActionStatus(api.patch(usersUrls.user.changePhone, data));

export const tryGetUser = () =>
  tryAction(api.get<IUser>(usersUrls.user.current));

export const tryConfirmEmail = (data: IEmailConfirmRequest) =>
  tryAction(api.put<IEmailConfirmResponse>(usersUrls.user.confirmEmail, data));

export const tryConsentEmail = (params: IPutConsentEmailReqParam) =>
  // tryActionStatus(
    api.put(usersUrls.user.consentEmail, undefined, {
      params
    })
  // );

// export const tryGetCompanyInfo = () =>
//   tryAction(api.get<ICompanyInfoVariants>(usersUrls.seller.companyInfo));

// export const trySetCompanyInfo = (data: ICompanyInfo) =>
//   tryAction(api.patch(usersUrls.seller.companyInfo, data));

export const tryGetDadataInfo = (data: IDadataParams) =>
  tryAction(api.get(usersUrls.dadata.dadataInfo(data)));
