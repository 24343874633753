export enum FieldType {
    TEXT = 'text',
    SELECT = 'select',
    MULTI_SELECT = 'multi_select',
    BOOLEAN = 'boolean',
    IMAGE = 'image',
}

export enum TabsCodeType {
    COMMON = 'common',
    CHARACTERISTICS = 'characteristics',
    ADDITIONAL_CHARACTERISTICS = 'additional_characteristics',
    IMAGES = 'images',
}