import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useToken } from '../utils/token';

import { useAppDispatch } from '@/store';
import {
  fetchUserAction,
  resetUser,
  selectFetchingUser,
  selectUser,
} from '@/store/slices/user/slice';

export const useUser = () => {
  const dispatch = useAppDispatch();
  const { token } = useToken();
  const isUserFetching = useSelector(selectFetchingUser);
  const user = useSelector(selectUser);

  const checkUser = async () => {
    if (isUserFetching) return;
    if (!token) {
      dispatch(resetUser);
    }
    if (!user && token) {
      await dispatch(fetchUserAction({ type: null }));
    }
  };

  useEffect(() => {
    checkUser();
  }, [user, token]);
};
