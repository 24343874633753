import { ICategory, ICategoryTree, TActiveCategories } from '@/types/entites/category';

import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, Group, Select, UnstyledButton } from '@mantine/core';
import { ContextModalProps, modals } from '@mantine/modals';

import ModalFooter from '@/ui/organisms/Modals/ModalFooter/ModalFooter';
import CategorySelection from '@/ui/organisms/СategorySelection/CategorySelection';
import { ModalLayout } from '@/ui/templates/Layouts/ModaLayout/ModalLayout';

import { useStyles } from './styles';

import { ReactComponent as ArrowBlack } from '@/assets/icons/chevron_bottom.svg';
import { AppDispatch } from '@/store';
import { categoriesApi } from '@/store/slices/category/api';
import {
  selectActiveCategories,
  selectCurrentCategory,
  selectCurrentTree,
  selectSelectedCategory,
} from '@/store/slices/category/selectors';
import {
  setActiveCategories,
  setCategoriesCurrentTree,
  setCurrentCategory,
  setSelectedCategory,
} from '@/store/slices/category/slice';
import { selectProductsLimit } from '@/store/slices/products/selectors';
import { deleteFilterProduct, setFilterProducts } from '@/store/slices/search/slice';
export interface IChooseCategory {
  props?: any;
  id: string;
}

const ChooseCategory: FC<ContextModalProps<IChooseCategory>> = ({ ...modalProps }) => {
  const dispatch = useDispatch<AppDispatch>();
  const selectedCategory = useSelector(selectSelectedCategory);
  const currentCategory = useSelector(selectCurrentCategory);
  const activeCategories = useSelector(selectActiveCategories);
  const currentTree = useSelector(selectCurrentTree);
  const productsLimit = useSelector(selectProductsLimit);

  const [currentTreeState, setCurrentTreeState] = useState<ICategoryTree[]>(currentTree);
  const [activeCategoriesState, setActiveCategoriesState] = useState<
    TActiveCategories[] | ICategory[]
  >(activeCategories);
  const [searchParams, setSearchParams] = useSearchParams();
  const [getCategoriesTree, { data: tree, isFetching: treeFetching }] =
    categoriesApi.useLazyGetCategoriesTreeQuery();
  const [selectValue, setSelectValue] = useState<string | null>(null);
  const [active, setActive] = useState(false);
  const { classes } = useStyles();
  const getParam = (paramName: string) =>
    searchParams.get(paramName) ? String(searchParams.get(paramName)) : null;
  const hasEmptyAttributes = getParam('has_empty_attributes');
  const statusCategories = getParam('categories');
  const statusNameCategories = getParam('nameCategories');
  const status = searchParams.get('status') ? String(searchParams.get('status')) : '';

  const params = useMemo(
    () => ({
      page: '1',
      limit: productsLimit.toString(),
      offset: '0',
      ...(status !== '' && { status: status }),
      ...(hasEmptyAttributes && { has_empty_attributes: hasEmptyAttributes }),
      ...(statusCategories && { categories: statusCategories }),
      ...(statusNameCategories && { nameCategories: statusNameCategories }),
    }),
    [searchParams]
  );

  const handlerClose = () => modals.close(modalProps.id);

  const handleReset = () => {
    dispatch(setSelectedCategory(null));
    setCurrentTreeState(tree ? [{ tree }] : []);
    setActiveCategoriesState([]);
  };

  const hanlerSendCategory = () => {
    handlerClose();
    const newParams = {
      ...params,
      ...(selectedCategory?.code && {
        categories: selectedCategory?.code ? String(selectedCategory?.code) : '',
      }),
      ...(selectedCategory?.label && {
        nameCategories: selectedCategory?.label ? String(selectedCategory?.label) : '',
      }),
    };

    if (selectedCategory?.code) {
      dispatch(
        setFilterProducts({
          fieldName: 'categories',
          fieldValue: String(selectedCategory?.code),
        })
      );
      dispatch(setCurrentCategory(selectedCategory));
    } else {
      dispatch(deleteFilterProduct('categories'));
      dispatch(setSelectedCategory(null));
      dispatch(setCurrentCategory(null));
      dispatch(setActiveCategories([]));
      dispatch(setCategoriesCurrentTree([]));
    }

    setSearchParams(newParams);
  };

  const hanlerSendFilter = () => {
    handlerClose();
    const newParams = {
      ...params,
      ...(selectValue && { has_empty_attributes: String(selectValue == 'Да' ? true : false) }),
    };

    if (selectValue) {
      dispatch(
        setFilterProducts({
          fieldName: 'has_empty_attributes',
          fieldValue: String(selectValue == 'Да' ? true : false),
        })
      );
    } else {
      dispatch(deleteFilterProduct('has_empty_attributes'));
    }

    setSearchParams(newParams);
  };

  const handleChange = (element: ICategory) => {
    dispatch(setSelectedCategory(element));
    setActiveCategoriesState((prev) => {
      const updated = prev.slice(0, element.depthLevel);
      updated[element.depthLevel] = element;
      return updated;
    });
  };

  const handleSetCurrentTree = (list: ICategoryTree[]) => {
    dispatch(setCategoriesCurrentTree(list));
    setCurrentTreeState(list);
  };

  useEffect(() => {
    if (currentTree.length <= 0) {
      getCategoriesTree();
      if (tree && tree.length > 0) {
        dispatch(setCategoriesCurrentTree([{ tree }]));
        setCurrentTreeState([{ tree }]);
      }
    }
  }, [tree]);

  useEffect(() => {
    if (!selectedCategory) dispatch(setSelectedCategory(currentCategory));
  }, [currentCategory]);

  useEffect(() => {
    setActiveCategoriesState(activeCategories);
  }, [activeCategories]);

  const handleClear = () => {
    const newParams = {
      page: '1',
      limit: productsLimit.toString(),
      offset: '0',
    };
    setSelectValue(null);
    setSearchParams(newParams);
    dispatch(setCurrentCategory(null));
  };

  return (
    <>
      {modalProps?.innerProps?.props?.id !== 'chooseFilter' ? (
        <ModalLayout title="Выбрать категорию" {...modalProps}>
          <Box>
            <CategorySelection
              currentTree={currentTreeState}
              activeCategories={activeCategoriesState}
              onChangeElement={handleChange}
              onCatergoriestTree={handleSetCurrentTree}
              handleReset={handleReset}
              showSelectCategories
              isLoading={treeFetching}
            />
          </Box>
          <ModalFooter align="center" variant="xs">
            <Button
              size="lg"
              variant="filled"
              onClick={() => hanlerSendCategory()}
              p={'13px, 24px'}
              miw={280}
              fz={14}
            >
              Применить
            </Button>
          </ModalFooter>
        </ModalLayout>
      ) : (
        <ModalLayout title="Фильтры" {...modalProps}>
          <Box className={classes.label}>
            <Select
              mt={32}
              h={'30vh'}
              classNames={classes}
              styles={() => ({
                rightSection: {
                  transition: 'transform 0.3s ease-in-out',
                  transform: !active ? '' : 'rotate(0.5turn)',
                },
                input: {
                  '&::placeholder': {
                    color: hasEmptyAttributes !== null ? 'black' : 'none',
                  },
                },
              })}
              label="Не заполненные характеристики"
              placeholder={
                hasEmptyAttributes !== null
                  ? hasEmptyAttributes == 'false'
                    ? 'Нет'
                    : 'Да'
                  : 'Не выбрано'
              }
              data={['Да', 'Нет']}
              rightSection={<ArrowBlack />}
              value={selectValue ?? hasEmptyAttributes}
              onChange={setSelectValue}
              onDropdownOpen={() => {
                setActive(true);
              }}
              onDropdownClose={() => {
                setActive(false);
              }}
            />
          </Box>
          <ModalFooter align="center" variant="xs">
            <>
              <Button
                size="lg"
                variant="filled"
                onClick={hanlerSendFilter}
                p={'13px, 24px'}
                miw={280}
                fz={14}
                mb={16}
              >
                Применить
              </Button>
              <UnstyledButton fz={14} onClick={handleClear}>
                <Group>Сбросить все</Group>
              </UnstyledButton>
            </>
          </ModalFooter>
        </ModalLayout>
      )}
    </>
  );
};

export default ChooseCategory;
