import { FC, useMemo, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Flex, Loader, Stack, Table, Text, Title, useMantineTheme } from '@mantine/core';
import { Box } from '@mantine/core';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';

import PageContent from '@/ui/organisms/Page/components/PageContent/PageContent';
import PageHeader from '@/ui/organisms/Page/components/PageHeader/PageHeader';
import Page from '@/ui/organisms/Page/Page';
import Cell from '@/ui/organisms/TableCustom/components/Cell/Cell';
import Row from '@/ui/organisms/TableCustom/components/Row/Row';
import TBody from '@/ui/organisms/TableCustom/components/TBody/TBody';
import THead from '@/ui/organisms/TableCustom/components/THead/THead';

import { buildDataForChart } from './utils/buildDataForChart';
import getChartOptions from './utils/common';
import { PERIOD_DATE } from './utils/constants';
import { getCurrentMonthStr } from './utils/utils';
import { useStyles } from './styles';

import { summaryOrdersApi } from '@/store/slices/summaryOrders/api';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Filler, Legend);

export interface IChartDataItem {
  shortDate?: string;
  date: string;
  soldSum: number;
  purchasedSum: number;
  soldCount: number;
  purchasedCount: number;
}

interface ISummaryOrdersPageProps {
  title: string;
}

const SummaryOrdersPage: FC<ISummaryOrdersPageProps> = ({ title }) => {
  const currentDate = useRef(new Date());
  const dateTo = new Date(currentDate.current);
  const dateFrom = new Date(currentDate.current);
  dateFrom.setDate(dateFrom.getDate() - PERIOD_DATE);
  dateTo.setDate(dateTo.getDate() - 1);
  const dateFromStr = dateFrom.toISOString();
  const currentDateStr = dateTo.toISOString();
  const { colors } = useMantineTheme();
  const { classes } = useStyles();

  const { data: summaryOrdersData, isLoading } = summaryOrdersApi.useGetStatsOfOrdersQuery({
    date_from: dateFromStr,
    date_to: currentDateStr,
  });

  const buildDateRange = `${getCurrentMonthStr(dateFrom)} - ${getCurrentMonthStr(dateTo)}`;

  const total = summaryOrdersData?.total;
  const mergedData = useMemo(
    () => buildDataForChart(summaryOrdersData?.items || [], currentDateStr),
    [summaryOrdersData]
  );
  const soldData = mergedData.map((item) => ({ x: item.date, y: item.soldCount }));
  const purchasedData = mergedData.map((item) => ({ x: item.date, y: item.purchasedCount }));
  const labelsData = mergedData.map((item) => item.date);

  const options = getChartOptions(mergedData || [], total || null);

  const data = {
    labels: labelsData,
    datasets: [
      {
        label: 'Заказано',
        fill: false,
        data: summaryOrdersData?.items?.length ? purchasedData : [],
        borderColor: colors.mainColor[6],
        borderWidth: 2,
        borderRadius: 20,
        pointBackgroundColor: colors.customWhite[0],
        pointRadius: 8,
        pointBorderWidth: 2,
        pointBorderColor: colors.mainColor[6],
        hoverRadius: 16,
        hoverBackgroundColor: colors.mainColor[6],
      },
      {
        label: 'Выкуплено',
        fill: true,
        borderWidth: 0,
        pointRadius: 0,
        borderColor: colors.brandGrey[2],
        backgroundColor: colors.brandGrey[2],
        data: summaryOrdersData?.items?.length ? soldData : [],
      },
    ],
  };

  const LoaderLayout = () => (
    <Flex h="60vh" justify={'center'} align={'center'}>
      <Loader />
    </Flex>
  );

  return (
    <Page>
      <PageHeader title={title} />
      <PageContent>
        <Stack spacing={32} px={20} py={20}>
          <Flex justify={'space-between'} align={'center'}>
            <Title order={2} fz={16}>
              Заказано товаров в рублях за {PERIOD_DATE} дней
            </Title>
            <Text color={colors.brandGrey[8]} fz={14}>
              Часовой пояс: UTC+3
            </Text>
          </Flex>
          <>
            {isLoading ? (
              <LoaderLayout />
            ) : (
              <>
                <Box className={classes.wrapper}>
                  <Line
                    options={{ ...options, maintainAspectRatio: false }}
                    data={data}
                    height={400}
                  />
                </Box>
                {Boolean(summaryOrdersData?.items?.length) && (
                  <Box w={470}>
                    <Table className={classes.table}>
                      <THead>
                        <Row>
                          <Cell className={classes.cell} th>
                            {buildDateRange}
                          </Cell>
                          <Cell className={classes.cell} th>
                            Заказано
                          </Cell>
                          <Cell className={classes.cell} th>
                            Выкуплено
                          </Cell>
                        </Row>
                      </THead>
                      <TBody>
                        <Row>
                          <Cell className={classes.cell}>
                            <Text fw={700}>Товаров</Text>
                          </Cell>
                          <Cell className={classes.cell}>
                            {summaryOrdersData?.total.purchased.count.toLocaleString()} шт
                          </Cell>
                          <Cell className={classes.cell}>
                            {summaryOrdersData?.total.sold.count.toLocaleString()} шт
                          </Cell>
                        </Row>
                        <Row>
                          <Cell className={classes.cell}>
                            <Text fw={700}>На сумму, ₽</Text>
                          </Cell>
                          <Cell className={classes.cell}>
                            {summaryOrdersData?.total.purchased.sum.toLocaleString()} ₽
                          </Cell>
                          <Cell className={classes.cell}>
                            {summaryOrdersData?.total.sold.sum.toLocaleString()} ₽
                          </Cell>
                        </Row>
                      </TBody>
                    </Table>
                  </Box>
                )}
              </>
            )}
          </>
        </Stack>
      </PageContent>
    </Page>
  );
};

export default SummaryOrdersPage;
