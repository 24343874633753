import { IDocumentFile } from '@/types/api/productDocuments';
import { ID } from '@/types/common/common';

import { FC, useState } from 'react';
import { Flex, Grid, ScrollArea, Stack, Text, useMantineTheme } from '@mantine/core';

import LoaderDots from '@/ui/atoms/LoaderDots/LoaderDots';
import { FileItem } from '@/ui/organisms/FileItem/FileItem';

import { SubtleButton } from '../SubtleButton/SubtleButton';

import { ReactComponent as IconDownload } from '@/assets/icons/redesign/iconDownload.svg';
import { productDocsApi } from '@/store/slices/productDocs/api';

interface FileListProps {
  files: IDocumentFile[];
  id: ID;
}

export const FileList: FC<FileListProps> = ({ files, id }) => {
  const [download] = productDocsApi.useLazyDownloadFilesQuery();
  const [isLoading, setIsLoading] = useState(false);
  const { colors } = useMantineTheme();

  const downloadFile = async () => {
    setIsLoading(true);
    const res = await download({ docId: id });
    setIsLoading(false);
    const { data: downloadedFile, isSuccess } = res;
    if (isSuccess && downloadedFile) {
      const anchor = document.createElement('a');
      const objectUrl = window.URL.createObjectURL(downloadedFile);

      anchor.href = objectUrl;
      anchor.download = `files_${id}.zip`;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    }
  };

  return (
    <Stack spacing={10} h="100%">
      <ScrollArea h={180}>
        <Grid gutter={28} w="100%" mt={-28} pl={8}>
          {files.map((file) => (
            <Grid.Col key={file.id} span={4} p={0} mt={28}>
              <FileItem
                date={file.date}
                ext={file.originalName.split('.').pop() || ''}
                size={file.size}
                name={file.originalName}
                buttons={false}
                key={file.id}
                nameEllipsis
              />
            </Grid.Col>
          ))}
        </Grid>
      </ScrollArea>
      <SubtleButton onClick={downloadFile} mt="auto" w={190} fz={14}>
        {isLoading
          ?
          <LoaderDots loading={isLoading} />
          :
          <Flex align={'center'}>
            <Text mr={6}>
              Cкачать все файлы
            </Text>
            <IconDownload fill={colors.mainColor[6]} />
          </Flex>
        }
      </SubtleButton>
    </Stack>
  );
};
