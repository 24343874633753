import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Text, TextInput, UnstyledButton, useMantineTheme } from '@mantine/core';
import debounce from 'debounce';
import { X } from 'tabler-icons-react';

import { ARTICLE_NOT_FOUND_TEXT } from '@/constants/documents';

import FormGroup from '@/ui/atoms/FormGroup/FormGroup';

import { MIN_SYMBOLS_FOR_ARTICLE } from '../../utils/constants';

import { AppDispatch } from '@/store';
import { checkArticle } from '@/store/slices/products/asyncActions';
import { selectProductArticleExist } from '@/store/slices/products/selectors';

interface IArticleInput {
  placeholder: string;
  classes: Record<string, string>;
  onChange: (value: string) => void;
  onError: (value: boolean) => void;
}

const ArticleInput: FC<IArticleInput> = ({ placeholder, classes, onChange, onError }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [value, setValue] = useState<string>('');
  const isArticleExist = useSelector(selectProductArticleExist);
  const theme = useMantineTheme();

  const handleCheckArticle = useCallback(
    debounce(async (article: string) => {
      if (article.length >= MIN_SYMBOLS_FOR_ARTICLE)
        await dispatch(checkArticle({ supplierProductId: article }));
      else onError(true);
    }, 500),
    []
  );

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue: string = event.target.value;
    // const numbers: string = inputValue.replace(/[^0-9]/g, '');
    setValue(inputValue);
    onChange(inputValue);
    handleCheckArticle(String(inputValue));
  };

  const handleReset = (event: any) => {
    event.stopPropagation();
    setValue('');
    onChange('');
  };

  useEffect(() => {
    if (isArticleExist) onError(false);
    else onError(true);
  }, [isArticleExist, value]);

  return (
    <FormGroup>
      <TextInput
        classNames={{ ...classes }}
        m={0}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        type="text"
        inputMode="numeric"
        rightSection={
          <>
            {value && (
              <UnstyledButton onClick={handleReset}>
                <X />
              </UnstyledButton>
            )}
          </>
        }
      />
      {!isArticleExist && value.length >= MIN_SYMBOLS_FOR_ARTICLE && (
        <Text
          sx={{
            position: 'absolute',
            bottom: '-12px',
            color: theme.colors.red[5],
            fontSize: '10px',
          }}
        >
          {ARTICLE_NOT_FOUND_TEXT}
        </Text>
      )}
    </FormGroup>
  );
};

export default ArticleInput;
