import { IDadataParams } from "@/types/api/dadata";
import { ID } from "@/types/common/common";

export const usersUrls = {
    editProfile: '/users/detail/',
    auth: {
        customerAuth: '/users/customer/auth',
        customerRefresh: '/users/customer/refresh',
    },
    signIn: {
        checkPhone: '/users/checkPhone',
        checkEmail: '/users/checkEmail',
        checkSellerEmailLogin: '/users/checkSellerEmailLogin',
        authByPhone: '/users/authByPhone',
        authByRefresh: '/users/authByRefresh',
        authByCode: '/users/authByCode',
        authByEmail: '/users/authByEmail',
    },
    logout: '/users/logout',
    signUp: {
        user: '/users/user',
        newCode: '/users/newCode',
    },
    changePassword: {
        changePassword: '/users/changePassword',
        changePasswordConfirm: '/users/changePasswordConfirm',
        changePasswordByEmail: '/users/changePasswordByEmail',
        changePasswordByEmailConfirm: '/users/changePasswordByEmailConfirm',
        changePasswordProfile: '/users/profile/changePassword',
    },
    user: {
        current: '/users/current',
        getByExternalId: '/users/getByExternalId',
        detail: (id: ID) => `/users/detail/${id}`,
        changePhone: '/users/detail/changePhone',
        confirmCurrentPhone: '/users/detail/confirmCurrentPhone',
        sendSms: '/users/sendSms',
        confirmEmail: '/users/email/confirm',
        consentEmail: '/users/email/consent',
        subscriptions: '/users/subscriptions',
    },
    upgrade: '/users/upgrade-to-seller',
    seller: {
        companyInfo: '/users/seller/company-info',
        profile: {
            tabs: '/users/seller/profile/tabs',
            uploadFile: (type: string, id?: string) =>
                `/users/seller/profile/document/${type}${id ? `?fileId=${id}` : ''}`,
            removeFile: (type: string) => `/users/seller/profile/file/${type}`,
            submit: {
                about: `/users/seller/profile/tab/about`,
                requisites: `/users/seller/profile/tab/requisites`,
                documents: `/users/seller/profile/tab/documents`,
            },
        },
    },
    dadata: {
        dadataInfo: ({ queryString, type }: IDadataParams) =>
            `/users/dadata/organization/suggestion?queryString=${queryString}${type ? '&type=' + type : ''
            }`,
        bank: (query: string) => `/users/dadata/bank/suggestion?queryString=${query}`,
    },
}