import { Box, Text } from '@mantine/core';

import { useStyles } from './styles';

export const Notification = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.notification}>
      <Text>
        Уважаемый, селлер. Работа в вашем личном кабинете не адаптирована под мобильные устройства.
        Перейдите на десктопную версию.
      </Text>
    </Box>
  );
};
