import { FC } from 'react';
import { Checkbox } from '@mantine/core';
import { Anchor } from '@mantine/core';

interface OfferCheckboxProps {
  onChange?: (v: boolean) => void;
  offerLink?: string;
}

export const OfferCheckbox: FC<OfferCheckboxProps> = ({ onChange, offerLink }) => (
  <Checkbox
    onChange={(e) => onChange?.(e.currentTarget.checked)}
    sx={{
      input: {
        width: '24px',
        height: '24px'
      }
    }}
    mt={48}
    label={
      <>
        Я ознакомлен и согласен с условиями{' '}
        <Anchor href={offerLink || '#'} target="_blank">
          оферты
        </Anchor>
      </>
    }
  />
);
