import { FC } from "react";
import { Box, Center } from "@mantine/core";

import { useStyles } from "./styles";

interface ILoaderDots {
    loading: boolean;
    bgr?: 'blue' | 'default',
}

const LoaderDots: FC<ILoaderDots> = ({ loading, bgr, ...props }) => {
    const { classes } = useStyles({
        background: bgr || '' 
    });

    return (
        <Box className={classes.container} {...props}>
            {loading && (
                <>
                    <Box className={classes.overlay}></Box>
                    <Center>
                        <Box className={classes.dotAnimation}></Box>
                    </Center>
                </>
            )}
        </Box>
    );
};

export default LoaderDots;
