import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  root: {
    padding: '16px 24px',
    borderRadius: '12px',
    backgroundColor: theme.colors.mainColor[9],
    backdropFilter: 'blur(8px)',
    color: theme.colors.customWhite[0],
    fontSize: '14px',
    lineHeight: '20px',
    maxWidth: '530px',
  },
  button: {
    padding: '0px',
    height: 'auto',
    marginLeft: '6px',
  },
  close: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.colors.customWhite[0],
    border: `1px solid ${theme.colors.brandGrey[3]}`,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.08)',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    position: 'absolute',
    top: 0,
    right: '-28px',
  },
  closeImage: {
    fill: theme.colors.text[7],
    width: '9px',
    height: '9px',
  },
}));
