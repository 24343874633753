import { AuthStepsEnum } from '@/types/api/auth';
import { IDadataParams } from '@/types/api/dadata';
import { ICompanyInfo } from '@/types/api/seller';

import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box, Button, Checkbox, Select, Stack } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import classNames from 'classnames';
import debounce from 'debounce';
import { ChevronDown } from 'tabler-icons-react';

import { AVAILABLE_REG_FORMS } from '@/constants/common';
import {
  VALIDATION_COMPANY_INFORMATION,
  VALIDATION_COMPANY_INFORMATION_IP,
  VALIDATION_COMPANY_INFORMATION_OTHER,
} from '@/constants/validationSchemas';
import { validationTexts } from '@/constants/validationTexts';
import useNavigateToProfile from '@/hooks/useNavigateToProfile';
import { useOutsideClick } from '@/hooks/useOutsideClick';

import InputWithLabel from '@/ui/atoms/InputWithLabel/InputWithLabel';

import AuthFooter from '../AuthFooter/AuthFooter';

import InputDropItem from './components/InputDropItem/InputDropItem';
import { useStyles } from './styles';

import { AppDispatch } from '@/store';
import { setAuthStep } from '@/store/slices/auth/auth';
import { fetchDadataInfo } from '@/store/slices/dadata/asyncActions';
import { selectCurrentDadata, selectDadataPrompts } from '@/store/slices/dadata/selectors';
import { setDadataPrompts } from '@/store/slices/dadata/slice';
import { sellerApi } from '@/store/slices/seller/slice';
import { fetchUserAction, selectUser } from '@/store/slices/user/slice';

const CompanyInformation: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { classes } = useStyles();
  const { data: variants, isLoading: variantsFetching } = sellerApi.useFetchCompanyInfoQuery();
  const [setCompanyInfo, { isLoading: settingCompanyInfo }] = sellerApi.useSetCompanyInfoMutation();
  const dadata = useSelector(selectDadataPrompts);
  const currentDadata = useSelector(selectCurrentDadata);
  const user = useSelector(selectUser);
  const [match, setMatch] = useState<boolean>(false);
  const [regForm, setRegForm] = useState<string>('');
  const [activeInput, setActiveInput] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigateToProfile = useNavigateToProfile(user);

  useOutsideClick(dropdownRef, () => setActiveInput(null));

  const handleSubmit = async (values: ICompanyInfo) => {
    await setCompanyInfo(values);
    await dispatch(fetchUserAction({ type: null }));
    navigateToProfile();
    setTimeout(() => {
      dispatch(setAuthStep(AuthStepsEnum.DATA_FILL));
    }, 400);
  };

  const currentValidateShema = useMemo(() => {
    const validationSchemaDifferent =
      regForm === 'ИП' || regForm === 'Самозанятый'
        ? VALIDATION_COMPANY_INFORMATION_IP
        : VALIDATION_COMPANY_INFORMATION_OTHER;

    return yupResolver(!regForm ? VALIDATION_COMPANY_INFORMATION : validationSchemaDifferent);
  }, [regForm]);

  const getLabel = useCallback(
    (fieldName: string) => {
      if (fieldName === 'legalName') {
        if (regForm === 'ИП') {
          return 'Полное наименование ИП';
        } else if (regForm === 'Самозанятый') {
          return 'ФИО';
        } else {
          return 'Юридическое название компании';
        }
      } else if (fieldName === 'legalAddress') {
        if (regForm === 'Самозанятый') {
          return 'Адрес регистрации';
        } else {
          return 'Юридический адрес';
        }
      }
      return '';
    },
    [regForm]
  );

  const form = useForm({
    initialValues: {
      inn: '',
      registrationForm: '',
      legalName: '',
      managementName: '',
      legalAddress: '',
      mailingAddress: '',
      shopName: '',
      matchAddress: false,
    },
    validateInputOnChange: true,
    validateInputOnBlur: true,
    validate: currentValidateShema,
  });

  const setRegFormValue = (e: string) => {
    setRegForm(e);
    form.setFieldValue('registrationForm', e);
  };

  useEffect(() => {
    if (match) {
      form.setValues((values) => ({
        ...values,
        mailingAddress: form.values.legalAddress,
      }));
    } else {
      form.setValues((values) => ({ ...values, mailingAddress: '' }));
    }
    if (regForm) form.validate();
  }, [match]);

  useEffect(() => {
    if (currentDadata) {
      form.setValues((values) => ({
        ...values,
        ...currentDadata,
        legalName: currentDadata?.legalName?.substring(0, 100),
        registrationForm: AVAILABLE_REG_FORMS.includes(currentDadata.registrationForm)
          ? currentDadata.registrationForm
          : '',
      }));
      setRegForm(currentDadata?.registrationForm ? currentDadata?.registrationForm : '');
    }
  }, [currentDadata]);

  useEffect(() => {
    form.validateField('inn');
  }, [currentValidateShema]);

  const debouncedPromts = useCallback(
    debounce((params: IDadataParams) => {
      if (params.queryString) dispatch(fetchDadataInfo(params));
      else {
        dispatch(setDadataPrompts(null));
      }
    }, 500),
    []
  );

  const handlePromptsChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
    const value = e.currentTarget.value;
    const isInputInnValid = fieldName === 'inn' && !/^\d*$/.test(value);

    if (isInputInnValid) {
      setActiveInput(null);
      form.errors.inn = validationTexts.NUMBER;
      return;
    }

    const params: IDadataParams = form.values?.registrationForm
      ? {
          queryString: value,
          type: form.values?.registrationForm == 'ИП' ? 'INDIVIDUAL ' : 'LEGAL',
        }
      : { queryString: value };

    if (value.trim() !== '') {
      setActiveInput(fieldName);
    } else {
      setActiveInput(null);
    }

    debouncedPromts(params);
    form.getInputProps(fieldName).onChange(e);
  };

  const handleRegFormChange = (value: string | null) => {
    setRegFormValue(value || '');
  };

  const handleSetFocus = (e: FocusEvent<HTMLInputElement>, state: boolean, fieldName: string) => {
    setActiveInput(fieldName);
    const onFocus = form.getInputProps(fieldName).onFocus;
    const onBlur = form.getInputProps(fieldName).onBlur;
    if (state) onFocus(e);
    if (!state) onBlur(e);
  };

  const handleChooseListItem = () => {
    setActiveInput(null);
  };

  const handleAddressChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    form.getInputProps('legalAddress').onChange(e);
    if (match) form.getInputProps('mailingAddress').onChange(e);
  };

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} noValidate={true}>
      <Box className={classes.formGroup}>
        <InputWithLabel
          className={classes.formElement}
          {...form.getInputProps('inn')}
          size="md"
          onChange={(e) => handlePromptsChange(e, 'inn')}
          onFocus={(e) => handleSetFocus(e, true, 'inn')}
          label="ИНН"
          autoComplete="off"
          maxLength={12}
          inputMode="numeric"
        />

        {activeInput === 'inn' && dadata && dadata?.length > 0 && regForm !== 'Самозанятый' && (
          <Box ref={dropdownRef} className={classes.dropdown}>
            {dadata?.slice(0, 6).map((item) => (
              <InputDropItem item={item} setFocus={handleChooseListItem} value={form.values.inn} />
            ))}
          </Box>
        )}
      </Box>

      {!variantsFetching && variants && (
        <Select
          className={classNames(classes.formElement)}
          {...form.getInputProps('registrationForm')}
          data={variants?.registrationForms || []}
          name="registrationForm"
          placeholder="Форма регистрации"
          value={regForm}
          onChange={(e) => handleRegFormChange(e)}
          size="md"
          rightSection={<ChevronDown width="20px" height="20px" />}
          disabled={currentDadata?.inn == ('' || undefined || null)}
        />
      )}

      {regForm && (
        <Box className={classes.formGroup}>
          <InputWithLabel
            className={classes.formElement}
            {...form.getInputProps('legalName')}
            onChange={(e) => handlePromptsChange(e, 'legalName')}
            name="legalName"
            label={getLabel('legalName')}
            autoComplete="off"
            size="md"
            maxLength={100}
            inputMode="text"
            disabled={currentDadata?.inn == ('' || undefined || null)}
          />
          {activeInput === 'legalName' &&
            dadata &&
            dadata?.length > 0 &&
            regForm !== 'Самозанятый' && (
              <Box ref={dropdownRef} className={classes.dropdown}>
                {dadata?.slice(0, 6).map((item) => (
                  <InputDropItem
                    item={item}
                    setFocus={handleChooseListItem}
                    value={form.values.legalName}
                  />
                ))}
              </Box>
            )}
        </Box>
      )}

      {regForm && regForm !== 'ИП' && regForm !== 'Самозанятый' && (
        <InputWithLabel
          className={classes.formElement}
          {...form.getInputProps('managementName')}
          name="managementName"
          label="ФИО руководителя"
          autoComplete="off"
          size="md"
          maxLength={200}
          inputMode="text"
          disabled={currentDadata?.inn == ('' || undefined || null)}
        />
      )}

      <Stack className={classes.formElement} spacing={16}>
        <InputWithLabel
          {...form.getInputProps('legalAddress')}
          onChange={handleAddressChange}
          name="legalAddress"
          label={getLabel('legalAddress')}
          autoComplete="off"
          id="legalAddress"
          size="md"
          maxLength={300}
          inputMode="text"
          disabled={currentDadata?.inn == ('' || undefined || null)}
        />
        <Checkbox
          className={classes.checkbox}
          checked={match}
          name="matchAddress"
          onChange={() => setMatch((state) => !state)}
          label="Совпадает с почтовым адресом"
        />
      </Stack>
      <InputWithLabel
        className={classes.formElement}
        {...form.getInputProps('shopName')}
        name="shopName"
        label="Название магазина"
        autoComplete="off"
        size="md"
        maxLength={50}
        inputMode="text"
      />
      <InputWithLabel
        {...form.getInputProps('mailingAddress')}
        disabled={match}
        error={!match ? form.getInputProps('mailingAddress').error : ''}
        name="mailingAddress"
        label="Почтовый адрес"
        autoComplete="off"
        id="mailingAddress"
        size="md"
        maxLength={300}
      />
      <AuthFooter>
        <Button
          loading={variantsFetching}
          disabled={!form.isValid() || settingCompanyInfo}
          type="submit"
          variant="filled"
          size="lg"
          fz={14}
          h={48}
          fullWidth
        >
          Продолжить
        </Button>
      </AuthFooter>
    </form>
  );
};

export default CompanyInformation;
