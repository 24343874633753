import { ISummaryOrdersStatsParams, ISummaryOrdersStatsRes } from "@/types/api/summaryOrders";

import { createApi } from "@reduxjs/toolkit/dist/query/react";

import { baseQuery } from "@/api/instance";
import { summaryOrdersUrls } from "@/api/urls/summaryOrderUrls";


export const summaryOrdersApi = createApi({
    reducerPath: 'summaryOrdersApi',
    baseQuery: baseQuery(),
    endpoints: (builder) => ({
        getStatsOfOrders: builder.query<ISummaryOrdersStatsRes, ISummaryOrdersStatsParams>({
            query: ({ ...params }) => ({
                url: summaryOrdersUrls.getStatsOfOrders,
                method: 'GET',
                params,
            }),
        }),
    })
})