import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Tabs } from '@mantine/core';

import { SuppliesPanels } from './components/SuppliesPanels/SuppliesPanels';
import { SuppliesTabs } from './components/SuppliesTabs/SuppliesTabs';
import { SuppliesContext } from './utils/Suppliescontext';

import { suppliesApi } from '@/store/slices/supplies/slice';

const DEFAULT_TAB = 'created';
const MAIN_LIST_URL = '/supplies/list/';

export const SuppliesPage = () => {
  const navigate = useNavigate();
  const { data: tabs, refetch, error } = suppliesApi.useGetTabsQuery();
  const { tab } = useParams();

  useEffect(() => {
    if (!tab) navigate(`${MAIN_LIST_URL}${DEFAULT_TAB}`)
  }, [tab]);

  useEffect(() => {
    if (!!error) navigate('/');
  }, [error]);

  return (
    <SuppliesContext.Provider value={{ refetchTabs: refetch }}>
      <Box pos="relative">
        <Tabs value={tab} onTabChange={(value) => navigate(`${MAIN_LIST_URL}${value || DEFAULT_TAB}`)} keepMounted={false}>
          <SuppliesTabs tabs={tabs?.tabs} />
          {!!tabs && <SuppliesPanels />}
        </Tabs>
      </Box>
    </SuppliesContext.Provider>
  );
};
