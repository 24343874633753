import { IOperations, TOperationsCodes } from '@/types/entites/template';

type TPopupType = 'success' | 'error';

export const getModalTitle = (operation: IOperations, popupType: TPopupType): string => {
  if (popupType === 'success') {
    if (operation.code === TOperationsCodes.ATTACH_PRODUCTS) {
      return 'Успешно!';
    } else if (operation.code === TOperationsCodes.PRODUCTS_EXPORT) {
      return `${operation?.title}`;
    }
    return 'Успешно!';
  } else {
    if (operation.code === TOperationsCodes.PRODUCTS_IMPORT) {
      return 'Ошибка добавления!';
    } else if (operation.code === TOperationsCodes.PRICES_IMPORT) {
      return 'Ошибка!';
    }
  }
  return '';
};
