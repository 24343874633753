import { FC, ReactNode } from "react";
import { Box, useMantineTheme } from "@mantine/core";

interface ISectionProps {
    children: ReactNode;
    last?: boolean;
}

const Section: FC<ISectionProps> = ({ children, last }) => {
    const theme = useMantineTheme();
    return (
        <Box mt={40} pb={40} sx={{
            borderBottom: !last ? `1px solid ${theme.colors.brandGrey[3]}` : ''
        }}>
            {children}
        </Box>
    )
}

export default Section;