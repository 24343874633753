import { createStyles } from '@mantine/core';

export const useStyles = createStyles(({ colors }) => ({
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    columnGap: 24,
    rowGap: 12,
    fontSize: 14,
  },
  label: {
    whiteSpace: 'nowrap',
    color: colors.text[5],
  },
  value: {
    textAlign: 'right',
    color: colors.text[7],
    whiteSpace: 'break-spaces',
  },
}));
