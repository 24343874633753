import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ScrollArea } from '@mantine/core';

import { useStyles } from './styles';

import { setCurrentScroll } from '@/store/slices/table/slice';

interface ChildrenData {
  scrollLeft?: number;
  maxScrollValue?: number;
  hasHorizontalScroll?: boolean;
}
interface ITableCustom {
  offsetScrollbars?: boolean;
  children: (data: ChildrenData) => ReactNode;
  elements?: any[];
  scrollbarType?: "auto" | "scroll" | "always" | "hover" | "never";
}

export const TableCustom: FC<ITableCustom> = ({
  children,
  elements,
  offsetScrollbars = true,
  scrollbarType = 'auto',
}) => {
  const dispatch = useDispatch();
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [scrollClass, setScrollClass] = useState<string>('');

  const [maxScrollValue, setMaxScrollValue] = useState<number>(0);
  const [hasHorizontalScroll, setHasHorizontalScroll] = useState<boolean>(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);

  const { classes } = useStyles({
    scrollbarBottom: scrollClass === 'scroll-bottom' ? true : false,
    hasHorizontalScroll: hasHorizontalScroll
  });

  const handleResize = () => {
    if (rootRef.current && tableRef.current && elements && elements.length > 0) {
      const boxWidth = rootRef.current.clientWidth;
      const tableWidth = tableRef.current.clientWidth;
      const newMaxScrollValue = tableWidth - boxWidth;
      setMaxScrollValue(newMaxScrollValue);
      setHasHorizontalScroll(newMaxScrollValue > 0);
    }
  };

  const onScrollPositionChange = (position: { x: number; y: number }) => {
    let tick = false;
    if (!tick) {
      window.requestAnimationFrame(() => {
        setScrollLeft(position.x);
        dispatch(setCurrentScroll(Math.ceil(position.x)));
        handleResize();
        tick = false;
      });
      tick = true;
    }
  };

  const onScroll = () => {
    if(rootRef.current && !hasHorizontalScroll){
      return;
    }
    const tableHeight = tableRef.current?.offsetHeight || 0;
    const halfwayPoint = tableHeight * 0.5;

    if (window.scrollY > halfwayPoint) {
      setScrollClass('scroll-bottom');
    } else {
      setScrollClass('scroll-top');
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', onScroll);
  }, [elements, scrollClass]);

  const renderedChildren = children({ scrollLeft, maxScrollValue, hasHorizontalScroll });

  return (
    <ScrollArea
      offsetScrollbars={offsetScrollbars}
      scrollbarSize={8}
      className={classes.root}
      onScrollPositionChange={onScrollPositionChange}
      viewportRef={rootRef}
      type={scrollbarType}
      classNames={{
        root: classes.scrollArea,
        scrollbar: classes.scrollbar
      }}
    >
      <table className={classes.table} ref={tableRef}>
        {renderedChildren}
      </table>
    </ScrollArea>
  );
};
