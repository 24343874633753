import { daysOfWeekShort } from "./constants";

export const shortDayOfWeek = (dateString: string): string => {
    const date = new Date(dateString);
    return `${daysOfWeekShort[date.getDay()]}`;
};

export const getCurrentMonthStr = (date: Date) => {
    return date.toLocaleString('ru', {
        month: 'long',
        day: 'numeric'
    })
}