import { IOperations, TOperationsCodes } from '@/types/entites/template';

import { pluralize } from '@/utils/pluralize';

import { MULTIPLE_PRODUCT_TITLES } from '@/constants/common';

export const getModalSuccessText = (operation: IOperations) => {
  if (operation.code === TOperationsCodes.PRODUCTS_IMPORT) {
    return `Загружено/обновлено ${operation.successCount}
                ${pluralize(operation.successCount, MULTIPLE_PRODUCT_TITLES)}.
                Добавьте документ(ы) для дальнейшей отправки товаров на модерацию`;
  } else if (operation.code === TOperationsCodes.PRICES_IMPORT) {
    return `Установлены/обновлены цены на ${operation.successCount}
                ${pluralize(operation.successCount, MULTIPLE_PRODUCT_TITLES)}`;
  } else if (operation.code === TOperationsCodes.ATTACH_PRODUCTS) {
    return `Все товары из файла загружены и в ближайшее время будут переведены на модерацию`;
  } else if (operation.code === TOperationsCodes.PRODUCTS_EXPORT) {
    return `${operation.message}`;
  }
  return '';
};
