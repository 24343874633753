import { createStyles } from "@mantine/core";

export const useStyles = createStyles<string, { interactive?: boolean }>((
    theme,
    { interactive }
) => ({
    row: {
        cursor: interactive ? 'pointer' : 'default',
        transition: 'background-color 0.2s',
        '&:hover': interactive
            ? {
                background: theme.colors.gray[0],
            }
            : {},
    },
}));