import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs } from '@mantine/core';

import PageContent from '@/ui/organisms/Page/components/PageContent/PageContent';
import PageHeader from '@/ui/organisms/Page/components/PageHeader/PageHeader';
import Page from '@/ui/organisms/Page/Page';

import DocumentsPanels from './components/DocumentsPanels/DocumentsPanels';
import DocumentsTabs from './components/DocumentsTabs/DocumetsTabs';
import { useStyles } from './styles';

interface IDocumentPageProps {
  title: string;
}

const DocumentsPage: FC<IDocumentPageProps> = ({ title }) => {
  const { tab } = useParams();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const [codeParam, setCodeParam] = useState(tab || 'closing-report');

  const handlerChangeTab = (code: string) => {
    setCodeParam(code);
    navigate(`/documents/${code}`, { replace: true });
  };

  useEffect(() => {
    if (!tab) {
      navigate(`/documents/${codeParam}`, { replace: true });
    }
  }, []);

  return (
    <Page>
      <PageHeader title={title} />
      <PageContent>
        <Tabs
          defaultValue={codeParam}
          value={tab}
          onTabChange={handlerChangeTab}
          classNames={{ ...classes }}
          keepMounted={false}
        >
          <DocumentsTabs />
          <DocumentsPanels currentTab={codeParam} />
        </Tabs>
      </PageContent>
    </Page>
  );
};

export default DocumentsPage;
