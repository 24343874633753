import { Box } from '@mantine/core';
import { ExclamationMark } from 'tabler-icons-react';

import { useStyles } from '../../styles';

export const TabRightSection = ({ state = false }: { state?: boolean }) => {
  const { classes } = useStyles();

  return state ? (
    <Box className={classes.required}>
      <ExclamationMark height={16} strokeWidth={3} />
    </Box>
  ) : null;
};
