import { ID, IStatus } from '@/types/common/common';

import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ActionIcon, Loader, Popover, UnstyledButton } from '@mantine/core';

import {
  TO_STATUS_ARCHIVED,
  TO_STATUS_EDITING,
  TO_STATUS_IN_SALE,
  TO_STATUS_NOT_SALE,
  TO_STATUS_RESTORE,
} from '@/constants/statuses';
import useIsSellerStatusActive from '@/hooks/useIsSellerStatusActive';

import { openProductToArchive } from '@/ui/organisms/Modals/modals';

import { getStatusOptions } from './utils/getStatusOptions';
import { useStyles } from './styles';

import { ReactComponent as IconDots } from '@/assets/icons/dots.svg';
import { AppDispatch } from '@/store';
import { productsApi } from '@/store/slices/products/api';
import {
  updateProductsArchiveRestore,
  updateProductsSaleRemove,
  updateProductsSaleReturn,
} from '@/store/slices/products/asyncActions';
import {
  selectProductsChangeStatus,
  selectProductsCurrentStatus,
} from '@/store/slices/products/selectors';
import { setFormProductData, setSupplierProductId } from '@/store/slices/products/slice';
import { setCurrentStatus } from '@/store/slices/products/slice';

interface IOptionsCellProps {
  status: IStatus;
  id: ID;
  identifier: string | number;
  stockQuantity: number;
}

const OptionsCell: FC<IOptionsCellProps> = ({ status, id, identifier, stockQuantity }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isActiveSeller = useIsSellerStatusActive();
  const currentStatus = useSelector(selectProductsCurrentStatus);
  const changeStatus = useSelector(selectProductsChangeStatus);
  const [openOptions, setOpenOptions] = useState<boolean>(false);
  const [statuses, setStatuses] = useState<IStatus[] | []>([]);
  const [
    getFormProductById,
    { isFetching: isFetchingGetFormProductById }
  ] = productsApi.useLazyGetFormProductByIdQuery();

  const isActiveEditProduct = (code: string) => code === TO_STATUS_EDITING && !isActiveSeller;
  const showOptions = useMemo(() => statuses.length > 0, [statuses]);

  const handleEditProduct = () => {
    getFormProductById({ supplierProductId: id.toString() }).then((response) => {
      dispatch(setFormProductData(response.data));
      dispatch(setSupplierProductId(id.toString()));
      dispatch(setCurrentStatus(status));

      localStorage.setItem('currentStatus', JSON.stringify(status));
      localStorage.removeItem(`uploadedImages`);

      navigate(`/products/create/common`);
    });
  };


  const hanlderClick = async (e: React.MouseEvent<HTMLButtonElement>, element: IStatus) => {
    const requestData = [{ supplierProductId: id, identifier: identifier }];
    setOpenOptions(false);

    if (element?.code === TO_STATUS_NOT_SALE) {
      await dispatch(updateProductsSaleRemove(requestData));
      openProductToArchive({ id: String(id), ...requestData[0] });
    }
    if (element?.code === TO_STATUS_IN_SALE) {
      await dispatch(updateProductsSaleReturn(requestData));
      openProductToArchive({ id: String(id), ...requestData[0] });
    }
    if (element?.code === TO_STATUS_RESTORE) {
      await dispatch(updateProductsArchiveRestore(requestData));
      openProductToArchive({ id: String(id), ...requestData[0] });
    }
    if (element?.code === TO_STATUS_ARCHIVED) {
      openProductToArchive({ id: String(id), ...requestData[0] });
    }
    if (element?.code === TO_STATUS_EDITING) {
      handleEditProduct();
    }
  };

  useEffect(() => {
    if (currentStatus?.code || status.code) {
      setStatuses(
        getStatusOptions(currentStatus?.code || '', status.code, stockQuantity)
      );
    }
  }, [changeStatus, status]);

  return (
    <Popover opened={openOptions} onChange={setOpenOptions} withinPortal position="left-start">
      {showOptions && (
        <Popover.Target>
          {isFetchingGetFormProductById ? (
            <Loader color="dark" size="sm" />
          ) : (
            <ActionIcon onClick={() => setOpenOptions(!openOptions)}>
              <IconDots />
            </ActionIcon>
          )}
        </Popover.Target>
      )}
      <Popover.Dropdown className={classes.box}>
        {openOptions && showOptions && (
          statuses.map((el, i: number) => (
            <UnstyledButton
              className={isActiveEditProduct(el.code)
                ? classes.disabledButton
                : classes.button
              }
              key={i}
              onClick={(event) => hanlderClick(event, el)}
              disabled={isActiveEditProduct(el.code)}
            >
              {el.label}
            </UnstyledButton>
          )
          ))}
      </Popover.Dropdown>
    </Popover>
  )
};

export default OptionsCell;
