import { IProductDocumentFilter } from '@/types/api/productDocuments';
import { ID } from '@/types/common/common';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ActionIcon,
  Box,
  Button,
  Group,
  LoadingOverlay,
  Stack,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { DatesRangeValue } from '@mantine/dates';
import debounce from 'debounce';
import isUndefined from 'lodash/isUndefined';
import { X } from 'tabler-icons-react';

import { dateFromString, formatDate } from '@/utils/formatDate';
import { pluralize } from '@/utils/pluralize';

import { EnhancedInput } from '@/ui/atoms/EnhancedInput/EnhancedInput';
import SelectCustom from '@/ui/atoms/SelectCustom/SelectCustom';
import { CalendarInput } from '@/ui/organisms/CalendarInput/CalendarInput';
import {
  openAddDocModal,
  openAddProductModal,
  openConfirmRemoveModal,
  openLoadingError,
} from '@/ui/organisms/Modals/modals';
import PaginationCustom from '@/ui/organisms/PaginationCustom/PaginationCustom';
import { ProductDocsOperationsController } from '@/ui/organisms/ProductDocsOperationsController/ProductDocsOperationsController';
import Cell from '@/ui/organisms/TableCustom/components/Cell/Cell';
import Row from '@/ui/organisms/TableCustom/components/Row/Row';
import TBody from '@/ui/organisms/TableCustom/components/TBody/TBody';
import THead from '@/ui/organisms/TableCustom/components/THead/THead';
import { TableCustom } from '@/ui/organisms/TableCustom/TableCustom';

import { AddProductsButton } from './components/AddProductsButton';
import { Tabs } from './components/Tabs/Tabs';

import { ReactComponent as IconPencil } from '@/assets/icons/redesign/iconPencil.svg';
import { productDocsApi } from '@/store/slices/productDocs/api';
import { selectProductDocs } from '@/store/slices/productDocs/slice';
import { selectUser } from '@/store/slices/user/slice';

type TFilter = IProductDocumentFilter[];

const columns = ['Тип документа', 'Номер', 'Статус', 'Дата выдачи', 'Дата окончания', 'Товары', ''];

export const ProductDocsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabFromUrl = queryParams.get('tab');

  const [activeTab, setActiveTab] = useState(tabFromUrl || 'all');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [tempSearchValue, setTempSearchValue] = useState('');
  const [filters, setFilters] = useState<TFilter>([]);
  const [concat, setConcat] = useState(false);
  const [filterDate, setFilterDate] = useState<[Date | null, Date | null]>();
  const theme = useMantineTheme();

  const user = useSelector(selectUser);
  const isActiveSeller = useMemo(() => user?.sellerStatus === 'active', [user]);
  const documents = useSelector(selectProductDocs);

  const {
    isFetching,
    error,
    refetch: refetchDocs,
  } = productDocsApi.useGetDocumentsListQuery(
    {
      limit: 50,
      offset: (currentPage - 1) * 50,
      search: tempSearchValue,
      filter: filters,
      concat,
    },
    { skip: !!tempSearchValue && tempSearchValue.length < 3 }
  );

  const {
    data: statuses,
    isFetching: isStatusesFetching,
    refetch: refetchStatuses,
  } = productDocsApi.useGetStatusesQuery();

  const showPagination = useMemo(
    () => documents && documents.meta.count < documents.meta.totalCount,
    [documents]
  );

  const {
    data: docTypes,
    isFetching: isTypesFetchig,
    isSuccess: isTypesLoaded,
  } = productDocsApi.useGetDocumentTypesQuery();

  const docTypesOptions = useMemo(
    () =>
      isTypesLoaded ? docTypes.items.map((item) => ({ label: item.label, value: item.code })) : [],
    [error, docTypes]
  );

  const isShowAllClearButton = useMemo(() => filters.length > 2, [filters]);

  const refetch = () => {
    refetchDocs();
    refetchStatuses();
  };

  const changeFieldFilter =
    (fieldName: IProductDocumentFilter['fieldName']) => (fieldValue: string | null) => {
      setCurrentPage(1);
      setFilters((currentFilters) => {
        const newFilters = currentFilters.filter((item) => item.fieldName !== fieldName);
        if (fieldValue && fieldValue !== 'all') newFilters.push({ fieldName, fieldValue });
        return newFilters;
      });
      setConcat(false);
    };

  const startDateChangeHandler = changeFieldFilter('startDate');
  const stopDateChangeHandler = changeFieldFilter('stopDate');

  const dateFilterChangeHandler = (value: [Date | null, Date | null]) => {
    const formatOptions: Intl.DateTimeFormatOptions = { month: 'numeric', year: 'numeric' };

    const [startDate, stopDate] = value;
    const formattedStartDate = startDate ? new Date(startDate) : null;
    const formattedStopDate = stopDate ? new Date(stopDate) : null;

    if (formattedStartDate instanceof Date !== formattedStopDate instanceof Date) return;

    setFilterDate(() => [formattedStartDate, formattedStopDate]);
    startDateChangeHandler(startDate ? formatDate(startDate, formatOptions) : null);
    stopDateChangeHandler(stopDate ? formatDate(stopDate, formatOptions) : null);
  };

  const tabChangeHandler = (value: string) => {
    refetchStatuses();
    navigate(`?tab=${value}`);
    setActiveTab(value);
    changeFieldFilter('status')(value);
  };

  const debouncedChangeHandler = useCallback(
    debounce((value: string) => {
      setTempSearchValue(value);
    }, 1000),
    []
  );

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
    debouncedChangeHandler(value);
  };

  const handleCloseRemoveModal = () => {
    setCurrentPage(1);
    refetch();
  };

  const handleOpenModal = () => {
    return docTypesOptions && docTypesOptions.length > 0
      ? openAddDocModal({ onSubmit: refetch })
      : openLoadingError({ id: 'loadingError' });
  };

  const handlerOpenProductModal = (id: ID) => {
    return docTypesOptions && docTypesOptions.length > 0
      ? openAddDocModal({ id: id, onSubmit: refetch })
      : openLoadingError({ id: 'loadingError' });
  };

  const handleClear = () => {
    setCurrentPage(1);
    setSearchValue('');
    setFilters([]);
  };

  const handlePageChange = (newPage: number, isMore: boolean) => {
    setConcat(isMore);
    setCurrentPage(newPage);
  };

  const filterDateValue = useMemo(() => {
    const startDate = filters.find((filter) => filter.fieldName === 'startDate')?.fieldValue;
    const stopDate = filters.find((filter) => filter.fieldName === 'stopDate')?.fieldValue;

    return [
      !isUndefined(startDate) ? dateFromString(startDate) : null,
      !isUndefined(stopDate) ? dateFromString(stopDate) : null,
    ] as DatesRangeValue;
  }, [filters, filterDate]);

  const filterTypeValue = useMemo(() => {
    return filters.find((filter) => filter.fieldName === 'type')?.fieldValue || null;
  }, [filters]);

  useEffect(() => {
    const currentTab = queryParams.get('tab');
    if (currentTab && currentTab !== activeTab) {
      setActiveTab(currentTab);
      refetchStatuses();
    }
  }, [location.search]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchValue]);

  return (
    <Box pos="relative">
      <ProductDocsOperationsController onRefetch={refetch} />
      <LoadingOverlay visible={isFetching || isTypesFetchig || isStatusesFetching} zIndex={100} />
      <Stack spacing={32}>
        <Tabs value={activeTab} onTabChange={tabChangeHandler} statuses={statuses} />
        <Group position="apart" px={24}>
          <Group spacing={8}>
            <SelectCustom
              onChange={changeFieldFilter('type')}
              options={docTypesOptions}
              miw={274}
              size="md"
              label="Тип документа"
              allowDeselect
              optionValue={filterTypeValue}
              clearable={true}
            />
            <EnhancedInput
              label="Введите номер"
              labelType="floating"
              miw={247}
              size="md"
              onChange={(e) => {
                handleInputChange(e);
              }}
              value={searchValue}
              clearable="true"
              inputMode="tel"
            />
            <CalendarInput
              w={260}
              h={48}
              allowSingleDateInRange
              placeholder={'Выберите даты'}
              type="range"
              disableBackwardsRange
              onChange={dateFilterChangeHandler}
              value={filterDateValue}
              clearable
            />
            {isShowAllClearButton && (
              <UnstyledButton
                fz={14}
                color={theme.colors.mainColor[6]}
                ml={40}
                onClick={handleClear}
              >
                <Group spacing={8}>
                  Очистить все
                  <X size={15} color={theme.colors.mainColor[6]} />
                </Group>
              </UnstyledButton>
            )}
          </Group>
          <Button h={48} onClick={() => handleOpenModal()} disabled={!isActiveSeller}>
            Добавить документ
          </Button>
        </Group>
        <Box>
          <TableCustom>
            {() => (
              <>
                <THead>
                  <Row>
                    {columns.map((title) => (
                      <Cell key={`title-${title}`} th sticky>
                        {title}
                      </Cell>
                    ))}
                  </Row>
                </THead>
                <TBody>
                  {!error &&
                    documents.items.map((doc) => (
                      <Row
                        key={doc.id}
                        onClick={() => {
                          navigate(`/docs/details/${doc.id}`);
                        }}
                      >
                        <Cell>{doc.type.label}</Cell>
                        <Cell>{doc.number}</Cell>
                        <Cell>{doc.status.label}</Cell>
                        <Cell>{doc.startDate}</Cell>
                        <Cell>{doc.stopDate || 'Бессрочно'}</Cell>
                        <Cell>
                          <Stack spacing={4}>
                            <AddProductsButton
                              onClick={() => openAddProductModal(doc.id)}
                              disabled={!isActiveSeller}
                            />
                            {doc.productsCount > 0 && (
                              <Text>
                                {doc.productsCount}{' '}
                                {pluralize(doc.productsCount, ['Артикул', 'Артикула', 'Артикулов'])}
                              </Text>
                            )}
                          </Stack>
                        </Cell>
                        <Cell>
                          {isActiveSeller &&
                            (doc.status.code === 'rejected' || doc.status.code === 'empty') && (
                              <Group spacing={16} position="right">
                                <ActionIcon sx={{ ['&:hover']: { backgroundColor: 'white' } }}>
                                  <IconPencil
                                    size={20}
                                    fill={theme.colors.brandGrey[5]}
                                    onClick={(e: Event) => {
                                      e.stopPropagation();
                                      handlerOpenProductModal(doc.id);
                                    }}
                                  />
                                </ActionIcon>
                                <ActionIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openConfirmRemoveModal(doc.id, handleCloseRemoveModal);
                                  }}
                                  sx={{ ['&:hover']: { backgroundColor: 'white' } }}
                                >
                                  <X size={20} fill={theme.colors.brandGrey[5]} />
                                </ActionIcon>
                              </Group>
                            )}
                        </Cell>
                      </Row>
                    ))}
                  {!documents?.meta.count &&
                    (isFetching ? (
                      <Row>
                        <Cell colspan={7}>
                          <Text align="center">Загрузка...</Text>
                        </Cell>
                      </Row>
                    ) : (
                      <Row>
                        <Cell colspan={7}>
                          <Text align="center" color={theme.colors.brandGrey[8]}>
                            Нет записей
                          </Text>
                        </Cell>
                      </Row>
                    ))}
                </TBody>
              </>
            )}
          </TableCustom>
          {showPagination && (
            <PaginationCustom
              totalItems={documents?.meta.totalCount || 0}
              limitItems={50}
              currentPage={currentPage}
              handler={handlePageChange}
            />
          )}
        </Box>
      </Stack>
    </Box>
  );
};
