import { AuthTypeEnum, IAuthByCodeRequest } from '@/types/api/auth';

import { FC, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Button, Input, useMantineTheme } from '@mantine/core';
import { Text } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { formatedPhone } from '@/utils/formatedPhone';
import reCaptchaVerify from '@/utils/reCaptchaVerify';

import { RESEND_CODE_TIME } from '@/constants/common';
import { routNames } from '@/constants/routNames';
import { SIGNUP } from '@/constants/validationSchemas';

import { MaskedInput } from '@/ui/organisms/MaskedInput/MaskedInput';
import { PhoneInput } from '@/ui/organisms/PhoneInput/PhoneInput';
import ResendCode from '@/ui/organisms/ResendCode/ResendCode';

import AuthFooter from '../AuthFooter/AuthFooter';

import { AppDispatch } from '@/store';
import { addSellerAction, addUserAction, resendCodeAction } from '@/store/slices/auth/asyncAction';
import { selectAuthType, selectFetchingAddUser, selectUserPhone } from '@/store/slices/auth/auth';
import { selectUser } from '@/store/slices/user/slice';

const SignUp: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const userPhone = useSelector(selectUserPhone);
  const user = useSelector(selectUser);
  const fetching = useSelector(selectFetchingAddUser);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { colors } = useMantineTheme();

  const authType = useSelector(selectAuthType);

  const formattedUserPhone = formatedPhone(userPhone);

  const getNewCode = useCallback(() => {
    reCaptchaVerify(executeRecaptcha, 'resendCodeAction').then((recaptchaToken) => {
      if (recaptchaToken) {
        const sendData = {
          phone: formattedUserPhone,
          recaptchaToken,
        };
        dispatch(resendCodeAction(sendData));
      }
    });
  }, [executeRecaptcha, userPhone]);

  const handleSubmit = (values: Record<string, unknown>) => {
    if (authType === AuthTypeEnum.SELLER) {
      reCaptchaVerify(executeRecaptcha, 'addSellerAction').then((recaptchaToken) => {
        if (recaptchaToken) {
          const sendData = {
            ...values,
            phone: formattedUserPhone,
            recaptchaToken,
            actionSource: 'web',
          };
          dispatch(addSellerAction(sendData as IAuthByCodeRequest));
        }
      });
    } else {
      reCaptchaVerify(executeRecaptcha, 'addUserAction').then((recaptchaToken) => {
        if (recaptchaToken) {
          const sendData = {
            ...values,
            phone: formattedUserPhone,
            recaptchaToken,
            actionSource: 'web',
          };
          dispatch(
            addUserAction({
              value: sendData as IAuthByCodeRequest,
            })
          );
        }
      });
    }
  };

  const form = useForm({
    initialValues: {
      phone: userPhone || user?.phone || '',
      code: '',
      ordersStatus: true,
      promotions: true,
    },
    validateInputOnChange: true,
    validate: yupResolver(SIGNUP),
  });

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} noValidate={true}>
      <Box>
        <PhoneInput
          name="phone"
          label="Телефон"
          labelType="floating"
          size="md"
          inputMode="tel"
          type="tel"
          pattern="[0-9]"
          autoComplete="off"
          disabled={!!userPhone}
          {...form.getInputProps('phone')}
          value={userPhone || form.getInputProps('phone').value}
          mb={16}
          unmask
        />
        <Box>
          <Input.Wrapper required sx={{ fontSize: '10px' }} mx="auto" mb={16}>
            <MaskedInput
              required
              name="code"
              label="Проверочный код"
              labelType="floating"
              type="input"
              inputMode="numeric"
              pattern="[0-9]"
              autoComplete="one-time-code"
              maxLength={4}
              mask="9999"
              maskChar=""
              size="md"
              {...form.getInputProps('code')}
            />
          </Input.Wrapper>
          <ResendCode
            resendHandler={getNewCode}
            timer={RESEND_CODE_TIME}
            timerText="Отправить код повторно можно через"
            buttonText="Отправить код повторно"
          />
        </Box>
        <Text ta={'left'} mt={32} fz={14} lh={'22px'}>
          Нажимая кнопку «Продолжить» вы соглашаетесь c{' '}
          <Link target="_blank" to={routNames.SERVICES.OFFER}>
            условиями пользования
          </Link>{' '}
          и{' '}
          <Link target="_blank" to={routNames.SERVICES.USER_AGREEMENT}>
            политикой обработки персональных данных
          </Link>
        </Text>
      </Box>
      <AuthFooter>
        <Button
          loading={fetching}
          disabled={!form.isValid()}
          type="submit"
          size="lg"
          variant="filled"
          fullWidth
          fz={14}
          h={48}
        >
          Продолжить
        </Button>
        <Box mt={16} fz={14}>
          <Link target="_blank" to={routNames.SERVICES.FAQ}>
            Не приходит код
          </Link>
        </Box>
      </AuthFooter>
    </form>
  );
};

export default SignUp;
