import { FC } from 'react';
import { Group, Text, useMantineTheme } from '@mantine/core';
import { Box } from '@mantine/core';

import { pluralize } from '@/utils/pluralize';

interface AutoCompleteMessageProps {
  daysToAutoComplete?: number;
}

export const AutoCompleteMessage: FC<AutoCompleteMessageProps> = ({ daysToAutoComplete }) => {
  const { colors } = useMantineTheme();
  return daysToAutoComplete && daysToAutoComplete > 0 ? (
    <Group spacing={4} color={colors.brandDark[9]} fz={14}>
      <Text>До автопринятия осталось: {daysToAutoComplete}{' '}</Text>
      <Text fw={700}>{pluralize(daysToAutoComplete, ['день', 'дня', 'дней'])}</Text>
    </Group>
  ) : (
    <Box></Box>
  );
};
