import { createStyles } from "@mantine/core";

export const useStyles = createStyles(() => ({
    wrapper: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
    },
    inner: {
        height: '100%',
    }
}));