import { Component, PropsWithChildren } from 'react';

interface ErrorBoundaryState {
  error: boolean;
}

export class ErrorBoundary extends Component<PropsWithChildren<{}>, ErrorBoundaryState> {
  state = {
    error: false,
  };

  static getDerivedStateFromError() {
    return { error: true };
  }

  goBack(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (typeof window === 'undefined') return;
    window.history.back();
    location.reload();
  }

  render() {
    if (!this.state.error) return this.props.children;
    else
      return (
        <p style={{ padding: '1rem', lineHeight: '2rem' }}>
          Страница не может быть отображена из-за возникшей ошибки 😰
          <br />
          <button
            onClick={this.goBack}
            style={{
              background: 'white',
              border: '1px solid grey',
              padding: '0.25rem',
              cursor: 'pointer',
            }}
          >
            Понятно
          </button>
        </p>
      );
  }
}
