import { ActionIcon, clsx, Tooltip } from '@mantine/core';

import { livetexShowWelcomeWindow } from '@/utils/livetex';
import { colors } from '@/utils/theme';

import { HeaderTooltip } from '../HeaderTooltips';
import { useStyles } from '../styles';

import { ReactComponent as Headphone } from '@/assets/icons/headphone.svg';

const HeaderHelper = () => {
  const { classes } = useStyles();

  return (
    <Tooltip
      label={<HeaderTooltip content="Поддержка" />}
      width={'auto'}
      color={colors.mainColor[6]}
      offset={10}
      position="bottom-end"
    >
      <ActionIcon
        className={clsx(classes.button, classes.outlined)}
        onClick={livetexShowWelcomeWindow}
      >
        <Headphone width="18px" height="18px" />
      </ActionIcon>
    </Tooltip>
  );
};

export default HeaderHelper;
