import { FC, ReactNode } from "react";

interface ITBody {
    children: ReactNode,
}

const TBody: FC<ITBody> = ({ children }) => {
    return (
        <tbody>
            {children}
        </tbody>
    )
}

export default TBody;