import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Group } from '@mantine/core';

import { SELLER_STATUS_INACTIVE, SELLER_STATUS_MODERATION } from '@/constants/common';

import FormGroup from '@/ui/atoms/FormGroup/FormGroup';
import LoaderDots from '@/ui/atoms/LoaderDots/LoaderDots';
import { CalendarInput } from '@/ui/organisms/CalendarInput/CalendarInput';

import { MIN_SYMBOLS_FOR_ARTICLE } from '../../utils/constants';
import { excludeDate } from '../../utils/excludeDate';
import { getformattedDate } from '../../utils/getFormattedDate';
import ArticleInput from '../ArticleInput/ArticleInput';

import { useStyles } from './styles';

import { documentsApi } from '@/store/slices/documents/api';
import { selectFetchingArticle } from '@/store/slices/products/selectors';
import { selectUser } from '@/store/slices/user/slice';

interface DocumentsHeadProps {
  refetchDocuments: () => void;
}

const DocumentsHead: React.FC<DocumentsHeadProps> = ({ refetchDocuments }: any) => {
  const user = useSelector(selectUser);
  const { data } = documentsApi.useGetDocumentTypesQuery();
  const [documentGenerate, { isLoading: isDocumentGenerateFetching }] =
    documentsApi.usePostDocumentGenerateMutation();
  const [articleValue, setArticleValue] = useState<string>('');
  const [isValueError, setIsValueError] = useState<boolean>(true);
  const [isButtonActive, setIsButtonActive] = useState<boolean>(false);
  const fetchingArticle = useSelector(selectFetchingArticle);
  const { classes } = useStyles();
  const { tab } = useParams();

  const registrationDate = useMemo(() => {
    return data?.meta.registrationDate || '';
  }, [data?.meta.registrationDate]);

  const isShowArticle = useMemo(
    () => !!data?.items.find((typeItem) => typeItem.code === tab && typeItem.hasArticle),
    [tab, data]
  );

  const dateType = useMemo(
    () =>
      data?.items.find((typeItem) => typeItem.code === tab && typeItem.dateSelectType === 'range')
        ? 'range'
        : 'default',
    [tab, data]
  );

  const [dateValue, setDateValue] = useState<[Date | null, Date | null] | Date | null>(null);

  const handlerBuildDoc = async () => {
    if (!tab) return;
    const { startDate, endDate } = getformattedDate(dateValue);
    await documentGenerate([
      { documentType: tab },
      { startDate, endDate, article: Number(articleValue) },
    ]);
    refetchDocuments();
  };

  const isShowButton = useMemo(
    () =>
      user?.sellerStatus !== SELLER_STATUS_INACTIVE &&
      user?.sellerStatus !== SELLER_STATUS_MODERATION,
    [user?.sellerStatus]
  );

  useEffect(() => {
    const isDatesValid = Array.isArray(dateValue)
      ? dateValue.every((date) => date !== null)
      : dateValue !== null;
    const isArticleValid = isShowArticle
      ? !isValueError && articleValue.length >= MIN_SYMBOLS_FOR_ARTICLE
      : true;

    setIsButtonActive(isShowArticle ? !(isDatesValid && isArticleValid) : !isDatesValid);
  }, [articleValue, dateValue, isValueError, isShowArticle]);

  useEffect(() => () => setDateValue(null), [tab]);

  return (
    <Group spacing={24} className={classes.container}>
      {isShowArticle && (
        <ArticleInput
          placeholder="Введите артикул"
          classes={classes}
          onChange={setArticleValue}
          onError={setIsValueError}
        />
      )}

      {dateType === 'range' ? (
        <CalendarInput
          type="range"
          placeholder={'Выберите даты'}
          excludeDate={(date) => excludeDate(date, registrationDate)}
          value={(dateValue || [null, null]) as [Date | null, Date | null]}
          onChange={setDateValue}
          miw={225}
        />
      ) : (
        <CalendarInput
          type="default"
          placeholder={'Выберите дату'}
          excludeDate={(date) => excludeDate(date, registrationDate)}
          value={dateValue as Date | null}
          onChange={setDateValue}
          miw={225}
        />
      )}
      {isShowButton && (
        <FormGroup>
          <Button
            onClick={handlerBuildDoc}
            variant="filled"
            className={classes.button}
            disabled={fetchingArticle || isDocumentGenerateFetching || isButtonActive}
          >
            <LoaderDots loading={fetchingArticle || isDocumentGenerateFetching} bgr="blue" />
            Сформировать
          </Button>
        </FormGroup>
      )}
    </Group>
  );
};

export default DocumentsHead;
