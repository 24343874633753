import { ERROR_TEXT_INVALID_XLSX, ERROR_TEXT_INVALID_ZIP, ERROR_TEXT_MAX_SIZE } from "./constants";

export const validateFile = (file: File | null, fileType: 'xlsx' | 'zip'): string => {
    if (!file) return '';
    if (fileType === 'xlsx') {
        if (!['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(file.type)) {
            return ERROR_TEXT_INVALID_XLSX;
        }
    } else if (fileType === 'zip') {
        const acceptedTypesZip = [
            'application/zip',
            'application/octet-stream',
            'application/x-zip-compressed',
            'multipart/x-zip'
        ];
        if (!acceptedTypesZip.includes(file.type)) {
            return ERROR_TEXT_INVALID_ZIP;
        } else if (file.size > 15 * 1024 * 1024 * 1024) {
            return ERROR_TEXT_MAX_SIZE;
        }
    }
    return '';
};