import { FC } from 'react';
import { useDocumentTitle } from '@mantine/hooks';

import NotFoundPage from '@/ui/pages/NotFoundPage/NotFoundPage';

const NotFound: FC = () => {
    useDocumentTitle('404');
    return (
        <NotFoundPage title='Ошибка 404'/>
    )
}

export default NotFound;