import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button } from '@mantine/core';
import { Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import DownloadTemplate from '@/ui/organisms/DownloadTemplate/DownloadTemplate';
import ModalCLose from '@/ui/organisms/Modals/ModalClose/ModalClose';
import ModalContent from '@/ui/organisms/Modals/ModalContent/ModalContent';
import ModalFooter from '@/ui/organisms/Modals/ModalFooter/ModalFooter';
import { openTemplateInstrtuction } from '@/ui/organisms/Modals/modals';
import ModalStep from '@/ui/organisms/Modals/ModalStep/ModalStep';
import UploadTemplate from '@/ui/organisms/UploadTemplate/UploadTemplate';

import { AppDispatch } from '@/store';
import { postProductsImport } from '@/store/slices/products/asyncActions';

const UploadProducts: FC<ContextModalProps> = ({ context, ...modalProps }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [zipFile, setZipFile] = useState<File | null>(null);
  const [excelFile, setExcelFile] = useState<File | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [isFilesReady, setIsFilesReady] = useState<boolean>(true);

  const handlerClick = () => {
    context.closeModal(modalProps.id);
    openTemplateInstrtuction({});
  };

  const handlerSubmitTemplate = async () => {
    if (!excelFile) {
      return;
    }
    context.closeModal(modalProps.id);
    const formData = new FormData();
    if (excelFile) {
      formData.append('file', excelFile);
    }
    if (zipFile) {
      formData.append('imagesFile', zipFile);
    }
    await dispatch(
      postProductsImport({
        file: formData.get('file'),
        imagesFile: formData.get('imagesFile') || null,
      })
    );
  };

  useEffect(() => {
    setIsFilesReady(!excelFile || isError);
  }, [excelFile, isError]);

  return (
    <ModalContent withoutFooter withoutPadding>
      <ModalCLose onClose={() => context.closeModal(modalProps.id)} />
      <Text align="center" weight={700} size={22} pt={32} style={{ lineHeight: 1.4 }} px={60}>
        Загрузка каталога
      </Text>
      <Box>
        <ModalStep
          titleSub="Шаг 1. Скачайте шаблон и заполните его"
          buttonText="Подробнее о том, как использовать шаблон"
          borderBottom
          onClick={handlerClick}
        >
          <DownloadTemplate />
        </ModalStep>
        <ModalStep titleSub="Шаг 2. Загрузите заполненный шаблон и архив с изображениями товаров (не&nbsp;обязательно)">
          <UploadTemplate
            setExcelFile={setExcelFile}
            setZipFile={setZipFile}
            setIsError={setIsError}
          />
        </ModalStep>
      </Box>
      <ModalFooter variant="xs" align="center">
        <Button
          size="lg"
          variant="filled"
          disabled={isFilesReady}
          onClick={handlerSubmitTemplate}
          sx={{
            padding: '13px 24px',
            minWidth: '280px',
            fontSize: '14px',
          }}
        >
          Продолжить
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

export default UploadProducts;
