export const validationTexts = {
  EMPTY: 'Поле не заполнено',
  REQUIRED: 'Заполните поле',
  PHONE: 'Некорректный формат телефона',
  EMAIL: 'Укажите правильный e-mail',
  INCORRECT_NAME: 'Укажите корректное имя',
  INCORRECT_LASTNAME: 'Укажите корректную фамилию',
  INCORRECT_SECONDNAME: 'Укажите корректное отчество',
  STRING: 'Поле должно содержать только буквы и строковые символы (, . ! ? ; : " \' ` № « » - )',
  MIN_LENGTH: (value: number) => `Поле должно содержать не менее ${value} символов`,
  MAX_LENGTH: (value: number) => `Поле должно содержать не более ${value} символов`,
  LENGTH: (value: number) => `Поле должно содержать ${value} символов`,
  PASSWORD_NOT_CONFIRM: 'Пароли не совпадают',
  CODE: 'Код должен содержать 4 символа',
  NUMBER: 'Поле может содержать только цифры',
  INCORRECT_SYMBOL: 'Ввод спецсимволов запрещён',
  FLAT_REQUIRED: 'Поставьте галочку или укажите квартиру/офис',
  INN_MAX_LENGTH: 'Поле должно содержать не более 12 символов',
  INN_MIN_LENGTH: 'Поле должно содержать не менее 10 символов',
  INN_IP_CORRECT_LENGTH: 'Поле должно содержать 12 символов',
  INN_OTHER_FORM_CORRECT_LENGTH: 'Поле должно содержать 10 символов',
  INCORRECT_VALUE: 'Некорректное значение',
  PASSWORD_FORMAT_INCORRECT:
    'Минимальная длина пароля 6 символов, можно использовать только цифры и латиницу. В пароле должно быть не менее 1 цифры и 1 латинской буквы, большие и малые буквы. Пробел запрещен.',
};
