import { AuthStepsEnum } from '@/types/api/auth';

import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box, Flex, Text, useMantineTheme } from '@mantine/core';

import AuthConent from './components/AuthContent/AuthConent';
import AuthHeader from './components/AuthHeader/AuthHeader';
import { getAuthDescription } from './utils/getAuthDescription';
import { getAuthTitle } from './utils/getAuthTitle';
import { getBackStep } from './utils/getBackStep';
import { getCurrentStep } from './utils/getCurrentStep';
import { useStyles } from './styles';

import { AppDispatch } from '@/store';
import { logoutAction } from '@/store/slices/auth/asyncAction';
import { selectAuthStep, selectUserToken, setAuthStep } from '@/store/slices/auth/auth';

interface IAuthPage {
  title: string;
}
const AuthPage: FC<IAuthPage> = () => {
  const { classes } = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const authStep = useSelector(selectAuthStep);
  const token = useSelector(selectUserToken);
  const { colors } = useMantineTheme();

  const handleBack = () => {
    dispatch(setAuthStep(getBackStep(authStep)));

    if (
      authStep === AuthStepsEnum.UPGRADE_TO_SELLER ||
      authStep === AuthStepsEnum.UPGRADE_TO_SELLER_NEW
    ) {
      if (token) dispatch(logoutAction(token?.refreshToken));
      dispatch(setAuthStep(AuthStepsEnum.SIGNIN_WITH_ONLY_EMAIL));
    }
  };

  return (
    <>
      <Box className={classes.imageSide}></Box>
      <Box className={classes.formSide}>
        <Box className={classes.wrapper}>
          <AuthHeader
            title={getAuthTitle(authStep)}
            description={getAuthDescription(authStep)}
            onBack={
              authStep !== AuthStepsEnum.SIGNIN_WITH_ONLY_EMAIL &&
              authStep !== AuthStepsEnum.DATA_FILL &&
              authStep !== AuthStepsEnum.COMPANY_INFORMATION
                ? handleBack
                : undefined
            }
          />
          <AuthConent>{getCurrentStep(authStep)}</AuthConent>
          <Flex justify="center" mt={-4}>
            <Link target="_blank" to={'/instruction'}>
              <Text fz={14} color={colors.mainColor[6]}>
                Инструкция
              </Text>
            </Link>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default AuthPage;
