import { IStatus } from '@/types/common/common';

import { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Group } from '@mantine/core';

import { Select } from '@/ui/atoms/Select/Select';
import { rebuildDataForSelect } from '@/ui/pages/ProductsPage/utils/rebuildDataForSelect';

import { useStyles } from './style';

import { AppDispatch } from '@/store';
import { getProductStatuses } from '@/store/slices/products/asyncActions';
import {
  selectProductsCurrentStatus,
  selectProductsLimit,
  selectProductsOffset,
  selectProductStatuses,
} from '@/store/slices/products/selectors';
import { setCurrentStatus, setSelected } from '@/store/slices/products/slice';
import { deleteFilterProduct, setFilterProducts } from '@/store/slices/search/slice';

const SelectStatus: FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch<AppDispatch>();
  const propductStatutes: IStatus[] = useSelector(selectProductStatuses);
  const productsLimit = useSelector(selectProductsLimit);
  const productsOffset = useSelector(selectProductsOffset);
  const currentStatus = useSelector(selectProductsCurrentStatus);
  const [searchParams, setSearchParams] = useSearchParams();

  const getParam = (paramName: string) => searchParams.get(paramName) ? String(searchParams.get(paramName)) : null;
  const statusCategories = getParam('categories');
  const statusNameCategories = getParam('nameCategories');
  const status = getParam('status');
  const [statusValue, setStatusValue] = useState<string>(String(status));

  const params = {
    limit: searchParams.get('limit') || productsLimit,
    offset: searchParams.get('offset') || productsOffset,
    page: searchParams.get('page') || 1,
    ...(statusCategories && { categories: statusCategories }),
    ...(statusNameCategories && { nameCategories: statusNameCategories }),
    ...(status !== null && { status: status }),
  };

  const handlerStatusChange = (value: any) => {
    const findElement = propductStatutes.find((el) => el.code === value);

    const newParams = {
      ...params,
      offset: 0,
      page: 1,
      status: value,
    };

    if (statusValue === value) {
      dispatch(deleteFilterProduct('status'));
      dispatch(setCurrentStatus(null));
      setStatusValue('');
      return;
    }

    dispatch(setCurrentStatus(findElement || null));
    dispatch(setFilterProducts({ fieldName: 'status', fieldValue: value }));
    dispatch(setSelected([]));

    setStatusValue(value);
    setSearchParams(JSON.parse(JSON.stringify(newParams)));
  };

  useEffect(() => {
    if (!currentStatus) {
      dispatch(getProductStatuses());
    }

    return () => {
      dispatch(deleteFilterProduct('status'));
      dispatch(setCurrentStatus(null));
      setStatusValue('');
    };
  }, []);

  const statusesData = useMemo(() => {
    if (propductStatutes && propductStatutes.length > 0) {
      return rebuildDataForSelect(propductStatutes);
    }
    return [];
  }, [propductStatutes]);

  const modifiedData = useMemo(() => {
    return statusesData.map((option) => {
      const buildLabel =
        option.label && option.label.length > 20 && option.value === statusValue
          ? option.label.slice(0, 20) + '...'
          : option.label || '';

      return {
        ...option,
        label: buildLabel,
      };
    });
  }, [statusesData, statusValue]);

  const onOpen = (isOpen: boolean) => {
    if (isOpen) dispatch(getProductStatuses());
  };

  return (
    <>
      {statusesData.length > 0 && (
        <Group className={classes.wrapper} spacing={0} pr={12} miw={220}>
          <Select
            label="Cтатус"
            data={modifiedData}
            onChange={(value) => handlerStatusChange(value)}
            onOpen={onOpen}
            value={currentStatus?.code || statusValue}
            clearable
          />
        </Group>
      )}
    </>
  );
};

export default SelectStatus;
