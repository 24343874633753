import { IProductDetailsResponse } from '@/types/api/productDocuments';
import { ID } from '@/types/common/common';

import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { X } from 'tabler-icons-react';

import { formatDate } from '@/utils/formatDate';

import { openAddDocModal, openConfirmRemoveModal, openLoadingError } from '@/ui/organisms/Modals/modals';

import { SubtleButton } from '../SubtleButton/SubtleButton';

import { ReactComponent as IconPencil } from '@/assets/icons/redesign/iconPencil.svg';
import { productDocsApi } from '@/store/slices/productDocs/api';
import { selectUser } from '@/store/slices/user/slice';

interface DocInfoProps {
  doc: IProductDetailsResponse;
  onRefetch?: () => void;
}

export const DocInfo: FC<DocInfoProps> = ({ doc, onRefetch }) => {
  const user = useSelector(selectUser);
  const isActiveSeller = useMemo(() => user?.sellerStatus === 'active', [user]);
  const { colors } = useMantineTheme();
  const navigate = useNavigate();
  const { isError } = productDocsApi.useGetDocumentTypesQuery();
  const displayInfo = useMemo<[string, string | number][]>(
    () => [
      ['Тип документа', doc.type.label],
      ['Номер', doc.number],
      ['Статус', doc.status.label],
      ['Дата выдачи', formatDate(doc.startDate)],
      ['Дата окончания', doc.stopDate ? formatDate(doc.stopDate) : 'Бессрочно'],
    ],
    [doc]
  );

  const handlerOpenAddModal = (id: ID) => {
    return (!isError)
      ? openAddDocModal({ id: id, onSubmit: onRefetch })
      : openLoadingError({ id: 'loadingError' });
  }

  return (
    <Stack spacing={32}>
      <Stack spacing={12}>
        {displayInfo.map((row) => (
          <Group position="apart" key={row[0]}>
            <Text size={14} color={colors.text[5]}>
              {row[0]}
            </Text>
            <Text size={14}>{row[1]}</Text>
          </Group>
        ))}
      </Stack>
      <Group spacing={24}>
        {(doc.status.code === 'rejected' || doc.status.code === 'empty') && (
          <SubtleButton
            onClick={() => handlerOpenAddModal(doc.id)}
            disabled={!isActiveSeller}
            fz={14}
          >
            <Text mr={6}>
              Редактировать
            </Text>
            <IconPencil fill={colors.mainColor[6]} style={{ width: '20px', height: '20px' }} />
          </SubtleButton>
        )}
        {(doc.status.code === 'empty' || doc.status.code === 'rejected') && (
          <SubtleButton
            onClick={() => openConfirmRemoveModal(doc.id, () => navigate('/docs/'))}
            disabled={!isActiveSeller}
            fz={14}
          >
            <Text mr={6} color={colors.brandGrey[8]}>Удалить</Text>
            <X color={colors.brandGrey[8]} size={20} />
          </SubtleButton>
        )}
      </Group>
    </Stack>
  );
};
