import { FC, useCallback, useMemo, useState } from 'react';
import { ActionIcon, Box, Group, Stack, Text, UnstyledButton } from '@mantine/core';
import { ChevronLeft, ChevronRight } from 'tabler-icons-react';

import {
  DateTuple,
  getConstraint,
  getMinMaxConstraints,
  getWeeks,
  isBefore,
  next,
  prev,
  sortDates,
} from './utils/helpers';
import { useStyles } from './styles';

interface CalendarProps {
  startDate?: Date;
  minDate?: Date;
  maxDate?: Date;
  availableDates?: Date[];
  onDateSelect?: (date: Date | null) => void;
}

const LOCALE_MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];
const LOCALE_WEEKDAYS = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

export const Calendar: FC<CalendarProps> = ({ availableDates, onDateSelect, ...props }) => {
  const { classes } = useStyles();
  const [selected, setSelected] = useState<Date | null>(null);
  const startDate = useMemo(
    () => props.startDate || (availableDates && sortDates(availableDates)[0]) || new Date(),
    [props.startDate]
  );
  const [currentDate, setCurrentDate] = useState<[number, number]>([
    startDate.getMonth(),
    startDate.getFullYear(),
  ]);

  const monthLabel = useMemo(
    () => `${LOCALE_MONTHS[currentDate[0]]} ${currentDate[1]}`,
    [currentDate]
  );

  const [minConstraint, maxConstraint] = useMemo(() => {
    if (availableDates) return getMinMaxConstraints(availableDates);
    return [getConstraint(props.minDate), getConstraint(props.maxDate)];
  }, [availableDates, props.minDate, props.maxDate]);

  const availableDatesMap = useMemo(() => {
    if (!availableDates || !availableDates.length) return;
    return availableDates.reduce<Record<string, true>>((res, date) => {
      const key = `${date.getMonth()}-${date.getDate()}-${date.getFullYear()}`;
      return { ...res, [key]: true };
    }, {});
  }, [availableDates]);

  const updateMonth = (tuple: DateTuple) => {
    if (selected?.getMonth() === tuple[0]) onDateSelect?.(selected);
    else onDateSelect?.(null);
    setCurrentDate(tuple);
  };

  const handleNextMonth = () => updateMonth(next(currentDate));

  const handlePrevMonth = () => updateMonth(prev(currentDate));

  const [isPrevMonthAvailable, isNextMonthAvailable] = useMemo(() => {
    let prevAvailable = true;
    let nextAvailable = true;

    if (minConstraint) prevAvailable = isBefore(minConstraint, prev(currentDate), true);
    if (maxConstraint) nextAvailable = isBefore(next(currentDate), maxConstraint, true);

    return [prevAvailable, nextAvailable];
  }, [minConstraint, maxConstraint, currentDate]);

  const weeks = useMemo(() => getWeeks(currentDate), [currentDate]);

  const isDayAvailable = useCallback(
    (day: number) => {
      if (!availableDatesMap) return true;
      const key = `${currentDate[0]}-${day}-${currentDate[1]}`;

      return Boolean(availableDatesMap[key]);
    },
    [currentDate, availableDatesMap]
  );

  const isDaySelected = useCallback(
    (day: number) => {
      if (!selected) return false;
      return (
        selected.getDate() === day &&
        selected.getMonth() === currentDate[0] &&
        selected.getFullYear() === currentDate[1]
      );
    },
    [selected, currentDate]
  );

  const updateSelection = (date: Date | null) => {
    setSelected(date);
    onDateSelect?.(date);
  };

  const handleSelect = (day: number | null) => {
    if (!day) return;
    if (isDaySelected(day)) updateSelection(null);
    else {
      const date = new Date(currentDate[1], currentDate[0], day);
      updateSelection(date);
    }
  };

  return (
    <Box>
      <Stack spacing={24}>
        <Group position="apart" pos="relative" w="100%">
          <Box>
            <ActionIcon onClick={handlePrevMonth} disabled={!isPrevMonthAvailable}>
              <ChevronLeft />
            </ActionIcon>
          </Box>
          <Text weight={700} className={classes.currentMonthLabel}>
            {monthLabel}
          </Text>
          <Box>
            <ActionIcon onClick={handleNextMonth} disabled={!isNextMonthAvailable}>
              <ChevronRight />
            </ActionIcon>
          </Box>
        </Group>
        <table className={classes.table}>
          <tr>
            {LOCALE_WEEKDAYS.map((day) => (
              <th>
                <Text>{day}</Text>
              </th>
            ))}
          </tr>
          {weeks.map((week, weekIndex) => (
            <tr key={weekIndex}>
              {week.map((day, dayIndex) => (
                <td key={dayIndex}>
                  <UnstyledButton
                    h="100%"
                    w="100%"
                    disabled={!day || !isDayAvailable(day)}
                    data-day={day}
                    data-selected={day && isDaySelected(day)}
                    onClick={() => {
                      handleSelect(day);
                    }}
                  >
                    {day}
                  </UnstyledButton>
                </td>
              ))}
            </tr>
          ))}
        </table>
      </Stack>
    </Box>
  );
};
