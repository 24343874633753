import { createStyles } from '@mantine/core';

import imageAuth from '@/assets/images/imageAuthNew.jpg';

export const useStyles = createStyles((_) => ({
  formSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 0 auto',
    // maxWidth: '50%',
  },
  imageSide: {
    flex: '0 1 50%',
    backgroundImage: `url(${imageAuth})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    height: '100vh',
    '@media (max-width:767px)': {
      display: 'none',
    },
  },
  wrapper: {
    width: '390px',
    margin: '38px 20px 32px',
    '@media (max-width:767px)': {
      width: 'calc(100% - 40px)',
    },
  },
}));
