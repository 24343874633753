import { FC } from 'react';
import { Text, useMantineTheme } from '@mantine/core';

interface ProductsCountPorops {
  count: number;
}

export const ProductsCount: FC<ProductsCountPorops> = ({ count }) => {
  const { colors } = useMantineTheme();
  return (
    <Text sx={{ whiteSpace: 'nowrap' }}>
      <span style={{ color: colors.text[5] }}>Товаров: </span>
      {count}
    </Text>
  );
};
