import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Group, Text } from '@mantine/core';

import { useStyles } from './styles';

import { ReactComponent as Headphone } from '@/assets/icons/headphone.svg';

interface IMarkInfoProps {
  text?: string | null;
  link?: string | null;
}

const MarkInfo: FC<IMarkInfoProps> = ({ text, link }) => {
  const { classes } = useStyles();

  return (
    <Group spacing={8}>
      <Group spacing={7} className={classes.root}>
        <Text className={classes.text}>{text}</Text>
      </Group>
      {link && (
        <Link className={classes.link} to={link} target="_blank" color="black">
          <Headphone width={20} fill-opacity="36%" />
        </Link>
      )}
    </Group>
  );
};

export default MarkInfo;
