import { createStyles, getStylesRef } from '@mantine/core';
import isString from 'lodash/isString';

interface CalendarInputStyleProps {
  showClearButton?: boolean;
  h?: string | number;
}

export const useStyles = createStyles<string, CalendarInputStyleProps>(
  (theme, { showClearButton, h }) => ({
    input: {
      paddingRight: showClearButton ? 80 : 48,
      height: isString(h) ? h : `${h}px`,
    },
    rightSection: {
      pointerEvents: 'none',
      width: showClearButton ? 80 : 48,
      color: theme.colors.gray[5],
    },
    monthCell: {
      padding: '0px !important',
      ref: getStylesRef('monthCell'),
      [`&:nth-of-type(6) > .${getStylesRef(
        'day'
      )}[data-outside="true"]:not(:disabled):not([data-selected="true"]),
      &:nth-of-type(7) > .${getStylesRef(
        'day'
      )}[data-outside="true"]:not(:disabled):not([data-selected="true"])`]: {
        color: theme.colors.red[3],
      },
      [`&:hover .${getStylesRef(
        'day'
      )}:not([data-disabled="true"]):not([data-last-in-range="true"]):not([data-first-in-range="true"])[data-in-range="true"]`]:
        {
          left: 0,
          backgroundImage: `
        radial-gradient(circle, rgba(255,255,255,1) 52%, rgba(255,255,255,0) 56%),
        radial-gradient(circle, ${theme.colors.brandGreen[0]} 58%, rgba(255,255,255,0) 62%),
        radial-gradient(circle, ${theme.colors.brandGreen[0]} 58%, rgba(255,255,255,0) 62%)
        `,
        },
    },
    day: {
      padding: '0 24px',
      ref: getStylesRef('day'),
      borderRadius: 0,
      color: theme.colors.brandDark[9],
      '&[data-outside="true"]': {
        color: `${theme.colors.gray[6]}`,
      },
      ':hover:not([data-disabled="true"])': {
        backgroundColor: 'transparent',
        backgroundImage: `radial-gradient(circle, rgba(255,255,255,1) 52%, rgba(255,255,255,0) 56%),
        radial-gradient(circle, ${theme.colors.brandGreen[0]} 58%, rgba(255,255,255,0) 62%)`,
      },
      '&[data-selected]': {
        backgroundColor: 'transparent',
        backgroundImage: `radial-gradient(circle, ${theme.colors.brandGreen[0]} 58%, rgba(255,255,255,0) 62%)`,
        ':hover': {
          color: theme.colors.brandDark[9],
          backgroundColor: 'transparent',
          backgroundImage: `
            radial-gradient(circle, rgba(255,255,255,1) 52%, rgba(255,255,255,0) 56%),
            radial-gradient(circle, ${theme.colors.brandGreen[0]} 58%, rgba(255,255,255,0) 62%)`,
        },
      },
      '&[data-in-range]:not([data-selected])': {
        borderRadius: 0,
        backgroundColor: theme.colors.brandGreen[1],
      },
      '&[data-first-in-range], &[data-last-in-range]': {
        position: 'relative',
        backgroundColor: 'transparent',
        ':hover': {
          backgroundColor: 'transparent',
        },
      },
      '&[data-first-in-range="true"]': {
        right: 0,
        backgroundImage: `
        radial-gradient(circle, ${theme.colors.brandGreen[0]} 58%, rgba(255,255,255,0) 62%),
        linear-gradient(90deg, rgba(255,255,255,0) 50%, rgba(229,250,233,1) 50%)
        `,
        ':hover': {
          backgroundImage: `
          radial-gradient(circle, rgba(255,255,255,1) 52%, rgba(255,255,255,0) 56%),
          radial-gradient(circle, ${theme.colors.brandGreen[0]} 58%, rgba(255,255,255,0) 62%),
          linear-gradient(90deg, rgba(255,255,255,0) 50%, rgba(229,250,233,1) 50%)
          `,
        },
      },
      '&[data-last-in-range="true"]': {
        left: 0,
        backgroundImage: `
        radial-gradient(circle, ${theme.colors.brandGreen[0]} 58%, rgba(255,255,255,0) 62%),
        linear-gradient(90deg, rgba(229,250,233,1) 50%, rgba(255,255,255,0) 50%)
        `,
        ':hover': {
          backgroundImage: `
          radial-gradient(circle, rgba(255,255,255,1) 52%, rgba(255,255,255,0) 56%),
          radial-gradient(circle, ${theme.colors.brandGreen[0]} 58%, rgba(255,255,255,0) 62%),
          linear-gradient(90deg, rgba(229,250,233,1) 50%, rgba(255,255,255,0) 50%)
          `,
        },
      },
      [`&[data-in-range="true"]:last-of-type(1 of .${getStylesRef('day')}})`]: {
        backgroundColor: 'red',
      },
    },
    [`.${getStylesRef('monthCell')}:hover > .${getStylesRef('day')}`]: {
      backgroundColor: 'red',
    },
    calendarWrapper: {
      [`&[data-has-value="true"] .${getStylesRef(
        'calendar-placeholder'
      )}, &:focus-within .${getStylesRef('calendar-placeholder')}`]: {
        display: 'none',
      },
      [`&[data-required="true"] .${getStylesRef('calendar-placeholder')}:after`]: {
        content: '""',
        position: 'absolute',
        top: 0,
        right: `-0.8em`,
        width: '0.4em',
        height: '0.4em',
        borderRadius: '50%',
        backgroundColor: theme.colors.red[6],
      },
      [`&[data-single="true"] .${getStylesRef('day')}[data-weekend="true"]:not([data-selected])`]: {
        color: 'black',
      },
    },
    placeholderWrapper: {
      position: 'relative',
    },
    placeholder: {
      ref: getStylesRef('calendar-placeholder'),
      position: 'absolute',
      left: '0.75em',
      top: '50%',
      color: theme.colors.gray[5],
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
      fontSize: '0.875em',
    },
    calendar: {
      borderRadius: '50%',
    },
    calendarHeader: {
      justifyContent: 'center',
      maxWidth: 'none',
    },
    calendarHeaderLevel: {
      color: 'black',
      flexGrow: 0,
      whiteSpace: 'nowrap',
      padding: '0 8px',
      minWidth: 120,
      fontWeight: 600,
      borderRadius: '16px',
    },
    calendarHeaderControl: {
      color: 'black',
      borderRadius: '50%',
    },
  })
);
