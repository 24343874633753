import { createStyles } from "@mantine/core";

export const useStyles = createStyles((_) => ({
    root: {
        lineHeight: '24px',
        fontSize: '14px',
        width: '100%',
        minHeight: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        '& div h2': {
            marginTop: '15px',
            marginBottom: '20px',
            fontSize: '20px',

            '&:first-of-type': {
                marginTop: 0,
            }
        },

        '& div h4': {
            fontSize: '16px',
            fontWeight: 700,
            marginTop: '20px',
            marginBottom: '16px'
        },

        '& br': {
            display: 'none'
        }
    },

}));
