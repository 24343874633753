import { FC } from 'react';
import { Box, BoxProps, Center, LoadingOverlay, Text } from '@mantine/core';

import { useStyles } from './styles';

interface IPageLoader extends BoxProps {
  loading: boolean;
  text?: string;
}

const PageLoader: FC<IPageLoader> = ({ loading, text, ...props }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.wrapper} {...props}>
      <LoadingOverlay visible={loading} />

      {!loading && text && (
        <Center className={classes.inner}>
          <Text align={'center'} color="dimmed">
            {text}
          </Text>
        </Center>
      )}
    </Box>
  );
};

export default PageLoader;
