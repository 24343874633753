import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    icon: {
        marginLeft: 'auto',
        fill: theme.colors.brandGrey[2],
    },
    button: {
        padding: '16px 12px 12px',
        borderBottom: `1px solid ${theme.colors.brandGrey[5]}`,
        width: '100%',
        fontSize: '14px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        color: theme.colors.brandDark[9],
        backgroundColor: 'transparent',
    },
    button_active: {
        backgroundColor: theme.colors.brandDark[0],
        "& > svg": {
            fill: theme.colors.brandDark[9],
        },
    },
    title: {
        padding: '16px 12px 12px',
        borderBottom: `1px solid  ${theme.colors.brandGrey[5]}`,
        width: '100%',
        fontSize: '14px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
    }
}));