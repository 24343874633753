import { IDocumentFile } from '@/types/api/productDocuments';

import { ChangeEvent, DragEvent, FC, useMemo, useState } from 'react';
import { Box, Button, Group, Text, useMantineTheme } from '@mantine/core';
import { Stack } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone';
import isArray from 'lodash/isArray';
import noop from 'lodash/noop';

import {
  fileFilterBuilder,
  FileRejection,
  FileRejectionType,
  getStringFromFileSize,
} from '@/utils/file';

import { FileItem } from '../../../FileItem/FileItem';

import { ReactComponent as IconAttach } from '@/assets/icons/upload.svg';

const ERROR_MESSAGES: Record<FileRejectionType, string> = {
  'file-too-large': 'Размер файла превышает 10МБ',
  'invalid-extension': 'Можно загружать только файлы формата PDF, JPEG, WEBP, PNG',
  'too-many-files': 'Загрузить можно только до 10 файлов',
  'size-limit-exceeded': 'Общий объём файлов не должен превышать 10МБ',
};

interface FilesInputProps {
  uploaded?: IDocumentFile[];
  value: File[];
  onChange: (value: File[]) => void;
  onErrorChange?: (isError: boolean) => void;
  onRemoveUploaded: (value: string) => void;
  loading?: boolean;
}

const FIXED_LIMIT = 10000000;
export const FIXED_FILES_LIMIT = 10;

export const FilesInput: FC<FilesInputProps> = ({
  uploaded = [],
  value,
  onChange,
  onErrorChange,
  onRemoveUploaded,
  loading,
}) => {
  const { colors } = useMantineTheme();
  const [errors, setErrors] = useState<FileRejection[]>([]);
  // const { classes } = useStyles();
  const localFilesLimit = FIXED_FILES_LIMIT - uploaded.length;
  const filesLimit = localFilesLimit - value.length;

  const filesFilter = useMemo(
    () =>
      fileFilterBuilder()
        .byExt(['jpeg', 'jpg', 'png', 'pdf', 'webp'])
        .bySize(FIXED_LIMIT)
        .getFilter(),
    [uploaded, value]
  );

  const processRejections = (rejections: FileRejection[]) => {
    const newErrors = errors.concat(rejections);
    setErrors(newErrors);
    onErrorChange?.(newErrors.length > 0 || value.length > localFilesLimit);
  };

  const handleDropAny = (files: File[]) => {
    const [acceptedFiles, rejections] = filesFilter(files);
    onChange([...value, ...acceptedFiles]);
    processRejections(rejections);
  };

  const handleRemoveLocalFile = (index: number) => {
    onChange(value.filter((file, i) => i != index));
  };

  const handleChangeLocalFile = (file: File | null, index: number) => {
    if (!file) return;

    const newValue = [...value];

    const [acceptedFile, rejections] = filesFilter([file]);
    processRejections(rejections);

    if (!acceptedFile[0]) return;

    newValue.splice(index, 1, acceptedFile[0]);
    onChange(newValue);
  };

  const handleChangeRemoteFile = (file: File | null, index: number) => {
    if (!file) return;

    const [acceptedFile, rejections] = filesFilter([file]);
    processRejections(rejections);

    if (!acceptedFile[0]) return;

    onRemoveUploaded(uploaded[index].key);
    onChange([...value, file]);
  };

  const handleChangeRejectedFile = (file: File | null, i: number) => {
    let newErrors = errors.filter((_, index) => i !== index);
    let acceptedFile: File | null = null;

    if (file) {
      const [accepted, rejections] = filesFilter([file]);
      if (rejections) newErrors = newErrors.concat(rejections);
      if (accepted.length) acceptedFile = accepted[0];
    }

    if (acceptedFile) onChange?.([...value, acceptedFile]);
    setErrors(newErrors);
    onErrorChange?.(newErrors.length > 0);
  };

  const getFiles = async (e: any) => {
    let files: File[] = [];

    if (!isArray(e)) {
      if (e.type === 'change') {
        const castedEvent: ChangeEvent<HTMLInputElement> = e;
        if (castedEvent.target.files) files = [...castedEvent.target.files];
      } else {
        const castedEvent: DragEvent = e;
        files = [...castedEvent.dataTransfer.files];
      }
    } else {
      const castedEvent = e as FileSystemFileHandle[];
      const promises = castedEvent.map((item) => item.getFile());
      files = await Promise.all(promises);
    }

    return files;
  };

  const handleReject = (e: any) => {
    const castedEvent: { file: File; errors: string[] }[] = e;
    const files = castedEvent.map((item) => item.file);
    const [, rejections] = filesFilter(files);
    processRejections(rejections);
  };

  return (
    <Stack spacing={24} w="100%">
      {/* <ScrollArea.Autosize mah={206} w="100%" offsetScrollbars type="always" classNames={classes}> */}
      <Stack spacing={24}>
        {uploaded.map((file, i) => (
          <Box sx={{ borderBottom: `1px solid ${colors.gray[2]}` }} pb={24} key={file.key}>
            <FileItem
              name={file.originalName}
              ext={file.extension}
              date={file.date}
              size={file.size}
              onRemove={() => onRemoveUploaded(file.key)}
              accept={`${IMAGE_MIME_TYPE},${PDF_MIME_TYPE}`}
              onChange={(newFile) => handleChangeRemoteFile(newFile, i)}
              nameEllipsis
              variant={'row'}
            />
          </Box>
        ))}
        {value.slice(0, localFilesLimit).map((file, i) => (
          <Box sx={{ borderBottom: `1px solid ${colors.gray[2]}` }} pb={24} key={i}>
            <FileItem
              name={file.name.split('.').slice(0, -1).join() || ''}
              ext={file.name.split('.').pop()?.toUpperCase() || ''}
              size={getStringFromFileSize(file.size)}
              onRemove={() => handleRemoveLocalFile(i)}
              onChange={(newFile) => handleChangeLocalFile(newFile, i)}
              accept={`${IMAGE_MIME_TYPE.join(',')},${PDF_MIME_TYPE.join(',')}`}
              nameEllipsis
              variant={'row'}
            />
          </Box>
        ))}
        {value.slice(localFilesLimit).map((file, i) => (
          <Box sx={{ borderBottom: `1px solid ${colors.gray[2]}` }} pb={24} key={i}>
            <FileItem
              name={file.name.split('.').slice(0, -1).join() || ''}
              ext={file.name.split('.').pop()?.toUpperCase() || ''}
              size={getStringFromFileSize(file.size)}
              onRemove={() => handleRemoveLocalFile(i + localFilesLimit)}
              onChange={(newFile) => handleChangeLocalFile(newFile, i + localFilesLimit)}
              accept={`${IMAGE_MIME_TYPE.join(',')},${PDF_MIME_TYPE.join(',')}`}
              error={ERROR_MESSAGES['too-many-files']}
              nameEllipsis
              variant={'row'}
            />
          </Box>
        ))}
        {errors.map(({ error, file }, i) => (
          <Box
            sx={{ borderBottom: `1px solid ${colors.gray[2]}` }}
            pb={24}
            key={`${file.name}-${file.size}`}
          >
            <FileItem
              name={file.name.split('.').slice(0, -1).join() || ''}
              ext={file.name.split('.').pop()?.toUpperCase() || ''}
              size={getStringFromFileSize(file.size)}
              onRemove={() => handleChangeRejectedFile(null, i)}
              onChange={(newFile) => handleChangeRejectedFile(newFile, i)}
              key={file.name}
              accept={`${IMAGE_MIME_TYPE.join(',')},${PDF_MIME_TYPE.join(',')}`}
              error={ERROR_MESSAGES[error]}
              nameEllipsis
              variant={'row'}
            />
          </Box>
        ))}
      </Stack>
      {/* </ScrollArea.Autosize> */}
      {filesLimit > 0 ? (
        <Dropzone
          onDrop={noop}
          onDropAny={handleDropAny}
          onReject={handleReject}
          h={156}
          styles={{ inner: { height: '100%' } }}
          accept={[...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE]}
          loading={loading}
          disabled={filesLimit === 0}
          getFilesFromEvent={getFiles}
        >
          <Stack h="100%" justify="center" spacing={16} align="center">
            <Text size={14} weight={700}>
              Перетащите файлы в эту область или выберите на компьютере
            </Text>
            <Text size={14} color={colors.gray[6]}>
              До 10 файлов в формате PDF, JPEG, WEBP, PNG весом не более 10 МБ{' '}
            </Text>
            <Button variant="subtle">
              <Group spacing={8}>
                <Text>Загрузить файлы</Text>
                <IconAttach width={20} />
              </Group>
            </Button>
          </Stack>
        </Dropzone>
      ) : (
        <Text align="center" size={14} color={colors.red[5]}>
          Загружено максимально возможное количество файлов - {FIXED_FILES_LIMIT}
        </Text>
      )}
    </Stack>
  );
};
