import { FC, useEffect, useState } from 'react';
import { Grid, Text, useMantineTheme } from '@mantine/core';

import workerFunc, { workerBuilder } from '@/utils/webWorkerTimer';

import { toMMSS } from '@/ui/organisms/Timer/utils/toMMSS';

interface ITimer {
  time: number; // seconds
  callback: () => void;
  text?: string;
}

let timer: Worker;
const Timer: FC<ITimer> = ({ time = 0, text, callback }) => {
  const theme = useMantineTheme();
  const deadline = Date.now() + time * 1000;
  const [value, setValue] = useState<string>(toMMSS((time - 1) * 1000));

  const getTime = (timerTime: number) => {
    const currentTime = timerTime - Date.now();

    setValue(toMMSS(currentTime));

    if (Date.now() >= deadline) {
      timer.postMessage({ turn: "off" });
      callback();
    }
  };

  useEffect(() => {
    timer = workerBuilder(workerFunc);
    timer.postMessage({ turn: "on" });
    timer.onmessage = () => getTime(deadline);
    return () => timer.postMessage({ turn: "off" });
  }, []);

  return (
    <Grid justify='left' mt={8} mx={0}>
      {text &&
        <Text
          fz={14}
          lh={1}
          color={theme.colors.brandDark[9]}>
          {text}
        </Text>
      }
      <Text sx={{
        marginLeft: '4px',
        color: theme.colors.red[5]
      }}>
        {value}
      </Text>
    </Grid>
  );
};

export default Timer;
