import { dateFromString } from '@/utils/formatDate';

export const excludeDate = (date: Date, registrationDate: string) => {
  const currentDate = new Date();
  const regDate = dateFromString(registrationDate);

  if (!regDate) return false;

  const currentDateNoTime = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 1 // запрещаем выбрать текущий день
  );
  const dateNoTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());

  const minDate = new Date(
    Math.max(
      new Date(
        currentDate.getFullYear() - 2,
        currentDate.getMonth(),
        currentDate.getDate()
      ).valueOf(),
      regDate.valueOf()
    )
  );

  return dateNoTime < minDate || dateNoTime > currentDateNoTime;
};
