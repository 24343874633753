import React, { FC, useMemo, useRef, useState } from 'react';
import { Box, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { Check } from 'tabler-icons-react';

import { useOutsideClick } from '@/hooks/useOutsideClick';

import { getAdditionalAttributes } from '@/ui/pages/ProfilePage/utils/formBuilderHelpers';

import { ReactComponent as IconPencil } from '@/assets/icons/redesign/iconPencil.svg';

export function InputWithLabelHoc(Component: FC<any>) {
  return function WithEditIcon({
    disabled = false,
    description,
    changeDescription,
    onChange,
    value,
    onCheckClick,
    isActiveCheckButton,
    disabledChangeButton,
    ...props
  }: typeof Component extends FC<infer P> ? P : never) {
    const [isEditing, setIsEditing] = useState<boolean>(disabled);
    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const [isChange, setIsChange] = useState<boolean>(!isActiveCheckButton);
    const [originalValue, setOriginalValue] = useState(value);
    const theme = useMantineTheme();

    useOutsideClick(wrapperRef, () => {
      if (!isEditing && isChange) {
        setIsEditing(true);
        setIsChange(false);
        onChange(originalValue);
      }
    });

    const buttonColor = useMemo(() => {
      if (disabledChangeButton) return theme.colors.gray[1];
      return !!props.error
        ? theme.colors.gray[1]
        : isEditing
        ? 'transparent'
        : theme.colors.mainColor[6];
    }, [disabledChangeButton, props, isEditing, isChange]);

    const toggleEditing = () => {
      setIsEditing(!isEditing);
      setIsChange(true);
      if (isEditing) {
        onChange('');
      }
    };

    const onAcceptHandler = () => {
      const res = onCheckClick();
      if (!props.error && !isEditing) {
        res.then((flag: boolean) => {
          setIsEditing(flag);
          if (flag) {
            setOriginalValue(value);
            onChange(value);
            setIsChange(false);
          }
        });
      }
    };

    return (
      <>
        <Box ref={wrapperRef} display="flex" w="100%">
          <Component
            size="lg"
            radius={8}
            value={value}
            {...props}
            {...getAdditionalAttributes(props.label)}
            onChange={onChange}
            disabled={isEditing}
          />
          <UnstyledButton
            w={60}
            ml={16}
            h={'inherit'}
            bg={buttonColor}
            onClick={!isChange && isEditing ? toggleEditing : onAcceptHandler}
            disabled={disabledChangeButton || !!props.error}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #C5D0D9',
              borderRadius: '50%',
            }}
          >
            {(isChange || !isEditing) && <Check color="white" style={{ cursor: 'pointer' }} />}
            {!isChange && isEditing && <IconPencil fill={theme.colors.brandDark[9]} />}
          </UnstyledButton>
        </Box>
        {description && (
          <Text fz={14} lh={'20px'} mt={22}>
            {!isEditing ? changeDescription : description}
          </Text>
        )}
      </>
    );
  };
}
