import { FC, useEffect, useState } from "react";
import { Box } from "@mantine/core";

import { getStringFromFileSize } from "@/utils/file";

import { FileItem } from "../FileItem/FileItem";

import { TEXT_DESCRIPTION_FILE_ARCHIVE } from "./utils/constants";
import { validateFile } from "./utils/validateFile";

interface FileState {
    file: File | null;
    error: string;
}
interface IUploadTemplateProps {
    setExcelFile: (file: File | null) => void;
    setZipFile?: (file: File | null) => void;
    setIsError?: (flag: boolean) => void;
}

const UploadTemplate: FC<IUploadTemplateProps> = ({
    setExcelFile,
    setZipFile,
    setIsError,
}) => {
    const [templateFile, setTemplateFile] = useState<FileState>({ file: null, error: '' });
    const [archiveFile, setArchiveFile] = useState<FileState>({ file: null, error: '' });

    const handleFileChange = (file: File | null, fileType: string) => {
        if (fileType === 'template') {
            const error = validateFile(file, 'xlsx');
            setTemplateFile({ file, error });
            setExcelFile(file);
        } else if (fileType === 'archive') {
            const error = validateFile(file, 'zip');
            setArchiveFile({ file, error });
            if (setZipFile) setZipFile(file);
        }
    };

    const handleFileRemove = (fileType: string) => {
        if (fileType === 'template') {
            setTemplateFile({ file: null, error: '' });
            setExcelFile(null);
        } else if (fileType === 'archive') {
            setArchiveFile({ file: null, error: '' });
            if (setZipFile) {
                setZipFile(null);
            }
        }
    };

    useEffect(() => {
        setIsError?.(!!templateFile.error || !!archiveFile.error);
    }, [templateFile, archiveFile, setIsError]);

    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <FileItem
                    name={templateFile.file?.name || 'Заполненный шаблон'}
                    description={'Файл в формате XLSX'}
                    ext={templateFile?.file?.name.split('.').pop() || ''}
                    size={getStringFromFileSize(templateFile.file?.size || 0)}
                    variant='rowText'
                    error={templateFile.error}
                    acceptedFileTypes={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                    onChange={(newFile) => handleFileChange(newFile, 'template')}
                    onRemove={() => handleFileRemove('template')}
                    buttons
                    required
                    nameEllipsis
                />
                {setZipFile &&
                    <FileItem
                        name={archiveFile.file?.name || 'Архив с изображениями'}
                        description={TEXT_DESCRIPTION_FILE_ARCHIVE}
                        ext={archiveFile.file?.name.split('.').pop() || ''}
                        size={getStringFromFileSize(archiveFile.file?.size || 0)}
                        variant='rowText'
                        error={archiveFile.error}
                        acceptedFileTypes={['application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip']}
                        onChange={(newFile) => handleFileChange(newFile, 'archive')}
                        onRemove={() => handleFileRemove('archive')}
                        buttons
                        nameEllipsis
                    />
                }
            </Box>
        </>
    )
}

export default UploadTemplate;