import { IFamily } from '@/types/common/common';
import { TActiveCategories } from '@/types/entites/category';
import { ICategory, ICategoryTree } from '@/types/entites/category';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface ICategoryState {
  currentTree: ICategoryTree[];
  categoriesActive: TActiveCategories[] | ICategory[];
  currentDepthLevel: number;
  currentCategory: ICategory | null;
  categorySelected: ICategory | null;
  categoryFamily: IFamily[] | null;
}

const initialState: ICategoryState = {
  currentTree: [],
  categoriesActive: [],
  currentDepthLevel: 0,
  currentCategory: null,
  categorySelected: null,
  categoryFamily: [],
};

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCurrentCategory: (state, action: PayloadAction<ICategory | null>) => {
      state.currentCategory = action.payload;
    },
    setSelectedCategory: (state, action: PayloadAction<ICategory | null>) => {
      state.categorySelected = action.payload;
    },
    setActiveCategories: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      const { categoriesActive: tree, currentDepthLevel } = state;
    
      if (payload.length === 0) {
        state.categoriesActive = [];
        return;
      }
    
      if (payload.depthLevel < currentDepthLevel) {
        delete tree[currentDepthLevel];
      }
    
      if (payload.depthLevel <= 1) {
        delete tree[currentDepthLevel - 1];
      }
    
      state.categoriesActive = {
        ...tree,
        [payload.depthLevel]: payload,
      };
      
    },
    setCurrentDeepLevel: (state, action: PayloadAction<number>) => {
      state.currentDepthLevel = action.payload;
    },
    setCategoriesCurrentTree: (state, action: PayloadAction<ICategoryTree[]>) => {
      state.currentTree = action.payload;
    },
    setCategoriesFamily: (state, action: PayloadAction<IFamily[] | null>) => {
      state.categoryFamily = action.payload;
    },
  },
});

export const {
  setCurrentCategory,
  setCategoriesCurrentTree,
  setActiveCategories,
  setCurrentDeepLevel,
  setCategoriesFamily,
  setSelectedCategory
} = categorySlice.actions;

export default categorySlice.reducer;
