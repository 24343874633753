import {IDadataInfo} from "@/types/api/dadata";

import {createSlice} from "@reduxjs/toolkit";

import { fetchDadataInfo } from "./asyncActions";

interface IDadataState {
  dadataPrompts: IDadataInfo[] | null;
  currentDadata: IDadataInfo | null
}

const initialState: IDadataState = {
  dadataPrompts: null,
  currentDadata: null
};

export const dadataSlice = createSlice({
  name: 'dadata',
  initialState,
  reducers: {
    setCurrentDadata: (state, action) => {
      state.currentDadata = action.payload;
    },
    setDadataPrompts: (state, action) => {
      state.dadataPrompts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(fetchDadataInfo.pending, (state) => {
      //   state.status.fetchingVariants = true;
      // })
      .addCase(fetchDadataInfo.fulfilled, (state, action) => {
        // state.status.fetchingVariants = false;
        state.dadataPrompts = action.payload;
      })
      // .addCase(fetchDadataInfo.rejected, (state) => {
      //   state.status.fetchingVariants = false;
      // });
  },
});

export const { setCurrentDadata, setDadataPrompts } = dadataSlice.actions;

export default dadataSlice.reducer;
