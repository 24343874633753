export enum AuthStepsEnum {
  SIGNIN_WITH_PHONE = 'signin_with_phone',
  SIGNIN_WITH_PHONE_CODE = 'signin_with_phone_code',
  SIGNIN_WITH_ONLY_EMAIL = 'signin_with_only_email',
  SIGNIN_WITH_EMAIL = 'signin_with_email',
  RESTORE_PASSWORD_EMAIL = 'restore_password_email',
  RESTORE_PASSWORD_EMAIL_CODE = 'restore_password_email_code',
  RESTORE_PASSWORD_PHONE_CODE = 'restore_password_phone_code',
  SIGNUP = 'signup',
  BLOCKED = 'blocked',
  UPGRADE_TO_SELLER = 'upgrade_to_seller',
  UPGRADE_TO_SELLER_NEW = 'upgrade_to_seller_new',
  CHECK_EMAIL = 'check_email',
  COMPANY_INFORMATION = 'company_information',
  DATA_FILL = 'data_fill',
}

export enum AuthTypeEnum {
  USER = 'user',
  SELLER = 'seller',
}

export interface ICheckPhoneRequest {
  phone: string;
  recaptchaToken: string;
  sendAuthCode: boolean;
}

export interface ICheckEmailRequest {
  email: string;
  recaptchaToken?: string;
  sendAuthCode?: boolean;
}

export interface ICheckPhoneResponse {
  userExist: boolean;
  interval: number;
}

export interface ICheckEmailResponse {
  userExist: boolean;
  userId?: number; 
}
export interface IAuthByPhoneRequest {
  phone: string;
  password: string;
  recaptchaToken: string;
}

export type IChangePasswordConfirmRequest = IAuthByPhoneRequest & {
  actionSource: 'web';
};

export interface IEmailConfirmRequest{
  email?: string;
  emailSellerLogin?: string;
  companyEmail?: string;
}

export interface IEmailConfirmResponse{
  alreadyConfirmed?: boolean;
}

export interface IAuthByCodeRequest {
  phone: string;
  code: string;
  ordersStatus: boolean;
  promotions: boolean;
  recaptchaToken: string;
  actionSource: 'web';
}

export interface IAuthByEmailRequest {
  // email: string;
  emailSellerLogin: string;
  password: string;
  recaptchaToken: string;
}

export type IChangePasswordByEmailConfirm = IAuthByEmailRequest & {
  actionSource: 'web';
}

export interface IChangePasswordProfileRequest {
  oldPassword: string;
  newPassword: string;
  confirmationNewPassword: string;
  refreshToken: string;
}

export interface IChangePasswordByEmailRequest {
  // email: string;
  emailSellerLogin: string;
  recaptchaToken: string;
}

export interface INewCodeRequest {
  phone: string;
  recaptchaToken: string;
}
export interface IUpgradeToSellerRequest {
  recaptchaToken: string;
  name: string;
  lastName: string;
  email: string;
}

export interface IUpgradeToSellerRequest {
  recaptchaToken: string;
  name: string;
  lastName: string;
  email: string;
}

export interface IUpgradeToSellerRequest {
  recaptchaToken: string;
  name: string;
  lastName: string;
  email: string;
}

export interface IChangePasswordRequest extends INewCodeRequest {}



