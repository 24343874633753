import PageContent from '@/ui/organisms/Page/components/PageContent/PageContent';
import PageHeader from '@/ui/organisms/Page/components/PageHeader/PageHeader';
import Page from '@/ui/organisms/Page/Page';
import { ProductDocsPage } from '@/ui/pages/ProductDocsPage/ProductDocsPage';

export const ProductDocs = () => {
  return (
    <Page>
      <PageHeader title="Документы на товары" />
      <PageContent>
        <ProductDocsPage />
      </PageContent>
    </Page>
  );
};
