import { createStyles } from "@mantine/core";

export const useStyles = createStyles(() => ({
    wrapper: {
        display: 'flex',
        alignItems: 'baseline',
        margin: '48px 0px 24px'
    },
    title:{
        lineHeight: 1,
        marginRight: '16px'
    }
}));