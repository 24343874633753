import { AuthStepsEnum } from '@/types/api/auth';

import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box, Button, Text } from '@mantine/core';

import useNavigateToProfile from '@/hooks/useNavigateToProfile';

import AuthFooter from '../AuthFooter/AuthFooter';

import { AppDispatch } from '@/store';
import { setAuthStep } from '@/store/slices/auth/auth';
import { selectUser } from '@/store/slices/user/slice';

const CheckEmail: FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(selectUser);
  const navigateToProfile = useNavigateToProfile(user);

  const handleClick = (): void => {
    if (user?.isSeller && !user?.companyInn)
      dispatch(setAuthStep(AuthStepsEnum.COMPANY_INFORMATION));
    else navigateToProfile();
  };

  return (
    <>
      <Box ta={'left'}>
        <Box fz={14} lh={'22px'}>
          Мы отправили письмо со ссылкой
          <br /> для подтверждения на электронный адрес
          <Text lh={'inherit'}>{user?.emailSellerLogin || user?.email}</Text>
        </Box>
        {process.env.NODE_ENV !== 'prod' && (
          <AuthFooter>
            <Button
              onClick={handleClick}
              type="submit"
              size="lg"
              variant="filled"
              fullWidth
              mt={32}
            >
              Понятно
            </Button>
          </AuthFooter>
        )}
      </Box>
    </>
  );
};

export default CheckEmail;
