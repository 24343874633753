import {
  ChangeEventHandler,
  ComponentProps,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ActionIcon,
  Box,
  clsx,
  Group,
  Input,
  InputStylesNames,
  Popover,
  useMantineTheme,
} from '@mantine/core';
import { Text } from '@mantine/core';
import omit from 'lodash/omit';
import { X } from 'tabler-icons-react';

import { useStyles } from './styles';

import { ReactComponent as IconInfo } from '@/assets/icons/redesign/iconInfo.svg';
import { ReactComponent as IconLock } from '@/assets/icons/redesign/iconLock.svg';

type InputProps<C = 'input'> = Omit<ComponentProps<typeof Input<C>>, keyof CommonProps>;
type StylesNames = Exclude<InputStylesNames, undefined> | 'label' | 'rightSection';
type ClassNames = {
  [index in StylesNames]: string;
};

type PlaceholderProps = { placeholder?: string };
type CommonProps = {
  value?: string;
  label?: string;
  labelSize?: string;
  description?: string;
  classNames?: ClassNames;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  clearable?: boolean | string;
  maxLength?: number;
  defaultValue?: string;
  tooltipText?: string;
  tooltipLabel?: boolean;
  tooltipPosition?: 'label' | 'default';
};

type EnhancedInputProps = InputProps &
  (
    | ({
        labelType: 'floating';
      } & CommonProps)
    | ({
        labelType?: 'normal';
      } & CommonProps &
        PlaceholderProps)
  );

export const EnhancedInput = forwardRef<HTMLInputElement, EnhancedInputProps>(
  (
    {
      classNames,
      error,
      labelType = 'normal',
      label,
      description,
      onChange,
      defaultValue = '',
      tooltipPosition = 'default',
      ...props
    },
    ref
  ) => {
    const { classes } = useStyles({
      floatingLabel: labelType === 'floating',
      size: props.size,
      h: props.height,
      tooltipLabel: Boolean(tooltipPosition === 'label'),
    });
    const theme = useMantineTheme();
    const [currentValue, setCurrentValue] = useState(props.value || defaultValue);

    const [openTooltip, setOpenTooltip] = useState<boolean>(false);
    const innerRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      setCurrentValue(props.value || defaultValue);
    }, [props.value, defaultValue]);

    useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
      ref,
      () => innerRef.current
    );

    const changeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
      setCurrentValue(e.target.value);
      onChange?.(e);
    };

    const clearHandler = () => {
      if (!innerRef.current) return;
      const e = new InputEvent('input', { bubbles: true });
      innerRef.current.value = '';
      setCurrentValue('');
      innerRef.current.dispatchEvent(e);
    };

    const displayValue = useMemo(
      () => (typeof props.value === 'undefined' ? currentValue : props.value),
      [currentValue, props.value]
    );

    const renderTooltip = () => (
      <Popover opened={openTooltip} onChange={setOpenTooltip} withinPortal trapFocus={false}>
        <Popover.Target>
          <div
            className={classes.buttonTooltip}
            onMouseEnter={() => setOpenTooltip(true)}
            onMouseLeave={() => setOpenTooltip(false)}
          >
            <IconInfo />
          </div>
        </Popover.Target>
        <Popover.Dropdown className={classes.tooltip}>{props.tooltipText}</Popover.Dropdown>
      </Popover>
    );

    return (
      <Input.Wrapper
        className={clsx(classNames?.wrapper, classes.wrapper)}
        data-value={!!displayValue}
        data-required={props.required}
        data-floating={labelType === 'floating'}
        sx={props.sx}
      >
        <Input.Description>{description}</Input.Description>

        <Box pos="relative">
          {labelType === 'normal' && (
            <Input.Label className={clsx(classes.label, classNames?.label)}>
              {label}
              {tooltipPosition === 'label' && props.tooltipText && renderTooltip()}
            </Input.Label>
          )}
          <Input
            {...omit(props, 'label')}
            classNames={{
              input: clsx(classNames?.input, classes.input),
              rightSection: classes.rightSection,
            }}
            onInput={changeHandler}
            value={props.value}
            defaultValue={currentValue}
            ref={innerRef}
            rightSection={
              props.disabled ? (
                <IconLock width="20px" height="20px" />
              ) : props.clearable && displayValue ? (
                <ActionIcon
                  onClick={clearHandler}
                  className={clsx(classes.rightSection, classNames?.rightSection)}
                >
                  <X color={theme.colors.brandDark[9]} />
                </ActionIcon>
              ) : null
            }
          />

          {labelType === 'floating' && (
            <Input.Label className={clsx(classes.label, classNames?.label)}>
              <Group align="center" spacing={10}>
                <Text className={classes.labelText}>{label}</Text>
                {tooltipPosition === 'label' && props.tooltipText && renderTooltip()}
              </Group>
            </Input.Label>
          )}

          {tooltipPosition !== 'label' && props.tooltipText && (
            <Group spacing={2}>
              {props.tooltipLabel && (
                <Text fz={12} color={theme.colors.brandGrey[8]}>
                  Этот номер телефона будет указан в онлайн-чеках для покупателей
                </Text>
              )}
              {renderTooltip()}
            </Group>
          )}
        </Box>

        {error && <Input.Error className={classes.error}>{error}</Input.Error>}
      </Input.Wrapper>
    );
  }
);
