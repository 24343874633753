import { FC, useRef, useState } from 'react';
import {
  Box,
  clsx,
  FileButton,
  Group,
  Loader,
  Stack,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import noop from 'lodash/noop';
import { Repeat } from 'tabler-icons-react';

import { useStyles } from './styles';

import { ReactComponent as FileIcon } from '@/assets/icons/fileIcon.svg';
import { ReactComponent as IconCross } from '@/assets/icons/redesign/iconCross.svg';
import { ReactComponent as IconEdit } from '@/assets/icons/redesign/iconEdit.svg';
import { ReactComponent as IconUpload } from '@/assets/icons/redesign/iconUpload.svg';

type AcceptedFileTypes =
  | 'application/pdf'
  | 'application/vnd.ms-excel'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip';

interface FileItemProps {
  name: string;
  ext: string;
  size: string;
  date?: string;
  onChange?: (value: File | null, fileType?: string) => void;
  onRemove?: () => void;
  disabled?: boolean;
  buttons?: boolean;
  accept?: string;
  error?: string | null;
  variant?: 'row' | 'rowText';
  description?: string;
  acceptedFileTypes?: AcceptedFileTypes[];
  required?: boolean;
  nameEllipsis?: boolean;
  border?: boolean;
}

export const FileItem: FC<FileItemProps> = ({
  name,
  ext,
  size,
  date,
  buttons = true,
  onChange = noop,
  onRemove,
  accept,
  error,
  variant = 'row',
  disabled = false,
  description,
  acceptedFileTypes,
  required,
  nameEllipsis = false,
  border,
}) => {
  const { colors } = useMantineTheme();
  const { classes } = useStyles({ nameEllipsis: !!nameEllipsis, border: !!border });
  const fileResetRef = useRef<() => void>(null);
  const [removing, seRemoving] = useState(false);
  const [value, setValue] = useState<File | null>(null);
  const [showExtensionError, setShowExtensionError] = useState(false);

  const handleSetInnerFile = (file: File | null) => {
    setShowExtensionError(false);
    setValue(file);
    onChange(file);
  };

  const handleOnRemove = () => {
    setShowExtensionError(false);
    setValue(null);
    seRemoving(true);
    if (onRemove) onRemove();
    seRemoving(false);
  };

  return (
    <Group
      className={classes.root}
      spacing={12}
      align={variant === 'rowText' ? 'center' : 'flex-end'}
      w="100%"
      noWrap
      sx={{
        flex: '0 0 0',
        padding: variant === 'rowText' ? '20px 0px' : '0px',
      }}
    >
      <Box sx={{ flex: '0 0 60px' }}>
        <FileIcon fill={colors.brandGrey[5]} />
      </Box>
      <Stack spacing={10} miw={0} w="100%">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <Text className={classes.name} truncate>
            {name}
          </Text>
          {required && !value && <Box className={classes.required}></Box>}
        </Box>
        {error ? (
          <Text color={colors.red[5]} size={14} sx={{ whiteSpace: 'nowrap' }}>
            {showExtensionError || error}
          </Text>
        ) : size && ext ? (
          <Text size={14} color={colors.text[5]} sx={{ whiteSpace: 'nowrap' }}>
            {!date ? [ext, size].join(' · ') : [ext, size, date].join(' · ')}
          </Text>
        ) : (
          description && (
            <Text className={classes.description} size={12} lh={1.7} color={colors.text[5]}>
              {description}
            </Text>
          )
        )}
        <Group></Group>
      </Stack>

      {buttons && variant === 'row' && (
        <Group noWrap sx={{ flex: '0 0 72px', alignSelf: 'center' }}>
          <FileButton onChange={onChange} accept={accept}>
            {(props) => (
              <UnstyledButton className={classes.button} {...props}>
                <Text className={classes.buttonFileName}>Изменить</Text>
                <IconEdit className={classes.iconEdit} />
              </UnstyledButton>
            )}
          </FileButton>
          <UnstyledButton
            className={clsx(classes.button, classes.buttonRemove)}
            onClick={handleOnRemove}
          >
            <Text className={classes.buttonFileName}>Удалить</Text>
            {removing ? <Loader size={20} ml={5} /> : <IconCross className={classes.IconCross} />}
          </UnstyledButton>
        </Group>
      )}
      {buttons && variant === 'rowText' && (
        <Group className={classes.buttons} spacing={14}>
          {value && !disabled && (
            <>
              <FileButton
                onChange={handleSetInnerFile}
                accept={acceptedFileTypes?.join(',')}
                resetRef={fileResetRef}
              >
                {(buttonProps) => (
                  <UnstyledButton className={classes.button} {...buttonProps}>
                    <Text className={classes.buttonFileName}>Изменить</Text>
                    <IconEdit className={classes.iconEdit} />
                  </UnstyledButton>
                )}
              </FileButton>
            </>
          )}

          {value && (
            <UnstyledButton
              className={clsx(classes.button, classes.buttonRemove)}
              onClick={handleOnRemove}
            >
              <Text className={classes.buttonFileName}>Удалить</Text>
              {removing ? <Loader size={20} ml={5} /> : <IconCross className={classes.IconCross} />}
            </UnstyledButton>
          )}

          {!value && (
            <FileButton
              onChange={handleSetInnerFile}
              accept={acceptedFileTypes?.join(',')}
              resetRef={fileResetRef}
            >
              {(buttonProps) => (
                <UnstyledButton className={classes.button} {...buttonProps}>
                  {error && (
                    <>
                      <Text className={classes.buttonFileName}>Загрузить ещё раз</Text>
                      <Repeat className={classes.iconRepeat} />
                    </>
                  )}
                  {!error && (
                    <>
                      <Text className={classes.buttonFileName}>Загрузить файл</Text>
                      <IconUpload className={classes.icon} />
                    </>
                  )}
                </UnstyledButton>
              )}
            </FileButton>
          )}
        </Group>
      )}
    </Group>
  );
};
