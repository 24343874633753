import { FC, useRef, useState } from 'react';
import { Box, Button, Group } from '@mantine/core';
import { ChevronDown } from 'tabler-icons-react';

import { useOutsideClick } from '@/hooks/useOutsideClick';

import { useStyles } from './styles';

interface IDropdownProps {
  links: Array<{
    name: string;
    href?: string;
    children?: Array<{
      name: string;
      onClick: () => void;
      disabled?: boolean;
    }>;
  }>;
}

const Dropdown: FC<IDropdownProps> = ({ links }) => {
  const { classes } = useStyles();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick(dropdownRef, () => setActiveIndex(null));

  return (
    <Box className={classes.root} ref={dropdownRef}>
      {links.map((menuItem, index) => (
        <Group key={index} onClick={() => setActiveIndex(index)} pos="relative" align="center">
          <Button
            unstyled
            className={classes.link}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {menuItem.name}
            {menuItem.children && (
              <ChevronDown width={20} height={20} className={classes.chevron} />
            )}
          </Button>
          {menuItem.children && activeIndex === index && (
            <Box className={classes.dropdownWrapper}>
              <Box className={classes.dropdown}>
                {menuItem.children.map((child, childIndex) => (
                  <Box
                    key={childIndex}
                    className={child.disabled ? classes.disdropdownLink : classes.dropdownLink}
                    onClick={child.disabled ? undefined : child.onClick}
                  >
                    {child.name}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Group>
      ))}
    </Box>
  );
};

export default Dropdown;
