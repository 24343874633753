import { AuthStepsEnum } from '@/types/api/auth';

export const getAuthDescription = (step: AuthStepsEnum): string | undefined => {
  switch (step) {
    case AuthStepsEnum.SIGNIN_WITH_ONLY_EMAIL:
      return 'Для регистрации укажите Ваш e-mail';
    case AuthStepsEnum.SIGNIN_WITH_PHONE:
      return undefined;
    case AuthStepsEnum.SIGNIN_WITH_EMAIL:
      return 'Только для зарегистрированных пользователей';
    case AuthStepsEnum.RESTORE_PASSWORD_EMAIL:
      return 'Введите e-mail, указанный при регистрации';
    case AuthStepsEnum.RESTORE_PASSWORD_EMAIL_CODE:
      return 'На указанный email отправлен новый пароль';
    case AuthStepsEnum.RESTORE_PASSWORD_PHONE_CODE:
      return 'На указанный телефон отправлен новый пароль';
    case AuthStepsEnum.COMPANY_INFORMATION:
      return 'Введите ИНН, и мы автоматически заполним некоторые поля';
    case AuthStepsEnum.SIGNIN_WITH_PHONE_CODE:
      return 'Только для зарегистрированных пользователей';
    case AuthStepsEnum.SIGNUP:
      return undefined;
    case AuthStepsEnum.BLOCKED:
      return undefined;
    case AuthStepsEnum.UPGRADE_TO_SELLER:
      return undefined;
    case AuthStepsEnum.UPGRADE_TO_SELLER_NEW:
      return undefined;
    case AuthStepsEnum.CHECK_EMAIL:
      return undefined;
    default:
      return undefined;
  }
};
