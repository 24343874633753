import { MouseEvent } from 'react';

export const livetexShowWelcomeWindow = (e?: MouseEvent) => {
  e?.preventDefault();
  if (typeof window === 'undefined') return;

  window.LiveTex?.showWelcomeWindow();
};

export const getinteractiveDescription = (description?: string) => {
  if (!description) return;
  return description.replace(
    /<a.*?>(.*)<\/a>/,
    '<a href="#" onClick="(function(){if (!window.LiveTex) return; window.LiveTex.showWelcomeWindow()})();return false">$1</a>'
  );
};
