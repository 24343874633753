import { IStatus } from '@/types/common/common';

import {
  TO_STATUS_ARCHIVED,
  TO_STATUS_EDITING,
  TO_STATUS_IN_SALE,
  TO_STATUS_NOT_SALE,
  TO_STATUS_RESTORE,
} from '@/constants/statuses';
import {
  STATUS_ARCHIVED,
  STATUS_IN_SALE,
  STATUS_NOT_SALE,
  STATUS_READY_FOR_SALE,
  STATUS_REJECTED,
} from '@/constants/statuses';

export const getStatusOptions = (currentStatus: string, itemStatus: string, isInStock: number) => {
  const isFilterStatus = (code: string) => itemStatus === code || currentStatus === code;
  let actionStatuses: IStatus[] = [];

  if (isFilterStatus(STATUS_IN_SALE)) {
    actionStatuses = [...actionStatuses, { label: 'Снять с продажи', code: TO_STATUS_NOT_SALE }];
  }
  if (isFilterStatus(STATUS_NOT_SALE)) {
    actionStatuses = [...actionStatuses, { label: 'Вернуть в продажу', code: TO_STATUS_IN_SALE }];
  }
  if (
    isFilterStatus(STATUS_NOT_SALE) ||
    isFilterStatus(STATUS_READY_FOR_SALE) ||
    isFilterStatus(STATUS_REJECTED)
  ) {
    actionStatuses = [
      ...actionStatuses,
      ...(isInStock === 0 ? [{ label: 'Переместить в архив', code: TO_STATUS_ARCHIVED }] : []),
    ];
  }
  if (isFilterStatus(STATUS_ARCHIVED)) {
    actionStatuses = [...actionStatuses, { label: 'Восстановить товар', code: TO_STATUS_RESTORE }];
  }

  if (!isFilterStatus(STATUS_ARCHIVED)) {
    actionStatuses = [...actionStatuses, { label: 'Редактировать товар', code: TO_STATUS_EDITING }];
  }

  return actionStatuses;
};
