import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: `1px solid ${theme.colors.brandGrey[3]}`,
    padding: '25px 24px 24px',
    '@media (max-width: 627px)': {
      flexDirection: 'column'
    },
  },
  pagination: {
    display: 'flex',
    margin: '0px -4px',
    marginLeft: 'auto',
    '@media (max-width: 627px)': {
      marginLeft: 0,
      marginTop: 10
    },
    '@media (max-width: 450px)': {
      flexDirection: 'column'
    },
  },
  paginationList: {
    display: 'flex',

  },
  paginationItem: {
    padding: '0px 4px',
    borderRadius: '8px',
  },
  paginationButton: {
    padding: '15px 20px 15px',
    height: '48px',
    color: `${theme.colors.text[7]}`,
    letterSpacing: '-0.01em',

    '&:hover': {
      backgroundColor: theme.colors.brandGrey[3],
    },
  },
  paginationButtonActive: {
    backgroundColor: theme.colors.brandGreen[3],
    color: 'white',
  },
  paginationShowMore: {
    height: '48px',
    borderColor: theme.colors.brandGrey[5],
    fontWeight: 700,
    padding: '16px 17px',
  },
}));
