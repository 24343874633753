import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Group, Text } from '@mantine/core';
import { useMantineTheme } from '@mantine/core';
import { Check, X } from 'tabler-icons-react';

import notify from '@/utils/notify';

import { suppliesApi } from '@/store/slices/supplies/slice';

const ACCEPT_MESSAGE =
  'В ближайшее время статус заявки изменится. При появлении вопросов мы свяжемся с вами.';

export const Controls: FC = () => {
  const [hide, setHide] = useState(false);
  const { id } = useParams<{ id: string }>();
  const [sendConfirmation, { isSuccess, isError }] = suppliesApi.useConfirmMutation();
  const { colors } = useMantineTheme();

  const handleConfirm = (state: boolean) => () => {
    if (!id) return;
    sendConfirmation({ id, accepted: state });
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      notify({ message: ACCEPT_MESSAGE, type: 'success' });
      setHide(true);
    }
  }, [isError, isSuccess]);

  return !hide ? (
    <Group spacing={8}>
      <Button size="lg" fz={14} h={48} bg={colors.brandGreen[0]} onClick={handleConfirm(true)}>
        <Text mr={8}>Принять</Text>
        <Check width={18} height={18} />
      </Button>
      <Button size="lg" onClick={handleConfirm(false)} fz={14} h={48} variant="default">
        <Text color={colors.brandDark[9]} mr={8}>
          Отклонить
        </Text>
        <X width={18} height={18} fill={colors.brandDark[9]} />
      </Button>
    </Group>
  ) : null;
};
