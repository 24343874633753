import { FC, PropsWithChildren } from 'react';
import { LoadingOverlay, Text } from '@mantine/core';
import { ModalsContextProps } from '@mantine/modals/lib/context';

import ModalCLose from '@/ui/organisms/Modals/ModalClose/ModalClose';
import ModalContent from '@/ui/organisms/Modals/ModalContent/ModalContent';

interface ModalLayoutProps {
  context: ModalsContextProps;
  id: string;
  title?: string;
  loading?: boolean;
  withoutFooter?: boolean;
  onClose?: () => void;
  scrollable?: boolean;
}

export const ModalLayout: FC<PropsWithChildren<ModalLayoutProps>> = ({
  title,
  id,
  context,
  loading = false,
  children,
  withoutFooter,
  onClose,
  scrollable = true,
}) => {
  const handlerClose = () => {
    context.closeModal(id);
    onClose?.();
  };

  return (
    <ModalContent boxSize={535} withoutFooter={withoutFooter} fullScreen={!scrollable}>
      <LoadingOverlay visible={loading} zIndex={100} />
      <ModalCLose onClose={handlerClose} />
      <Text align="center" weight={700} size={22} style={{ lineHeight: 1.4 }} px={60}>
        {title}
      </Text>
      {children}
    </ModalContent>
  );
};
