import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Tabs, useMantineTheme } from '@mantine/core';

import { SELLER_STATUS_MODERATION } from '@/constants/common';

import ModalCustom from '@/ui/organisms/ModalCustom/ModalCustom';
import PageContent from '@/ui/organisms/Page/components/PageContent/PageContent';
import PageHeader from '@/ui/organisms/Page/components/PageHeader/PageHeader';
import Page from '@/ui/organisms/Page/Page';

import { ProfileSubmitted } from '../AuthPage/components/ProfileSubmitted/ProfileSubmitted';
import { SameFilesNames } from '../AuthPage/components/SameFilesNames/SameFilesNames';

import { ProfilePanels } from './components/ProfilePanels/ProfilePanels';
import { ProfileTabs } from './components/ProfileTabs/ProfileTabs';
import { useStyles } from './styles';

import { useAppDispatch } from '@/store';
import {
  selectModalSameFilesNames,
  selectProfileSubmittedModal,
} from '@/store/slices/modal/selectors';
import { setModalSameFilesNames, setModalSubmitted } from '@/store/slices/modal/slice';
import { selectUser } from '@/store/slices/user/slice';

interface IPorfilPageProps {
  title: string;
}

const ProfilePage: FC<IPorfilPageProps> = ({ title }) => {
  const dispatch = useAppDispatch();
  const { tab } = useParams();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { colors } = useMantineTheme();
  const user = useSelector(selectUser);
  const modelProfileSubmittedID = useSelector(selectProfileSubmittedModal);
  const modalSameFilesNames = useSelector(selectModalSameFilesNames);

  useEffect(() => {
    if (user?.sellerStatus === SELLER_STATUS_MODERATION) dispatch(setModalSubmitted('true'));
  }, [user]);

  const tabChangeHandler = (nextTab: string) => {
    navigate(`/profile/${nextTab}`);
  };

  return (
    <Page>
      <PageHeader title={title} />
      <PageContent>
        <Tabs
          color={colors.mainColor[8]}
          defaultValue="account"
          value={tab}
          onTabChange={tabChangeHandler}
          classNames={{ ...classes }}
          keepMounted={false}
        >
          <ProfileTabs />
          <ProfilePanels />
        </Tabs>
      </PageContent>
      <ModalCustom
        opened={!!modelProfileSubmittedID}
        title="Данные отправлены"
        size="xs"
        onClose={() => {
          dispatch(setModalSubmitted(null));
        }}
      >
        <ProfileSubmitted />
      </ModalCustom>
      <ModalCustom
        opened={!!modalSameFilesNames}
        title="Обратите внимание"
        size="xs"
        onClose={() => {
          dispatch(setModalSameFilesNames(null));
        }}
      >
        <SameFilesNames fileName={modalSameFilesNames?.fileName} />
      </ModalCustom>
    </Page>
  );
};

export default ProfilePage;
