import { FC } from 'react';
import { UnstyledButton, useMantineTheme } from '@mantine/core';
import { Image } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import { ReactComponent as IconClose } from '@/assets/icons/reducePhoto.svg';

export interface PreviewModalProps {
  img: string;
}

export const ModalPreviewProduct: FC<ContextModalProps<PreviewModalProps>> = ({
  context,
  id,
  innerProps,
}) => {
  const { colors } = useMantineTheme();

  const handleClose = () => {
    context.closeModal(id);
  };

  return (
    <>
      <UnstyledButton
        onClick={handleClose}
        style={{
          height: '40px',
          width: '40px',
          fill: colors.brandDark[9],
          position: 'absolute',
          top: '1%',
          right: '1%',
          backgroundColor: colors.customWhite[0],
          zIndex: 100,
          borderRadius: '40%',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <IconClose />
      </UnstyledButton>
      <Image radius="md" key={innerProps.img} src={innerProps.img} width={420} height={420} />
    </>
  );
};
