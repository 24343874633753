import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IDocumentsState {
  limit: number;
  offset: number;
  currentTab: string | null;
  registrationDate: string;
}

const initialState: IDocumentsState = {
  limit: 10,
  offset: 0,
  currentTab: null,
  registrationDate: '',
};

export const DocumentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },
  },
});

export const { setCurrentTab } = DocumentsSlice.actions;

export default DocumentsSlice.reducer;
