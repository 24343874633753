import { TFile } from '@/types/api/profile';

import { FC, useEffect, useRef, useState } from 'react';
import {
  Box,
  clsx,
  FileButton,
  Flex,
  Group,
  Loader,
  Sx,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { Image } from '@mantine/core';
import cn from 'classnames';
import { AlertCircle, Circle, Repeat } from 'tabler-icons-react';

import { NOOP } from '@/constants/common';

import { openPreviewProduct } from '@/ui/organisms/Modals/modals';
import { ERROR_INVALID_IMAGES_TYPE, ERROR_INVALID_TYPE } from '@/ui/pages/ProfilePage/utils/files';

import LoaderIcon from '../LoaderIcon/LoaderIcon';

import FileInfo from './components/FileInfo/FileInfo';
import { useStyles } from './styles';

import { ReactComponent as IconClose } from '@/assets/icons/close.svg';
import { ReactComponent as IconPen } from '@/assets/icons/edit.svg';
import { ReactComponent as IconAttach } from '@/assets/icons/upload.svg';
import { ReactComponent as IconX } from '@/assets/icons/x.svg';
import { ReactComponent as IconPlus } from '@/assets/icons/zoomPhoto.svg';
import previewImageStub from '@/assets/images/previewImage.jpg';

export interface IDocumentInputProps {
  value?: TFile | null;
  id: string;
  description?: string;
  required?: boolean;
  uploading?: boolean;
  removing?: boolean;
  extraFile?: boolean;
  uploadProgress?: number;
  uploadFailed?: boolean;
  changeable?: boolean;
  removeable?: boolean;
  disabled?: boolean;
  label?: string;
  error?: string | null;
  onUpload?: (file: File, id: string) => void;
  onRemove?: (id: string) => void;
  onUploadCancel?: (id: string) => void;
  sx?: Sx;
  accept?: string[];
  imagePreview?: string;
  topDescription?: string | string[];
  typeComponent?: 'image' | 'file';
}

const DocumentInput: FC<IDocumentInputProps> = (props) => {
  const {
    value,
    id,
    description = '',
    required = false,
    uploading = false,
    extraFile = false,
    removing = false,
    uploadProgress = 0,
    changeable = true,
    removeable = true,
    disabled = false,
    label,
    error,
    onUpload = NOOP,
    onRemove = NOOP,
    onUploadCancel = NOOP,
    sx,
    accept,
    topDescription,
    typeComponent = 'file',
  } = props;
  const fileResetRef = useRef<() => void>(null);
  const theme = useMantineTheme();
  const [showExtensionError, setShowExtensionError] = useState<string | null>('');
  const { classes } = useStyles({
    extraFile: extraFile,
    typeComponent: typeComponent,
  });

  const handleSetInnerFile = (file: File | null) => {
    if (!file) return;
    const { name } = file;
    const match = name.match(/^.*\.(.*?)$/);
    if (!match || accept?.includes(match[1].toLowerCase())) {
      setShowExtensionError(match && match[1].toLowerCase());
      return;
    }

    onUpload(file, id);
    if (fileResetRef.current) fileResetRef.current();
  };
  useEffect(() => {
    setShowExtensionError(null);
  }, [props]);

  return (
    <>
      {typeComponent === 'image' && <Text className={classes.name}>{label}</Text>}
      {typeComponent === 'image' && topDescription && topDescription?.length > 0 && (
        <Group spacing={2} className={classes.topDescription}>
          {Array.isArray(topDescription) ? (
            <>
              <Text color={theme.colors.brandGreen[0]}>{topDescription[0]}</Text>
              <Text>{topDescription[1]}</Text>
            </>
          ) : (
            <Text>{topDescription}</Text>
          )}
        </Group>
      )}
      <Box className={classes.root} sx={sx}>
        <Box className={classes.inner}>
          <Box className={classes.iconWrapper}>
            {typeComponent === 'file' && error && (
              <Box className={classes.errorCirlce}>
                <AlertCircle fill="red" stroke="white" />
              </Box>
            )}

            {typeComponent === 'image' ? (
              value?.imageUrl ? (
                <Box style={{ position: 'relative' }}>
                  <UnstyledButton
                    className={classes.iconPlus}
                    onClick={() => openPreviewProduct({ img: value?.imageUrl || '' })}
                  >
                    {uploading ? <Loader color="dark" size="xs" /> : <IconPlus />}
                  </UnstyledButton>

                  <Image
                    radius="md"
                    src={value?.imageUrl}
                    key={value?.imageUrl}
                    width={60}
                    height={60}
                    sx={{ opacity: uploading ? '40%' : '100%' }}
                  />
                </Box>
              ) : (
                <Flex
                  w={60}
                  h={60}
                  align={'center'}
                  justify={'center'}
                  sx={{ border: `1px solid ${theme.colors.brandGrey[4]}` }}
                >
                  <Image radius="md" src={previewImageStub} width={24} height={24} />
                </Flex>
              )
            ) : (
              <LoaderIcon
                flood="black"
                width={48}
                height={48}
                progress={value && !uploading ? 100 : uploadProgress}
                id={id || ''}
              >
                <path
                  d="M42 16V41.986C42.0018 42.2486 41.9519 42.5091 41.8531 42.7524C41.7543 42.9958 41.6085 43.2173 41.4241 43.4043C41.2397 43.5913 41.0203 43.7402 40.7783 43.8424C40.5364 43.9446 40.2766 43.9982 40.014 44H7.986C7.45963 44 6.95478 43.791 6.58239 43.419C6.21 43.047 6.00053 42.5424 6 42.016V5.984C6 4.91 6.898 4 8.004 4H29.994L42 16ZM38 18H28V8H10V40H38V18Z"
                  fill={theme.colors.brandGrey[3]}
                />
              </LoaderIcon>
            )}
          </Box>

          <Box className={classes.info}>
            <Box>
              {typeComponent === 'image' ? (
                <Text className={classes.name}>{value?.originalName ?? 'Название файла'}</Text>
              ) : (
                <Text className={classes.name}>{label}</Text>
              )}
              {required && !value && (
                <Circle
                  size={8}
                  fill={theme.colors.red[8]}
                  stroke={theme.colors.red[8]}
                  className={classes.required}
                />
              )}
            </Box>
            {showExtensionError && (
              <Text size={14} color={theme.colors.red[8]}>
                {showExtensionError === 'pdf' ? ERROR_INVALID_TYPE : ERROR_INVALID_IMAGES_TYPE}
              </Text>
            )}
            {error && !showExtensionError && (
              <Text size={14} color={theme.colors.red[8]}>
                {error}
              </Text>
            )}
            {value && !uploading && !error && (
              <FileInfo file={value} typeComponent={typeComponent} />
            )}
            {uploading && <FileInfo uploadProgress={uploadProgress} />}
            {uploading && typeComponent === 'image' && (
              <Text
                sx={{
                  fontSize: '14px',
                  color: theme.colors.brandGrey[5],
                }}
              >
                Загрузка
              </Text>
            )}
            {!value && !uploading && !error && (
              <Text size={14} color={theme.colors.text[5]}>
                {description}
              </Text>
            )}
          </Box>
        </Box>
        <Group align="center" spacing={24}>
          {changeable && value && !disabled && !uploading && (
            <>
              <FileButton
                onChange={handleSetInnerFile}
                accept={accept?.join(',')}
                resetRef={fileResetRef}
              >
                {(buttonProps) => (
                  <UnstyledButton className={classes.button} {...buttonProps}>
                    Изменить
                    <IconPen className={classes.iconEdit} />
                  </UnstyledButton>
                )}
              </FileButton>
            </>
          )}

          {removeable && value && !uploading && !disabled && (
            <UnstyledButton
              className={clsx(classes.button, classes.buttonRemove)}
              onClick={() => onRemove(id)}
              disabled={removing}
            >
              Удалить
              {removing ? <Loader size={20} ml={5} /> : <IconX className={classes.iconTrash} />}
            </UnstyledButton>
          )}

          {uploading && (
            <UnstyledButton
              className={cn(classes.button, classes.buttonCanceled)}
              onClick={() => onUploadCancel(id)}
            >
              Отменить загрузку
              <IconClose className={cn(classes.icon, classes.iconClose)} />
            </UnstyledButton>
          )}

          {!value && !disabled && !uploading && (
            <FileButton
              onChange={handleSetInnerFile}
              accept={accept?.join(',')}
              resetRef={fileResetRef}
            >
              {(buttonProps) => (
                <UnstyledButton className={classes.button} {...buttonProps}>
                  {error && (
                    <>
                      Загрузить ещё раз
                      <Repeat className={classes.iconRepeat} />
                    </>
                  )}
                  {!error && (
                    <>
                      Загрузить файл
                      <IconAttach className={classes.icon} />
                    </>
                  )}
                </UnstyledButton>
              )}
            </FileButton>
          )}
        </Group>
      </Box>
    </>
  );
};

export default DocumentInput;
