import { useSelector } from 'react-redux';

import { SELLER_STATUS_ACTIVE } from '@/constants/common';

import { selectUser } from '@/store/slices/user/slice';

const useIsSellerStatusActive = () => {
  const user = useSelector(selectUser);

  return user?.sellerStatus === SELLER_STATUS_ACTIVE;;
};

export default useIsSellerStatusActive;