export const downloadFromUrl = (url: string, name?: string) => {
  const fileName = name || url.split('/').pop()?.split('?')[0];

  const a = document.createElement('a');
  a.href = url;
  a.download = fileName || 'file';
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
};
