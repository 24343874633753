import { AuthStepsEnum } from "@/types/api/auth";

export const getAuthTitle = (step: AuthStepsEnum): string => {
  switch (step) {
    case AuthStepsEnum.SIGNIN_WITH_PHONE:
      return 'Вход или регистрация';
    case AuthStepsEnum.SIGNIN_WITH_EMAIL:
      return 'Войти';
    case AuthStepsEnum.RESTORE_PASSWORD_EMAIL:
    case AuthStepsEnum.RESTORE_PASSWORD_EMAIL_CODE:
    case AuthStepsEnum.RESTORE_PASSWORD_PHONE_CODE:
      return 'Восстановить пароль';
    case AuthStepsEnum.SIGNIN_WITH_PHONE_CODE:
      return 'Войти по телефону';
    case AuthStepsEnum.SIGNUP:
      return 'Регистрация';
    case AuthStepsEnum.BLOCKED:
      return 'Вы заблокированы';
    case AuthStepsEnum.UPGRADE_TO_SELLER:
      return 'Создайте аккаунт продавца';
    case AuthStepsEnum.UPGRADE_TO_SELLER_NEW:
      return 'Создайте аккаунт продавца';
    case AuthStepsEnum.CHECK_EMAIL:
      return 'Проверьте почту';
    case AuthStepsEnum.COMPANY_INFORMATION:
      return 'Информация о компании';
    case AuthStepsEnum.DATA_FILL:
      return 'Профиль не активен';
    default:
      return 'Вход или регистрация';
  }
};
