import { TFile } from '@/types/api/profile';

import { FC, useMemo, useState } from 'react';
import { AxiosProgressEvent } from 'axios';

import DocumentInput from '@/ui/atoms/DocumentInput/DocumentInput';

import { IFileInputProps } from '../../types';
import { ERROR_MSG, MAX_ADDITIONAL_FILES_SIZE, removeFile } from '../../utils/files';
import { ERROR_MAX_SIZE } from '../../utils/files';

type TFileItemProps = Omit<IFileInputProps, 'value' | 'onChange'> & {
  value?: TFile | null;
  onChange: (v: TFile | null) => void;
  uploader?: (
    type: string,
    file: File,
    onUploadProgress: (e: AxiosProgressEvent) => void,
    id?: string
  ) => { request: Promise<any>; controller: AbortController };
  uploaderQuery?: (params: { formId: string; files: File[] }) => Promise<any>;
  id?: string;
  accept?: string[];
  topDescription?: string | string[];
  typeComponent?: 'image' | 'file';
};

const RESTRICTIONS_TEXT = 'Файл не более 10 Мб в формате PDF';

const FileItem: FC<TFileItemProps> = ({
  label,
  value,
  disabled,
  onChange = () => null,
  required,
  type,
  id,
  uploader,
  uploaderQuery,
  accept,
  topDescription,
  typeComponent = 'file',
}) => {
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [imagePreview, setImagePreview] = useState<string | undefined>();
  const [currentController, setCurrentController] = useState<AbortController | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>(ERROR_MSG);

  const handleUpdateState = () => {
    setUploading(false);
    setUploadProgress(0);
    setCurrentController(null);
  };

  const handleProgress = (e: AxiosProgressEvent) => {
    if (!e.total) return;
    const newProgress = Math.round((e.loaded / e.total) * 100);
    setUploadProgress(newProgress);
  };

  const handleRemove = async () => {
    if (!value) return;
    setRemoving(true);
    try {
      await removeFile(value?.id);
      onChange(null);
    } catch {
      return;
    }
    setRemoving(false);
  };

  const transformFileToFileItem = (file: File, url: string): TFile => {
    return {
      id: id || '',
      originalName: file.name,
      extension: file.type,
      size: file.size.toString(),
      date: new Date().toLocaleString(),
      imageUrl: url,
    };
  };

  const handleUpload = async (file: File) => {
    if (!file) return;
    setUploading(true);
    setUploadFailed(false);

    if (file.size > MAX_ADDITIONAL_FILES_SIZE) {
      onChange?.(null);
      setUploadFailed(true);
      setErrorMessage(ERROR_MAX_SIZE);
      handleUpdateState();
      return;
    }

    try {
      if (uploader) {
        const { request, controller } = uploader(type || '', file, handleProgress);
        setCurrentController(controller);
        const { data } = await request;
        onChange(data.values[0]);
      } else if (uploaderQuery) {
        const imageUrl = URL.createObjectURL(file);
        await uploaderQuery({
          formId: id || '',
          files: [file],
        });
        setImagePreview(imageUrl);

        onChange(transformFileToFileItem(file, imageUrl));
      }
    } catch {
      setUploadFailed(true);
      setErrorMessage(ERROR_MSG);
    }

    handleUpdateState();
  };

  const handleCancel = () => {
    if (currentController) currentController.abort();
  };
  const error = useMemo(() => (uploadFailed ? errorMessage : null), [uploadFailed, errorMessage]);

  return (
    <DocumentInput
      id={type || ''}
      value={value}
      label={label}
      description={typeComponent === 'file' ? RESTRICTIONS_TEXT : ''}
      onUpload={handleUpload}
      uploadProgress={uploadProgress}
      onUploadCancel={handleCancel}
      removing={removing}
      onRemove={handleRemove}
      uploading={uploading}
      required={required}
      disabled={disabled}
      error={error}
      accept={accept}
      imagePreview={imagePreview}
      topDescription={topDescription}
      typeComponent={typeComponent}
    />
  );
};

export default FileItem;
