import { FC, ReactNode } from 'react';
import { Box, Text, UnstyledButton } from '@mantine/core';

import { useStyles } from './styles';

import { ReactComponent as CLoseIcon } from '@/assets/icons/close.svg';

interface ITag {
  handlerReset?: (param: any) => void;
  children: ReactNode;
  short?: boolean;
}

const Tag: FC<ITag> = ({ handlerReset, children, short }) => {
  const { classes } = useStyles({ short: !!short });

  return (
    <Box className={classes.root}>
      <UnstyledButton className={classes.tag} >
        <Text className={classes.text}>{children}</Text>
      </UnstyledButton>
      {handlerReset && (
        <UnstyledButton
          className={classes.buttonReset}
          onClick={handlerReset}
        >
          <Text>Очистить</Text>
          <CLoseIcon className={classes.icon} />
        </UnstyledButton>
      )}
    </Box>
  );
};

export default Tag;
