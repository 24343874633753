import { useParams } from 'react-router-dom';
import { ActionIcon, Button, Group, LoadingOverlay, Tooltip, useMantineTheme } from '@mantine/core';
import { Text } from '@mantine/core';

import { downloadBlob } from '@/utils/downloadBlob';

import { useStyles } from './styles';

import { ReactComponent as IconDownload } from '@/assets/icons/redesign/iconDownload.svg';
import { ReactComponent as IconInfo } from '@/assets/icons/redesign/iconInfo.svg';
import { suppliesApi } from '@/store/slices/supplies/slice';


const TOOLTIP_TEXT =
  'После подтверждения результатов приемки скачайте документы, распечатайте, оформите их и отправьте нам на почтовый адрес. Подробнее в инструкции';

export const DownloadButton = () => {
  const { id } = useParams<{ id: string }>();
  const { colors } = useMantineTheme();
  const [downloadDoc, { isFetching }] = suppliesApi.useLazyGetAcceptanceDocQuery();

  const handleDownload = async () => {
    if (!id) return;
    try {
      const { data: doc, isSuccess } = await downloadDoc(id);
      if (!isSuccess) return;
      downloadBlob(doc.file, doc.name);
    } catch (e) { }
  };

  const {
    classes: { tooltip, button },
  } = useStyles();
  return (
    <Group>
      <Button
        variant="outline"
        className={button}
        onClick={handleDownload}
        size="md"
        fz={14}
        miw={245}
        pos="relative"
        disabled={isFetching}
      >
        <Group spacing={8}>
          <IconDownload fill={colors.brandDark[9]} />
          <Text>Скачать документы</Text>
          <Tooltip
            label={TOOLTIP_TEXT}
            position="bottom"
            offset={10}
            multiline
            width={425}
            classNames={{ tooltip }}
          >
            <ActionIcon>
              <IconInfo />
            </ActionIcon>
          </Tooltip>
        </Group>
        <LoadingOverlay
          visible={ isFetching}
          radius={'34px'}
          loaderProps={{ size: 16 }}
          overlayOpacity={1} 
          />
      </Button>
    </Group>
  );
};
