import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
root: {
    width: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 1000,
    padding: '18px 24px',
  },
  icon: {
    marginLeft: 8,
    fill: theme.colors.mainColor[6],
    transition: 'fill 0.25s ease-in-out',
    width: 22,
    height: 22,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      svg: {
        fill: '#006FD6',
      },
    },
  },
  close: {
    width: '20px',
    height: '20px',
    color: 'rgba(0, 0, 0, 0.36)',
    transition: 'color 0.2s ease-in-out',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.2)',
      background: 'transparent',
    },
  },
}));
