import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme, { size, borderBottom }: { size: string, borderBottom: boolean }) => ({
  root: {
    padding: '28px 24px 0px',
    paddingBottom: borderBottom ? '30px' : '0px',
    lineHeight: '20px',
    // marginLeft: '-24px',
    // marginRight: '-24px',
    position: 'relative',
    overflow: 'hidden',
    ...(borderBottom && { borderBottom: `solid 1px ${theme.colors.brandGrey[2]}`})
  },
  header:{
    margin: '0px 0px 8px',
  },
  title: {
    fontWeight: 700,
    fontSize: size == 'lg' ? '16px' : '14px',
    color: theme.colors.brandGrey[8],
    backgroundColor: theme.colors.brandGrey[1],
    margin: '0px 0px 8px',
    lineHeight: 1.25,
    borderRadius: '45px',
    padding: '8px 12px 7px',
    display: 'inline-block',
  },
  subTitle: {
    margin: '12px 0px 0px',
    fontSize: '16px',
    fontWeight: 600,
  },
  description: {
    fontSize: size == 'lg' ? '16px' : '14px',
    lineHeight: size == 'lg' ? '24px' : '20px',
    color: theme.colors.text[5],
  },
  link: {
    margin: '8px 0px 0px',
    display: 'block',
    color: theme.colors.mainColor[6],
  },
}));