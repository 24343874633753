import { IGetProductsTemplateRes } from "@/types/api/product";

import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Button, useMantineTheme } from "@mantine/core";
import { modals } from "@mantine/modals";

import LoaderDots from "@/ui/atoms/LoaderDots/LoaderDots";

import { openLoadingError } from "../Modals/modals";

import { AppDispatch } from "@/store";
import { getPricesTemplate } from "@/store/slices/products/asyncActions";

const DownloadPricesTemplate = () => {
    const dispatch = useDispatch<AppDispatch>();
    const buttonRef = useRef<HTMLAnchorElement>(null);
    const [downloadUrl, setDownloadUrl] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { colors } = useMantineTheme();

    const handlerDownload = async () => {
        setIsLoading(true);
        try {
            const response = await dispatch(getPricesTemplate());
            const { path } = response.payload as IGetProductsTemplateRes;

            if (path) {
                setDownloadUrl(path);
                const link = document.createElement('a');
                link.href = path;
                link.click();
            }
        } catch (error) {
            console.error(error);
            if (error) {
                modals.closeAll();
                openLoadingError({ id: 'loadingError' });
              }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box>
            <a style={{ display: 'none' }} href={downloadUrl} ref={buttonRef} download></a>
            <Button
                mt={10}
                bg={colors.mainColor[6]}
                sx={{
                    overflow: 'hidden',
                    color: colors.customWhite[0],
                    '&:hover': {
                        color: colors.mainColor[6]
                    }
                }}
                size="md"
                variant='outline'
                onClick={handlerDownload}
            >
                <LoaderDots loading={isLoading} />
                Скачать шаблон
            </Button>
        </Box>
    )
}

export default DownloadPricesTemplate;