import { ID } from "@/types/common/common";
import { IProduct } from "@/types/entites/product";

import { FC, useEffect, useState } from "react";
import { Checkbox } from "@mantine/core";


interface IСheckboxCell {
    text?: string,
    isChecked?: boolean,
    item?: IProduct,
    handler: (event: any, id?: ID) => void;
}

const СheckboxCell: FC<IСheckboxCell> = ({ text, item, isChecked, handler }) => {
    const [stateChecked, setStateChecked] = useState<boolean>(!!isChecked);

    useEffect(() => {
        setStateChecked(!!isChecked);
    }, [isChecked]);

    const handlerCheckobox = (event: React.ChangeEvent<HTMLInputElement>) => {
        handler(event, item?.supplierProductId)
        setStateChecked((prev) => !prev);
    }

    return (
        <Checkbox
            onChange={handlerCheckobox}
            checked={stateChecked}
            label={text}
        />
    )
}

export default СheckboxCell;