import { FC } from "react";
import { Box, BoxProps, Text, Title } from "@mantine/core";

import { useStyles } from "./styles";

interface IPageHeader extends BoxProps {
    title: string;
    statusComment?: string;
}

const PageHeader: FC<IPageHeader> = ({ title, children }) => {
    const { classes } = useStyles();

    return (
        <Box className={classes.wrapper}>
            <Title className={classes.title} align="left" order={1}>
                {title}
            </Title>
            <Text>
                {children}
            </Text>
        </Box>
    )
}

export default PageHeader;