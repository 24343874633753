import { createStyles } from "@mantine/core";

export const useStyles = createStyles((_) => ({
    wrapper: {
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '24px',
        width: '100%'
    },
}));