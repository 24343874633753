import { SupplyStatus } from '@/types/api/supplies';

import { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Group, Loader, Text, useMantineTheme } from '@mantine/core';
import { Stack, UnstyledButton } from '@mantine/core';

import { downloadBlob } from '@/utils/downloadBlob';

import Cell from '@/ui/organisms/TableCustom/components/Cell/Cell';

import { StatusInfoButton } from '../StatusInfoButton/StatusInfoButton';

import { suppliesApi } from '@/store/slices/supplies/slice';

interface SupplyStatusCellProps {
  id: string;
  updFolderId?: string;
  status?: SupplyStatus;
  displayGobutton?: boolean;
  hasAcceptance?: boolean;
}

export const SupplyStatusCell: FC<SupplyStatusCellProps> = ({
  id,
  updFolderId,
  status,
  displayGobutton,
  hasAcceptance,
}) => {
  const { colors } = useMantineTheme();

  const [downloadDoc, { isFetching }] = suppliesApi.useLazyGetDocumentsQuery();

  const handleDownload = async () => {
    if (!updFolderId) return;
    try {
      const { file, name } = await downloadDoc({ id, folderId: updFolderId }).unwrap();
      downloadBlob(file, name);
    } catch (e) {}
  };

  return (
    <Cell>
      <Stack spacing={4} maw={228}>
        {displayGobutton && hasAcceptance && (
          <NavLink to={`/supplies/details/${id}`}>
            <Text color={colors.blue[5]} size={14}>
              Перейти
            </Text>
          </NavLink>
        )}
        {Boolean(updFolderId) && !displayGobutton && (
          <UnstyledButton onClick={handleDownload}>
            <Text size={14}>
              {isFetching ? <Loader size={12} /> : 'Скачать акт приема-передачи'}
            </Text>
          </UnstyledButton>
        )}
        <Group spacing={4} noWrap align="center" mih={28}>
          <Text size={12} color={colors.gray[7]}>
            {status?.label}
          </Text>
          {status?.tooltip && <StatusInfoButton tooltip={status.tooltip} />}
        </Group>
      </Stack>
    </Cell>
  );
};
