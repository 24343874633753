import { TFile, TProfileField } from '@/types/api/profile';

import { ChangeEvent, createContext, Dispatch, useContext } from 'react';
import { UseFormReturnType } from '@mantine/form';
import noop from 'lodash/noop';

interface LinkedFieldsContextValue {
  linked: [string, string][];
  setLinked: Dispatch<React.SetStateAction<[string, string][]>>;
  fields: TProfileField[];
  onReset: (fieldCode: string) => void;
  onCheckClick?: (fieldCode: any) => void;
  fieldConfirmed: boolean;
  tooltipText: string;
}

export const LinkedFieldsContext = createContext<LinkedFieldsContextValue>({
  linked: [],
  setLinked: () => {},
  fields: [],
  onReset: noop,
  onCheckClick: () => {},
  fieldConfirmed: false,
  tooltipText: '',
});

export const useLinkedFieldsContext = (
  field: TProfileField,
  form: UseFormReturnType<Record<string, string | string[] | TFile | TFile[]>>
) => {
  const {
    linked: linkedFields,
    setLinked,
    fields,
    onReset,
    onCheckClick,
    fieldConfirmed,
    tooltipText,
  } = useContext(LinkedFieldsContext);

  let hasLinked = false;
  let isLinked = false;
  let isLinkDisabled = false;

  const linkedField = fields.find((item) => field.linkedWith?.code === item.code);
  const parentField = fields.find((item) => item.linkedWith?.code === field.code);

  const fieldValue = form.values[field.code];

  if (parentField) {
    const parentFieldValue = form.values[parentField.code];
    isLinked = parentFieldValue === fieldValue;
  }

  if (field.linkedWith?.code) {
    const linkedFieldValue = form.values[field.linkedWith?.code];
    hasLinked =
      linkedFieldValue === fieldValue ||
      Boolean(linkedFields.find((tuple) => tuple[1] === field.code));
  }

  if (linkedField) {
    const linkedValue = form.getInputProps(linkedField.code).value;
    if (field.disabled || !linkedValue) isLinkDisabled = true;
  }

  const toggleLink = (e: ChangeEvent<HTMLInputElement>) => {
    if (!field.linkedWith) return;
    const state = e.currentTarget.checked;

    if (state) {
      setLinked([...linkedFields, [field.linkedWith.code, field.code]]);
      const linkedValue = form.getInputProps(field.code).value;
      form.setFieldValue(field.linkedWith.code, linkedValue);
    } else {
      setLinked(linkedFields.filter((tuple) => tuple[1] !== field.code));
      onReset(field.linkedWith.code);
    }
  };

  return {
    linkedFields,
    hasLinked,
    isLinked,
    isLinkDisabled,
    toggleLink,
    onCheckClick,
    fieldConfirmed,
    tooltipText,
  };
};
