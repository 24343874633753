import { FC } from "react";
import { Flex, Text, Title, useMantineTheme } from "@mantine/core";

import { ReactComponent as IconNotFound } from '@/assets/icons/redesign/iconNotFound.svg'

interface INotFoundProps {
    title: string;
}

const NotFoundPage: FC<INotFoundProps> = ({ title }) => {
    const { colors } = useMantineTheme();
    return (
        <Flex
            p={40}
            direction={'column'}
            justify={'center'}
            align={'center'}
            h={'100%'}
            bg={colors.customWhite[0]}
            sx={{ borderRadius: '16px', textAlign: 'center' }}
        >
            <IconNotFound />
            <Title order={1} fz={28} mt={24} fw={600}>{title}</Title>
            <Text mt={16} lh={'24px'} color={colors.brandGrey[6]}>
                Запрашиваемая страница не найдена.<br />
                Возможно, вы перешли по ссылке, в которой<br />
                была допущена ошибка, или ресурс был удален
            </Text>
        </Flex>
    )
}

export default NotFoundPage;