import { FC } from "react"
import { ActionIcon } from "@mantine/core"
import { X } from "tabler-icons-react"

import { useStyles } from "./styles";

interface IModalClose {
    onClose: () => void;
}

const ModalCLose: FC<IModalClose> = ({ onClose }) => {
    const { classes } = useStyles();
    return (
        <ActionIcon className={classes.close} onClick={onClose}>
            <X size={24} />
        </ActionIcon>
    )
}

export default ModalCLose;