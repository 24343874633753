import { useForm, UseFormReturnType } from '@mantine/form';
import { UseFormInput } from '@mantine/form/lib/types';
import { capitalize as _capitalize } from 'lodash';
import isString from 'lodash/isString';
import noop from 'lodash/noop';

import { hasValue, isNodeWithValue } from './common';
type TranformFn<T extends Record<string, unknown>, N extends keyof T, F = UseFormReturnType<T>> = (
  value: T[N],
  form: F,
  name: N
) => T[N];
type Transform<T extends Record<string, unknown>> = {
  [index in keyof T]?: TranformFn<T, index>;
};

type Effect<T extends Record<string, unknown>, N extends keyof T, F = UseFormReturnType<T>> = (
  value: T[N],
  form: F,
  name: N
) => void;

type Effects<T extends Record<string, unknown>> = {
  [index in keyof T]?: {
    onChange?: Effect<T, index>;
    onBlur?: Effect<T, index>;
    onFocus?: Effect<T, index>;
  };
};
type CustomizedFormProps<T extends Record<string, unknown>> = {
  effects?: Effects<T>;
  transform?: Transform<T>;
};
type CustomizedFormOptions<T extends Record<string, unknown>> = UseFormInput<T> &
  CustomizedFormProps<T>;
type TransformMethods = 'onChange' | 'onBlur' | 'onFocus';

const getValuefromPayload = <T>(e: any): T => {
  if (e.currentTarget && e.currentTarget.hasOwnProperty('value')) return e.currentTarget.value as T;
  return e as T;
};

/**
 * Simple callback for updating input caret position after transforming a value
 */
const updateSelecton = (el: HTMLInputElement | HTMLTextAreaElement, offset: number) => {
  let [start, end] = [el.selectionStart || 0, el.selectionEnd || 0];

  if (start === end) start = end = start + offset;
  else {
    if (offset < 0) end = start = start + offset;
    if (offset > 0) start = end = end + offset;
  }

  const p = new Promise<void>((resolve) => resolve()).then(() =>
    el.setSelectionRange(start || null, end || null)
  );
  Promise.resolve(p);
};

const getTransformFn =
  <E extends any, A extends Array<any>, R>(fn: (e: E, ...args: A) => R) =>
  (e: E, ...args: A): [R, () => void] => {
    const res = fn(getValuefromPayload(e), ...args);
    if (hasValue(e) && isNodeWithValue(e.currentTarget) && isString(res)) {
      const target = e.currentTarget;
      const oldValue = target.value;
      const postUpdateFn = () => {
        const offset = res.length - oldValue.length;
        updateSelecton(target, offset);
      };
      return [res, postUpdateFn];
    }
    return [fn(e, ...args), noop];
  };

export const useCustomizedForm = <T extends Record<string, unknown>>(
  opts: CustomizedFormOptions<T>
) => {
  const { effects, transform, ...originalOpts } = opts;
  const form = useForm(originalOpts);
  const getInputProps = form.getInputProps;

  const createCbWrapper = (code: keyof T, path: TransformMethods) => (e: any) => {
    const tranformFn = transform?.[code];
    const wrappedTransformFn = tranformFn ? getTransformFn(tranformFn) : null;
    let payload: any;
    let postUpdate = noop;

    if (path === 'onChange' && wrappedTransformFn)
      [payload, postUpdate] = wrappedTransformFn(e, form, code);
    else payload = e;
    getInputProps(code)[path](payload);
    postUpdate();

    const effect = effects?.[code]?.[path];
    if (!effect) return;

    effect(form.values[code], form, code);
  };

  return {
    ...form,
    getInputProps: (path: string) => ({
      ...form.getInputProps(path),
      onChange: createCbWrapper(path, 'onChange'),
      onBlur: createCbWrapper(path, 'onBlur'),
      onFocus: createCbWrapper(path, 'onFocus'),
    }),
  };
};

export namespace FormEffects {
  export const capitalize = (value: string, form: UseFormReturnType<any>, name: string) => {
    form.setFieldValue(name, _capitalize(value));
  };
}
