import { useSelector } from 'react-redux';
import { LoadingOverlay } from '@mantine/core';

import PaginationCustom from '@/ui/organisms/PaginationCustom/PaginationCustom';

import DocumentsTable from '../DocumentsTable/DocumentsTable';

import { selectDocumentsLimit } from '@/store/slices/documents/selectors';

interface DocumentsListProps {
  documents: {} | undefined;
  setCurrentPage: (page: number) => void;
  setConcat: (concat: boolean) => void;
  currentPage: number;
  isDocumentsFetching: boolean;
  isSuccess: boolean;
}

const DocumentsList: React.FC<DocumentsListProps> = ({
  documents,
  setCurrentPage,
  setConcat,
  currentPage,
  isDocumentsFetching,
  isSuccess,
}: any) => {
  const documentsLimit = useSelector(selectDocumentsLimit);

  const pageChangeHandler = (page: number, isConcat: boolean) => {
    setCurrentPage(page);
    setConcat(isConcat);
  };

  return (
    <>
      <LoadingOverlay visible={isDocumentsFetching} />
      {isSuccess && <DocumentsTable items={documents?.items || []} />}
      <PaginationCustom
        limitItems={Number(documentsLimit)}
        totalItems={Number(documents?.meta?.totalCount)}
        currentPage={currentPage}
        handler={pageChangeHandler}
        moreButton
        scrollOnChange
      />
    </>
  );
};

export default DocumentsList;
