import { AxiosError } from 'axios';
import { deleteCookie } from 'cookies-next';

import { cookiesNames } from '@/constants/common';

import { isClient } from './common';
import { errorHandler } from './errorHandler';
import notify from './notify';

export const axiosErrorHandler = (
  error: AxiosError<{ message: string; error_description: string }>,
  fallbackMsg?: string
) => {
  const message =
    error.response?.data?.message ||
    (error.response?.data?.error_description && 'Неверный логин или пароль') ||
    fallbackMsg ||
    'Неизвестная ошибка';

  const handleErrorMessage = (e: AxiosError, m: string) => {
    if (m) errorHandler(e, m);
  };

  if (error.response?.status === 401) {
    deleteCookie(cookiesNames.NEXT_CUSTOMER_TOKEN, {
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    });
    deleteCookie(cookiesNames.NEXT_USER_TOKEN, { domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN });

    if (isClient && window.location.pathname !== '/auth') {
      window.location.href = '/auth';
      notify({
        message: 'Авторизуйтесь, пожалуйста, чтобы продолжить использовать личный кабинет селлера',
        type: 'error',
      });
    } else {
      handleErrorMessage(error, message);
    }
  } else {
    handleErrorMessage(error, message);
  }
};
