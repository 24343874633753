import { AuthStepsEnum } from '@/types/api/auth';
import { IPutConsentEmailReqParam } from '@/types/api/user';
import { IAsyncThunkDataWrapper } from '@/types/common/common';

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import notify from '@/utils/notify';

import { NOTIFY_SUCCESS_EMAIL } from '@/constants/notifyMessages';

import { AppDispatch } from '@/store';
import { setAuthStep } from '@/store/slices/auth/auth';
import { consentEmailAction, selectUser } from '@/store/slices/user/slice';

const useEmailConsent = () => {
  const dispatch: AppDispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [isEmailConfirmationPending, setIsEmailConfirmationPending] = useState(false); 

  // const isEmailConfirmationPending = useMemo(
  //   () => Boolean(searchParams.get('confirm_hash')),
  //   [searchParams]
  // );

  const handleEmailConsent = async () => {
    const confirmHash = searchParams.get('confirm_hash');
    const email = searchParams.get('email');
    const pending = Number(localStorage.getItem('confirm_pending'));
    if (pending || !confirmHash || !email) return;

    try {
      setIsEmailConfirmationPending(true);

      await setSearchParams((params) => {
        params.set('pending', '1');
        return params;
      });

      const data: IAsyncThunkDataWrapper<IPutConsentEmailReqParam> = {
        value: {
          confirm_hash: confirmHash,
          email: email,
        },
        onSuccess() {
          notify({
            type: 'success',
            message: NOTIFY_SUCCESS_EMAIL,
          });
          if (!user?.companyInn) {
            dispatch(setAuthStep(AuthStepsEnum.COMPANY_INFORMATION));
          }
        },
        onFail() {
          if (!user?.companyInn) {
            if (location.pathname !== '/auth') {
              navigate('/auth');
            }
          }
          notify({
            type: 'error',
            message: 'Подтверждение не найдено',
          });
        },
      };

      localStorage.setItem('confirm_pending', '1');
      await dispatch(consentEmailAction(data));
      localStorage.removeItem('confirm_pending');
      setSearchParams((params) => {
        params.delete('confirm_hash');
        params.delete('email');
        return params;
      });

    } catch (error) {
      console.error("Error sending consent email:", error);
    } finally {
      setIsEmailConfirmationPending(false);
    }
  };

  useEffect(() => {
    if (!user) return;

    if (user && user?.isSeller) {
      handleEmailConsent();
    }
  }, [user, searchParams]);


  return isEmailConfirmationPending;
};

export default useEmailConsent;
