import { createStyles, getStylesRef } from '@mantine/core';

export const useStyles = createStyles(
  (
    theme,
    {
      sticky,
      minWidth,
      maxWidth,
    }: { sticky?: boolean; minWidth?: string | number; maxWidth?: string | number }
  ) => {
    const miw = minWidth || 'auto';
    const maw = maxWidth || 'none';
    return {
      td: {
        padding: '16px 24px',
        textAlign: 'left',
        wordWrap: 'break-word',
        minWidth: miw,
        maxWidth: maw,
        borderBottom: `1px solid ${theme.colors.gray[2]}`,
        backgroundColor: sticky ? 'white' : 'transparent',
        '&[data-grow="true"]': {
          width: '100%',
        },
        '&[data-nowrap="true"]': { whiteSpace: 'nowrap' },
        [`& .${getStylesRef('labelCheckbox')}`]: {
          wordBreak: 'break-word',
        },
      },
      th: {
        whiteSpace: 'nowrap',
        borderBottom: 'none !important',
        backgroundColor: `${theme.colors.brandGrey[9]} !important`,
      },
      md: {
        minWidth: '250px !important',
      },
      xl: {
        minWidth: '300px !important',
        maxWidth: '500px',
      },
      sticky: {
        position: 'sticky',
        left: 0,
        right: 0,
        zIndex: 100,
      },

      stickyLeft: {
        '&:before': {
          boxSizing: 'border-box',
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          display: 'block',
          left: 0,
          right: '2px',
          boxShadow: '20px 0px 24px 0px rgba(0, 0, 0, 0.04)',
          zIndex: -1,
        },
      },

      stickyRight: {
        '&:after': {
          boxSizing: 'border-box',
          content: '""',
          position: 'absolute',
          top: 0,
          bottom: 0,
          display: 'block',
          right: 0,
          left: '2px',
          boxShadow: '-20px 0px 24px 0px rgba(0, 0, 0, 0.04)',
          zIndex: -1,
        },
      },
      tbody_td: {
        paddingTop: '20px',
        paddingBottom: '20px',
      },
    };
  }
);
