import { FC, ReactNode } from "react";
import { Box, Title } from "@mantine/core";

interface IHead {
    title: string;
    children?: ReactNode;
}

const Head: FC<IHead> = ({ title, children }) => {
    return (
        <Box mb={60} fz={14}>
            <Title order={2} fz={22} mb={12}>{title}</Title>
            {children}
        </Box >
    )
}

export default Head;