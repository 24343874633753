import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface IUseHistoryListenerProps {
    onCloseCallback?: () => void;
    dependencies?: any[];
}

const useHistoryListener = ({
    onCloseCallback,
    dependencies = []
}: IUseHistoryListenerProps) => {
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            onCloseCallback?.();
        };
    }, [...dependencies, onCloseCallback, navigate]);
};

export default useHistoryListener;
