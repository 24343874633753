import { createStyles, getStylesRef } from '@mantine/core';

export const useSelectStyles = createStyles((theme) => ({
  selectWrapper: {
    position: 'relative',
    backgroundColor: theme.colors[theme.primaryColor][6],
    borderRadius: 99999,
    fontSize: 14,
    lineHeight: 20,
    padding: '14px 24px',
    fontWeight: 'normal',
    color: 'white',
    transition: '0.3s',
    zIndex: 100,
    ':hover': {
      backgroundColor: theme.colors[theme.primaryColor][7],
    },
  },
  content:{
    '@media (max-width: 627px)': {
    justifyContent: 'center'
    },
  },
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
  },
  wrapper: {
    whiteSpace: 'nowrap',
    width: 'fit-content',
  },
  itemsWrapper: {
    // paddingTop: '45px',
    borderRadius: '20px 20px 20px 20px',
    border: `solid 1px ${theme.colors.brandGrey[4]}`,
  },
  item: {
    ref: getStylesRef('selectOption'),
    padding: '12px',
    borderRadius: '24px',
    display: 'flex-row',
    '&:checked': {
      backgroundColor: 'transaprent !important',
    },
    '&[data-selected="true"]': {
      backgroundColor: 'transparent !important',
      color: theme.colors.brandDark[9],
    },
  },
  dropdown: {
    border: 'none',
    boxShadow: 'none'
  },
  input: {
    paddingLeft: 12,
    height: 40,
    color: 'white',
    borderBottom: 'none',
    padding: '14px 48px 14px 24px',
    minWidth: 0,
    fontSize: '14px'
  },
  rightSection: {
    pointerEvents: 'none',
    right: 12,
  },
}));

export const useInputStyles = createStyles((
  theme,
  { clearable }: { clearable: boolean }
) => ({
  root: {
    flex: '1 0 0',
  },
  wrapper: {
    // padding: 4,
    borderRadius: 0,
  },
  input: {
    height: 48,
    fontSize: '14px',
    paddingRight: clearable ? '40px' : '0',
    '&:focus, &:focus-within': {
      borderColor: theme.colors.brandGrey[7],
    },
  },
}));
