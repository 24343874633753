import { SupplyAcceptance } from '@/types/api/supplies';

import { FC } from 'react';
import { Box, Divider, Grid, Group, Loader, Stack } from '@mantine/core';

import { NamedList } from '@/ui/organisms/NamedList/NamedList';

import { AutoCompleteMessage } from './components/AutoCompleteMessage/AutoCompleteMessage';
import { Controls } from './components/Controls/Controls';
import { DownloadButton } from './components/DownloadButton/DownloadButton';
import { ProductsList } from './components/ProductsList/ProductsList';
import { getCommoninfo, getProductsInfo } from './utils/info';

interface SupplyDetailsPageProps {
  acceptance?: SupplyAcceptance;
}

const LoaderLayout = () => (
  <Box h="60vh">
    <Loader />
  </Box>
);

export const SupplyDetailsPage: FC<SupplyDetailsPageProps> = ({ acceptance }) => {
  return acceptance ? (
    <Stack spacing={0}>
      <Grid p={'24px 24px 40px'} grow gutter={80}>
        <Grid.Col span={6}>
          <NamedList title="Основная информация" items={getCommoninfo(acceptance)}>
            <DownloadButton />
          </NamedList>
        </Grid.Col>
        <Grid.Col span={6}>
          <NamedList title="Итого по товарам" items={getProductsInfo(acceptance)} />
        </Grid.Col>
      </Grid>
      <Divider mb={30} />
      <ProductsList />
      {acceptance.canAccept && (
        <Group position="apart" p={24} align="center">
          <AutoCompleteMessage daysToAutoComplete={acceptance.daysToAutoComplete} />
          <Controls />
        </Group>
      )}
    </Stack>
  ) : (
    <LoaderLayout />
  );
};
