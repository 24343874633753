import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import PageContent from '@/ui/organisms/Page/components/PageContent/PageContent';
import PageHeader from '@/ui/organisms/Page/components/PageHeader/PageHeader';
import PageLoader from '@/ui/organisms/Page/components/PageLoader/PageLoader';
import Page from '@/ui/organisms/Page/Page';
import { SupplyDetailsPage } from '@/ui/pages/SupplyDetailsPage/SupplyDetailsPage';

import { suppliesApi } from '@/store/slices/supplies/slice';

const DEFAULT_TITLE = 'Акт приемки';

export const SupplyDetails = () => {
  const { id } = useParams<{ id: string }>();
  const {
    data: acceptance,
    isFetching,
    isSuccess,
  } = suppliesApi.useGetAcceptanceQuery(id || '', {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  const title = useMemo(() => {
    if (!acceptance) return DEFAULT_TITLE;
    return `${DEFAULT_TITLE} № ${acceptance.number} от ${acceptance.date.replace(/-/g, '.')}`;
  }, [acceptance]);

  return (
    <Page>
      <PageHeader title={title} />
      <PageContent>
        {isFetching ? (
          <PageLoader loading h={150} />
        ) : isSuccess ? (
          <SupplyDetailsPage acceptance={acceptance} />
        ) : null}
      </PageContent>
    </Page>
  );
};
