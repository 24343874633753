import {
  IDocumentGenerateBody,
  IDocumentGenerateParams,
  IDocumentGenerateRes,
  IDocumentItemsListParams,
  IDocumentItemsListRes,
  IDocumentStatusParams,
  IDocumentStatusRes,
  IDocumentTypesRes,
} from '@/types/api/documents';
import { Concatinable } from '@/types/common/common';

import { createApi } from '@reduxjs/toolkit/dist/query/react';
import isEqual from 'lodash/isEqual';

import { tryGetDocumentStatus } from '@/api/documents/documents';
import { baseQuery } from '@/api/instance';
import { documentsUrls } from '@/api/urls/documentsUrls';

import notify from '@/utils/notify';

export const documentsApi = createApi({
  reducerPath: 'documentsApi',
  baseQuery: baseQuery(),
  endpoints: (builder) => ({
    getDocumentsItems: builder.query<IDocumentItemsListRes, Concatinable<IDocumentItemsListParams>>(
      {
        query: ({ documentType, ...params }) => ({
          url: documentsUrls.getDocumentsList,
          params: { documentType: documentType, ...params },
        }),
        serializeQueryArgs: ({ endpointName }) => endpointName,
        forceRefetch: ({ currentArg, previousArg }) => !isEqual(currentArg, previousArg),
        merge: (cache, data, { arg: { concat } }) => {
          if (concat) return { ...cache, items: cache.items.concat(data.items) };
          return data;
        },
      }
    ),
    getDocumentTypes: builder.query<IDocumentTypesRes, void>({
      query: () => ({
        url: documentsUrls.getDocumentTypes,
        method: 'GET',
      }),
      transformResponse: (res: IDocumentTypesRes) => {
        return { ...res };
      },
    }),
    getDocumentStatus: builder.mutation<IDocumentStatusRes, IDocumentStatusParams>({
      query: ({ ...params }) => ({
        url: documentsUrls.getDocumentStatus,
        method: 'GET',
        params: params,
      }),
    }),
    postDocumentGenerate: builder.mutation<
      IDocumentGenerateRes,
      [IDocumentGenerateParams, IDocumentGenerateBody]
    >({
      query: (params: [IDocumentGenerateParams, IDocumentGenerateBody]) => ({
        url: documentsUrls.postDocumentGenerate,
        method: 'POST',
        params: params[0],
        data: params[1],
      }),
      transformResponse: async (responseQuery: any) => {
        const maxAttempts = 10;
        const timeoutCount = 3000;

        for (let attempt = 1; attempt <= maxAttempts; attempt++) {
          const response = (await tryGetDocumentStatus({
            requestId: responseQuery.requestId,
          })) as IDocumentStatusRes;

          if (response.status === 'success') {
            notify({
              message: 'Отчет формируется, это может занимать несколько часов',
              type: 'success',
            });
            return responseQuery;
          } else if (response.status === 'error' || attempt === maxAttempts) {
            notify({
              message:
                'Не удалось отправить вашу заявку. Попробуйте еще раз позже или обратитесь в техподдержку',
              type: 'error',
            });
          }
          await new Promise((resolve) => setTimeout(resolve, timeoutCount));
        }
        return responseQuery;
      },
    }),
  }),
});
