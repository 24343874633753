import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { LoadingOverlay, Tabs } from '@mantine/core';

import { SELLER_STATUS_INACTIVE } from '@/constants/common';

import { TabRightSection } from '../TabRightSection/TabRightSection';

import { selectProfileRequires } from '@/store/slices/profile/selectors';
import { profileApi } from '@/store/slices/profile/slice';
import { selectUser } from '@/store/slices/user/slice';

export const ProfileTabs = () => {
  const isFiled = useSelector(selectProfileRequires);
  const user = useSelector(selectUser);

  const isActiveSeller = useCallback(
    (flag: boolean) => {
      return !flag && user?.sellerStatus === SELLER_STATUS_INACTIVE;
    },
    [user, isFiled]
  );

  const { data, isSuccess, isFetching } = profileApi.useGetTabsQuery();

  return (
    <Tabs.List>
      <Tabs.Tab value={'account'} rightSection={<TabRightSection state={!isFiled.account} />}>
        Управление аккаунтом
      </Tabs.Tab>
      <LoadingOverlay visible={isFetching} />
      {isSuccess &&
        data.tabs.map((tab) => (
          <Tabs.Tab
            value={tab.code}
            rightSection={<TabRightSection state={isActiveSeller(tab.confirmed)} />}
            key={tab.code}
          >
            {tab.title}
          </Tabs.Tab>
        ))}
    </Tabs.List>
  );
};
