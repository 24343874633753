import { createStyles, getStylesRef } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  tabsList: {
    borderBottom: `1px solid ${theme.colors.brandGrey[3]}`,
    padding: '0px 24px',
  },
  tab: {
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.colors.text[7],
    padding: '16px 0px',
    borderWidth: '3px',

    '&:hover': {
      background: 'transparent',
    },
  },
  text: {
    lineHeight: '20px',
  },
  required: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 22,
    height: 22,
    ref: getStylesRef('tab__required'),
    borderRadius: '50%',
  },
  inner: {
    padding: '48px 0px 112px',
    width: '662px',
    margin: '0 auto',
    '@media (max-width:767px)': {
      width: '100%',
      padding: '48px 24px 112px',
    },
  },
}));
