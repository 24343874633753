import { useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import { errornotifier } from './middlewares/errorNotifier';
import { logoutMiddleWare } from './middlewares/logoutMiddleware';
import { categoriesApi } from './slices/category/api';
import { documentsApi } from './slices/documents/api';
import { instructionsApi } from './slices/instructions/api';
import { productDocsApi } from './slices/productDocs/api';
import { productsApi } from './slices/products/api';
import { profileApi } from './slices/profile/slice';
import { sellerApi } from './slices/seller/slice';
import { summaryOrdersApi } from './slices/summaryOrders/api';
import { suppliesApi } from './slices/supplies/slice';
import { appReducer } from './rootReducer';

export const store = configureStore({
  reducer: appReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      profileApi.middleware,
      sellerApi.middleware,
      productDocsApi.middleware,
      categoriesApi.middleware,
      suppliesApi.middleware,
      documentsApi.middleware,
      summaryOrdersApi.middleware,
      productsApi.middleware,
      instructionsApi.middleware,
      errornotifier,
      logoutMiddleWare,
    ]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
