import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  item: {
    display: 'block',
    width: '100%',
    padding: 16,
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[3]}`,
    transition: 'background-color 0.1s',
    ':hover': {
      backgroundColor: theme.colors.gray[0],
      borderColor: theme.colors.gray[2],
    },
    '&[data-selected="true"]': {
      borderColor: theme.colors[theme.primaryColor],
    },
  },
  warehousesSection: {
    position: 'relative',
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.gray[3]}`,
    // padding: '0px 32px 0px 16px',
  },
  warehousesScrollbar: {
    borderLeft: `1px solid ${theme.colors.gray[3]}`,
    padding: 4,
    '&[data-orientation="horizontal"]': {
      display: 'none',
    },
  },
  warehousesCorner: {
    borderLeft: `1px solid ${theme.colors.gray[3]}`,
  },
  checkbox: {
    '&:checked': {
      backgroundColor: `${theme.colors[theme.primaryColor][6]} !important`,
      borderColor: `${theme.colors[theme.primaryColor][6]} !important`,
    },
  },
  placeholder: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
  },
  text: {
    '@media (max-width: 767px)': {
      whiteSpace: 'normal',
    },
  },
}));
