import { FC, ReactNode } from 'react';
import { Box, DefaultProps, Stack, Text } from '@mantine/core';

import { Row } from './components/Row';
import { ListItem } from './utils/types';
import { useStyles } from './styles';

interface NamedListProps extends DefaultProps {
  title: string;
  items: ListItem[];
  children?: ReactNode;
}

export const NamedList: FC<NamedListProps> = ({ title, items, children, ...props }) => {
  const { classes } = useStyles();
  return (
    <Stack spacing={24} {...props}>
      <Text size={24} weight={700}>
        {title}
      </Text>
      <Box className={classes.grid}>
        {items.map(([label, value]) => (
          <Row key={label} {...{ label, value }} />
        ))}
      </Box>
      {children &&
        <Box mt={32}>
          {children}
        </Box>
      }
    </Stack>
  );
};
