import { InputModeType } from '@/types/common/common';

export const getInputmode = (input: string): InputModeType => {
  switch (input) {
    case 'company_inn':
    case 'company_kpp':
    case 'company_ogrn':
    case 'company_rs':
    case 'company_bik':
    case 'company_ks':
      return 'numeric';
    case 'companyPhone':
      return 'tel';
    case 'companyEmail':
      return 'email';
    default:
      return 'text';
  }
};
