import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    margin: '15px 0px 0px',
    '@media (max-width: 767px)': {
      maxWidth: 'calc(100vw - 10vw - 48px)',
    },
  },
  wrapper: {
    height: '300px',
    width: '100%',
    display: 'flex',
    border: `1px solid ${theme.colors.brandGrey[2]}`,
    borderRadius: '12px',
    overflow: 'hidden',
    margin: '0px 0px 18px',
  },
  rootScrollBar: {
    paddingBottom: '0px',
    height: '100%',
    backgroundColor: theme.colors.customWhite[0],
  },
  scrollbar: {
    backgroundColor: theme.colors.customWhite[0],
    borderLeft: `1px solid  ${theme.colors.brandGrey[2]}`,
    borderRight: `1px solid  ${theme.colors.brandGrey[2]}`,
    padding: '3px',
    '&[data-orientation="horizontal"]': {
      display: 'none',
    },
    '&[data-orientation="vertical"]': {
      display: 'block',
    },
    zIndex: 0,
  },
  thumb: {
    backgroundColor: theme.colors.brandGrey[2],
  },
}));
