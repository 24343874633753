import { TProductsStatsusNames } from '@/types/entites/product';

export const STATUS_ON_MODERATION = 'on_moderation'; // На модерации
export const STATUS_IN_SALE = 'in_sale'; // В продаже
export const STATUS_REJECTED = 'rejected'; // Товары с ошибками
export const STATUS_ARCHIVED = 'archived'; // В Архиве
export const STATUS_NOT_SALE = 'not_sale'; // Снят с продажи
export const STATUS_READY_FOR_SALE = 'ready_for_sale'; // Готов к продаже
export const STATUS_RESTORE = 'restore'; // Восстановленный

export const TO_STATUS_IN_SALE = 'to_' + STATUS_IN_SALE; // Вернуть в продажу
export const TO_STATUS_ARCHIVED = 'to_' + STATUS_ARCHIVED; // Отправить в архив
export const TO_STATUS_RESTORE = 'to_' + STATUS_RESTORE; // Восстановить
export const TO_STATUS_NOT_SALE = 'to_' + STATUS_NOT_SALE; // Снять с продажи
export const TO_STATUS_EDITING = 'to_' + STATUS_ON_MODERATION; // Отправить на модерацию

export const PRODUCTS_STATUS_NAMES: TProductsStatsusNames = {
  [STATUS_ON_MODERATION]: {
    code: 'on_moderation',
    label: 'На модерации',
  },
  [STATUS_IN_SALE]: {
    code: 'in_sale',
    label: 'В продаже',
    action: [{ status: TO_STATUS_NOT_SALE, label: 'Снять с продажи' }],
  },
  [STATUS_REJECTED]: {
    code: 'rejected',
    label: 'Товары с ошибками',
    // action: [
    //     { status: TO_STATUS_ARCHIVED, label: 'Переместить в архив' }
    // ]
  },
  [STATUS_ARCHIVED]: {
    code: 'archived',
    label: 'В Архиве',
    action: [{ status: TO_STATUS_RESTORE, label: 'Восстановить товар' }],
  },
  [STATUS_NOT_SALE]: {
    code: 'not_sale',
    label: 'Снят с продажи',
    action: [
      { status: TO_STATUS_IN_SALE, label: 'Вернуть в продажу' },
      // { status: TO_STATUS_ARCHIVED, label: 'Переместить в архив' }
    ],
  },
  [STATUS_READY_FOR_SALE]: {
    code: 'ready_for_sale',
    label: 'Готов к продаже',
    // action: [
    //     { status: TO_STATUS_ARCHIVED, label: 'Переместить в архив' }
    // ]
  },
  [STATUS_RESTORE]: {
    code: 'restore',
    label: 'Восстановленный',
  },
};
