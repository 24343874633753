import { createStyles } from "@mantine/core";

export const useStyles = createStyles((theme, { short }: { short: boolean }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 627px)': {
      gap: 10,
      flexDirection: 'column'
    },
  },
    tag: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.colors.brandGrey[3],
      borderRadius: '36px',
      fontSize: '14px',
      padding: '8px 16px',
      color: theme.colors.text[5],
      marginRight: '16px',
    },
    text: {
      overflow: short ? 'hidden' : 'visible',
      textOverflow: short ? 'ellipsis' : 'initial',
      whiteSpace: short ? 'nowrap' : 'normal',
      width: '100%',
      maxWidth: short ? '200px' : '100%',
      lineHeight: 'initial',
    },
    icon: {
      fill: theme.colors.mainColor[6],
      marginLeft: '8px',
      width: '10px',
      height: '10px'
    },
    buttonReset: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px'
    }
  }));
