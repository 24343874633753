import { useEffect, useMemo, useState } from 'react';
import {
  ActionIcon,
  Box,
  Group,
  ScrollArea,
  Stack,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { Check, ChevronDown, X } from 'tabler-icons-react';

import { useStyles } from './styles';

export type SelectData = { label: string; value: string };

type SelectCommonProps = {
  label?: string;
  data: SelectData[];
  size?: number;
  resetLabel?: string;
  clearable?: boolean;
};

type SelectBaseProps = {
  label?: string;
  data: (SelectData & { checked?: boolean })[];
  size?: number;
  open: boolean;
  setOpen: (v: boolean) => void;
  onClear: () => void;
  displayValue: string;
  onChange: (v: string) => void;
  resetLabel?: string;
  clearable?: boolean;
  error?: string;
  disabled?: boolean;
};

type MultiselectProps = {
  value?: string[];
  onChange?: (v: string[]) => void;
  error?: string;
  disabled?: boolean;
};

type SimpleselectProps = {
  value?: string;
  onChange?: (v?: string) => void;
  onOpen?: (isOpen: boolean) => void;
  error?: string;
  disabled?: boolean;
};

export function SelectBase({
  size = 14,
  label,
  data,
  open,
  displayValue,
  setOpen,
  onClear,
  onChange,
  resetLabel: clearLabel,
  clearable,
  error,
  disabled,
}: SelectBaseProps) {
  const { classes } = useStyles({ size });
  const clickRef = useClickOutside(() => setOpen(false));
  const theme = useMantineTheme();

  return (
    <>
      <Box
        className={classes.wrapper}
        ref={clickRef}
        data-value={displayValue}
        data-disabled={disabled}
      >
        <input
          type="search"
          className={classes.input}
          readOnly
          onClick={() => setOpen(!open)}
          value={displayValue}
        />
        <Box className={classes.overlay}>
          <Box className={classes.valueDisplay}>{displayValue}</Box>
          <Box className={classes.label}>{label}</Box>
          <Box className={classes.rightSection}>
            {clearable && displayValue && (
              <ActionIcon onClick={onClear} className={classes.clear}>
                <X width={20} stroke="black" />
              </ActionIcon>
            )}
            <ChevronDown stroke="black" />
          </Box>
        </Box>
        {open && (
          <Box className={classes.dropdown}>
            <ScrollArea mah={400}>
              <Stack spacing={4} p={8}>
                {clearLabel && (
                  <UnstyledButton className={classes.dropdownItem} onClick={onClear}>
                    <Text>{clearLabel}</Text>
                  </UnstyledButton>
                )}
                {data.map((item) => (
                  <UnstyledButton
                    className={classes.dropdownItem}
                    key={item.value}
                    onClick={() => onChange(item.value)}
                  >
                    <Group position="apart" noWrap>
                      <Text>{item.label}</Text>
                      {item.checked && <Check className={classes.check} />}
                    </Group>
                  </UnstyledButton>
                ))}
              </Stack>
            </ScrollArea>
          </Box>
        )}
      </Box>
      {error && (
        <Text size={12} color={theme.colors.red[5]}>
          {error}
        </Text>
      )}
    </>
  );
}

export function Multiselect({
  data,
  value,
  onChange,
  ...props
}: SelectCommonProps & MultiselectProps) {
  const [innerValue, setInnerValue] = useState(value || []);
  const [open, setOpen] = useState(false);

  const update = (payload: string[]) => {
    setInnerValue(payload);
    onChange?.(payload);
  };

  const innerData = useMemo(
    () => data.map((item) => ({ ...item, checked: innerValue.includes(item.value) })),
    [data, innerValue]
  );

  const dataMap = useMemo(
    () =>
      data.reduce<Record<string, string>>(
        (acc, item) => ({
          ...acc,
          [item.value]: item.label,
        }),
        {}
      ),
    [data]
  );

  const displayValue = useMemo(
    () => innerValue.map((code) => dataMap[code]).join(', '),
    [innerValue, dataMap]
  );

  const changeHandler = (payload: string) => {
    let newValue: string[];
    if (innerValue.includes(payload)) newValue = innerValue.filter((item) => item !== payload);
    else newValue = [...innerValue, payload];

    update(newValue);
  };

  const clearHandler = () => {
    setOpen(false);
    update([]);
  };

  return (
    <SelectBase
      {...props}
      data={innerData}
      displayValue={displayValue}
      onChange={changeHandler}
      onClear={clearHandler}
      open={open}
      setOpen={setOpen}
    />
  );
}

function SimpleSelect({
  data,
  onChange,
  onOpen,
  value,
  ...props
}: SelectCommonProps & SimpleselectProps) {
  const [open, setOpen] = useState(false);
  const [innerValue, setInnerValue] = useState(value);

  const update = (payload?: string) => {
    setInnerValue(payload);
    onChange?.(payload);
  };

  const dataMap = useMemo(
    () =>
      data.reduce<Record<string, string>>(
        (acc, item) => ({
          ...acc,
          [item.value]: item.label,
        }),
        {}
      ),
    [data]
  );

  const displayValue = useMemo(() => {
    if (!innerValue) return '';
    return dataMap[innerValue] || '';
  }, [innerValue, dataMap]);

  const innerData = useMemo(
    () => data.map((item) => ({ ...item, checked: innerValue === item.value })),
    [data, innerValue]
  );

  const changeHandler = (payload: string) => {
    let newValue: string | undefined;
    if (payload !== value) newValue = payload;
    update(newValue);
    setOpen(false);
  };

  const clearHandler = () => {
    update();
  };

  useEffect(() => {
    if (open) onOpen?.(open);
  }, [open]);

  return (
    <SelectBase
      {...props}
      open={open}
      setOpen={setOpen}
      displayValue={displayValue}
      data={innerData}
      onChange={changeHandler}
      onClear={clearHandler}
    />
  );
}

interface SelectType {
  (props: SelectCommonProps & MultiselectProps & { multiple: true }): JSX.Element;
  (
    props: SelectCommonProps & SimpleselectProps & { multiple?: false } & { clearable: true }
  ): JSX.Element;
  (
    props: SelectCommonProps & SimpleselectProps & { multiple?: false } & { clearable?: false }
  ): JSX.Element;
}

export const Select: SelectType = (props) => {
  return props.multiple ? <Multiselect {...props} /> : <SimpleSelect {...props} />;
};
